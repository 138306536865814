import { Upload } from 'antd';
import { FileImageOutlined, LoadingOutlined } from 'farmhub-ui-core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FormItem } from 'components';

const CustomUpload = styled(Upload)<{
  width: string;
  height: string;
  isUrl: boolean;
  objectFit?: 'cover' | 'contain';
}>`
  position: relative;
  padding: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  width: ${props => props.width};
  display: flex;
  justify-content: center;

  .avatar-uploader > .ant-upload {
    width: 100%;
    height: ${props => props.height};
    background: #f6fcf9;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: ${props => (props.isUrl ? 'auto' : '100%')};
    height: ${props => props.height};
    border: none;
    background: #f6fcf9;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .ant-upload.ant-upload-select-picture-card img {
    width: 100%;
    height: ${props => props.height};
    background: #f6fcf9;
    object-fit: ${props => props.objectFit};
  }
`;

const Img = styled.img<{
  width: string;
  height: string;
}>`
  width: 100% !important;
  height: ${props => props.height} !important;

  object-fit: cover;
`;

interface UploadSingleProps {
  width: string;
  height: string;
  disabled?: boolean;
  name: string;
  valuePropName: string;
  initUrl?: any;
  objectFit?: 'cover' | 'contain';
  getValueFromEvent: (e: any) => void;
}

const UploadSingle = ({
  disabled,
  width,
  height,
  name,
  valuePropName,
  initUrl,
  objectFit,
  getValueFromEvent = () => console.log('getvalue'),
}: UploadSingleProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');

  const handleChangeImg = ({ file }): void => {
    if (file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const InitData = (): void => {
      if (initUrl) {
        if (typeof initUrl === 'string') {
          setUrl(initUrl);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(initUrl);
          reader.onload = (e: any) => {
            setUrl(e?.target.result);
          };
        }
      } else {
        setUrl('');
      }
    };

    InitData();
  }, [initUrl]);

  return (
    <FormItem
      valuePropName={valuePropName}
      name={name}
      getValueFromEvent={getValueFromEvent}
      marginouter="0px !important"
      margininer="0px !important"
    >
      <CustomUpload
        objectFit={objectFit}
        width={width}
        height={height}
        isUrl={url !== ''}
        disabled={disabled}
        onChange={handleChangeImg}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={file => {
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
              setUrl(e?.target.result);
            };
          }
          return false;
        }}
      >
        {isLoading ? (
          <LoadingOutlined style={{ fontSize: '30px', color: '#2AC17E' }} />
        ) : url ? (
          <Img width={width} height={height} src={url} alt="st" />
        ) : (
          <FileImageOutlined style={{ fontSize: '30px', color: '#2AC17E' }} />
        )}
      </CustomUpload>
    </FormItem>
  );
};

UploadSingle.defaultProps = {
  objectFit: 'contain',
};

export default UploadSingle;
