import {
  CaretDownOutlined,
  Dropdown,
  DropdownPlacement,
  Space,
} from 'farmhub-ui-core';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { entityTypes } from 'commons/data';
import { Action, ActionButton } from 'components';
import { SELECTED_ENTITY } from 'types/localStorage';

interface DropDownActionTableProps {
  id?: string;
  type?: string;
  name?: string;
  status?: number;
  onChangeStatus?: any;
  menu?: any;
}

const DropDownActionTable = ({
  id,
  type,
  name,
  status,
  onChangeStatus,
  menu,
}: DropDownActionTableProps): JSX.Element => {
  const history = useHistory();
  const selectedEntity = localStorage.getItem(SELECTED_ENTITY);
  const { type: entityType } = JSON.parse(selectedEntity as string);
  const entityTypeStr = entityTypes[entityType];

  const defaultDropdownItems = [
    <Action
      onClick={() => {
        history.push(`/${entityTypeStr}/${type}/${id}/detail`);
      }}
      dropdownItemProps={{
        key: '2',
      }}
    >
      Chỉnh sửa
    </Action>,
    <Action
      onClick={() => {
        onChangeStatus(id);
      }}
      dropdownItemProps={{
        key: '3',
      }}
    >
      {status === 0 ? `Ẩn ${name}` : `Hiện ${name}`}
    </Action>,
  ];

  return (
    <Wrapper onClick={(e: any) => e.stopPropagation()}>
      <Dropdown
        containerId="header_main"
        items={menu || defaultDropdownItems}
        placement={DropdownPlacement.BottomLeft}
      >
        <ActionButton width="120px">
          Lựa chọn <Space width={12} /> <CaretDownOutlined />
        </ActionButton>
      </Dropdown>
    </Wrapper>
  );
};

export default DropDownActionTable;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
