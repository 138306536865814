import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FindOptions } from 'api/common';
import { ProductApi } from 'api/product';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProductCensorship {
  id: string;
  code: string;
  displayName: string;
  phoneNumber: string;
  type: number;
  email: string;
  createdAt: string;
}

interface IProductCensorshipState {
  list: IProductCensorship[];
  loading: ILoadingStatus;
  error: unknown;
  savedPagination: any;
  savedList: Array<any>;
}

export const findProductCensorship = createAsyncThunk<any, any>(
  'product/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await ProductApi.findProductsByAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const findOneProduct = createAsyncThunk(
  'product/findOneProduct',
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, option } = params;
      const response = await ProductApi.findOne(id, option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusProduct = createAsyncThunk(
  'product/updateStatusActivatedProduct',
  async (body: any, { rejectWithValue }) => {
    try {
      const { id, status } = body;
      const response = await ProductApi.updateStatus(id, { status });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusActivatedProduct = createAsyncThunk(
  'product/updateStatusActivatedProduct',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.updateStatus(id, { status: 0 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
export const updateStatusDeactivatedProduct = createAsyncThunk(
  'product/updateStatusDeActivatedProduct',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.updateStatus(id, { status: 1 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusRejectProduct = createAsyncThunk(
  'product/updateStatusRejectProduct',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.updateStatus(id, { status: 3 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findProductProvider = createAsyncThunk(
  'product/findProvider',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await ProductApi.findProvider(params);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMultipleStatusActivatedProduct = createAsyncThunk(
  'product/updateMultipleStatusActivatedProduct',
  async (
    { IDs, ownerID }: { IDs: string[]; ownerID: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await ProductApi.updateMultipleStatus({
        productIDs: IDs,
        ownerID,
        status: 0,
      });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllStatusActivatedProduct = createAsyncThunk(
  'product/updateAllStatusActivatedProduct',
  async (ownerID: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.updateAllStatus({ ownerID, status: 0 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllStatusRejectProduct = createAsyncThunk(
  'product/updateAllStatusRejectProduct',
  async (ownerID: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.updateAllStatus({ ownerID, status: 3 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMultipleStatusRejectProduct = createAsyncThunk(
  'product/updateMultipleStatusActivatedProduct',
  async (
    { IDs, ownerID }: { IDs: string[]; ownerID: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await ProductApi.updateMultipleStatus({
        productIDs: IDs,
        ownerID,
        status: 3,
      });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAllEntityProducts = createAsyncThunk(
  'product/deleteAllEntityProducts',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await ProductApi.deleteAllEntityProducts(id);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
  savedPagination: { current: 1, next: 2, prev: 0, take: 10, total: 0 },
  savedList: [],
} as IProductCensorshipState;

const productCensorshipSlice = createSlice({
  name: 'productCensorship',
  initialState,
  reducers: {
    clearProductCensorshipState(state) {
      state.list = [];
      state.loading = LOADING_STATUS.idle;
    },
    updateSavedPaginationProduct(state, action) {
      state.savedPagination = action.payload;
    },
    updateSavedListProduct(state, action) {
      state.savedList = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findProductCensorship.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findProductCensorship.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findProductCensorship.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      })
      .addCase(findOneProduct.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findOneProduct.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = [data];
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findOneProduct.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});
export const getProductCensorshipLoading = (state: RootState): ILoadingStatus =>
  state.productCensorship.loading;

export const getListProductCensorship = (state: RootState): any =>
  state.productCensorship.list;

export const getProductCensorshipById =
  (id: string) =>
  (state: RootState): any =>
    state.productCensorship.list.find(item => item.id === id);

export const {
  clearProductCensorshipState,
  updateSavedPaginationProduct,
  updateSavedListProduct,
} = productCensorshipSlice.actions;
export default productCensorshipSlice.reducer;
