import { Badge } from 'antd';
import { Row, Space } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

interface BadgeTextProps {
  status: 'success' | 'processing' | 'default' | 'error' | 'warning';
  content: React.ReactNode;
  size?: number;
  styles?: any;
}

const BadgeText = ({
  status,
  content,
  size = 12,
  styles,
}: BadgeTextProps): JSX.Element => (
  <Row styles={styles} alignItems="flex-start">
    <CBadge sizeDot={size} status={status} />
    <Space width={2} />
    {content}
  </Row>
);

export default BadgeText;

const CBadge = styled(Badge)<{ sizeDot?: number }>`
  padding-top: 2px;
  .ant-badge-status-processing {
    background-color: ${props => props.theme.colors.primary.main};
  }
  .ant-badge-status-dot {
    width: ${p => p.sizeDot}px;
    height: ${p => p.sizeDot}px;
  }
`;
