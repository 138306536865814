import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface ReviewModalProps {
  isVisible: boolean;
  setIsVisible: (e: boolean) => void;
  url: string;
}

const ReviewModal = ({
  isVisible,
  setIsVisible,
  url,
}: ReviewModalProps): JSX.Element => {
  const handleCloseModal = (): void => {
    const video: any = document.getElementById('videoPreview');
    video.pause();
    video.currentTime = 0;
    setIsVisible(false);
  };
  return (
    <VideoModal
      maskClosable={false}
      centered
      width="700px"
      visible={isVisible}
      onCancel={handleCloseModal}
    >
      <WrapperContent>
        <video id="videoPreview" width={700} height={500} controls autoPlay>
          <track kind="captions" />
          <source src={url} type="video/mp4" />
        </video>
      </WrapperContent>
    </VideoModal>
  );
};

export default ReviewModal;

const VideoModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px !important;

    .ant-modal-body {
      padding: 0px;
    }

    .ant-modal-footer {
      display: none;
    }
  }
`;

const WrapperContent = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;
`;
