import { Row, Space } from 'farmhub-ui-core';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
// import { EditableTagGroup } from 'components';

interface UserCardProps {
  dot?: JSX.Element;
  avatar?: JSX.Element;
  name: JSX.Element;
  info?: JSX.Element;
  link?: string;
  redirectLink?: string;
  width?: number;
  horizontalSpace?: number;
}

const UserCard = ({
  width,
  avatar,
  name,
  info,
  link,
  redirectLink,
  dot,
  horizontalSpace,
}: UserCardProps): JSX.Element => {
  const history = useHistory();
  const handleRedirect = (): void => {
    const win: any = window.open(redirectLink, '_blank');
    win.focus();
  };

  return (
    <Wrapper
      onClick={() =>
        (link && history.push(link)) || (redirectLink && handleRedirect())
      }
    >
      {avatar}
      <DivInfo
        width={width || (avatar ? 180 : 240)}
        horizontalSpace={horizontalSpace}
      >
        <Row>
          {dot}
          {dot && <Space width={6} />}
          {name}
        </Row>
        {/* <Space height={middleSpace} /> */}
        {info}
        {/* {tag && <EditableTagGroup tags={tag} />} */}
      </DivInfo>
    </Wrapper>
  );
};

export default UserCard;

const Wrapper = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  height: auto;
  cursor: pointer;
  flex-shrink: 0;
`;

const DivInfo = styled.div<{ width?: number; horizontalSpace?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${p => p.width}px;
  height: auto;
  margin-left: ${p => p.horizontalSpace ?? '12'}px;
  flex-shrink: 0;
`;
