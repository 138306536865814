import React from 'react';

import { IconProps } from './Icon';

export const CoBuyIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 335.436 335.436"
  >
    <g>
      <circle cx="255.436" cy="110.304" r="44.138" />
      <path d="M100.725,187.928C45.096,187.928,0,233.024,0,288.653h201.45C201.45,233.024,156.354,187.928,100.725,187.928z" />
      <path
        d="M255.436,184.442c-20.787,0-39.711,7.938-53.931,20.938c14.212,17.138,23.672,37.368,27.712,59.062h106.219
       C335.436,220.259,299.618,184.442,255.436,184.442z"
      />
      <circle cx="100.725" cy="102.355" r="55.573" />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
