/* eslint-disable react/no-array-index-key */
import { Input } from 'antd';
import { Button, ButtonType, Icon, Tag, Tooltip } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { TinyText } from 'components';
import { PlusIcon } from 'components/Icon';
import { truncateWords } from 'utils/function';

interface EditableTagGroupProps {
  id?: string;
  tags: any[];
  editAble?: boolean;
  type?: 'PRODUCT' | 'ORDER';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableTagGroup = ({
  id,
  tags,
  editAble,
  type = 'PRODUCT',
}: EditableTagGroupProps): JSX.Element => {
  const inputRef: any = React.useRef(null);
  const editInputRef: any = React.useRef(null);
  const [currentTags] = React.useState(tags);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [currentInputValue, setCurrentInputValue] = React.useState('');
  const [editInputIndex, setEditInputIndex] = React.useState(-1);
  const [editInputValue, setEditInputValue] = React.useState('');

  const handleAddTag = (): void => {
    setIsCreating(true);
  };

  const handleEditInputChange = (value: string): void => {
    setEditInputValue(value);
  };

  React.useEffect(() => {
    inputRef.current?.focus();
  }, [isCreating]);

  React.useEffect(() => {
    editInputRef.current?.focus();
  }, [isEditing]);

  return (
    <Wrapper onClick={e => e.stopPropagation()}>
      {(currentTags || []).map((tag: any, index: number) => {
        if (editInputIndex === index && editAble) {
          return (
            <TagInput
              width={82}
              key={index}
              ref={editInputRef}
              value={editInputValue}
              onChange={e => handleEditInputChange(e.target.value)}
              // onBlur={handleEditInputConfirm}
              // onPressEnter={handleEditInputConfirm}
              style={{ marginBottom: 6, marginRight: 6 }}
            />
          );
        }

        return (
          <Tooltip key={tag.id} title={tag.content}>
            <Tag
              key={tag.id}
              closable={editAble}
              color={tag.color?.code}
              // onClose={() => handleDelete(tag.id)}
              styles={{ 'margin-bottom': '6px' }}
            >
              <span
                onDoubleClick={(e: any) => {
                  setIsEditing(true);
                  setEditInputIndex(index);
                  setEditInputValue(tag.content);
                  e.preventDefault();
                }}
                title={tag.content}
              >
                {truncateWords(tag.content, 2)}
              </span>
            </Tag>
          </Tooltip>
        );
      })}
      {isCreating ? (
        <TagInput
          width={72}
          value={currentInputValue}
          onChange={e => setCurrentInputValue(e.target.value)}
          ref={inputRef}
          onBlur={() => setIsCreating(false)}
          // onPressEnter={handleInputConfirm}
        />
      ) : (
        editAble && (
          <Button
            styles={{
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
            }}
            width="auto"
            height="22px"
            onClick={handleAddTag}
            type={ButtonType.Dashed}
            icon={{
              children: (
                <Icon>
                  <PlusIcon width={8} height={8} />
                </Icon>
              ),
            }}
          >
            <TinyText>Thêm</TinyText>
          </Button>
        )
      )}
    </Wrapper>
  );
};

export default EditableTagGroup;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TagInput = styled(Input)<{ width: number }>`
  width: ${p => p.width}px;
  height: 22px !important;
  font-size: 12px;
  font-weight: ${props => props.theme.font.weight.regular} !important;
`;
