import React from 'react';

import { IconProps } from './Icon';

export const CopyIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m116.59.099-63.536 63.536h63.536z" />
      <path d="m383.843 0h-237.253v93.635h-93.635v343.163h330.889v-436.798zm-61.787 289.838h-207.313v-30h207.313zm0-63.734h-207.313v-30h207.313zm0-63.735h-207.313v-30h207.313z" />
      <path d="m413.843 75.202v391.596h-285.686v45.202h330.888v-436.798z" />
    </g>
  </svg>
);
