import React from 'react';

import { IconProps } from './Icon';

export const FarmerColorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 420.592 420.592"
  >
    <g>
      <g>
        <path
          style={{ fill: '#FFD24F' }}
          d="M289.26,69.466c-7.677-36.192-29.611-60.868-39.482-65.803c-22.209-11.104-29.611,6.58-39.482,6.58
         s-17.273-17.685-39.482-6.58c-9.87,4.935-31.805,29.611-39.482,65.803s78.964,6.58,78.964,6.58S296.937,105.658,289.26,69.466z"
        />
      </g>
      <g>
        <ellipse
          style={{ fill: '#FFBE1E' }}
          cx="210.296"
          cy="121.736"
          rx="151.347"
          ry="55.933"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFE1B2' }}
          d="M121.247,162.233c-9.864-2.468-16.326,2.805-16.326,13.161c0,13.709,13.265,47.296,27.21,26.321
         S121.247,162.233,121.247,162.233z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFE1B2' }}
          d="M297.67,162.233c10.767-2.468,17.821,2.805,17.821,13.161c0,13.709-14.479,47.296-29.701,26.321
         S297.67,162.233,297.67,162.233z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFD7A3' }}
          d="M138.003,281.887c14.716-9.475,13.07-37.675,13.07-44.255h118.446c0,6.58-1.825,34.78,12.89,44.255
         l-72.113,80.771L138.003,281.887z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FDC88E' }}
          d="M256.358,310.016l26.051-28.129c-14.716-9.474-12.89-37.675-12.89-44.255H151.073
         C159.571,262.065,215.78,303.984,256.358,310.016z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FF6953' }}
          d="M361.643,420.592c10.903,0,19.741-8.838,19.741-19.741v-39.208
         c0-28.954-22.562-59.864-50.138-68.687l-41.168-13.173c-22.149,16.936-53.802,40.039-79.782,40.039s-57.633-23.103-79.782-40.039
         l-41.168,13.173c-27.576,8.823-50.138,39.733-50.138,68.687v39.208c0,10.903,8.838,19.741,19.741,19.741H361.643z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#E95F43' }}
          d="M380.955,404.922H370.74v-13.835h10.644v-10.074H370.74v-13.835h10.644v-5.536
         c0-1.508-0.097-3.023-0.217-4.538H370.74V343.27h7.738c-1.041-3.417-2.337-6.785-3.884-10.074h-3.854v-7.216
         c-3.667-6.106-8.144-11.757-13.224-16.693h-10.684v-8.691c-4.914-3.274-10.146-5.9-15.585-7.641l-23.681-7.578h-8.552v-2.737
         l-8.935-2.859c-0.37,0.283-0.763,0.58-1.139,0.866v4.73h-6.266c-5.527,4.126-11.465,8.395-17.643,12.487v11.422h-13.835v-2.902
         c-11.606,6.535-23.519,11.538-34.666,12.975h0.684v13.835h-13.835v-13.835h0.684c-11.148-1.437-23.061-6.441-34.667-12.976v2.902
         h-13.835v-11.422c-6.178-4.092-12.116-8.361-17.643-12.487h-6.266v-4.73c-0.375-0.286-0.769-0.583-1.139-0.866l-8.935,2.859v2.737
         h-8.552l-23.681,7.578c-5.439,1.74-10.671,4.366-15.585,7.641v8.691H63.077c-5.08,4.936-9.557,10.586-13.224,16.693v7.216h-3.854
         c-1.546,3.289-2.842,6.656-3.884,10.074h7.738v13.835H39.425c-0.12,1.515-0.217,3.03-0.217,4.538v5.536h10.644v13.835H39.208
         v10.074h10.644v13.835H39.638c0.822,3.915,2.811,7.388,5.577,10.074h4.637v3.278c2.734,1.431,5.797,2.318,9.097,2.318h0.978
         v-5.596h13.835v5.596h10.075v-5.596H97.67v5.596h10.074v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.074v-5.596h13.835
         v5.596h10.075v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.074
         v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.074v-5.596h13.835v5.596h10.075v-5.596h13.835v5.596h0.978
         c3.3,0,6.363-0.887,9.097-2.318v-3.278h4.637C378.143,412.31,380.133,408.837,380.955,404.922z M73.761,404.922H59.926v-13.835
         h13.835V404.922z M73.761,381.014H59.926v-13.835h13.835V381.014z M73.761,357.105H59.926V343.27h13.835V357.105z M73.761,333.196
         H59.926v-13.835h13.835V333.196z M97.67,404.922H83.835v-13.835H97.67V404.922z M97.67,381.014H83.835v-13.835H97.67V381.014z
          M97.67,357.105H83.835V343.27H97.67V357.105z M97.67,333.196H83.835v-13.835H97.67V333.196z M97.67,309.287H83.835v-13.835H97.67
         V309.287z M121.579,404.922h-13.835v-13.835h13.835V404.922z M121.579,381.014h-13.835v-13.835h13.835V381.014z M121.579,357.105
         h-13.835V343.27h13.835V357.105z M121.579,333.196h-13.835v-13.835h13.835V333.196z M121.579,309.287h-13.835v-13.835h13.835
         V309.287z M145.487,404.922h-13.835v-13.835h13.835V404.922z M145.487,381.014h-13.835v-13.835h13.835V381.014z M145.487,357.105
         h-13.835V343.27h13.835V357.105z M145.487,333.196h-13.835v-13.835h13.835V333.196z M145.487,309.287h-13.835v-13.835h13.835
         V309.287z M169.396,404.922h-13.835v-13.835h13.835V404.922z M169.396,381.014h-13.835v-13.835h13.835V381.014z M169.396,357.105
         h-13.835V343.27h13.835V357.105z M169.396,333.196h-13.835v-13.835h13.835V333.196z M193.305,404.922H179.47v-13.835h13.835
         V404.922z M193.305,381.014H179.47v-13.835h13.835V381.014z M193.305,357.105H179.47V343.27h13.835V357.105z M193.305,333.196
         H179.47v-13.835h13.835V333.196z M217.213,404.922h-13.835v-13.835h13.835V404.922z M217.213,381.014h-13.835v-13.835h13.835
         V381.014z M217.213,357.105h-13.835V343.27h13.835V357.105z M241.122,404.922h-13.835v-13.835h13.835V404.922z M241.122,381.014
         h-13.835v-13.835h13.835V381.014z M241.122,357.105h-13.835V343.27h13.835V357.105z M241.122,333.196h-13.835v-13.835h13.835
         V333.196z M265.031,404.922h-13.835v-13.835h13.835V404.922z M265.031,381.014h-13.835v-13.835h13.835V381.014z M265.031,357.105
         h-13.835V343.27h13.835V357.105z M265.031,333.196h-13.835v-13.835h13.835V333.196z M288.94,404.922h-13.835v-13.835h13.835
         V404.922z M288.94,381.014h-13.835v-13.835h13.835V381.014z M288.94,357.105h-13.835V343.27h13.835V357.105z M288.94,333.196
         h-13.835v-13.835h13.835V333.196z M288.94,309.287h-13.835v-13.835h13.835V309.287z M312.848,404.922h-13.835v-13.835h13.835
         V404.922z M312.848,381.014h-13.835v-13.835h13.835V381.014z M312.848,357.105h-13.835V343.27h13.835V357.105z M312.848,333.196
         h-13.835v-13.835h13.835V333.196z M312.848,309.287h-13.835v-13.835h13.835V309.287z M336.757,404.922h-13.835v-13.835h13.835
         V404.922z M336.757,381.014h-13.835v-13.835h13.835V381.014z M336.757,357.105h-13.835V343.27h13.835V357.105z M336.757,333.196
         h-13.835v-13.835h13.835V333.196z M336.757,309.287h-13.835v-13.835h13.835V309.287z M360.666,404.922h-13.835v-13.835h13.835
         V404.922z M360.666,381.014h-13.835v-13.835h13.835V381.014z M360.666,357.105h-13.835V343.27h13.835V357.105z M360.666,333.196
         h-13.835v-13.835h13.835V333.196z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFBE1E' }}
          d="M210.296,319.821l-27.456,27.456c-2.859,2.859-7.596,2.487-9.975-0.783l-48.113-66.156
         l15.326-15.326c2.475-2.475,6.454-2.579,9.055-0.238L210.296,319.821z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFBE1E' }}
          d="M210.296,319.821l27.456,27.456c2.859,2.859,7.596,2.487,9.975-0.783l48.113-66.156l-15.326-15.326
         c-2.475-2.475-6.454-2.579-9.055-0.238L210.296,319.821z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFE1B2' }}
          d="M122.275,117.737c-1.539,9.309-1.623,20.502-1.623,33.803c0,52.696,29.526,118.446,89.645,118.446
         s89.645-65.75,89.645-118.446c0-13.302-0.084-24.494-1.623-33.803c-24.117-8.127-54.708-13-88.021-13
         S146.391,109.609,122.275,117.737z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#A7795E' }}
          d="M300.278,128.382c-5.267-6.759-10.916-13.704-10.916-13.704v6.58
         c1.632,59.634-19.588,85.544-19.588,85.544l-27.038,3.027c-21.086,2.361-42.366,2.361-63.452,0l-27.038-3.027
         c0,0-21.22-25.91-19.588-85.544v-6.58c0,0-5.649,6.944-10.916,13.704c-1.372,5.869-3.571,12.25-3.571,19.254
         c0,52.696,31.536,128.671,92.839,128.671s91.41-75.975,91.41-128.671C302.421,140.632,301.651,134.251,300.278,128.382z
          M191.422,222.431h39.176c5.371,0,9.976,3.273,11.982,7.944c-7.233,3.172-18.335,5.216-30.849,5.216
         c-13.213,0-24.826-2.284-31.995-5.765C181.862,225.462,186.272,222.431,191.422,222.431z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#543D38' }}
          d="M213.792,216.577c0-8.689-8.431-10.656-13.983-8.55c-10.179,3.861-21.386,14.632-30.845,7.971
         c0,0,3.29,13.903,8.841,13.903C188.909,229.9,213.792,231.349,213.792,216.577z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#543D38' }}
          d="M211.131,216.577c0-8.689,8.431-10.656,13.983-8.55c10.179,3.861,21.386,14.632,30.845,7.971
         c0,0-3.29,13.903-8.841,13.903C236.014,229.9,211.131,231.349,211.131,216.577z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#C55F54' }}
          d="M322.162,289.191l-26.321-8.423v80.969c0,3.012-1.999,5.675-4.923,6.395
         c-22.275,5.486-50.216,8.77-80.621,8.77s-58.346-3.284-80.621-8.77c-2.925-0.72-4.923-3.383-4.923-6.395v-80.969l-26.321,8.423
         v131.401h223.731V289.191z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#543D38' }}
          d="M118.613,163.084l17.769,3.276c0,0,6.939-38.912,10.659-55.078
         c-9.705,1.937-18.858,4.267-27.202,7.003c-1.582,9.309-1.668,20.502-1.668,33.803C118.172,155.7,118.328,159.376,118.613,163.084z
         "
        />
      </g>
      <g>
        <path
          style={{ fill: '#543D38' }}
          d="M301.979,163.084l-17.769,3.276c0,0-6.939-38.912-10.659-55.078
         c9.705,1.937,18.858,4.267,27.202,7.003c1.582,9.309,1.668,20.502,1.668,33.803C302.421,155.7,302.264,159.376,301.979,163.084z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#543D38' }}
          d="M248.956,127.357l15.789-17.973c-16.762-2.964-35.121-4.647-54.449-4.647
         c-11.763,0-23.16,0.637-34.079,1.781C186.539,105.812,227.158,111.282,248.956,127.357z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
