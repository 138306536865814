import { Row, Space, Text, TextProps } from 'farmhub-ui-core';
import * as React from 'react';

import * as S from './styles';

const Header = ({
  title,
  action,
  titleProps = { color: 'dark', weight: 'bold', size: 'lg' },
  space = 24,
}: HeaderProps): JSX.Element => {
  let justifyContent = 'flex-end';
  if (!title && !action) {
    return <></>;
  }
  if (title && action) {
    justifyContent = 'space-between';
  } else if (title) {
    justifyContent = 'flex-start';
  }

  return (
    <>
      <Row justifyContent={justifyContent}>
        {title && !React.isValidElement(title) && (
          <Text color="secondary" {...titleProps}>
            {title}
          </Text>
        )}
        {title && React.isValidElement(title) && title}
        {action}
      </Row>
      <Space height={space} />
    </>
  );
};

const Section = ({
  children,
  headerProps,
  ...props
}: SectionProps): JSX.Element => (
  <S.Wrapper padding={props.wrapperPadding} {...props}>
    <Header {...headerProps} />
    <S.Content padding={props.contentPadding}>{children}</S.Content>
  </S.Wrapper>
);

export default Section;

export interface HeaderProps {
  title?: string | React.ReactNode;
  titleProps?: TextProps;
  action?: React.ReactNode;
  space?: number;
}

export interface SectionProps {
  id?: string;
  headerProps?: HeaderProps;
  height?: string;
  width?: string;
  wrapperPadding?: string;
  contentPadding?: string;
  styles?: any;
  children: React.ReactNode;
}
