import styled from 'styled-components';

export const WrapperFilter = styled.div<{
  width: string;
  isDisplayFilter: boolean;
  padding?: string;
  borderBottom?: boolean;
  background: string;
}>`
  justify-content: space-between;
  display: ${p => (p.isDisplayFilter ? 'flex' : 'none')};
  width: ${p => p.width};
  height: auto;
  background: ${props => props.background};
  align-items: center;
  border-bottom: ${p =>
    p.borderBottom ? `1px solid ${p.theme.colors.border.light}` : ''};
  padding: ${p => p.padding || `${p.theme.app.padding.vertical} 0px`};

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      height: 23px;
    }
  }
`;

export const WrapperTagsFilter = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 0px 18px 0px;
  border-bottom: ${p =>
    p.borderBottom ? `1px solid p.theme.colors.border.light` : ''};
`;

export const WrapperStatus = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
  > div {
    padding-right: 23px;
  }
`;

export const WrapperIcon = styled.span`
  position: absolute;
  right: 5px;
  font-size: ${props => props.theme.font.size.tiny};
  color: #575757;
  &:hover {
    cursor: pointer;
  }
`;
