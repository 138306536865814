import axios from 'axios';
import queryString from 'query-string';

import { env } from 'env';

// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs
const { CancelToken } = axios;
export const sourceCancel = CancelToken.source();

const baseURLUpload = `${env.hubtech.uploadService}`;

// upload service
const uploadService = axios.create({
  baseURL: baseURLUpload,
  withCredentials: true,
  cancelToken: sourceCancel.token,
  headers: {
    accept: 'application/json',
    'Content-type': 'multipart/form-data',
  },
  paramsSerializer: params => queryString.stringify(params),
});

uploadService.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    // Handle errors
    throw error;
  }
);

export const getTokenBearer = () =>
  uploadService.defaults.headers.common.Authorization;

export const updateTokenBearer = (token: string) => {
  uploadService.defaults.headers.common.Authorization = token;
};

export default uploadService;
