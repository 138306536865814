/* eslint-disable no-useless-catch */
import { serialize } from '../utils';
import { FindOptions } from './common';
import { entityService } from './services';
// #endregion Local Imports

const pathname = '/packages';

export const PackageAPI = {
  findMasterData: async () => {
    const url = `${pathname}/master-data`;

    try {
      return await entityService.get(url);
    } catch (error) {
      throw error;
    }
  },
};
