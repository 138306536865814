import { Upload } from 'antd';
import { FileImageOutlined, Row, StarFilled, Text } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { FormItem } from 'components';

const CustomUploadList = styled(Upload)`
  width: 100%;
  height: auto;
  padding: 12px 12px 4px;
  border: 1px solid #f1f1f1;
  border-radius: 6px;

  position: relative;
  .avatar-uploader > .ant-upload {
    width: 104px;
    height: 104px;
    border-radius: 6px;
    background: #f6fcf9;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    border-radius: 6px;
    border: none;
    background: #f6fcf9;
  }

  .ant-upload.ant-upload-select-picture-card img {
    width: 104px;
    height: 104px;
    border-radius: 6px;
    background: #f6fcf9;
  }
`;

const uploadButton = (
  <div>
    <FileImageOutlined style={{ fontSize: '30px', color: '#2AC17E' }} />
  </div>
);

interface UploadListProps {
  valuePropName: string;
  name: string;
  getValueFromEvent: (e: any) => void;
  count: number;
  limit?: number | boolean;
  onRemove?: (e: any) => void;
}

const UploadList = ({
  valuePropName,
  name,
  getValueFromEvent,
  count,
  limit = 7,
  onRemove,
}: UploadListProps): JSX.Element => {
  const handleRemove = (e: any): void => {
    if (e.originFileObj) {
      return;
    }
    onRemove && onRemove(e.uid);
  };

  return (
    <>
      <FormItem
        valuePropName={valuePropName}
        name={name}
        getValueFromEvent={getValueFromEvent}
        marginouter="0px !important"
        margininer="0px !important"
      >
        <CustomUploadList
          name="files"
          listType="picture-card"
          showUploadList
          multiple
          onRemove={handleRemove}
          beforeUpload={(file, fileList) => {
            if (fileList) {
              fileList.forEach(filename => {
                const reader = new FileReader();
                reader.readAsDataURL(filename);
              });
            }
            return false;
          }}
        >
          {limit ? (count >= limit ? null : uploadButton) : uploadButton}
        </CustomUploadList>
      </FormItem>
      {limit && (
        <Row alignItems="center" styles={{ 'margin-top': '12px' }}>
          <StarFilled style={{ color: '#FF6347', marginRight: '6px' }} />
          <Text size="xxs">{`Tối đa ${limit} ảnh`}</Text>
        </Row>
      )}
      <Row alignItems="center" styles={{ 'margin-top': '12px' }}>
        <StarFilled style={{ color: '#FF6347', marginRight: '6px' }} />
        <Text size="xxs">Dung lượng tối đa: 2MB, dài: 720px, rộng: 720px.</Text>
      </Row>
    </>
  );
};

export default UploadList;
