import { serialize } from '../utils';
import googleService from './services/google';
// #endregion Local Imports

export const GoogleApi = {
  getLocationInfo: async (option: any) => {
    let url = '/geocode/json';
    const query = serialize(option);
    url += query;

    try {
      const response = await googleService.get(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
