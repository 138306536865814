import React from 'react';

import { IconProps } from './Icon';

export const TravelIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M487.663,282.767c-32.447-32.447-85.054-32.447-117.501,0c-26.833,26.833-32.076,68.438-12.738,101.089l53.549,90.417
         H105.657c-26.329,0-47.749-21.421-47.749-47.75c0-26.329,21.42-47.749,47.749-47.749h143.589
         c42.871,0,77.749-34.878,77.749-77.749c0-42.871-34.878-77.749-77.749-77.749H101.027l53.549-90.416
         c19.338-32.651,14.095-74.256-12.738-101.089c-32.447-32.447-85.054-32.447-117.501,0C-2.496,58.603-7.739,100.208,11.599,132.859
         l71.489,120.708l0.172-0.291h165.986c26.329,0,47.749,21.42,47.749,47.749c0,26.329-21.42,47.749-47.749,47.749H105.657
         c-42.871,0-77.749,34.878-77.749,77.749c0,42.871,34.878,77.75,77.749,77.75H428.74l0.172,0.291l71.489-120.707
         C519.739,351.205,514.496,309.6,487.663,282.767z M83.087,116.713c-14.442,0-26.191-11.749-26.191-26.191
         c0-14.442,11.749-26.191,26.191-26.191c14.442,0,26.191,11.749,26.191,26.191C109.278,104.964,97.529,116.713,83.087,116.713z
          M427.79,367.71c-14.442,0-26.191-11.749-26.191-26.191c0-14.442,11.749-26.191,26.191-26.191
         c14.442,0,26.191,11.749,26.191,26.191S442.232,367.71,427.79,367.71z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
