// api/axiosClient.js
import axios from 'axios';
import queryString from 'query-string';

import { env } from 'env';

// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs
const { CancelToken } = axios;
export const sourceCancel = CancelToken.source();

const baseURLIdentity = `${env.hubtech.identityService}/api/v2`;

// farm service
const identityService = axios.create({
  baseURL: baseURLIdentity,
  withCredentials: true,
  cancelToken: sourceCancel.token,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: params => queryString.stringify(params),
});

const loadJWTToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    identityService.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

loadJWTToken();

identityService.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    // Handle errors
    throw error;
  }
);

export const getTokenBearer = (): string =>
  identityService.defaults.headers.common.Authorization;

export const updateTokenBearer = (token: string): void => {
  identityService.defaults.headers.common.Authorization = token;
};

export default identityService;
