/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Layout } from 'antd';
import { Row, Space, Text } from 'farmhub-ui-core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { RootState } from 'app';
import logoBeta from 'assets/images/logo_beta.png';
import logoFullTextBeta from 'assets/images/logo_full_text_beta.png';
import { HeaderMainNavbar } from 'components';
import { updateMenuToggle, logout } from 'slice';
import { GlobalStyle } from 'styled';
import { LOGO } from 'utils';

import { BackMenu, MenuItem, MenuTitle } from './components';
import * as S from './Styles';
import { dataMenu } from './utils/function';
import { routes } from './utils/route';

export function App(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isToggle = useSelector((state: RootState) => state.account.isToggle);
  const user = useSelector((state: RootState) => state.auth.item);

  const [menuActive, setMenuActive] = React.useState('');
  const [currentDataMenu, setCurrentDataMenu] = React.useState<any>(dataMenu());

  const handleLogoutUser = async (): Promise<void> => {
    history.push('/login');
    await dispatch(logout());
  };

  const handleSelectMenu = (item: any): void => {
    history.push(item.link);
    if (item.subMenu.length === 0) {
      setMenuActive(item.link);
      return;
    }

    setMenuActive(item.link);
    setCurrentDataMenu(item.subMenu);
  };

  const formatPath = (path: string): string => path;

  const handleSetMenuActive = (path: string): void => {
    const newPath = formatPath(path);
    if (newPath === `/`) {
      setMenuActive(path);
    } else {
      setMenuActive(newPath);
    }
  };

  const handleBackMenu = (layer: number): void => {
    if (layer === 3) {
      history.goBack();
      return;
    }
    history.push(`/`);
  };

  const renderMenu = (): any[] => {
    const result: any = [];
    let menuFilter: any;
    if (currentDataMenu[0].layer !== 1) {
      result.push(
        <BackMenu
          isToggle={isToggle}
          onClick={() => handleBackMenu(currentDataMenu[0].layer)}
        />
      );
    }
    for (let i = 0; i < currentDataMenu.length; i += 1) {
      if (isToggle) {
        result.push(
          <>
            {i !== 0 && <Space height={12} />}
            <MenuTitle title={currentDataMenu[i].title} />
          </>
        );
      }
      for (let j = 0; j < currentDataMenu[i].menu?.length; j += 1) {
        const menuItem = currentDataMenu[i].menu[j];
        if (!menuItem.hidding) {
          result.push(
            <MenuItem
              isToggle={isToggle}
              menuItem={menuItem}
              menuActive={menuActive}
              onClick={() => handleSelectMenu(menuItem)}
            />
          );
        }
      }
    }
    if (menuFilter) {
      result.push(menuFilter);
    }

    return result;
  };

  const handleWindowResize = (): void => {
    if (window.innerWidth >= 1450) {
      dispatch(updateMenuToggle(true));
    } else {
      dispatch(updateMenuToggle(false));
    }
  };

  React.useLayoutEffect(() => {
    handleWindowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const initMenuData = (initDataMenu: any): void => {
      const currentPath = location.pathname;
      handleSetMenuActive(currentPath);
      for (let i = 0; i < initDataMenu.length; i += 1) {
        for (let j = 0; j < initDataMenu[i].menu.length; j += 1) {
          const menuItem = initDataMenu[i].menu[j];
          if (menuItem.link.includes(formatPath(currentPath))) {
            if (menuItem.subMenu.length === 0) {
              setCurrentDataMenu(initDataMenu);
              return;
            }
            setCurrentDataMenu(menuItem.subMenu);
            return;
          }
          initMenuData(menuItem.subMenu);
        }
      }
    };

    const initDataMenu = dataMenu();
    initMenuData(initDataMenu);
  }, [location]); //eslint-disable-line

  React.useEffect(() => {
    if (location.pathname === '/') {
      history.push(`/`);
    }
  }, [history, location.pathname]);

  const ref: any = React.useRef(null);

  return (
    <S.Wrapper>
      {/* <Tool /> */}
      <S.WrapperMenu ref={ref} isToggle={isToggle}>
        <S.HeadWrapper>
          <Space height={12} />
          <Row
            width="fit-content"
            onClick={() => {
              history.push(`/`);
            }}
            styles={{ gap: 6, width: '100%' }}
            justifyContent={isToggle ? 'flex-start' : 'center'}
          >
            <S.Image src={LOGO} />
            {isToggle && (
              <Row width="fit-content" styles={{ gap: 2 }}>
                <Text
                  size="md"
                  weight="extraBold"
                  color="dark"
                  lineNumber={1}
                  styles={{ letterSpacing: '2px' }}
                >
                  HUB
                </Text>
                <Space width={4} />
                <Text
                  size="md"
                  weight="extraBold"
                  color="main"
                  lineNumber={1}
                  styles={{ letterSpacing: '2px' }}
                >
                  SUPER ADMIN
                </Text>
              </Row>
            )}
          </Row>
        </S.HeadWrapper>
        <Space height={12} />
        <S.ContentMenu>
          <S.CMenu>{renderMenu()}</S.CMenu>
        </S.ContentMenu>
      </S.WrapperMenu>
      <Layout style={{ background: '#F8F8F8' }}>
        <HeaderMainNavbar user={user} onLogoutUser={handleLogoutUser} />
        <S.WrapperContent>
          <S.Content width="100%">
            <Switch>
              {routes().map((item: any) => (
                <Route
                  key={item.path}
                  exact={item.exact}
                  path={item.path}
                  component={item.component}
                  render={item.render}
                />
              ))}
            </Switch>
          </S.Content>
        </S.WrapperContent>
      </Layout>
      <GlobalStyle />
    </S.Wrapper>
  );
}
