import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from 'farmhub-ui-core';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { css } from 'styled-components';

import * as S from './styles';

export enum SwitchSize {
  Default = 'default',
  Small = 'small',
}

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

export const DragHandle = SortableHandle(
  ({ disabled = false }: { disabled?: boolean }) => (
    <MenuOutlined
      style={{
        cursor: disabled ? 'not-allowed' : 'grab',
        color: '#999',
      }}
    />
  )
);

const Table = ({
  switchSize = SwitchSize.Default,
  pagination,
  columns,
  dataSource,
  typeSelection,
  onRow,
  draggable,
  callbackOnSortEnd,
  callbackOnUpdateBeforeSortStart,
  id,
  showCheckAll = true,
  showHeader = true,
  isSheetTable = false,
  ...props
}: TableProps): JSX.Element => {
  const onSortEnd = ({ oldIndex, newIndex }): void => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      if (callbackOnSortEnd) {
        callbackOnSortEnd(newData);
      }
    }
  };

  const onUpdateBeforeSortStart = (): void => {
    if (callbackOnUpdateBeforeSortStart) {
      callbackOnUpdateBeforeSortStart();
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      updateBeforeSortStart={onUpdateBeforeSortStart}
      {...(draggable && {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        },
      })}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    let index = -1;
    if (restProps['data-row-key'] != undefined) {
      index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    }
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <S.Table
      showHeader={showHeader}
      typeSelection={typeSelection}
      columns={columns}
      dataSource={dataSource}
      switchSize={switchSize}
      pagination={pagination}
      id={id}
      showCheckAll={showCheckAll}
      {...(draggable && {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        },
      })}
      styles={isSheetTable ? sheetTableStyles : ''}
      {...props}
    />
  );
};

export default Table;

export interface TableProps {
  showHeader?: boolean;
  typeSelection?: string;
  loading?: boolean;
  switchSize?: SwitchSize;
  styles?: any;
  columns: any;
  dataSource: any;
  pagination?: any;
  bordered?: boolean;
  rowSelection?: any;
  components?: TableComponents;
  columnWidth?: string | number;
  expandable?: any;
  locale?: any;
  sticky?: boolean;
  id?: string;
  scroll?: {
    scrollToFirstRowOnChange?: boolean;
    x?: string | number | true;
    y?: string | number;
  };
  onRow?: (e: any, index: number) => void;
  onChange?: (e: any) => void;
  draggable?: boolean;
  callbackOnSortEnd?: (data: any[]) => void;
  callbackOnUpdateBeforeSortStart?: (data?: any) => void;
  rowKey?: any;
  showCheckAll?: boolean;
  isSheetTable?: boolean;
  summary?: any;
  marginPagination?: string;
}

export interface TableComponents {
  table?: any;
  header?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
  body?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
}

const sheetTableStyles = css`
  width: 100%;

  .column-invitation {
    width: 100%;
  }

  .ant-table-container {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-left: 0px solid #9f9f9f !important;
  }

  table {
    border-top: 0px solid #9f9f9f !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;

    &:first-child {
      border-left: 1px solid #000000 !important;
    }
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #ffffff !important;

    font-weight: 600;

    &:first-child {
      border-top-left-radius: 0px !important;
      border-left: 1px solid #000000 !important;
    }

    &:last-child {
      border-top-right-radius: 0px !important;
    }
  }

  .ant-table-summary > tr > td {
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-left: 1px solid #ffffff !important;

    font-weight: 800;

    background-color: #e1e1e1;

    color: #000000;

    &:first-child {
      border-top-left-radius: 0px !important;
      border-left: 1px solid #000000 !important;
    }

    &:last-child {
      border-top-right-radius: 0px !important;
    }
  }

  .ant-table-cell {
    font-weight: ${props => props.theme.font.weight.bold} !important;
    padding: 4px !important;
  }

  .ant-table-tbody {
    tr {
      position: relative;

      .actions-row {
        position: absolute;
        bottom: 0;
        left: 0;

        transform: translate(-1px, 100%);
        display: flex;
        z-index: -1;
      }

      &:hover {
        .actions-row {
          display: flex;
          z-index: 10;
        }
      }

      &:has(td) {
        &:has(.row-selected) {
          td {
            background: #b7e4be !important;
          }
        }
      }
    }

    tr:nth-child(2n + 0) {
      td {
        background-color: #f8f8f8;
      }
    }

    tr:nth-child(2n + 1) {
      td {
        background-color: #ffffff;
      }
    }
  }
`;
