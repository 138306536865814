import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${p => (p.height ? p.height : '100%')};
`;

const Container = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  -webkit-animation: rotation 1s infinite;
  animation: rotation 1s infinite;

  .shape {
    position: absolute;
    width: 14px;
    height: 12px;
    border-radius: 50%;
    transform-origin: center center;
  }

  .shape.shape-1 {
    background-color: #4285f4;
    transform: rotate(0deg);
  }
  .shape.shape-2 {
    background-color: #d5153c;
    transform: rotate(72deg);
  }
  .shape.shape-3 {
    background-color: #25aa95;
    transform: rotate(144deg);
  }
  .shape.shape-4 {
    background-color: #34a853;
    transform: rotate(216deg);
  }
  .shape.shape-5 {
    background-color: #fbbc05;
    transform: rotate(288deg);
  }

  .shape-1 {
    -webkit-animation: shape1 0.5s infinite alternate;
    animation: shape1 0.5s infinite alternate;
  }
  .shape-2 {
    -webkit-animation: shape2 0.5s infinite alternate;
    animation: shape2 0.5s infinite alternate;
  }
  .shape-3 {
    -webkit-animation: shape3 0.5s infinite alternate;
    animation: shape3 0.5s infinite alternate;
  }
  .shape-4 {
    -webkit-animation: shape4 0.5s infinite alternate;
    animation: shape4 0.5s infinite alternate;
  }
  .shape-5 {
    -webkit-animation: shape5 0.5s infinite alternate;
    animation: shape5 0.5s infinite alternate;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes shape1 {
    from {
      -webkit-transform: rotate(0deg) translate(0, 0);
      transform: rotate(0deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(0deg) translate(20px, 0);
      transform: rotate(0deg) translate(20px, 0);
    }
  }
  @keyframes shape1 {
    from {
      -webkit-transform: rotate(0deg) translate(0, 0);
      transform: rotate(0deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(0deg) translate(20px, 0);
      transform: rotate(0deg) translate(20px, 0);
    }
  }
  @-webkit-keyframes shape2 {
    from {
      -webkit-transform: rotate(72deg) translate(0, 0);
      transform: rotate(0deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(72deg) translate(20px, 0);
      transform: rotate(72deg) translate(20px, 0);
    }
  }
  @keyframes shape2 {
    from {
      -webkit-transform: rotate(72deg) translate(0, 0);
      transform: rotate(72deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(72deg) translate(20px, 0);
      transform: rotate(72deg) translate(20px, 0);
    }
  }
  @-webkit-keyframes shape3 {
    from {
      -webkit-transform: rotate(144deg) translate(0, 0);
      transform: rotate(144deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(144deg) translate(20px, 0);
      transform: rotate(144deg) translate(20px, 0);
    }
  }
  @keyframes shape3 {
    from {
      -webkit-transform: rotate(144deg) translate(0, 0);
      transform: rotate(144deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(144deg) translate(20px, 0);
      transform: rotate(144deg) translate(20px, 0);
    }
  }
  @-webkit-keyframes shape4 {
    from {
      -webkit-transform: rotate(216deg) translate(0, 0);
      transform: rotate(216deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(216deg) translate(20px, 0);
      transform: rotate(216deg) translate(20px, 0);
    }
  }
  @keyframes shape4 {
    from {
      -webkit-transform: rotate(216deg) translate(0, 0);
      transform: rotate(216deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(216deg) translate(20px, 0);
      transform: rotate(216deg) translate(20px, 0);
    }
  }
  @-webkit-keyframes shape5 {
    from {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    to {
      -webkit-transform: translate(20px, 0);
      transform: translate(20px, 0);
    }
  }
  @keyframes shape5 {
    from {
      -webkit-transform: rotate(288deg) translate(0, 0);
      transform: rotate(288deg) translate(0, 0);
    }
    to {
      -webkit-transform: rotate(288deg) translate(20px, 0);
      transform: rotate(288deg) translate(20px, 0);
    }
  }
`;

interface LazyLoadingPageProps {
  height?: string;
}

const LazyLoadingPage = ({ height }: LazyLoadingPageProps): JSX.Element => (
  <Wrapper height={height}>
    <Container>
      <div className="shape shape-1" />
      <div className="shape shape-2" />
      <div className="shape shape-3" />
      <div className="shape shape-4" />
      <div className="shape shape-5" />
    </Container>
  </Wrapper>
);

export default LazyLoadingPage;
