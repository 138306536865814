/* eslint-disable no-useless-catch */
import { uploadService } from './services';

const pathname = '/cdn';

export const UploadApi = {
  uploadSingleFile: async (id: string, type: string, file: any) => {
    const formData = new FormData();

    if (file.length > 0) {
      file.forEach((item, index) => formData.append(`files${index}`, item));
    } else {
      formData.append('files', file);
    }
    try {
      const response = await uploadService.post(
        `${pathname}?id=${id}&type=${type}`,
        formData
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
