import React from 'react';

import { IconProps } from './Icon';

export const CollaboratorOrderFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    id="Capa_1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m341 8.787v81.213h81.213z" />
      <path d="m182.899 131.104h92.721c25.316 0 49.13 9.841 67.061 27.71l40.192 39.79h48.127v-78.604h-105c-8.284 0-15-6.716-15-15v-105h-215c-8.284 0-15 6.716-15 15v146.104h24.435c21.226-19.387 48.529-30 77.464-30z" />
      <path d="m129.525 383.386c-10.945-13.043-16.156-29.569-14.672-46.533 1.484-16.963 9.485-32.333 22.53-43.279l76.226-63.961c2.701-2.267 6.115-3.509 9.642-3.509h43.439c3.978 0 7.794 1.58 10.607 4.393l54.385 54.385c13.004 13.004 34 13.146 47.177.424 13.628-13.156 13.301-35.294-.16-48.621l-57.165-56.592c-12.271-12.245-28.573-18.989-45.913-18.99h-92.721c-22.704 0-44.05 8.842-60.103 24.896l-5.104 5.104h-102.691c-8.284 0-15 6.716-15 15v159.999c0 8.284 6.716 15 15 15h56.876c18.184 24.869 47.593 40 78.676 40h13.428c-2.686-5.725-4.489-11.808-5.362-18.085-11.219-3.646-21.32-10.365-29.095-19.631z" />
      <path d="m497 228.604h-86.733c5.759 9.676 8.854 20.75 8.884 32.312.044 17.066-6.578 33.111-18.646 45.179-12.411 12.412-28.715 18.618-45.019 18.618-16.303 0-32.606-6.206-45.018-18.618l-49.991-49.991h-31.767l-72.044 60.452c-14.279 11.981-16.141 33.269-4.16 47.548 11.981 14.279 33.269 16.141 47.548 4.16-14.279 11.981-16.141 33.269-4.16 47.548 11.981 14.279 33.269 16.141 47.548 4.16l7.659-6.427c-14.277 11.982-16.139 33.269-4.158 47.547 11.981 14.279 33.269 16.141 47.548 4.16l15.32-12.855c-14.278 11.981-16.141 33.269-4.159 47.548 11.981 14.279 33.269 16.141 47.548 4.16l101.895-85.5h41.905c8.284 0 15-6.716 15-15v-160c0-8.286-6.716-15.001-15-15.001z" />
    </g>
  </svg>
);
