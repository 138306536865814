import * as React from 'react';

import { CTabs } from './styles';

const DecorationTabs = ({
  children,
  onChange,
}: {
  children: React.ReactNode[];
  onChange: (val: string) => void;
}): JSX.Element => (
  <>
    <CTabs defaultActiveKey="1" type="card" onChange={onChange}>
      {children}
    </CTabs>
  </>
);

export default DecorationTabs;
