import { Row } from 'farmhub-ui-core';
import styled from 'styled-components';

export const Wrapper = styled(Row)<{
  background: string;
  padding?: string;
  fontSize?: string;
  styles?: any;
  width?: string;
}>`
  width: ${p => p.width || 'fit-content'};
  border-radius: 3px;
  padding: ${p => p.padding || '2px 8px'};
  position: relative;
  white-space: nowrap;
  color: ${p => p.background};
  font-size: ${p => p.fontSize || '12px'} !important;
  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background: ${p => p.background};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${p => p.styles}
`;
