import React from 'react';

import { IconProps } from './Icon';

export const PeopleLinkColorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    id="Capa_1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <g>
          <path
            d="m445.657 263.844h-60.888c-4.268 0-7.726-3.459-7.726-7.726 0-4.268 3.459-7.726 7.726-7.726h60.888c4.268 0 7.726 3.459 7.726 7.726s-3.459 7.726-7.726 7.726z"
            fill="#2b4d66"
          />
        </g>
        <g>
          <path
            d="m455.426 463.319c-1.977 0-3.955-.755-5.464-2.263l-43.055-43.054c-3.017-3.018-3.017-7.909 0-10.928 3.018-3.016 7.909-3.016 10.928 0l43.055 43.054c3.017 3.018 3.017 7.909 0 10.928-1.509 1.508-3.487 2.263-5.464 2.263z"
            fill="#2b4d66"
          />
        </g>
        <g>
          <path
            d="m56.407 463.152c-1.978 0-3.955-.755-5.464-2.263-3.017-3.018-3.017-7.909 0-10.928l43.055-43.054c3.019-3.017 7.91-3.015 10.928 0 3.017 3.018 3.017 7.909 0 10.928l-43.055 43.054c-1.509 1.508-3.487 2.263-5.464 2.263z"
            fill="#2b4d66"
          />
        </g>
        <g>
          <path
            d="m127.232 263.609h-60.888c-4.268 0-7.726-3.459-7.726-7.726 0-4.268 3.459-7.726 7.726-7.726h60.888c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
            fill="#2b4d66"
          />
        </g>
        <g>
          <path
            d="m99.628 107.188c-1.977 0-3.955-.755-5.464-2.263l-43.054-43.054c-3.017-3.018-3.017-7.909 0-10.928 3.018-3.016 7.909-3.016 10.928 0l43.054 43.054c3.017 3.018 3.017 7.909 0 10.928-1.509 1.509-3.487 2.263-5.464 2.263z"
            fill="#2b4d66"
          />
        </g>
        <g>
          <path
            d="m412.538 107.355c-1.977 0-3.955-.755-5.464-2.263-3.017-3.018-3.017-7.909 0-10.928l43.054-43.054c3.018-3.016 7.909-3.016 10.928 0 3.017 3.018 3.017 7.909 0 10.928l-43.054 43.054c-1.509 1.509-3.487 2.263-5.464 2.263z"
            fill="#2b4d66"
          />
        </g>
      </g>
      <path
        d="m364.446 448.833h-215.812c-3.143 0-5.691-2.548-5.691-5.691v-109.048c0-41.65 33.764-75.414 75.414-75.414h76.366c41.65 0 75.414 33.764 75.414 75.414v109.049c0 3.143-2.548 5.69-5.691 5.69z"
        fill="#61a2f9"
      />
      <path
        d="m294.723 258.68h-21.347c41.65 0 75.414 33.764 75.414 75.414v114.74h15.657c3.143 0 5.691-2.548 5.691-5.691v-109.049c-.001-41.65-33.765-75.414-75.415-75.414z"
        fill="#5490f9"
      />
      <path
        d="m326.212 229.311h-139.345c-12.469 0-22.577-10.634-22.577-23.753v-98.356c0-24.198 18.645-43.814 41.645-43.814h73.81c11.181 0 20.989 7.738 24.165 18.943.117.413.603.584.944.324l9.15-6.987c9.124-6.968 21.784-5.515 29.265 3.358 3.557 4.218 5.521 9.661 5.521 15.299v111.232c-.001 13.12-10.109 23.754-22.578 23.754z"
        fill="#365e7d"
      />
      <path
        d="m343.269 79.027c-6.158-7.303-15.821-9.574-24.136-6.266 3.233 1.281 6.219 3.378 8.654 6.266 3.556 4.218 5.52 9.661 5.52 15.299v111.232c0 13.118-10.108 23.753-22.577 23.753h15.482c12.469 0 22.577-10.634 22.577-23.753v-111.232c0-5.638-1.964-11.081-5.52-15.299z"
        fill="#2b4d66"
      />
      <path
        d="m256.54 322.61-22.624 16.511c-2.113 1.542-5.114.608-5.979-1.86l-27.533-78.58 16.5-16.146 11.166 30.765z"
        fill="#e27d47"
      />
      <path
        d="m256.54 322.61 22.624 16.511c2.113 1.542 5.114.608 5.979-1.86l27.533-78.58-16.5-16.146-11.166 30.765z"
        fill="#e27d47"
      />
      <path d="m256.54 322.61 28.469-49.311h-56.939z" fill="#e2a770" />
      <path
        d="m256.54 284.782c-40.551 0-73.424-32.873-73.424-73.424v-63.578c0-5.238 1.419-10.096 3.87-14.248 7.641-12.943 25.101-15.856 37.039-6.725 21.908 16.756 45.845 22.153 72.327 13.297 15.849-5.3 32.834 5.557 33.583 22.252.019.422.029.847.029 1.273v47.73c0 40.55-32.873 73.423-73.424 73.423z"
        fill="#f1d0a5"
      />
      <path
        d="m329.935 162.356c-.75-16.695-17.734-27.552-33.583-22.252-.138.046-.274.086-.412.131 9.162 3.23 16.229 11.487 16.707 22.121.019.422.029.847.029 1.273v47.73c0 37.626-28.303 68.636-64.78 72.915 2.836.333 5.719.509 8.644.509 40.551 0 73.424-32.873 73.424-73.424v-47.73c0-.427-.01-.851-.029-1.273z"
        fill="#f1b986"
      />
      <g>
        <path
          d="m222.864 195.942c-4.268 0-7.726-3.459-7.726-7.726v-8.1c0-4.268 3.459-7.726 7.726-7.726 4.268 0 7.726 3.459 7.726 7.726v8.1c0 4.267-3.459 7.726-7.726 7.726z"
          fill="#2b4d66"
        />
      </g>
      <g>
        <path
          d="m290.216 195.942c-4.268 0-7.726-3.459-7.726-7.726v-8.1c0-4.268 3.459-7.726 7.726-7.726s7.726 3.459 7.726 7.726v8.1c0 4.267-3.458 7.726-7.726 7.726z"
          fill="#2b4d66"
        />
      </g>
      <g>
        <path
          d="m256.54 242.009c-5.68 0-11.36-1.709-16.882-5.128-3.629-2.245-4.75-7.008-2.503-10.636 2.246-3.63 7.011-4.748 10.636-2.503 6.05 3.744 11.449 3.744 17.499 0 3.629-2.245 8.39-1.125 10.636 2.503s1.126 8.39-2.503 10.636c-5.523 3.419-11.203 5.128-16.883 5.128z"
          fill="#dd636e"
        />
      </g>
      <path
        d="m183.116 204.507h-11.773c-8.167 0-14.788-6.621-14.788-14.788 0-8.167 6.621-14.788 14.788-14.788h11.773z"
        fill="#e2a770"
      />
      <path
        d="m329.964 204.507h11.773c8.167 0 14.788-6.621 14.788-14.788 0-8.167-6.621-14.788-14.788-14.788h-11.773z"
        fill="#e2a770"
      />
      <circle cx="473.433" cy="256.111" fill="#61a2f9" r="38.567" />
      <path
        d="m473.433 217.544c-3.593 0-7.066.502-10.366 1.421 16.263 4.529 28.201 19.439 28.201 37.146s-11.938 32.617-28.201 37.146c3.3.919 6.773 1.421 10.366 1.421 21.3 0 38.567-17.267 38.567-38.567s-17.267-38.567-38.567-38.567z"
        fill="#5490f9"
      />
      <circle cx="471.35" cy="471.507" fill="#61a2f9" r="38.567" />
      <path
        d="m498.621 444.236c-2.54-2.54-5.351-4.642-8.335-6.325 8.297 14.703 6.196 33.687-6.325 46.207-12.521 12.521-31.505 14.622-46.208 6.325 1.683 2.983 3.785 5.794 6.325 8.335 15.061 15.061 39.481 15.061 54.542 0 15.063-15.061 15.062-39.48.001-54.542z"
        fill="#5490f9"
      />
      <circle cx="40.493" cy="471.35" fill="#61a2f9" r="38.567" />
      <path
        d="m67.764 498.621c2.54-2.54 4.642-5.351 6.325-8.335-14.703 8.297-33.687 6.196-46.207-6.325-12.521-12.521-14.622-31.505-6.325-46.208-2.983 1.683-5.794 3.785-8.335 6.325-15.061 15.061-15.061 39.481 0 54.542 15.061 15.063 39.48 15.062 54.542.001z"
        fill="#5490f9"
      />
      <circle cx="38.567" cy="255.889" fill="#61a2f9" r="38.567" />
      <path
        d="m38.567 294.456c3.593 0 7.066-.502 10.366-1.421-16.263-4.529-28.201-19.439-28.201-37.146s11.938-32.617 28.201-37.146c-3.3-.919-6.773-1.421-10.366-1.421-21.3 0-38.567 17.267-38.567 38.567s17.267 38.567 38.567 38.567z"
        fill="#5490f9"
      />
      <circle cx="40.65" cy="40.493" fill="#61a2f9" r="38.567" />
      <path
        d="m13.379 67.764c2.54 2.54 5.351 4.642 8.335 6.325-8.297-14.703-6.196-33.687 6.325-46.207 12.521-12.521 31.505-14.622 46.208-6.325-1.683-2.983-3.785-5.794-6.325-8.335-15.062-15.062-39.482-15.062-54.543 0-15.062 15.061-15.061 39.48 0 54.542z"
        fill="#5490f9"
      />
      <circle cx="471.507" cy="40.65" fill="#61a2f9" r="38.567" />
      <path
        d="m444.236 13.379c-2.54 2.54-4.642 5.351-6.325 8.335 14.703-8.297 33.687-6.196 46.207 6.325 12.521 12.521 14.622 31.505 6.325 46.208 2.983-1.683 5.794-3.785 8.335-6.325 15.061-15.061 15.061-39.481 0-54.542-15.061-15.063-39.48-15.062-54.542-.001z"
        fill="#5490f9"
      />
    </g>
  </svg>
);
