import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { UploadApi } from 'api';

const DEFAULT_ID = moment().unix().toString();
const DEFAULT_TYPE = 'image';

export const uploadSingleFile = createAsyncThunk(
  'upload/uploadSingleFile',
  async (
    {
      id = DEFAULT_ID,
      type = DEFAULT_TYPE,
      file,
    }: { id?: string; type?: string; file: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await UploadApi.uploadSingleFile(id, type, file);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ message: err.response.data });
    }
  }
);

interface UploadState {
  data: any[];
}

const initialState: UploadState = {
  data: [],
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  // extraReducers: builder => {},
});

const { reducer: uploadReducer } = uploadSlice;

export default uploadReducer;
