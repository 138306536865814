import { CheckCircleOutlined, CloseCircleOutlined } from 'farmhub-ui-core';
import * as React from 'react';

interface IStatusSwitchProps {
  isCheck?: boolean;
  setIsCheck?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const StatusSwitch = ({
  isCheck,
  setIsCheck,
}: IStatusSwitchProps): JSX.Element => {
  const CheckIcon = (
    <CheckCircleOutlined
      className="icon"
      style={{
        fontSize: 18,
        backgroundColor: 'green',
        color: 'white',
        borderRadius: '50%',
      }}
      onClick={() => {
        setIsCheck?.(prevState => !prevState);
      }}
    />
  );

  const CancelIcon = (
    <CloseCircleOutlined
      className="icon"
      style={{
        fontSize: 18,
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
      }}
      onClick={() => {
        setIsCheck?.(prevState => !prevState);
      }}
    />
  );

  return isCheck ? CheckIcon : CancelIcon;
};

StatusSwitch.defaultProps = {
  isCheck: true,
};

export default React.memo(StatusSwitch);
