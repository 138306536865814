import styled from 'styled-components';

const DotColor = styled.div<{ color?: string; size?: string; styles?: any }>`
  width: ${p => p.size || '10px'};
  height: ${p => p.size || '10px'};
  background: ${props => props.color || 'red'};
  border-radius: 3px;
  flex-shrink: 0;
  ${p => p.styles};
  cursor: pointer;
`;

export default DotColor;
