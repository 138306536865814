import * as React from 'react';

import { PreviewStoreWrapper, PreviewScaleWrapper } from './styles';

const handleScaleIndex = () => {
  const TAB_WIDTH = 480;
  const PADDING = 36 + 24 + 24 + 36;
  const MENU_WIDTH = 280;

  return (window.innerWidth - (TAB_WIDTH + PADDING + MENU_WIDTH)) / 1360;
};

const PreviewScale = ({
  children,
  triggerCalcHeight,
}: {
  children: React.ReactNode;
  triggerCalcHeight: boolean;
}): JSX.Element => {
  const [isScale, setIsScale] = React.useState(false);
  const [scale, setScale] = React.useState(handleScaleIndex());
  const divRef = React.useRef<HTMLDivElement>(null);
  const div1Ref = React.useRef<HTMLDivElement>(null);

  const debounce = (func, delay) => {
    let inDebounce;

    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func(), delay);
  };

  // const initSize = () => {
  //   if (divRef?.current && !isScale && !isFirstLoad) {
  //     console.log(divRef.current.offsetWidth, divRef.current.offsetHeight);
  //     setWidth(divRef.current.offsetWidth);
  //     setHeight(divRef.current.offsetHeight);
  //     setIsFirstLoad(true);
  //   }
  // };

  const handleResize = React.useCallback(() => {
    if (divRef?.current && !isScale && div1Ref.current) {
      divRef.current.style.width = `${
        handleScaleIndex() * div1Ref.current?.offsetWidth
      }px`;
      divRef.current.style.height = `${
        div1Ref.current.offsetHeight * handleScaleIndex()
      }px`;

      setIsScale(true);
    }
  }, [isScale]);

  React.useEffect(() => {
    handleResize();
  }, [handleResize]);

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      event => {
        debounce(() => {
          setIsScale(false);
          setScale(handleScaleIndex());
          handleResize();
        }, 1000);
      },
      true
    );

    document.addEventListener(
      'DOMContentLoaded',
      event => {
        debounce(() => {
          setIsScale(false);
          setScale(handleScaleIndex());
          handleResize();
        }, 1000);
      },
      true
    );

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('DOMContentLoaded', handleResize);
    };
  }, [handleResize]);

  React.useEffect(() => {
    if (div1Ref.current && divRef.current) {
      divRef.current.style.height = `${
        div1Ref.current.offsetHeight * handleScaleIndex()
      }px`;
    }
  }, [triggerCalcHeight]);

  return (
    <PreviewStoreWrapper ref={divRef}>
      <PreviewScaleWrapper ref={div1Ref} scale={scale}>
        {children}
      </PreviewScaleWrapper>
    </PreviewStoreWrapper>
  );
};

export default PreviewScale;
