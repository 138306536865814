import { Button, ButtonType, Row, Space, Spacing } from 'farmhub-ui-core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { NameText } from 'components';
import { PlusIcon } from 'components/Icon';
import { theme } from 'styled';

import { Wrapper } from './styles';

interface PageTitleBarProps {
  title?: string | React.ReactNode;
  url?: any;
  extraAction?: any;
  padding?: number;
  logo?: JSX.Element;
  background?: string;
  radius?: string;
  breadcrumb?: React.ReactNode;
  isCreateButtonHidden?: boolean;
  extraActionSide?: 'left' | 'right';
}

const PageTitleBar = ({
  title,
  url,
  extraAction,
  padding,
  logo,
  background,
  radius,
  breadcrumb,
  isCreateButtonHidden = false,
  extraActionSide = 'right',
}: PageTitleBarProps): JSX.Element => (
  <Wrapper radius={radius} padding={padding} background={background}>
    <Row>
      {logo}
      {logo && <Space width={24} />}
      {breadcrumb}
      {typeof title === 'string' ? (
        <NameText color="dark" lineHeight="auto" size="sm" weight="extraBold">
          {title}
        </NameText>
      ) : (
        title
      )}
    </Row>
    <Row width="fit-content" styles={{ gap: theme.spacing.xs }}>
      {extraActionSide === 'left' && extraAction}
      {extraActionSide === 'right' && extraAction}
      {url && !isCreateButtonHidden ? (
        <Link to={url}>
          <Button
            height={theme.button.height.sm}
            type={ButtonType.Primary}
            icon={{ children: <PlusIcon width={12} height={12} /> }}
          >
            Thêm mới
          </Button>
        </Link>
      ) : null}
    </Row>
  </Wrapper>
);

export default PageTitleBar;
