import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CategoryApi, SwitchCategory } from 'api/category';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICategory {
  id: string;
  code: string;
  name: string;
}

interface ICategoryState {
  list: ICategory[];
  loading: ILoadingStatus;
  error: unknown;
}

export const findCategories = createAsyncThunk<any, void>(
  'category/findCategories',
  async (_, thunkAPI) => {
    try {
      const response = await CategoryApi.findMasterData();
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const findCategoriesForAdmin = createAsyncThunk<any, any>(
  'category/findCategoriesForAdmin',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await CategoryApi.findForAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const checkExistCategory = createAsyncThunk<any, any>(
  'category/exists',
  async (params, thunkAPI) => {
    try {
      const response = await CategoryApi.checkExists(params?.name);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const createCategory = createAsyncThunk(
  'category/createCategory',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await CategoryApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCategory = createAsyncThunk(
  'category/removeCategory',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await CategoryApi.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const switchCategory = createAsyncThunk(
  'category/switchCategory',
  async (body: SwitchCategory, { rejectWithValue }) => {
    try {
      const response = await CategoryApi.switch(body);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await CategoryApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
} as ICategoryState;

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findCategories.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findCategories.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findCategories.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list.push(data);
      })
      .addCase(removeCategory.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = state.list.filter(item => item.id !== data.id);
      });
  },
});

export const getListCategory = (state: RootState): any => state.category.list;

export default categorySlice.reducer;
