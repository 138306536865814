import { Table } from 'antd';
import styled, { createGlobalStyle, css } from 'styled-components';

import { device } from './device';
import { theme } from './theme/theme';

export const CTable = styled(Table)`
  tr {
    th {
      padding: ${p => p.theme.app.padding.vertical};
      color: ${props => props.theme.colors.text.primary} !important;
    }

    td {
      padding: ${p => p.theme.app.padding.vertical};
    }

    .ant-typography-edit {
      display: none !important;
    }

    &:hover {
      .ant-typography-edit {
        display: inline-block !important;
      }
    }
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border: 0px;
    }
  }

  .ant-table-summary {
    td {
      padding: ${p => p.theme.app.padding.vertical};
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-weight: ${p => p.theme.font.weight.medium};
  }

  svg { vertical-align: baseline; }
  
  div:first-child:has(.create_page_mobile){
    height: 100% !important;
  }

  body {
    width: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', sans-serif;
  }

  #app {
    background-color: #FFFFFF;
    min-height: 100%;
    min-width: 100%;
  }

  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: #FFF;
        color: #262626;
        font-weight: ${p => theme.font.weight.medium};

        &:before {
          display: none;
        }
      }
    }
  }

  a:hover {
    color: ${props => props.theme.colors.primary.main};
  }

  /* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FAFAFA; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background: #CCCCCC; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8C8C8C;
  width: 12px;
  height: 12px;
}

.ant-btn {
  span {
    font-weight: ${props => props.theme.font.weight.medium};
  }
}

.ant-table-tbody > tr.ant-table-row-selected {
  > td {
    background: ${props => `${props.theme.colors.primary.main}10`};
  } 

  &:hover {
    > td {
      background: #fafafa !important;
    }
  }
}

.ant-table-placeholder {
  .ant-table-cell {
    border-bottom: 0px;
  }
}

  .ant-tabs {
    .ant-tabs-tab {
      margin: 0px;
      padding: ${p => p.theme.app.padding.vertical} 36px;
    }

    .ant-tabs-tab-btn {
      text-shadow: none !important;
      font-weight: ${p => theme.font.weight.medium} !important;
      color: ${props => props.theme.colors.text.secondary};
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-weight: ${p => theme.font.weight.medium};
      }
    }
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background: #ffffff !important;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background: ${props => props.theme.colors.primary.main};
      opacity: 0.1;
      top: 0;
      left: 0;
    }
  }

  .ant-select-item.ant-select-item-option{
    padding: ${p => p.theme.app.padding.vertical};
    color: ${props => props.theme.colors.text.secondary};
    font-weight: ${p => theme.font.weight.regular};
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    color: ${props => props.theme.colors.primary.main};
  }

  .ant-image-preview-wrap {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .u-padding-horizontal {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @media ${device.md} { 
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }
  .u-padding-horizontal-wrap {
    padding-left: 2rem;
    padding-right: 2rem;
    @media ${device.md} { 
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }
  .u-content {
    max-width: 1536px;
    margin: auto;
  }

  .u-detail {
    width: 100%;
    max-width: 1120px !important;

    padding: 0px ${p => p.theme.app.padding.vertical};

    @media ${device.md} {
      padding: 0px;
    }
  }

  .u-item {
    width: calc(100% / 2) !important;
    
    @media ${device.sm} {
      width: calc(100% / 3) !important;
    }
    @media ${device.md} {
      width: calc(100% / 4) !important;
    }
    @media ${device.lg} {
      width: calc(100% / 5) !important;
    }
  }

  .ant-popover-placement-bottomLeft .ant-popover-arrow {
    left: 6px;
  }

  .ant-popover-placement-rightTop {
    padding-left: 4px;
  }

  .ant-steps-item-title {
    line-height: 18px !important;
  }

  .readonly {
    *:not(.un-readonly) {
      pointer-events: none;
    }
  }

  .readonly-antd {
    .ant-input, 
    .ant-input-number,
    .ant-select,
    .ant-select-selector {
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #f5f5f5; /* Optional: change background to indicate it's readonly */
    }

    /* Exception for any element with the 'un-readonly' class */
    *:not(.un-readonly) {
      cursor: not-allowed !important;
      pointer-events: none;
    }
}

  .un-readonly {
    pointer-events: visible !important;
    cursor: auto;
    * {
    pointer-events: visible !important;
      cursor: auto;
    }
  }

  .ant-form-item-with-help .ant-form-item-explain {
    height: auto;
    min-height: 18px;
    line-height: 18px;
    opacity: 1;
  }

  .ant-notification-notice-close {
    top: 12px;
  }

  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-modal-header {
    padding: 12px 24px !important;
  }

  .ant-notification-notice {
    padding: 18px !important;
    margin-bottom: 18px !important;
  }

  .ant-notification-bottomLeft {
    margin-left: 18px !important;
  }

  .ant-notification-notice-message {
    * {
      font-size: ${props => props.theme.font.size.xs} !important;
    }
  }

  .ant-notification-notice-description {
    * {
      font-size: ${props => props.theme.font.size.xxs} !important;
      line-height: 18px;
    } 
  }

  .row-dragging {
    z-index: 1200;
  }

  .ant-tabs-tab {
    padding: 12px 24px !important;
  }

  .ant-tabs-tabpane {
    padding: 0px;
  }

  .ant-tabs-ink-bar {
    background: none;
    height: 6px !important;

    &::after {
      content: ' ';
      width: 24px;
      height: 6px !important;
      border-radius: 999px;
      background: ${props => props.theme.colors.primary.main};

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  .overlay {
    ::-webkit-scrollbar {
      width: 8px;
      cursor: pointer;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 999px;
      background: none;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: none;
      background: #8C8C8C;
      width: 8px;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background: #CCCCCC;
      }  
    }
  }

  .adm-picker-header-button {
    position: relative;

    &:nth-child(1) {
      color: #ffffff;

      &::after {
        content: 'Hủy';
        width: 100%;
        height: 100%;
        background: #ffffff;
        color: ${props => props.theme.colors.text.secondary};
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 1;
      }
    }

    &:last-child {
      color: #ffffff;

      &::after {
        content: 'Chọn';
        width: 100%;
        height: 100%;
        background: #ffffff;
        color: ${props => props.theme.colors.primary.main};
        top: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 1;
      }
    }
  }

  .adm-picker-header-button:last-child {
    position: relative;
    color: #ffffff;

      &::after {
        content: 'Chọn';
        width: 100%;
        height: 100%;
        background: #ffffff;
        color: ${props => props.theme.colors.primary.main};
        top: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 1;
      }
    
  }

  .ant-scrolling-effect {
    overflow: hidden !important;
    width: 100% !important;

    @media ${device.md} {
      overflow: hidden !important;
      width: calc(100% - 8px) !important;
    }
  }
`;

export const overlay = css`
  ::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: none !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: none;
    background: #8c8c8c !important;
    width: 8px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #cccccc !important;
    }
  }
`;

export const EmptyDiv = styled.div``;

export const CImage = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
  objectFit?: string;
}>`
  width: ${props => props.width || '42px'};
  height: ${props => props.height || '42px'};
  border-radius: ${props => props.borderRadius || '0px'};

  object-fit: ${props => props.objectFit || 'cover'};
  flex-shrink: 0;
`;

export const LazyLoadingImageWrapper = styled.div<{
  width?: string;
  height?: string;
  borderRadius?: string;
  objectFit?: string;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  img {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: ${props => props.borderRadius || '0px'};

    object-fit: ${props => props.objectFit || 'cover'};
    flex-shrink: 0;
  }
`;
