/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import accountReducer from 'slice/accountSlice';
import appRouteSlice from 'slice/appRouteSlice';
import authReducer from 'slice/authSlice';
import categoryReducer from 'slice/categorySlice';
import certificationReducer from 'slice/certificationSlice';
import entityCensorshipReducer from 'slice/entityCensorshipSlice';
import errorListReducer from 'slice/errorListSlice';
import organizationReducer from 'slice/organizationSlice';
import packageReducer from 'slice/packageSlice';
import productCensorshipReducer from 'slice/productCensorshipSlice';
import userCustomDomainReducer from 'slice/userCustomDomainSlice';
import userSMSAccountReducer from 'slice/userSMSAccountSlice';
import userSubscriptionReducer from 'slice/userSubscriptionSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  entityCensorship: entityCensorshipReducer,
  productCensorship: productCensorshipReducer,
  errorList: errorListReducer,
  category: categoryReducer,
  organization: organizationReducer,
  certification: certificationReducer,
  appRoute: appRouteSlice,
  userSMSAccount: userSMSAccountReducer,
  userCustomDomain: userCustomDomainReducer,
  userSubscription: userSubscriptionReducer,
  package: packageReducer,
});

export default rootReducer;
