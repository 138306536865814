import { BgColorsOutlined, MessageFilled } from '@ant-design/icons';
import {
  AppstoreFilled,
  CodepenSquareFilled,
  QuestionCircleFilled,
  SettingFilled,
} from 'farmhub-ui-core';

import {
  AreaIcon,
  BannerFilledIcon,
  BellFilledIcon,
  BillIcon,
  BoxIcon,
  BuyingFullFillIcon,
  CartIcon,
  CertificateIcon,
  CloudIcon,
  CoBuyIcon,
  CoffeeBeanIcon,
  CollaboratorOrderFilledIcon,
  CompleteIcon,
  ConnectIcon,
  CopyIcon,
  CouponFilledIcon,
  CouponIcon,
  CustomerIcon,
  DomainIcon,
  DraftIcon,
  LandIcon,
  MegaphoneIcon,
  PendingConfirmIcon,
  PhotoIcon,
  PlantIcon,
  PlusFilledIcon,
  PolicyIcon,
  ProcessingIcon,
  ProductObjectFilledIcon,
  RejectOrderIcon,
  RequestIcon,
  ShopIcon,
  ShoppingBagIcon,
  StampIcon,
  StatisticFilledIcon,
  TravelIcon,
  TruckIcon,
  UserIcon,
  WaitingIcon,
} from 'components/Icon';
import { HistoryIconFilledIcon } from 'components/Icon/HistoryIconFilledIcon';
import { COLLABORATOR_TEXT, MENU_TITLE_ORDER_BY_STATUS } from 'utils/constants';

export const dataMenu = (): any[] => [
  {
    id: Date.now(),
    layer: 1,
    title: 'Hệ thống',
    menu: [
      {
        id: Date.now(),
        name: 'Tổng quan',
        link: '/',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Danh mục',
        link: '/system/category',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Chứng nhận',
        link: '/system/organization',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      // {
      //   id: Date.now(),
      //   name: 'Banner',
      //   link: '/system/banner',
      //   subMenu: [],
      //   filter: undefined,
      //   quantity: 0,
      // },
    ],
  },
  // {
  //   id: Date.now(),
  //   layer: 1,
  //   title: 'Quản lý',
  //   menu: [
  //     {
  //       id: Date.now(),
  //       name: 'Người dùng',
  //       link: '/user',
  //       subMenu: [
  //         {
  //           id: Date.now(),
  //           title: 'Đối tác',
  //           layer: 2,
  //           menu: [],
  //         },
  //       ],
  //       filter: undefined,
  //       quantity: 0,
  //     },
  //     {
  //       id: Date.now(),
  //       name: 'Chính sách',
  //       link: '/policy',
  //       subMenu: [],
  //       filter: undefined,
  //       quantity: 0,
  //     },
  //   ],
  // },
  {
    id: Date.now(),
    layer: 1,
    title: 'Kiểm duyệt',
    menu: [
      {
        id: Date.now(),
        name: 'Sản phẩm',
        link: '/censorship/product/list',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Thực thể',
        link: '/censorship/entity/list',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      // {
      //   id: Date.now(),
      //   name: 'Chứng nhận ',
      //   link: '/censorship/certification/list',
      //   subMenu: [],
      //   filter: undefined,
      //   quantity: 0,
      // },
    ],
  },
  {
    id: Date.now(),
    layer: 1,
    title: 'Tính năng nâng cao',
    menu: [
      {
        id: Date.now(),
        name: 'Phê duyệt domain',
        link: '/advanced/custom-domain',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Tin nhắn SMS',
        link: '/advanced/sms',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Domain thương hiệu',
        link: '/advanced/brand-domain',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
      {
        id: Date.now(),
        name: 'Quản lý gói doanh nghiệp',
        link: '/advanced/subscription',
        subMenu: [],
        filter: undefined,
        quantity: 0,
      },
    ],
  },
];

export const renderEntityName = (entityType: number): string => {
  switch (entityType) {
    case 1:
      return 'Nông trại';
    case 2:
      return 'Cửa hàng';
    case 3:
      return COLLABORATOR_TEXT.CAPITALIZE;
    case 4:
      return 'Kho chứa';
    case 5:
      return 'Vận chuyển';
    case 6:
      return 'Thiện nguyện';
    default:
      return '';
  }
};

export const renderIconMenu = (name: string): JSX.Element => {
  switch (name) {
    case 'Tổng quan':
      return <LandIcon className="icon" width={22} height={22} />;
    case 'Thống kê':
      return <StatisticFilledIcon className="icon" width={22} height={22} />;
    case 'Đơn hàng cộng tác':
      return (
        <CollaboratorOrderFilledIcon className="icon" width={22} height={22} />
      );
    case 'Lịch sử thay đổi':
      return <HistoryIconFilledIcon className="icon" width={22} height={22} />;
    case 'Đối tượng':
      return (
        <ProductObjectFilledIcon className="icon" width={22} height={22} />
      );
    case 'Coupon của bạn':
      return <CouponFilledIcon className="icon" width={22} height={22} />;
    case 'Banner':
      return <BannerFilledIcon className="icon" width={22} height={22} />;
    case 'Giao dịch':
      return <AppstoreFilled className="icon" style={{ fontSize: '22px' }} />;
    case 'Mua bán':
    case 'Đơn hàng bán':
    case 'Tôi bán':
      return <ShoppingBagIcon className="icon" width={22} height={22} />;
    case 'Tôi mua':
    case 'Đơn hàng mua':
      return <BuyingFullFillIcon className="icon" width={22} height={22} />;
    case 'Chiến dịch':
    case 'Kênh marketing':
    case 'Kênh Marketing':
    case 'Thông báo chung':
      return <MegaphoneIcon className="icon" width={22} height={22} />;
    case 'Đơn hàng':
      return <CartIcon className="icon" width={22} height={22} />;
    case 'Hóa đơn':
      return <BillIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.PENDING:
      return <WaitingIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.CONFIRM:
      return <PendingConfirmIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.SUPPLIER_CONFIRM:
      return <PendingConfirmIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.PROCESSING:
      return <ProcessingIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.DRAF:
      return <DraftIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.DELIVERY:
      return <TruckIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.REJECT:
      return <RejectOrderIcon className="icon" width={22} height={22} />;
    case MENU_TITLE_ORDER_BY_STATUS.COMPLETE:
      return <CompleteIcon className="icon" width={22} height={22} />;
    case 'Đối tác':
    case 'Thông báo liên kết':
      return <ConnectIcon className="icon" width={22} height={22} />;
    case 'Thành viên':
      return <CustomerIcon className="icon" width={22} height={22} />;
    case 'Chính sách':
      return <PolicyIcon className="icon" width={22} height={22} />;
    case 'Thư viện':
      return <PhotoIcon className="icon" width={22} height={22} />;
    case 'Hình ảnh':
      return <LandIcon className="icon" width={22} height={22} />;
    case 'Tập tin':
      return <CopyIcon className="icon" width={22} height={22} />;
    case 'Sản phẩm':
      return (
        <CodepenSquareFilled className="icon" style={{ fontSize: '22px' }} />
      );
    case 'Vùng sản xuất':
      return <LandIcon className="icon" width={22} height={22} />;
    case 'Danh sách vùng':
      return <LandIcon className="icon" width={22} height={22} />;
    case 'Đối tượng theo vùng':
      return <AreaIcon className="icon" width={22} height={22} />;
    case 'Đối tượng nuôi trồng':
      return <PlantIcon className="icon" width={22} height={22} />;
    case 'Đối tượng sản xuất':
      return <BoxIcon className="icon" width={22} height={22} />;
    case 'Quy trình':
      return <CloudIcon className="icon" width={22} height={22} />;
    case 'Nhân viên':
    case 'Khách hàng':
    case 'Nhà cung cấp':
      return <CustomerIcon className="icon" width={22} height={22} />;
    case 'Quản lý tem':
      return <StampIcon className="icon" width={22} height={22} />;
    case 'Tạo sản phẩm':
      return <PlusFilledIcon className="icon" width={22} height={22} />;
    case 'Thiết kế gian hàng':
    case 'Trang trí gian hàng':
      return <ShopIcon className="icon" width={22} height={22} />;
    case 'Thiết lập':
      return <SettingFilled className="icon" style={{ fontSize: '22px' }} />;
    case 'Kết nối Kiotviet':
      return <SettingFilled className="icon" style={{ fontSize: '22px' }} />;
    case 'Kết nối Misa':
      return <SettingFilled className="icon" style={{ fontSize: '22px' }} />;
    case 'Hỗ trợ':
      return (
        <QuestionCircleFilled className="icon" style={{ fontSize: '22px' }} />
      );
    case 'Coupon khuyến mãi':
      return <CouponIcon className="icon" width={22} height={22} />;
    case 'Chuỗi cửa hàng':
      return <CloudIcon className="icon" width={22} height={22} />;
    case 'Tuyến đường':
      return <TravelIcon className="icon" width={22} height={22} />;
    case 'Kho chứa':
      return <BoxIcon className="icon" width={22} height={22} />;
    case 'Mua chung':
      return <CoBuyIcon className="icon" width={22} height={22} />;
    case 'Thông báo hệ thống':
      return <BellFilledIcon className="icon" width={22} height={22} />;
    case 'Thông báo đơn hàng':
      return <ShoppingBagIcon className="icon" width={22} height={22} />;
    case 'Yêu cầu mua hàng':
      return <RequestIcon className="icon" width={22} height={22} />;
    case 'Chứng nhận':
    case 'Chứng nhận ':
      return <CertificateIcon className="icon" width={22} height={22} />;
    case 'Thực thể':
    case 'Người dùng':
      return <UserIcon className="icon" width={22} height={22} />;
    case 'Danh mục':
      return <CoffeeBeanIcon className="icon" width={22} height={22} />;
    case 'Phê duyệt domain':
      return <DomainIcon className="icon" width={22} height={22} />;
    case 'Quản lý doanh nghiệp':
      return (
        <ProductObjectFilledIcon className="icon" width={22} height={22} />
      );
    case 'Tin nhắn SMS':
      return (
        <MessageFilled
          style={{ fontSize: '22px' }}
          className="icon"
          width={22}
          height={22}
        />
      );
    case 'Domain thương hiệu':
      return (
        <BgColorsOutlined
          style={{ fontSize: '22px' }}
          className="icon"
          width={22}
          height={22}
        />
      );
    case 'Quản lý gói doanh nghiệp':
      return <BuyingFullFillIcon className="icon" width={22} height={22} />;

    default:
      return <div>default</div>;
  }
};
