/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const OrganizationPage = lazyLoad(
  () => import('./index'),
  module => module.OrganizationPage
);
