import {
  BellFilled,
  Button,
  ButtonType,
  Column,
  Modal,
  Row,
  Text,
} from 'farmhub-ui-core';
import * as React from 'react';

import { TitleText } from 'components';
import { theme } from 'styled';

import { ModalStyles, Wrapper, WrapperContent, WrapperIcon } from './styles';

interface ConfirmModalProps {
  isVisible: boolean;
  setIsVisible: (e: boolean) => void;
  onOke?: () => void;
  onCancel?: () => void;
  title?: string;
  message?: React.ReactNode;
  name?: string | React.ReactNode;
  isLoading?: boolean;
  haveLoading?: boolean;
  okText?: string;
  children?: React.ReactNode;
  bodyPadding?: string;
  hideIcon?: boolean;
}

const ConfirmModal = ({
  isVisible,
  setIsVisible,
  onOke,
  onCancel,
  title,
  message,
  name,
  isLoading,
  haveLoading = false,
  okText,
  children,
  bodyPadding = '24px',
  hideIcon = false,
}: ConfirmModalProps): JSX.Element => (
  <Wrapper onClick={(e: any) => e.stopPropagation()}>
    <Modal
      maskClosable={false}
      centered
      bodyHeight="auto"
      styles={ModalStyles}
      bodyPadding={bodyPadding}
      visible={isVisible}
      onCancel={() => {
        onCancel && onCancel();
        setIsVisible(false);
      }}
    >
      <WrapperContent>
        <Column styles={{ gap: 8 }} alignItems="center">
          {!hideIcon && (
            <WrapperIcon>
              <BellFilled style={{ fontSize: 54 }} />
            </WrapperIcon>
          )}
          <Column styles={{ gap: 2 }} alignItems="center">
            <TitleText color="dark" style={{ textAlign: 'center' }}>
              {title}
            </TitleText>
            <Text style={{ textAlign: 'center' }}>{message}</Text>
            {typeof name === 'string' ? (
              <Text weight="bold" style={{ textAlign: 'center' }}>
                {name}
              </Text>
            ) : (
              name
            )}
          </Column>

          {children}
          <Row justifyContent="center" styles={{ gap: 6 }}>
            {onCancel && (
              <Button
                height={theme.button.height.md}
                width="108px"
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  }
                  setIsVisible(false);
                }}
                type={ButtonType.Default}
              >
                Hủy bỏ
              </Button>
            )}
            {onOke && (
              <Button
                height={theme.button.height.md}
                onClick={() => {
                  if (onOke) {
                    onOke();
                  }
                  !haveLoading && setIsVisible(false);
                }}
                type={ButtonType.Primary}
                width="108px"
                loading={isLoading}
              >
                {okText || 'Xác nhận'}
              </Button>
            )}
          </Row>
        </Column>
      </WrapperContent>
    </Modal>
  </Wrapper>
);

export default ConfirmModal;
