import React from 'react';

import { IconProps } from './Icon';

export const RequestIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    id="Layer_1"
    viewBox="0 0 512 512"
  >
    <g>
      <path d="m366.03 0h-351.03c-8.284 0-15 6.716-15 15v482c0 8.284 6.716 15 15 15h351.03c8.284 0 15-6.716 15-15v-482c0-8.284-6.715-15-15-15zm-208.553 293.644-45.563 45.562c-2.813 2.813-6.628 4.393-10.606 4.393s-7.794-1.58-10.606-4.394l-21.473-21.473c-5.858-5.858-5.858-15.355 0-21.213s15.356-5.858 21.213 0l10.866 10.866 34.956-34.956c5.857-5.857 15.355-5.858 21.213 0 5.858 5.859 5.858 15.357 0 21.215zm0-91.005-45.563 45.563c-2.813 2.813-6.628 4.393-10.606 4.393s-7.794-1.58-10.606-4.394l-21.473-21.473c-5.858-5.858-5.858-15.355 0-21.213s15.356-5.857 21.213 0l10.866 10.866 34.956-34.956c5.857-5.858 15.355-5.858 21.213 0s5.858 15.356 0 21.214zm0-91.005-45.563 45.563c-2.813 2.813-6.628 4.393-10.606 4.393s-7.794-1.58-10.606-4.394l-21.473-21.473c-5.858-5.858-5.858-15.355 0-21.213s15.356-5.857 21.213 0l10.866 10.866 34.956-34.956c5.857-5.858 15.355-5.858 21.213 0 5.858 5.859 5.858 15.356 0 21.214zm142.963 308.544h-95.259c-8.284 0-15-6.716-15-15s6.716-15 15-15h95.259c8.284 0 15 6.716 15 15s-6.715 15-15 15zm0-94.49h-95.259c-8.284 0-15-6.716-15-15s6.716-15 15-15h95.259c8.284 0 15 6.716 15 15s-6.715 15-15 15zm0-91.004h-95.259c-8.284 0-15-6.716-15-15s6.716-15 15-15h95.259c8.284 0 15 6.716 15 15s-6.715 15-15 15zm0-91.005h-95.259c-8.284 0-15-6.716-15-15s6.716-15 15-15h95.259c8.284 0 15 6.716 15 15s-6.715 15-15 15z" />
      <path d="m512 50.911c0-28.072-22.839-50.911-50.911-50.911-28.073 0-50.912 22.839-50.912 50.911v43.995h101.823z" />
      <path d="m411.072 413.653c-1.34 3.703-1.171 7.784.472 11.362l35.912 78.242c2.445 5.328 7.771 8.743 13.633 8.743s11.188-3.415 13.633-8.743l35.911-78.242c1.643-3.579 1.812-7.66.472-11.362l-9.217-25.464h-81.599z" />
      <path d="m512 343.189v-218.283h-101.823v218.283c0 8.284 6.716 15 15 15h71.823c8.284 0 15-6.716 15-15z" />
    </g>
  </svg>
);
