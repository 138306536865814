import { Row } from 'farmhub-ui-core';
import * as React from 'react';

import { SlideShow, SlideShowProps } from 'components';

import Section, { SectionProps } from '../BaseSection';

const SlideSection = ({
  children,
  sectionProps,
  slideShowProps,
}: SlideSectionProps): JSX.Element => (
  <Section {...sectionProps}>
    <SlideShow {...slideShowProps}>
      <Row>{children}</Row>
    </SlideShow>
  </Section>
);

export default SlideSection;

export interface SlideSectionProps {
  sectionProps?: Pick<SectionProps, Exclude<keyof SectionProps, 'children'>>;
  slideShowProps: Pick<
    SlideShowProps,
    Exclude<keyof SlideShowProps, 'children'>
  >;
  children: React.ReactNode;
  name?: string;
}
