import { Card } from 'antd';
import { Row, Space, StarFilled, Text } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { theme } from 'styled';
import { BANNER } from 'utils';

import { PreviewSeoWrapper, CCard, CMeta } from './styles';

const Li = styled.li`
  list-tyle: none;
  padding-left: 24px;
  margin-top: 12px;
  position: relative;

  &::after {
    content: ' ';
    width: 8px;
    height: 8px;

    border-radius: 50%;
    background: ${props => props.theme.colors.primary.main};
    position: absolute;
    top: 6px;
    left: 6px;
  }
`;

const REQUIRES = [
  'Tiêu đề rõ ràng, ngắn gọn bao quát hết nội dung',
  'Mô tả nên chứa những từ khóa quan trọng.',
  'Kích thước Banner tiêu chuẩn: 1200px x 630px',
];

interface PreviewDecorationProps {
  displayName?: string;
  banner?: string;
  title?: string;
  description?: string;
  icon?: string | any;
}

const PreviewSeo = ({
  data,
}: {
  data: PreviewDecorationProps;
}): JSX.Element => {
  const handleCheckType = (val: any) =>
    typeof val == 'object' ? URL.createObjectURL(val) : val;

  return (
    <PreviewSeoWrapper>
      <Row width="100%">
        <Text
          weight="extraBold"
          color="main"
          styles={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.colors.primary.main}`,

            flexShrink: 0,
          }}
        >
          1
        </Text>
        <Space width={12} />
        <Text weight="bold">
          Nội dung SEO sẽ được hiển thị trên Facebook, Zalo ...
        </Text>
      </Row>
      <Space height={18} />
      <CCard
        hoverable={false}
        style={{
          width: '100%',
          maxWidth: '520px',
        }}
        cover={
          <img alt="example" src={handleCheckType(data.banner || BANNER)} />
        }
      >
        <CMeta
          title={`${data?.title || 'Tiêu đề'}|${
            data?.displayName || 'Tên hiển thị'
          }`}
          description={
            <Text lineNumber={3}>{data?.description || 'Mô tả website'}</Text>
          }
        />
      </CCard>
      <Space height={24} />
      <Row>
        <Text
          weight="extraBold"
          color="main"
          styles={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.colors.primary.main}`,

            flexShrink: 0,
          }}
        >
          2
        </Text>
        <Space width={12} />
        <Text weight="bold">Các lưu ý để SEO tốt trên Google</Text>
      </Row>
      <Space height={12} />
      {REQUIRES.map(item => (
        <Row alignItems="center" styles={{ marginTop: 8 }}>
          <StarFilled style={{ color: '#FF6347' }} />
          <Space width={6} />
          <Text size="xxs">{item}</Text>
        </Row>
      ))}
    </PreviewSeoWrapper>
  );
};

export default PreviewSeo;
