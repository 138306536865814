import { Text, Space, Modal, Row } from 'farmhub-ui-core';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';

import { NameText, SlideShowImage, TitleText } from 'components';

interface ViewImageModalProps {
  closeModel: (e: any) => void;
  visible: boolean;
  image: any;
  setImage?: (e: any) => void;
  imageList: Array<any>;
}

const ViewImageModal = ({
  closeModel,
  visible,
  image,
  setImage,
  imageList,
}: ViewImageModalProps): JSX.Element => (
  <Modal
    styles={ModalStyles}
    width="100vw"
    height="100vh"
    bodyPadding="0px"
    title="Hình ảnh"
    visible={visible}
    onCancel={closeModel}
  >
    <WrapLeft>
      <ImageActive src={image.src} />
      <WrapListImage>
        <SlideShowImage spacing={12} width={528}>
          <Row>
            {imageList.map((item, index) => (
              <Row
                onClick={() => setImage && setImage(item)}
                width="fit-content"
                key={item.key}
              >
                <WrapImage selected={image.src === item.src}>
                  <ImageItem src={item.src} />
                </WrapImage>
                {index < imageList.length - 1 && <Space width={12} />}
              </Row>
            ))}
          </Row>
        </SlideShowImage>
      </WrapListImage>
    </WrapLeft>
    <WrapRight>
      <Space height={14} />
      <Row>
        <Space width={18} />
        <TitleText>{image.name || 'Chưa có tiêu đề'}</TitleText>
      </Row>
      <Space height={12} />
      <WrapContent>
        <Row>
          <NameText>Ngày tạo:</NameText>
        </Row>
        <Row>
          <Text>{moment(image.createdAt).format('DD/MM/YYYY')}</Text>
        </Row>
        <Space height={24} />
        <Row>
          <NameText>Vị trí:</NameText>
        </Row>
        <Row>
          <Text>{image.address || 'Chưa có'}</Text>
        </Row>
        <Space height={24} />
        <Row>
          <NameText>Chú thích:</NameText>
        </Row>
        <Row>
          <Text>{image.description || 'Chưa có'}</Text>
        </Row>
      </WrapContent>
    </WrapRight>
  </Modal>
);

export default ViewImageModal;

const ModalStyles = css`
  width: 100vw !important;
  max-width: 100vw !important;
  top: 0px !important;
  padding: 0px !important;

  .ant-modal-content {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0px;

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      height: 100%;
      padding: 0px;

      display: flex;
    }
  }
`;

const WrapLeft = styled.div`
  width: calc(100vw - 420px);
  height: 100%;

  padding: 0px 24px;
`;

const WrapListImage = styled.div`
  padding: 24px;

  display: flex;
  justify-content: center;
`;

const ImageActive = styled.img`
  margin-top: 20px;
  width: 100%;
  height: calc(100vh - 164px);

  object-fit: scale-down;
`;

const WrapImage = styled.div<{
  selected?: boolean;
}>`
  width: 96px;
  height: 96px;
  background: #fff;
  border-radius: 6px;
  ${props =>
    props.selected ? `box-shadow: 0px 2px 4px #CCC;` : 'box-shadow: none;'}

  flex-shrink: 0;

  object-fit: cover;
  position: relative;

  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${props =>
      props.selected ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.2)'};

    border-radius: 6px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

const ImageItem = styled.img`
  width: 96px;
  height: 96px;

  border-radius: 6px;
  object-fit: cover;
`;

const WrapRight = styled.div`
  width: 420px;
  height: 100%;
  background: #fff;
`;

const WrapContent = styled.div`
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 18px;
`;
