import { TextProps } from 'farmhub-ui-core';
import styled, { css } from 'styled-components';

const PriceText = styled.span<PriceTextProps>`
  word-break: break-word;
  color: ${p => p.theme.colors.text.price};
  line-height: ${p => p.lineHeight || '22px'};
  font-size: ${p => p.theme.font.size[p.size || 'xs']};
  font-weight: ${p => p.theme.font.weight.bold} !important;
  ${p =>
    p.textTransform &&
    css`
      text-transform: ${p.textTransform};
    `};
  ${p =>
    p.capitalizeFirstLetter &&
    css`
      &::first-letter {
        text-transform: capitalize !important;
      }
    `};
  ${p => p.styles}

  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.lineNumber};
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export default PriceText;

type PriceTextProps = Pick<
  TextProps,
  Exclude<keyof TextProps, 'weight' | 'color'>
>;
