import { Row } from 'farmhub-ui-core';
import styled from 'styled-components';

import { TitleText } from 'components';

interface MenuTitleProps {
  title: string;
}

const MenuTitle = ({ title }: MenuTitleProps): JSX.Element => (
  <Row justifyContent="space-between">
    <Title key={title}>
      <TitleText className="titleText">{title}</TitleText>
    </Title>
    {/* {title === 'Bán hàng' && <AddGroupModal />} */}
  </Row>
);

export default MenuTitle;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 18px;
  .titleText {
    overflow: hidden;
    white-space: nowrap;
  }
`;
