import { Table as AntTable } from 'antd';
import styled, { css } from 'styled-components';

const TableStyles = css`
  .ant-checkbox-inner {
    background-color: #f1f1f1;
    display: inline-block;
    height: 22px;
    position: relative;
    width: 44px;
    border-radius: 999px;
    border: none;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #ecf3eb;
    border-color: none;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox > .ant-checkbox-inner::after {
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    opacity: 1 !important;
    transform: none;
  }

  .ant-checkbox-inner::after {
    width: 18px;
    height: 18px;
    background-color: #fff;
  }

  .ant-checkbox-checked > .ant-checkbox-inner::after {
    transform: translate(22px, 0px);
    opacity: 1 !important;
  }
`;

const ChildTableStyles = css`
  .ant-checkbox-inner {
    background-color: #f1f1f1;
    display: inline-block;
    height: 18px;
    position: relative;
    width: 36px;
    border-radius: 999px;
    border: none;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox > .ant-checkbox-inner::after {
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    opacity: 1 !important;
    transform: none;
  }

  .ant-checkbox-inner::after {
    width: 14px;
    height: 14px;
    background-color: #fff;
  }

  .ant-checkbox-checked > .ant-checkbox-inner::after {
    transform: translate(19px, 0px);
    opacity: 1 !important;
  }
`;

export const Table = styled(AntTable)<{
  switchSize: string;
  styles?: any;
}>`
  width: 100%;
  .ant-table-container table > thead > tr:first-child th:last-child {
    width: 120px;
  }

  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${p => p.theme.colors.primary.main};
  }

  ${p => p.switchSize === 'default' && TableStyles}
  ${p => p.switchSize === 'small' && ChildTableStyles}
  ${p => p.styles}
`;
