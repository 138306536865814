import { Text, Space, Spacing, Row, Column, StarFilled } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import NODATA from 'assets/images/no_data/default_image.png';
import { theme } from 'styled';

import PreviewScale from './PreviewScale';

const InformationWrapper = styled.div<{ background: any; height?: number }>`
  width: 100%;
  position: relative;
  height: ${p => (p?.height ? p.height : 600)}px;
  max-height: 100vh;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    content: ' ';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Introduce = styled.div`
  position: absolute;
  width: 100%;

  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transform: translate(-50%, -50%);

  z-index: 1;

  .title {
    font-size: 36px;
  }
`;

const Avatar = styled.img`
  width: 52px;
  height: 52px;
  background: #ffffff;
  object-fit: contain;

  border-radius: 50%;

  cursor: pointer;
`;

const Header = styled.div<{ color: string }>`
  width: 100%;
  height: 72px;
  background: ${props =>
    props?.color ? props.color : props.theme.colors.primary.main};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavHeader = styled.div`
  width: 100%;
  height: 62px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavItem = styled.div<{ isActive: boolean }>`
  span {
    ${props =>
      props?.isActive &&
      `
        color: #FFBA07 !important;
      `}
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Li = styled.li`
  list-tyle: none;
  padding-left: 24px;
  margin-top: 12px;
  position: relative;

  &::after {
    content: ' ';
    width: 8px;
    height: 8px;

    border-radius: 50%;
    background: ${props => props.theme.colors.primary.main};
    position: absolute;
    top: 6px;
    left: 6px;
  }
`;

const REQUIRES = [
  'Tiêu đề rõ ràng, ngắn gọn bao quát hết nội dung',
  'Màu sắc website nên chọn những màu sắc nổi bật',
  'Kích thước Banner tiêu chuẩn: 1920px x 1200px',
];

interface PreviewDecorationProps {
  banner?: string;
  title?: string;
  displayName?: string;
  avatar?: string | any;
  theme?: any;
}

const PreviewHubAdmin = ({
  data,
}: {
  data: PreviewDecorationProps;
}): JSX.Element => {
  const handleCheckType = (val: any) =>
    typeof val == 'object' ? URL.createObjectURL(val) : val;

  return (
    <>
      <Row>
        <Text
          weight="extraBold"
          color="main"
          styles={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.colors.primary.main}`,

            flexShrink: 0,
          }}
        >
          1
        </Text>
        <Space width={12} />
        <Text weight="bold">
          Nội dung sẽ được hiển trị trên trang bán hàng của bạn
        </Text>
      </Row>
      <Space height={18} />
      <PreviewScale triggerCalcHeight={false}>
        <Header color={data?.theme?.color} className="u-padding-horizontal">
          <NavHeader className="u-content">
            <Row width="fit-content">
              <Space width={12} />
              <Avatar src={handleCheckType(data?.avatar ?? NODATA)} />
              <Space width={24} />
              <Menu>
                <Spacing styles={{ gap: '24px !important' }}>
                  {[
                    'Trang chủ',
                    'Giới thiệu',
                    'Sản phẩm',
                    'Thư viện',
                    'Chuỗi cửa hàng',
                    'Cộng sự bán hàng',
                  ].map(item => (
                    <NavItem isActive={false}>
                      <Text
                        weight="bold"
                        size="xs"
                        lineHeight="14px"
                        styles={{ color: '#FFFFFF', cursor: 'pointer' }}
                      >
                        {item}
                      </Text>
                    </NavItem>
                  ))}
                </Spacing>
              </Menu>
            </Row>

            <Row width="fit-content" alignItems="center" />
          </NavHeader>
        </Header>
        <Column width="100%" alignItems="center">
          <InformationWrapper
            height={600}
            background={handleCheckType(data?.banner ?? NODATA)}
            className="u-padding-horizontal"
          >
            <Introduce className="u-content">
              <Text
                className="title"
                color="white"
                weight="extraBold"
                styles={{
                  fontSize: 48,

                  textAlign: 'center',
                }}
              >
                {data?.title || 'Nông sản sạch'}
              </Text>
              <Text
                className="title"
                color="white"
                styles={{
                  fontSize: 48,
                  fontWeight: '400 !important',
                  fontFamily: 'Dancing Script, cursive',
                  textAlign: 'center',
                }}
              >
                _{data?.displayName}_
              </Text>
              <Space height={24} />
            </Introduce>
          </InformationWrapper>
        </Column>
      </PreviewScale>
      <Space height={24} />
      <Row>
        <Text
          color="main"
          weight="extraBold"
          styles={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.colors.primary.main}`,
          }}
        >
          2
        </Text>
        <Space width={12} />
        <Text weight="bold">Các lưu ý để hiển thị thông tin tốt nhất</Text>
      </Row>
      <Space height={12} />
      {REQUIRES.map(item => (
        <Row alignItems="center" styles={{ marginTop: 8 }}>
          <StarFilled style={{ color: '#FF6347' }} />
          <Space width={6} />
          <Text size="xxs">{item}</Text>
        </Row>
      ))}
    </>
  );
};

export default PreviewHubAdmin;
