import { Tooltip, TooltipPlacement } from 'farmhub-ui-core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Badge, NameText } from 'components';
import { renderIconMenu } from 'containers/App/utils/function';

interface MenuItemProps {
  isToggle: boolean;
  menuItem: any;
  menuActive: string;
  onClick: () => void;
}

const MenuItem = ({
  isToggle,
  menuItem,
  menuActive,
  onClick,
}: MenuItemProps): JSX.Element => {
  const [isDisplay, setIsDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (isToggle) {
      setTimeout(() => {
        setIsDisplay(isToggle);
      }, 250);
    } else {
      setIsDisplay(isToggle);
    }
  }, [isToggle]);

  return (
    <Tooltip
      title={!isToggle && `${menuItem.name}`}
      placement={TooltipPlacement.Right}
    >
      <Wrapper
        key={menuItem.link}
        onClick={onClick}
        active={
          menuActive === menuItem.link ||
          (menuActive !== `/` && menuItem.link.includes(menuActive))
        }
      >
        {renderIconMenu(menuItem.name)}
        {isToggle && (
          <NameText className="textMenu" style={{ marginLeft: 10 }}>
            {menuItem.name}
          </NameText>
        )}
        <WrapperQuantity isToggle={isToggle} isDisplay={isDisplay}>
          <Badge count={menuItem.quantity} />
        </WrapperQuantity>
      </Wrapper>
    </Tooltip>
  );
};

export default MenuItem;

const Wrapper = styled.li<{
  active: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 18px;
  color: ${props => (props.active ? props.theme.colors.primary.main : null)};
  background: ${props =>
    props.active ? props.theme.colors.navbar.main : null};
  border-radius: 6px;
  transition: color 0.5s;
  cursor: pointer;
  .textMenu {
    transition: color 0.5s;
    color: ${props =>
      props.active
        ? props.theme.colors.primary.main
        : props.theme.colors.text.primary} !important;
    font-weight: ${props =>
      props.active
        ? `${props.theme.font.weight.extraBold} !important`
        : `${props.theme.font.weight.medium} !important`};
    overflow: hidden;
    white-space: nowrap;
  }

  .icon {
    transition: color 0.5s;
    color: ${props =>
      props.active
        ? props.theme.colors.primary.main
        : props.theme.colors.text.primary};
  }

  &:hover {
    background: ${props => props.theme.colors.navbar.main};
  }
`;

const WrapperQuantity = styled.div<{ isToggle?: boolean; isDisplay?: boolean }>`
  position: absolute;
  ${p =>
    p.isToggle
      ? p.isDisplay
        ? 'right: 18px;'
        : 'display: none;'
      : 'left: 32px; top: 6px;'}
`;
