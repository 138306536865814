import { Space, Text, Avatar, AvatarShape } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { TitleText } from 'components';
import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

interface ActivityTypeCardProps {
  onClick?: () => void;
  item: any;
}

const ActivityTypeCard = ({
  item,
  onClick,
}: ActivityTypeCardProps): JSX.Element => (
  <Wrapper onClick={onClick}>
    <WrapperAvatar background={item.background}>
      <Avatar
        shape={AvatarShape.Square}
        src={formatImageUrl(item.avatar, IMAGETYPE.AVATAR.xxs)}
        size={64}
      />
    </WrapperAvatar>
    <Space height={18} />
    <TitleText>{item.name}</TitleText>
    <Space height={6} />
    <Text>{item.description}</Text>
  </Wrapper>
);

export default ActivityTypeCard;

const Wrapper = styled.div`
  width: 100%;
  height: 282px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 18px;
`;

const WrapperAvatar = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${props => props.background};
`;
