import {
  CaretDownOutlined,
  LogoutOutlined,
  UserOutlined,
  Dropdown,
  Icon,
  Spacing,
} from 'farmhub-ui-core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import logoFullTextBeta from 'assets/images/logo_full_text_beta.png';
import { Action, NameText, TinyText } from 'components';
import { env } from 'env';
import { logout } from 'slice';
import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl, truncate } from 'utils/function';

interface User {
  fullName: string;
  email: string;
  avatar: string;
}

interface HeaderNavbarProps {
  user: User;
  onLogoutUser: any;
}

const HeaderNavbar = ({
  user,
  onLogoutUser,
}: HeaderNavbarProps): JSX.Element => {
  const dispatch = useDispatch();
  const handleRedirect = (): void => {
    const win: any = window.open(env.hubtech.hubhubWebApp, '_blank');
    win.focus();
  };

  useEffect(() => {
    const handleInvalidToken = (e): void => {
      if (e.key === 'USER_ID') {
        if (e.oldValue && !e.newValue) {
          dispatch(logout());
        }

        if (e.oldValue && e.newValue && e.oldValue !== e.newValue) {
          window.location.reload();
        }
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, logout]);

  const dropdownItems = [
    <UserInfo>
      <Spacing direction="vertical">
        <NameText lineNumber={2}>{user.fullName}</NameText>
        <TinyText>{user.email}</TinyText>
      </Spacing>
    </UserInfo>,
    <Action
      onClick={() => console.log('click')}
      dropdownItemProps={{
        icon: {
          children: (
            <Icon>
              <UserOutlined />
            </Icon>
          ),
        },
        key: '1',
      }}
    >
      Thông tin của tôi
    </Action>,
    <Action
      onClick={onLogoutUser}
      dropdownItemProps={{
        icon: {
          children: (
            <Icon>
              <LogoutOutlined />
            </Icon>
          ),
        },
        key: '2',
      }}
    >
      Thoát tài khoản
    </Action>,
  ];

  return (
    <Wrapper id="header">
      <LogoBar>
        <Logo src={logoFullTextBeta} onClick={handleRedirect} />
        {/* <Space width={10} /> */}
        {/* <WrapperText> */}
        {/*  <NameText style={{ fontSize: 26 }}>FarmCheck</NameText> */}
        {/* </WrapperText> */}
      </LogoBar>
      <Spacing size="large">
        {/* <DivRow> */}
        {/*  <HomeFilled style={{ fontSize: '16px', color: '#39B54A' }} /> */}
        {/*  <Space width={12} /> */}
        {/*  <NameText */}
        {/*    styles={{ cursor: 'pointer' }} */}
        {/*    onClick={handleRedirect} */}
        {/*    color="green" */}
        {/*  > */}
        {/*    Trang chủ HubHub */}
        {/*  </NameText> */}
        {/* </DivRow> */}
        <Dropdown arrow items={dropdownItems} containerId="header">
          <WrapperAvatar>
            <Avatar src={formatImageUrl(user.avatar, IMAGETYPE.AVATAR.xxs)} />
            <InfoAvatar>
              <TinyText>Tài khoản</TinyText>
              <DivRow>
                <span className="name-text">{truncate(user.fullName, 16)}</span>
                <CaretDownOutlined />
              </DivRow>
            </InfoAvatar>
          </WrapperAvatar>
        </Dropdown>
      </Spacing>
    </Wrapper>
  );
};

export default HeaderNavbar;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 0px 64px;
`;

const LogoBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
`;

const Logo = styled.img`
  width: 170px;
  height: auto;
  cursor: pointer;
`;

const WrapperAvatar = styled.div`
  width: auto;
  height: 52px;
  display: flex;
  align-items: center;
  .name-text {
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.xs};
    line-height: 22px !important;
    margin-right: 12px;
  }
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 52px;
  height: 52px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
  border-radius: 50%;
  margin-right: 12px;
`;

const InfoAvatar = styled.div`
  display: flex;
  width: 160px;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const DivRow = styled.div`
  display: flex;
  width: auto;
  align-items: center;
`;

const UserInfo = styled.div`
  width: 300px;
  padding: 24px;
  font-weight: ${props => props.theme.font.weight.regular};
  border-bottom: 1px solid #f1f1f1;
`;
