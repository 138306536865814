import {
  Popover,
  PopoverPlacement,
  Tooltip,
  TooltipPlacement,
} from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

interface IconWrapperProps {
  disabled?: boolean;
  displayBackground?: boolean;
  icon: JSX.Element;
  size?: 'xs' | 'sm' | 'md' | 'lg' | number;
  background?: string;
  styles?: any;
  hoverBackground?: string;
  hoverColorIcon?: string;
  tooltip?: string;
  popover?: JSX.Element;
  onClick?: (e: any) => void;
  containerId?: string;
}

const IconWrapper = ({
  disabled,
  icon,
  size = 'md',
  background,
  styles,
  hoverBackground,
  hoverColorIcon,
  displayBackground,
  tooltip,
  popover,
  onClick,
  containerId,
}: IconWrapperProps): JSX.Element => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    !disabled && onClick && onClick(e);
  };
  return (
    <>
      {!tooltip && !popover && (
        <Wrapper
          disabled={disabled}
          onClick={handleClick}
          size={size}
          background={background}
          hoverBackground={hoverBackground}
          displayBackground={displayBackground}
          hoverColorIcon={hoverColorIcon}
          styles={styles}
        >
          {icon}
        </Wrapper>
      )}
      {tooltip && (
        <Tooltip placement={TooltipPlacement.Top} title={tooltip}>
          <Wrapper
            disabled={disabled}
            onClick={handleClick}
            size={size}
            background={background}
            hoverBackground={hoverBackground}
            displayBackground={displayBackground}
            hoverColorIcon={hoverColorIcon}
            styles={styles}
          >
            {icon}
          </Wrapper>
        </Tooltip>
      )}
      {popover && (
        <Popover
          placement={PopoverPlacement.LeftTop}
          content={popover}
          mouseEnterDelay={1}
          containerId={containerId || 'content'}
        >
          <Wrapper
            disabled={disabled}
            onClick={handleClick}
            size={size}
            background={background}
            hoverBackground={hoverBackground}
            displayBackground={displayBackground}
            hoverColorIcon={hoverColorIcon}
            styles={styles}
          >
            {icon}
          </Wrapper>
        </Popover>
      )}
    </>
  );
};

export default IconWrapper;

const Wrapper = styled.div<{
  disabled?: boolean;
  displayBackground?: boolean;
  size: string | number;
  background?: string;
  hoverBackground?: string;
  hoverColorIcon?: string;
  styles?: any;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.button.icon[props.size]}px;
  height: ${props => props.theme.button.icon[props.size]}px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${props =>
    props.displayBackground
      ? props.background
        ? props.background
        : '#F8F8F8 !important'
      : 'none'};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  /* transition: background-color 0.2s; */
  /* 
  path {
    fill: ${props => props.theme.colors.text.secondary};
  } */
  ${p => p.disabled && `pointer-events: none;`}
  &:hover {
    background: ${props =>
      props.displayBackground
        ? props.hoverBackground
          ? props.hoverBackground
          : 'rgba(57, 181, 74, 0.1) !important'
        : 'none'};

    > svg path {
      fill: ${props =>
        props?.hoverColorIcon
          ? props.hoverColorIcon
          : props.theme.colors.primary.main};
    }
  }
  ${p => p.styles};
`;
