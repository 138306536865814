import styled from 'styled-components';

export const Wrapper = styled.div<{
  padding?: number;
  background?: string;
  radius?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background: ${p => p.background || '#fff'};
  padding: ${props =>
    props.padding
      ? `${props.padding}px`
      : `0px 0px ${props.theme.app.padding.vertical} 0px`};
  border-bottom: 1px solid ${p => p.theme.colors.border.light};
  border-radius: ${p => p.radius};
`;
