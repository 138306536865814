import React from 'react';

import { IconProps } from './Icon';

export const ErrorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_112_5529)">
      <path
        d="M20.4891 3.51091C15.8069 -1.1703 8.19233 -1.1703 3.51013 3.51091C-1.17004 8.19211 -1.17004 15.8086 3.51013 20.4899C5.85123 22.83 8.92566 23.9995 12.0001 23.9995C15.0746 23.9995 18.148 22.8299 20.4891 20.4899C25.1703 15.8087 25.1703 8.19211 20.4891 3.51091ZM16.9514 15.5375C17.3426 15.9287 17.3426 16.561 16.9514 16.9522C16.7563 17.1473 16.5002 17.2453 16.2441 17.2453C15.988 17.2453 15.7318 17.1473 15.5367 16.9522L12.0001 13.4145L8.46443 16.9512C8.26835 17.1462 8.0122 17.2443 7.75708 17.2443C7.50097 17.2443 7.24482 17.1462 7.04973 16.9512C6.65857 16.56 6.65857 15.9267 7.04973 15.5365L10.5854 11.9999L7.04875 8.46322C6.65758 8.07206 6.65758 7.43876 7.04875 7.04858C7.43893 6.65741 8.07223 6.65741 8.4634 7.04858L12 10.5852L15.5367 7.04858C15.9279 6.65741 16.5602 6.65741 16.9513 7.04858C17.3425 7.43876 17.3425 8.07206 16.9513 8.46322L13.4147 11.9999L16.9514 15.5375Z"
        fill="#F05540"
      />
    </g>
    <defs>
      <clipPath id="clip0_112_5529">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
