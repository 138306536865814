import { Skeleton } from 'antd';
import {
  CaretDownOutlined,
  IconProps,
  Select,
  SelectOption,
} from 'farmhub-ui-core';
import * as React from 'react';

import { theme } from 'styled';
import { SELECT_MODE } from 'utils/constants';
import { filterOption } from 'utils/function';

interface CSelectProps {
  loading?: boolean;
  placeholder?: string;
  icon?: IconProps;
  containerId?: string;
  value?: any;
  width?: string;
  height?: 'xs' | 'sm' | 'md' | 'lg';
  styles?: any;
  onChange?: (value: any) => void;
  onSearch?: (value: any) => void;
  onPopupScroll?: (e: any) => void;
  onFocus?: () => void;
  color?: string;
  weight?: string;
  disabled?: boolean;
  defaultValue?: any;
  showSearch?: boolean;
  filterOption?: any;
  list?: Array<any>;
  notFoundContent?: React.ReactNode;
  allowClear?: boolean;
  dropdownMatchSelectWidth?: boolean;
  children?: React.ReactNode;
  optionLabelProp?: string;
  mode?: 'multiple' | 'tags' | undefined;
  tagRender?: any;
}

const CSelect = ({
  loading = false,
  list = [],
  icon,
  height = 'md',
  mode,
  children,
  defaultValue,
  ...props
}: CSelectProps): JSX.Element => (
  <>
    {loading ? (
      <Skeleton.Input style={{ width: 178, height: 34, borderRadius: '6px' }} />
    ) : (
      <Select
        loading
        mode={mode}
        maxTagCount={mode === SELECT_MODE.MULTIPLE ? 'responsive' : undefined}
        showSearch
        // dropdownMatchSelectWidth={false}
        filterOption={(input: string, option: any) =>
          filterOption(input, option)
        }
        height={theme.select.height[height]}
        icon={{ children: <CaretDownOutlined /> }}
        allowClear={mode === SELECT_MODE.MULTIPLE}
        defaultValue={defaultValue}
        placeholder={props.placeholder}
        {...props}
      >
        {children ||
          (list.length > 0 &&
            list.map((item: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <SelectOption label={item.name} key={index} value={item.value}>
                {item.name}
              </SelectOption>
            )))}
      </Select>
    )}
  </>
);

export default CSelect;
