import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ORDER_STATUS } from 'utils/constants';
import { genOrderStatus } from 'utils/function';

import { Wrapper } from './styles';

const OrderStatus = ({
  status,
  padding,
  fontSize,
  label,
  width,
  styles,
}: OrderStatusProps): JSX.Element => {
  const history = useHistory();
  const [orderStatus, setOrderStatus] = React.useState<any>({});

  React.useEffect(() => {
    const getData = () => {
      const data = genOrderStatus(status);

      if ((history?.location?.pathname ?? '').includes('sale')) {
        if (status == ORDER_STATUS.SUPPLIER_CONFIRM) {
          setOrderStatus({
            ...data,
            name: 'Chờ bạn xác nhận',
          });

          return;
        }

        if (status == ORDER_STATUS.SUPPLIER_DRAFT) {
          setOrderStatus({
            ...data,
            name: 'Bản nháp của bạn',
          });
          return;
        }
      }

      if ((history?.location?.pathname ?? '').includes('purchase')) {
        if (status == ORDER_STATUS.CUSTOMER_CONFIRM) {
          setOrderStatus({
            ...data,
            name: 'Chờ bạn xác nhận',
          });
          return;
        }

        if (status == ORDER_STATUS.CUSTOMER_DRAFT) {
          setOrderStatus({
            ...data,
            name: 'Bản nháp của bạn',
          });
          return;
        }
      }

      setOrderStatus({
        ...data,
      });
    };

    getData();
  }, [status]);

  return (
    <Wrapper
      background={orderStatus.color}
      padding={padding}
      fontSize={fontSize}
      width={width}
    >
      {/* {showIcon && ( */}
      {/*  <> */}
      {/*     <Icon>{orderStatus.icon}</Icon> */}
      {/*     <Space width={6} /> */}
      {/*  </> */}
      {/* )} */}

      {/* <Text
        styles={{
          whiteSpace: 'nowrap',
          textAlign: 'left',
          color: `${orderStatus.color} !important`,
        }}
      >
        {label || orderStatus.name}
      </Text> */}
      {label || orderStatus.name}
    </Wrapper>
  );
};

export default OrderStatus;

interface OrderStatusProps {
  status: number;
  padding?: string;
  fontSize?: string;
  label?: string;
  width?: string;
  styles?: any;
}
