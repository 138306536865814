import React from 'react';

import { IconProps } from './Icon';

export const PlusFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m4.96957 1c-2.17175 0-3.96957 1.79782-3.96957 3.96957v14.06083c0 2.1718 1.79782 3.9696 3.96957 3.9696h14.06083c2.1718 0 3.9696-1.7978 3.9696-3.9696v-14.06083c0-2.17175-1.7978-3.96957-3.9696-3.96957zm.04785 11c0-.5283.42825-.9565.95652-.9565h5.06956v-5.0694c0-.52827.4282-.95652.9565-.95652s.9565.42825.9565.95652v5.0694h5.0696c.5283 0 .9565.4282.9565.9565s-.4282.9565-.9565.9565h-5.0696v5.0698c0 .5283-.4282.9565-.9565.9565s-.9565-.4282-.9565-.9565v-5.0698h-5.06956c-.52827 0-.95652-.4282-.95652-.9565z"
      fillRule="evenodd"
    />
  </svg>
);
