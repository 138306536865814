import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined, Row as RowDiv, Space } from 'farmhub-ui-core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ActionButton, NameText } from 'components';
import { DiagramIcon, TableIcon, TrelloChartIcon } from 'components/Icon';

const ListButton = (): JSX.Element => {
  const [displayType, setDisplayType] = useState('table');
  const menu = (): JSX.Element => (
    <Menu>
      <CMenuItem onClick={() => setDisplayType('table')}>
        {' '}
        Tổng hợp theo DM{' '}
      </CMenuItem>
      <CMenuItem onClick={() => setDisplayType('chart')}>
        Dạng Biểu đồ
      </CMenuItem>
      <CMenuItem onClick={() => setDisplayType('column')}>Dạng cột</CMenuItem>
    </Menu>
  );
  const renderDisplayType = (type: string): JSX.Element => {
    switch (type) {
      case 'table':
        return (
          <RowDiv
            style={{ position: 'relative' }}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <RowDiv>
              <WrapperIcon>
                <TableIcon width={20} height={20} color="#39B54A" />
              </WrapperIcon>
              <Space width={48} />
              <NameText color="main"> Tổng hợp theo DM </NameText>
            </RowDiv>
            <Space width={6} />
            <CaretDownOutlined />
          </RowDiv>
        );
      case 'chart':
        return (
          <RowDiv
            style={{ position: 'relative' }}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <RowDiv>
              <WrapperIcon>
                <DiagramIcon width={20} height={20} color="#39B54A" />
              </WrapperIcon>
              <Space width={48} />
              <NameText color="main">Dạng biểu đồ</NameText>
            </RowDiv>
            <Space width={6} />
            <CaretDownOutlined />
          </RowDiv>
        );
      case 'column':
        return (
          <RowDiv
            style={{ position: 'relative' }}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <RowDiv>
              <WrapperIcon>
                <TrelloChartIcon width={20} height={20} color="#39B54A" />
              </WrapperIcon>
              <Space width={48} />
              <NameText color="main">Dạng cột</NameText>
            </RowDiv>
            <Space width={6} />
            <CaretDownOutlined />
          </RowDiv>
        );
      default:
        return <div>Default</div>;
    }
  };
  return (
    <Dropdown trigger={['click']} overlay={menu()} placement="bottomRight">
      <ActionButton height="44px" width="230px">
        {renderDisplayType(displayType)}
      </ActionButton>
    </Dropdown>
  );
};

const WrapperIcon = styled.div`
  position: absolute;
  left: 0px;
  top: 2px;
`;

const CMenuItem = styled(Menu.Item)`
  padding: 12px 24px !important;
  font-weight: ${props => props.theme.font.weight.regular} !important;
`;

export default ListButton;
