/* eslint-disable */

import { Skeleton } from 'antd';
import location from 'commons/location.json';
import {
  BoxColorIcon,
  CheckFilledIcon,
  ColorUserIcon,
  FarmerColorIcon,
  IncognitoColorIcon,
  PeopleLinkColorIcon,
  RejectFilledIcon,
  ShopColorIcon,
  StopFilledIcon,
  TruckColorIcon,
  VolunteerColorIcon,
  WaitingFilledIcon,
} from 'components/Icon';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  Notification,
  Spacing,
  SyncOutlined,
  Text,
  CheckSquareOutlined,
  Space,
  Row,
  ErrorIcon,
} from 'farmhub-ui-core';
import moment from 'moment';

import { unwrapResult } from '@reduxjs/toolkit';
import { theme } from 'styled';
import {
  CELLPHONE_REGEX,
  COLLABORATOR_TEXT,
  ImageSize,
  LANDLINE_REGEX,
  MENU_TITLE_DOMAIN_BY_STATUS,
  MENU_TITLE_ORDER_BY_STATUS,
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  PACKAGE_TYPE,
  PACKAGE_TYPE_TEXT,
  SMS_APP_TYPE,
  SMS_APP_TYPE_TEXT,
  USER_CUSTOM_DOMAIN_TEXT,
  USER_CUSTOM_DOMAIN_TYPE,
} from './constants';

export const formatMoney = number => {
  if (!number || number === 0) return 0;
  // if (!number) return '';
  return (
    number &&
    number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/-/g, '- ')
  );
};

export const checkOnlySpaces = (str: string) => str.trim().length === 0;

export const checkOnlyNumber = (str: string) => /^(?:\d*)$/.test(str);

export const checkFirstCharIsNotSpace = (str: string) => str.charAt(0) === ' ';

export const formatAppearPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return 'Chưa có liên hệ';
  }

  const cleaned = `${phoneNumber}`.replace(/\D/g, '').replace('84', '0');
  const cellPhoneMatch = cleaned.match(CELLPHONE_REGEX);
  const landlineMatch = cleaned.match(LANDLINE_REGEX);

  if (cellPhoneMatch) {
    // For Phone 10 number
    if (cellPhoneMatch[0].length === 10)
      return `${[
        cellPhoneMatch[0].slice(0, 4),
        cellPhoneMatch[0].slice(4, 7),
        cellPhoneMatch[0].slice(7, 10),
      ].join(` `)}`;

    // For Phone 11 number
    return `${[
      cellPhoneMatch[0].slice(0, 3),
      cellPhoneMatch[0].slice(3, 5),
      cellPhoneMatch[0].slice(5, 8),
      cellPhoneMatch[0].slice(8, 11),
    ].join(' ')}`;
  }

  if (landlineMatch) {
    return `${landlineMatch[1]} ${[
      landlineMatch[6].slice(0, 3),
      landlineMatch[6].slice(3, 6),
      landlineMatch[6].slice(6),
    ].join(' ')}`;
  }
  return phoneNumber;
};

export function removeVietnameseTones(str: string) {
  if (!str) return '';
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  return str;
}

export const detechTime = createdAt => {
  const diffMinute = moment().diff(createdAt, 'm');
  if (diffMinute === 0) {
    return 'Mới gần đây';
  }
  if (diffMinute < 60) {
    return `${diffMinute} phút trước`;
  }
  if (diffMinute < 60 * 24) {
    return `${Math.floor(diffMinute / 60) + 1} giờ trước`;
  }
  if (diffMinute < 60 * 24 * 30) {
    return `${Math.floor(diffMinute / (60 * 24)) + 1} ngày trước`;
  }
  if (diffMinute < 60 * 24 * 30 * 12) {
    return `${Math.floor(diffMinute / (60 * 24 * 30)) + 1} tháng trước`;
  }
  return `${Math.floor(diffMinute / (60 * 24 * 30 * 12)) + 1} năm trước`;
};

export const renderObjectType = type => {
  switch (type) {
    case 0:
      return <Text>Cây</Text>;
    case 1:
      return <Text>Luống</Text>;
    case 2:
      return <Text>Toàn bộ vùng</Text>;
    case 3:
      return <Text>Trang trại</Text>;
    case 4:
      return <Text>Nhà kính</Text>;
    case 5:
      return <Text>Khác</Text>;
    default:
      return '';
  }
};

export const isVietnamesePhoneNumber = (value: string): boolean => {
  if (!value) {
    return false;
  }

  const phoneNumber = value.trim();
  // /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/.test(number); //eslint-disable-line
  return CELLPHONE_REGEX.test(phoneNumber) || LANDLINE_REGEX.test(phoneNumber);
};

export const formatPhoneNumber = (value: string): string => {
  if (!value) {
    return '';
  }

  const number = value.trim();
  switch (number[0]) {
    case '0':
      return number.replace('0', '+84');
    case '8':
      return `+${number}`;
    default:
      return number;
  }
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(String(email).toLowerCase());
};

export const validateURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const getQueryString = ({
  search,
  field = '',
  fields = [] as string[],
  defaultValue = '',
  whiteList = [] as string[],
}): any => {
  const value: string = new URLSearchParams(search).get(field) || '';

  let fieldResults = {};
  if (fields.length > 0) {
    fields.forEach(key => {
      fieldResults[key] = new URLSearchParams(search).get(key) || '';
    });

    return fieldResults;
  }

  if (whiteList.length === 0) {
    return value || defaultValue;
  }
  if (whiteList.includes(value)) {
    return value;
  }

  return defaultValue;
};

export const truncate = (str: string, n: number) => {
  if (!str) return '';
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const truncateWords = (text: string, wordNumber: number) => {
  const wordList = text?.split(' ');
  return `${wordList?.slice(0, wordNumber).join(' ')}...`;
};

export const parseUtils = (obj, key, type) => {
  if (obj[key] || obj[key] === 0) {
    obj[key] = parseType(type, obj[key]);
  }
};

const parseType = (type, value) => {
  switch (type) {
    case 'string': {
      if (value === 0) {
        return '0';
      } else {
        return value.toString();
      }
    }

    case 'images':
      return (
        value &&
        value.map(data => {
          if (!data?.isVideo) {
            return {
              type: 0,
              url: data.url,
            };
          } else {
            return {
              type: 1,
              url: data[0].url[0],
            };
          }
        })
      );

    case 'video':
      return (
        value && {
          type: 1,
          url: value[0]?.url[0],
        }
      );

    case 'url':
      return value && value.map(data => data.url);

    case 'image':
      return value && value[0]?.url[0];

    default:
      return value && parseInt(value, 0);
  }
};

export const parseGalleryImage = async (url: string) => {
  const img = new Image();
  img.src = url;
  await img.decode();
  return {
    src: url,
    width: img.width,
    height: img.height,
  };
};

export const removeEmptyElement = (body: any) => {
  Object.keys(body).forEach(e => {
    if (body[e] === undefined || body[e] === null) {
      delete body[e];
    }
  });
};

export const renderTableSkeleton = columns => {
  const skeleton = columns.map((column, index) => {
    let renderContent;

    switch (column.type) {
      case 'avatar':
        renderContent = (
          <Spacing>
            <Skeleton.Avatar
              shape="circle"
              style={{
                width: theme.avatar.size.md,
                height: theme.avatar.size.md,
              }}
            />
            <Skeleton.Input size="small" style={{ width: column.width }} />
          </Spacing>
        );
        break;
      case 'image':
        renderContent = (
          <Spacing>
            <Skeleton.Input
              size="small"
              style={{
                width: theme.avatar.size.md,
                height: theme.avatar.size.md,
                borderRadius: 6,
              }}
            />
            <Skeleton.Input size="small" style={{ width: column.width }} />
          </Spacing>
        );
        break;
      case 'doubleInput':
        renderContent = (
          <Spacing direction="vertical">
            <Skeleton.Input size="small" style={{ width: column.width }} />
            <Skeleton.Input size="small" style={{ width: column.width }} />
          </Spacing>
        );
        break;
      case 'button':
        renderContent = <Skeleton.Button size="small" />;
        break;
      case 'banner':
        renderContent = (
          <Skeleton.Input style={{ width: column.width, height: 110 }} />
        );
        break;
      case 'quantityInput':
        renderContent = (
          <Row>
            <Skeleton.Input
              size="small"
              style={{
                width: column.width,
                height: column.width,
                borderRadius: '4px 0px 0px 4px',
              }}
            />
            <Space width={1}></Space>
            <Skeleton.Input
              size="small"
              style={{
                width: column.width + column.width,
                height: column.width,
              }}
            />
            <Space width={1}></Space>
            <Skeleton.Input
              size="small"
              style={{
                width: column.width,
                height: column.width,
                borderRadius: '0px 4px 4px 0px',
              }}
            />
          </Row>
        );
        break;
      default:
        renderContent = (
          <Skeleton.Input size="small" style={{ width: column.width }} />
        );
    }

    const result = {
      title: column.title,
      key: index.toString(),
      render: () => renderContent,
    };

    return column.width
      ? {
          ...result,
          width:
            column.type === 'image' && typeof column.width === 'number'
              ? column.width + 60
              : column.width,
        }
      : result;
  });

  return skeleton;
};

export const renderColorTag = (name: string) => {
  switch (name) {
    case 'Chiến dịch':
      return 'gold';
    case 'Mới':
      return 'green';
    case 'Free ship':
      return 'blue';
    case 'Organic':
      return 'red';
    case 'Free ship > 1000k':
      return 'cyan';
    case 'Hỗ trợ ship 30k':
      return 'geekblue';
    case 'Hữu cơ':
      return 'lime';
    case 'An toàn':
      return 'orange';
    default:
      return 'green';
  }
};

export const renderEmployeeRole = (role: number) => {
  switch (role) {
    case 0:
      return 'Nhân viên';
    case 1:
      return 'Quản lý';
    default:
      return 'Trống';
  }
};

export const getEntityInfo = (type: number) => {
  switch (type) {
    case 0:
      return {
        name: 'User',
        icon: <ColorUserIcon width={18} height={18} />,
      };
    case 1:
      return {
        name: 'Nông trại',
        icon: <FarmerColorIcon width={18} height={18} />,
      };
    case 2:
      return {
        name: 'Cửa hàng',
        icon: <ShopColorIcon width={18} height={18} />,
      };
    case 3:
      return {
        name: COLLABORATOR_TEXT.CAPITALIZE,
        icon: <PeopleLinkColorIcon width={18} height={18} />,
      };
    case 4:
      return {
        name: 'Kho chứa',
        icon: <BoxColorIcon width={18} height={18} />,
      };
    case 5:
      return {
        name: 'Vận chuyển',
        icon: <TruckColorIcon width={18} height={18} />,
      };
    case 6:
      return {
        name: 'Thiện nguyện',
        icon: <VolunteerColorIcon width={18} height={18} />,
      };
    case 7:
      return {
        name: 'Ẩn danh',
        icon: <IncognitoColorIcon width={18} height={18} />,
      };
    default:
      return {};
  }
};

export const genEntityName = (type: number) => {
  switch (type) {
    case 0:
      return 'Người dùng';
    case 1:
      return 'Nông trại';
    case 2:
      return 'Đại lý';
    case 3:
      return COLLABORATOR_TEXT.CAPITALIZE;
    case 4:
      return 'Kho chứa';
    case 5:
      return 'Vận chuyển';
    case 6:
      return 'Thiện nguyện';
    case 7:
      return 'Ẩn danh';
    default:
      return '';
  }
};

export const reportQuantity = (reportInfo: any) => {
  const { reportType, relativeQuantity, absoluteQuantity } = reportInfo;
  switch (reportType) {
    case 0:
      switch (relativeQuantity) {
        case 0:
          return 'Hết hàng';
        case 1:
          return 'Có ít';
        case 2:
          return 'Có vừa';
        case 3:
          return 'Có nhiều';
        default:
          return 0;
      }
    case 1:
      return formatMoney(absoluteQuantity);
    default:
      return 0;
  }
};

export const getRelativeQuantityColor = (reportInfo: any) => {
  const { reportType, relativeQuantity } = reportInfo;
  if (reportType === 0) {
    switch (relativeQuantity) {
      case 0:
        return theme.colors.text.red;
      case 1:
        return theme.colors.text.price;
      case 2:
        return theme.colors.text.orange;
      case 3:
        return theme.colors.text.green;
      default:
        return '#262626';
    }
  }
  return '#262626';
};

export const getOrderStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.PENDING,
        color: 'geekblue',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.PROCESSING,
        color: 'warning',
        icon: <SyncOutlined />,
      };
    case 2:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.DELIVERY,
        color: 'processing',
        icon: <SyncOutlined />,
      };
    case 3:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.CONFIRM,
        color: 'cyan',
        icon: <SyncOutlined />,
      };
    case 4:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.REJECT,
        color: 'error',
        icon: <CloseCircleOutlined />,
      };
    case 5:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.DRAF,
        color: 'purple',
        icon: <EditOutlined />,
      };
    case 6:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.COMPLETE,
        color: 'success',
        icon: <CheckCircleOutlined />,
      };
    case 7:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.PRE_PROCESSING,
        color: 'default',
        icon: <CheckCircleOutlined />,
      };
    case 8:
      return {
        name: MENU_TITLE_ORDER_BY_STATUS.SUPPLIER_CONFIRM,
        color: 'default',
        icon: <CheckSquareOutlined />, // TODO: update icon
      };
    default:
      return { name: 'Trống', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const genEntityStatus = (status: number, size = 12) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: (
          <CheckFilledIcon
            width={size}
            height={size}
            color={theme.colors.primary.main}
          />
        ),
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'default',
        icon: <StopFilledIcon width={size} height={size} color="#595959" />,
      };
    case 2:
      return {
        name: 'Chờ phê duyệt',
        color: 'warning',
        icon: (
          <WaitingFilledIcon
            width={size}
            height={size}
            color={theme.colors.warning}
          />
        ),
      };
    case 3:
      return {
        name: 'Bị từ chối',
        color: 'error',
        icon: (
          <RejectFilledIcon
            width={size}
            height={size}
            color={theme.colors.error.main}
          />
        ),
      };
    default:
      return {
        name: 'Không xác định',
        color: 'default',
        icon: <ClockCircleOutlined style={{ fontSize: `${size}px` }} />,
      };
  }
};

export const getCouponStatus = (createdTime: string, endedTime: string) => {
  let status = 0;
  if (moment() >= moment(createdTime) && moment() <= moment(endedTime)) {
    status = 1;
  } else if (moment() >= moment(endedTime)) {
    status = 2;
  }

  switch (status) {
    case 0:
      return {
        name: 'Sắp diễn ra',
        color: 'warning',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <SyncOutlined />,
      };
    case 2:
      return {
        name: 'Đã kết thúc',
        color: 'default',
        icon: <CheckCircleOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getCobuyStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'warning',
        icon: <SyncOutlined />,
      };
    case 2:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <CheckCircleOutlined />,
      };
    case 3:
      return {
        name: 'Đã hết hạn',
        color: 'error',
        icon: <CheckCircleOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getOrderType = (type: number) => {
  switch (type) {
    case typeOrderValue.wholeSale:
      return { name: 'Đơn sỉ', color: 'cyan' };
    case typeOrderValue.retail:
      return { name: 'Đơn lẻ', color: 'blue' };
    case typeOrderValue.coBuy:
      return { name: 'Đơn mua chung', color: 'magenta' };
    default:
      return { name: '', color: 'default' };
  }
};

export const getUserCustomDomainStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'default',
        icon: <SyncOutlined />,
      };
    case 2:
      return {
        name: 'Chờ phê duyệt',
        color: 'warning',
        icon: <CheckCircleOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getUserSubscriptionStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'default',
        icon: <SyncOutlined />,
      };
    case 2:
      return {
        name: 'Chờ phê duyệt',
        color: 'warning',
        icon: <CheckCircleOutlined />,
      };
    case 3:
      return {
        name: 'Hết hạn',
        color: 'orange',
        icon: <SyncOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getUserSMSAccountStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <ClockCircleOutlined />,
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'default',
        icon: <SyncOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getUserSMSAccountAppType = (appType: number) => {
  const appTypeValue = Number(appType);
  switch (appTypeValue) {
    case 0:
      return {
        name: SMS_APP_TYPE_TEXT[SMS_APP_TYPE.ESMS],
        color: 'geekblue',
      };
    case 1:
      return { name: SMS_APP_TYPE_TEXT[SMS_APP_TYPE.VIVAS], color: 'cyan' };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getPackageType = (type: string) => {
  switch (type) {
    case PACKAGE_TYPE_TEXT[PACKAGE_TYPE.BASIC]:
      return {
        name: 'Gói cơ bản',
        color: 'cyan',
      };
    case PACKAGE_TYPE_TEXT[PACKAGE_TYPE.PRO]:
      return {
        name: 'Gói tiêu chuẩn',
        color: 'blue',
      };
    case PACKAGE_TYPE_TEXT[PACKAGE_TYPE.ENTERPRISE]:
      return {
        name: 'Gói cao cấp',
        color: 'magenta',
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getUserCustomDomainAppType = (appType: number) => {
  const appTypeValue = Number(appType);
  switch (appTypeValue) {
    case 0:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_TECH],
        color: 'geekblue',
      };
    case 1:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN],
        color: 'purple',
      };
    case 2:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK],
        color: 'cyan',
      };
    case 3:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING],
        color: 'blue',
      };
    case 4:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY],
        color: 'warning',
      };
    case USER_CUSTOM_DOMAIN_TYPE.HUB_ABOUT_US:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_ABOUT_US],
        color: 'orange',
      };
    case USER_CUSTOM_DOMAIN_TYPE.HUB_LANDING_PAGE:
      return {
        name: USER_CUSTOM_DOMAIN_TEXT[USER_CUSTOM_DOMAIN_TYPE.HUB_LANDING_PAGE],
        color: 'green',
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const getDomainStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: MENU_TITLE_DOMAIN_BY_STATUS.ACTIVE,
        color: 'success',
        icon: <CheckCircleOutlined />,
      };
    case 1:
      return {
        name: MENU_TITLE_DOMAIN_BY_STATUS.DEACTIVE,
        color: 'error',
        icon: <CloseCircleOutlined />,
      };
    case 2:
      return {
        name: MENU_TITLE_DOMAIN_BY_STATUS.PENDING,
        color: 'geekblue',
        icon: <ClockCircleOutlined />,
      };
    case 3:
      return {
        name: MENU_TITLE_DOMAIN_BY_STATUS.PENDING_SUPPORT,
        color: 'magenta',
        icon: <ClockCircleOutlined />,
      };
    default:
      return { name: 'Trống', color: 'default', icon: <ClockCircleOutlined /> };
  }
};

export const typeOrderValue = {
  wholeSale: 0,
  retail: 1,
  coBuy: 2,
};

export const statusOrder = {
  0: 'Chờ xử lý',
  1: 'Đang xử lý',
  2: 'Giao hàng',
  3: 'Hoàn thành',
  4: 'Từ chối',
  5: 'Bản nháp',
};

export const confirmOrderStatus = {
  1: 'Xử lý đơn hàng',
  2: 'Giao hàng',
  4: 'Từ chối đơn hàng',
  5: 'Đưa vào đơn hàng nháp',
};

export const activityType = (type: number) => {
  switch (type) {
    case 0:
      return 'đã mua đơn hàng';
    case 10:
      return 'gửi lời mời liên kết';
    case 11:
      return 'chấp nhận lời mời liên kết';
    case 20:
      return 'thực hiện chiến dịch';
    case 30:
      return 'cập nhật trạng thái đơn hàng';
    case 31:
      return 'trộn đơn hàng';
    case 32:
      return 'chuyển tiếp đơn hàng';
    case 33:
      return 'cập nhật thực giao và chuyển trạng thái giao hàng';
    case 34:
      return 'cập nhật thực nhận và chuyển trạng thái hoàn thành';
    case 40:
      return 'yêu thích sản phẩm';
    case 41:
      return 'đánh giá sản phẩm';
    case 42:
      return 'đánh giá';
    case 43:
      return 'đánh giá đối tượng nuôi trồng';
    case 44:
      return 'mời bạn tham gia nhóm mua chung';
    case 45:
      return 'mời bạn tham gia chiến dịch';
    default:
      return 'thông báo';
  }
};

export const genNameListActivityType = (activityType: number) => {
  switch (activityType) {
    case 10 || 11:
      return 'linkedList';
    case 20:
      return 'systemList';
    case 30 || 31 || 32 || 33 || 34:
      return 'orderList';
    case 0:
      return 'requestList';
    default:
      return '';
  }
};

export const getDistrict: any = (
  provinceCode: string,
  districtCode: string
) => {
  const province = location.find(
    (item: any) => item.province_code === provinceCode
  );
  if (province) {
    return province.districtInfo.find(
      (item: any) => item.district_code === districtCode
    );
  }
};

export const getProvince = (provinceCode: string) => {
  const province = location.find(
    (item: any) => item.province_code === provinceCode
  );
  if (province) {
    return {
      province_code: province.province_code,
      province_name: province.province_name,
    };
  }
  return { province_code: '', province_name: '' };
};

export const getProvinceList = () =>
  location.map((item: any) => ({
    province_code: item.province_code,
    province_name: item.province_name,
  }));

export const getDistrictList = (provinceCode: string | undefined) => {
  const province = location.find(
    (item: any) => item.province_code === provinceCode
  );
  if (province) {
    return province.districtInfo;
  }
  return [];
};

export const renderTextConfirm = (status: number) => {
  switch (status) {
    case 0:
      return 'Chờ xử lý đơn hàng';
    case 1:
      return 'Xử lý đơn hàng';
    case 2:
      return 'Giao hàng';
    case 3:
      return 'Thực nhận đơn hàng';
    case 4:
      return 'Từ chối đơn hàng';
    case 5:
      return 'Đưa vào đơn hàng nháp';
    case 6:
      return 'Hoàn thành đơn hàng';
    default:
      return '';
  }
};

export const filterOption = (input: string, option: any) => {
  if (
    removeVietnameseTones(
      typeof option.children === 'string'
        ? option.children?.toLocaleLowerCase()
        : option.label?.toLocaleLowerCase()
    ).includes(removeVietnameseTones(input.toLocaleLowerCase()))
  ) {
    return true;
  }
  return false;
};

export const getDummyArray = (size: number) =>
  Array(size)
    .fill('0')
    .map((item, index) => ({ key: index }));

export const genQRCodeNumber = (order: number) => {
  switch (true) {
    case order < 10:
      return '00' + order;
    case order < 100:
      return '0' + order;
    case order < 1000:
      return order;
    case order < 10000:
      return '00' + order;
    case order < 100000:
      return '0' + order;
    case order < 1000000:
      return order;
    default:
      return order;
  }
};

export const convertToSlug = (text: string) => {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  const formatText = text
    .toString()
    .toLowerCase()
    .replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
    .replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
    .replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
    .replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
    .replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
    .replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
    .replace(/đ/gi, 'd')
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

  const uniqueNumber = moment().unix();
  return `${formatText}-${uniqueNumber}`;
};

export const getFullAddress = (
  address: string | undefined,
  district: string | undefined,
  province: string | undefined
) => {
  let fullAddress = '';
  if (address) {
    fullAddress += address;
  }
  if (district) {
    fullAddress += `, ${district}`;
  }
  if (province) {
    fullAddress += `, ${province}`;
  }
  return fullAddress || 'Trống';
};

export const getPaymentMethod = (value: number) => {
  switch (value) {
    case 0:
      return 'Thanh toán khi nhận hàng';
    case 1:
      return 'Ví điện tử';
    case 2:
      return 'Internet Banking';
    case 3:
      return 'Thẻ tín dụng/Thẻ ghi nợ';
    default:
      return '';
  }
};

export const getDeliveryMethod = (value: number) => {
  switch (value) {
    case 0:
      return 'Giao hàng Farmhub Fast';
    case 1:
      return 'Nhận tại cửa hàng';
    default:
      return '';
  }
};

export const parseQueryString = (fieldKeys: any) => {
  if (fieldKeys['order.name'] || fieldKeys['order.value']) {
    fieldKeys.order = {
      name: fieldKeys['order.name'],
      value: Number(fieldKeys['order.value']),
    };

    delete fieldKeys['order.name'];
    delete fieldKeys['order.value'];
  }

  // Object.keys(fieldKeys).forEach(key => {
  //   if (Number(fieldKeys[key]) === 0 || Number(fieldKeys[key])) {
  //     fieldKeys[key] = Number(fieldKeys[key]);
  //   }
  // });

  Object.keys(fieldKeys).forEach(key => {
    if (fieldKeys[key] === '') {
      delete fieldKeys[key];
    }
  });

  if (fieldKeys.page || fieldKeys.take) {
    fieldKeys.page = Number(fieldKeys.page);
    fieldKeys.take = Number(fieldKeys.take);
  }

  if (fieldKeys['where.status']) {
    fieldKeys['where.status'] = Number(fieldKeys['where.status']);
  }

  if (fieldKeys['where.product.status']) {
    fieldKeys['where.product.status'] = Number(
      fieldKeys['where.product.status']
    );
  }

  if (fieldKeys['where.objectType']) {
    fieldKeys['where.objectType'] = Number(fieldKeys['where.objectType']);
  }

  if (fieldKeys['where.totalStep']) {
    fieldKeys['where.totalStep'] = Number(fieldKeys['where.totalStep']);
  }
};

export const handleUploadSingleImage = async (
  fileList: any[],
  action: (e: { file: any }) => void
) => {
  if (!fileList || (Array.isArray(fileList) && fileList.length === 0)) {
    return undefined;
  }
  let result: any;

  if (fileList.length) {
    const fileData = fileList
      .filter(item => item.originFileObj)
      .map(item => item.originFileObj);

    try {
      let formatData = [];
      if (fileData.length) {
        const actionResult: any = await action({ file: fileData });
        const { data } = unwrapResult(actionResult);

        formatData = data.map((item, index) => {
          return {
            isNew: true,
            type: 0,
            uid: `${Date.now()}${index}`,
            url: item.url[0],
          };
        });
      }

      result = fileList.filter(item => !item.originFileObj).concat(formatData);
    } catch (error: any) {
      Notification('error', 'Tải ảnh thất bại', error);
    }
  } else {
    try {
      const actionResult: any = await action({ file: fileList });
      const { data } = unwrapResult(actionResult);
      result = data;
    } catch (error: any) {
      Notification('error', 'Tải ảnh thất bại', error);
    }
  }

  return result;
};

export const handleUploadMultiImage = async (
  fileList: any[],
  action: (e: { file: any }) => void
) => {
  if (!fileList || (Array.isArray(fileList) && fileList.length === 0)) {
    return undefined;
  }
  let result: any = undefined;

  if (fileList.length) {
    const fileData = fileList.map(item => item.originFileObj);
    try {
      const actionResult: any = await action({ file: fileData });
      const { data } = unwrapResult(actionResult);
      const formatData = data.map(item => ({
        isNew: true,
        type: 0,
        uid: Date.now(),
        url: item.url[0],
      }));
      result = formatData;
    } catch (error: any) {
      Notification('error', 'Tải ảnh thất bại');
    }
  } else {
    try {
      const actionResult: any = await action({ file: fileList });
      const { data } = unwrapResult(actionResult);
      result = data;
    } catch (error: any) {
      Notification('error', 'Tải ảnh thất bại');
    }
  }
  return result;
};

export const handleUploadVideo = async (fileList: any[], action: any) => {
  if (!fileList || fileList === undefined) {
    return undefined;
  }
  let result: any = undefined;

  try {
    const actionResult: any = await action();
    const { data } = unwrapResult(actionResult);
    result = {
      ...data,
      isVideo: true,
    };
  } catch (error: any) {
    Notification('error', 'Tải video thất bại');
  }
  return result;
};

const unitPrefix = (unit: number) => {
  switch (unit) {
    case 0:
      return '';
    case 1:
      return 'bịch';
    case 2:
      return 'hộp';
    case 3:
      return 'vỉ';
    case 4:
      return 'phần';
    case 5:
      return 'cái';
    case 6:
      return 'thùng';
    case 7:
      return 'con';
    case 8:
      return 'hũ';
    case 9:
      return 'chai';
    case 10:
      return 'bó';
    case 11:
      return 'túi';
    case 12:
      return 'ổ';
    case 13:
      return 'set';
    case 100:
      return 'khác';
    default:
      return '';
  }
};

const unitPostfix = (unit: number) => {
  switch (unit) {
    case 0:
      return 'gr';
    case 1:
      return 'kg';
    case 2:
      return 'yến';
    case 3:
      return 'tạ';
    case 4:
      return 'tấn';
    case 5:
      return 'cm';
    case 6:
      return 'm';
    case 7:
      return 'quả';
    case 8:
      return 'bịch';
    case 9:
      return 'ml';
    case 10:
      return 'l';
    case 11:
      return 'nải';
    case 12:
      return 'set';
    case 13:
      return 'gói';
    case 14:
      return 'cái';
    case 15:
      return 'túi';
    case 16:
      return 'con';
    case 17:
      return 'hộp';
    case 18:
      return 'chục';
    case 19:
      return 'chai';
    case 20:
      return 'thùng';
    case 21:
      return 'hũ';
    case 22:
      return 'khay';
    case 23:
      return 'cặp';
    case 24:
      return 'chiếc';
    case 25:
      return 'lọ';
    case 26:
      return 'đĩa';
    case 27:
      return 'ống';
    case 28:
      return 'niêu';
    case 29:
      return 'vỉ';
    case 30:
      return 'phần';
    case 31:
      return 'cành';
    case 32:
      return 'bó';
    case 33:
      return 'ổ';
    case 100:
      return 'khác';
    default:
      return '';
  }
};

type UnitType = {
  unitPrefix: number;
  unitWeight: number;
  unitPostfix: number;
};

export const genUnit = (unit: UnitType): string => {
  const {
    unitPrefix: prefix,
    unitWeight: weight,
    unitPostfix: postfix,
  } = unit || {};
  const rs: string[] = [];

  if (prefix === 100) {
    return `đơn vị ${unitPrefix(prefix)}`;
  }

  rs.push(unitPrefix(prefix));

  if (weight != null) {
    rs.push(`${weight} ${unitPostfix(postfix)}`);
  }

  const productUnit = rs.join(' ');
  return productUnit.trim() || 'Chưa có đơn vị';
};

export const genWholesaleUnit = (unit: {
  unitPrefix: any;
  unitWeight: number;
  unitPostfix: any;
}): string => {
  const rs: string[] = [];

  if (unit.unitPrefix?.name === 'khác') {
    return `đơn vị ${unit.unitPrefix?.name}`;
  }

  rs.push(unit.unitPrefix?.name);

  if (unit.unitWeight != null) {
    rs.push(`${unit.unitWeight} ${unit.unitPostfix?.name}`);
  }

  const productUnit = rs.join(' ');
  return productUnit.trim() || 'Chưa có đơn vị';
};

export const parseDate = date => {
  return moment(date).format('DD/MM/YYYY');
};
export const handleRedirect = (url: string) => {
  const win: any = window.open(`${url}`, '_blank');
  win.focus();
};

export const replaceItemInlist = (
  item: any,
  list: any[],
  setList: (e: any[]) => void
) => {
  const index = list.findIndex(e => e.id === item.id);
  if (index !== -1) {
    const newList = [...list];
    // const newItem = {
    //   ...newList[index],
    //   ...item,
    // };
    // newList.splice(index, 1, newItem);
    newList[index] = {
      ...newList[index],
      ...item,
    };
    setList(newList);
  }
};

export const getLastPage = (
  paginationTake: number,
  paginationTotal: number,
  arrDeletes: any[]
) => {
  return Math.ceil((paginationTotal - arrDeletes?.length) / paginationTake);
};

export const isLastPage = (
  paginationCurrent: number,
  paginationTake: number,
  paginationTotal: number
) => {
  return (
    paginationCurrent > 1 &&
    Math.ceil(paginationTotal / paginationTake) == paginationCurrent
  );
};

export const isLastPagePagination = (pagination: {
  current: number;
  take: number;
  total: number;
}) => {
  return isLastPage(pagination?.current, pagination?.take, pagination?.total);
};

export const hasLoadLastPagePagination = (
  newList: any[],
  pagination: { current: number; take: number; total: number }
) => {
  return (
    newList?.length === 0 &&
    isLastPage(pagination.current, pagination.take, pagination.total)
  );
};

export const hasLoadLastPage = (
  newList: any[],
  paginationCurrent: number,
  paginationTake: number,
  paginationTotal: number
) => {
  return (
    newList?.length === 0 &&
    isLastPage(paginationCurrent, paginationTake, paginationTotal)
  );
};

export const checkLoadPageWithID = (
  savedList: any[],
  route: string,
  prefix?: string,
  postfix?: string
) => {
  return (
    savedList.length > 0 &&
    new RegExp(
      prefix +
        '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}' +
        postfix,
      'g'
    ).test(route)
  );
};

export const formatImageUrl = (
  url?: string,
  type?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
) => {
  if (type && url && !url.includes('/master/')) {
    return url.replace(/\.jpg|\.jpeg/g, `_s${ImageSize[type]}.jpg`);
  }
  return url || '';
};

export const genOrderStatus = (status: number) => {
  switch (status) {
    case ORDER_STATUS.ERROR:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.ERROR],
        color: '#eb403d',
        // icon: <AllStatusIcon className="order-status-icon" />,
        colorHex: '#eb403d',
      };
    case ORDER_STATUS.ALL:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.ALL],
        color: '#C4C4C4',
        // icon: <AllStatusIcon className="order-status-icon" />,
        colorHex: '#C4C4C4',
      };
    case ORDER_STATUS.PENDING:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.PENDING],
        color: '#c41d7f',
        // icon: <PendingIcon className="order-status-icon" />,
        colorHex: '#c41d7f',
      };
    case ORDER_STATUS.PROCESSING:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.PROCESSING],
        color: '#faad14',
        // icon: <ProcessingIcon className="order-status-icon" />,
        colorHex: '#faad14',
      };
    case ORDER_STATUS.DELIVERY:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.DELIVERY],
        color: '#1890ff',
        // icon: <DeliveringIcon className="order-status-icon" />,
        colorHex: '#1890ff',
      };
    case ORDER_STATUS.CUSTOMER_CONFIRM:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.CUSTOMER_CONFIRM],
        color: '#531dab',
        // icon: <CustomerConfirmIcon className="order-status-icon" />,
        colorHex: '#531dab',
      };
    case ORDER_STATUS.REJECT:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.REJECT],
        color: '#f5222d',
        // icon: <RejectedIcon className="order-status-icon" />,
        colorHex: '#f5222d',
      };
    case ORDER_STATUS.CUSTOMER_DRAFT:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.CUSTOMER_DRAFT],
        color: '#C4C4C4',
        // icon: <DraftIcon className="order-status-icon" />,
        colorHex: '#C4C4C4',
      };
    case ORDER_STATUS.COMPLETE:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.COMPLETE],
        color: '#4eb92d',
        // icon: <CompletedIcon className="order-status-icon" />,
        colorHex: '#4eb92d',
      };
    case ORDER_STATUS.PRE_PROCESSING:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.PRE_PROCESSING],
        color: '#d4380d',
        // icon: <PreProcessingIcon className="order-status-icon" />,
        colorHex: '#d4380d',
      };
    case ORDER_STATUS.SUPPLIER_CONFIRM:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.SUPPLIER_CONFIRM],
        color: '#1d39c4',
        // icon: <SupplierConfirmIcon className="order-status-icon" />,
        colorHex: '#1d39c4',
      };
    case ORDER_STATUS.PENDING_EXECUTE:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.PENDING_EXECUTE],
        color: '#08979c',
        // icon: <PendingExecuteIcon className="order-status-icon" />,
        colorHex: '#08979c',
      };
    case ORDER_STATUS.SUPPLIER_DRAFT:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.SUPPLIER_DRAFT],
        color: '#7cb305',
        // icon: <DraftIcon className="order-status-icon" />,
        colorHex: '#7cb305',
      };
    case ORDER_STATUS.ADDITIONAL_ORDER:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.ADDITIONAL_ORDER],
        color: '#4eb92d',
        // icon: <SnippetsFilled className="order-status-icon" style={{ fontSize: 16 }} />,
        colorHex: '#4eb92d',
      };
    case ORDER_STATUS.MERGED:
      return {
        name: ORDER_STATUS_TEXT[ORDER_STATUS.MERGED],
        color: '#4eb92d',
        // icon: <SnippetsFilled className="order-status-icon" style={{ fontSize: 16 }} />,
        colorHex: '#4eb92d',
      };
    default:
      return {
        name: 'Trống',
        color: '#C4C4C4',
        // icon: <ClockCircleOutlined className="order-status-icon" />,
        colorHex: '#C4C4C4',
      };
  }
};

export const isUUID = str => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

export const removeSpaceString = (str: string) => str.replace(/\s/g, '');

export const getStatus = (status: number, size = 12) => {
  switch (status) {
    case 0:
      return {
        name: 'Đang hoạt động',
        color: 'success',
        icon: <CheckCircleOutlined />,
      };
    case 1:
      return {
        name: 'Dừng hoạt động',
        color: 'default',
        icon: <SyncOutlined />,
      };
    default:
      return { name: '', color: 'default', icon: <ClockCircleOutlined /> };
  }
};
