import React from 'react';

import { IconProps } from './Icon';

export const CouponFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    xmlns="http://www.w3.org/2000/svg"
    id="icons"
    viewBox="0 0 48 48"
  >
    <circle cx="29" cy="31" r="2" />
    <circle cx="19" cy="21" r="2" />
    <path d="M47,23V14a1,1,0,0,0-1-1H41.14a87.2,87.2,0,0,1-16-1.48A88.42,88.42,0,0,0,8.86,10H2a1,1,0,0,0-1,1v9A5,5,0,0,1,1,30v8a1,1,0,0,0,1,1H8.86a87.2,87.2,0,0,1,16,1.48A88.42,88.42,0,0,0,41.14,42H46a1,1,0,0,0,1-1V33a5,5,0,0,1,0-10ZM19,17a4,4,0,1,1-4,4A4,4,0,0,1,19,17ZM17.71,33.71a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42l14-14a1,1,0,0,1,1.42,1.42ZM29,35a4,4,0,1,1,4-4A4,4,0,0,1,29,35Z" />
    <path d="M45,11.12V7a1,1,0,0,0-.37-.77A1,1,0,0,0,43.8,6,57.82,57.82,0,0,1,34,7H21A102.3,102.3,0,0,0,7,8c5.71.22,15.66,1.28,20,2A102.69,102.69,0,0,0,45,11.12Z" />
  </svg>
);
