import * as React from 'react';

import { PreviewDecorationWrapper } from './styles';

const PreviewDecoration = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <PreviewDecorationWrapper>{children}</PreviewDecorationWrapper>
);

export default PreviewDecoration;
