import React from 'react';

import { IconProps } from './Icon';

export const TruckColorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <path
        style={{ fill: '#FFC107' }}
        d="M128,160c0-8.832-7.168-16-16-16H16c-8.832,0-16,7.168-16,16s7.168,16,16,16h96
     C120.832,176,128,168.832,128,160z"
      />
      <path
        style={{ fill: '#FFC107' }}
        d="M112,208H48c-8.832,0-16,7.168-16,16s7.168,16,16,16h64c8.832,0,16-7.168,16-16S120.832,208,112,208
     z"
      />
      <path
        style={{ fill: '#FFC107' }}
        d="M112,272H80c-8.832,0-16,7.168-16,16s7.168,16,16,16h32c8.832,0,16-7.168,16-16S120.832,272,112,272
     z"
      />
    </g>
    <path
      style={{ fill: '#F44336' }}
      d="M509.728,263.776l-48-80C458.848,178.944,453.632,176,448,176H336c-8.832,0-16,7.168-16,16v160
 c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16v-80C512,269.088,511.232,266.24,509.728,263.776z"
    />
    <polygon
      style={{ fill: '#FAFAFA' }}
      points="384,208 438.944,208 477.344,272 384,272 "
    />
    <path
      style={{ fill: '#FFC107' }}
      d="M336,112H112c-8.832,0-16,7.168-16,16v224c0,8.832,7.168,16,16,16h240V128
 C352,119.168,344.832,112,336,112z"
    />
    <circle style={{ fill: '#FAFAFA' }} cx="432" cy="352" r="32" />
    <path
      style={{ fill: '#455A64' }}
      d="M432,400c-26.464,0-48-21.536-48-48s21.536-48,48-48s48,21.536,48,48S458.464,400,432,400z M432,336
 c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S440.8,336,432,336z"
    />
    <path
      style={{ fill: '#F44336' }}
      d="M352,368H112c-8.832,0-16-7.168-16-16v-48h256V368z"
    />
    <circle style={{ fill: '#FAFAFA' }} cx="208" cy="352" r="32" />
    <path
      style={{ fill: '#455A64' }}
      d="M208,400c-26.464,0-48-21.536-48-48s21.536-48,48-48s48,21.536,48,48S234.464,400,208,400z M208,336
 c-8.832,0-16,7.2-16,16s7.168,16,16,16s16-7.2,16-16S216.832,336,208,336z"
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
