import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FindOptions } from 'api/common';
import { EntityApi } from 'api/entity';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IEntityCensorship {
  id: string;
  code: string;
  displayName: string;
  phoneNumber: string;
  type: number;
  email: string;
  createdAt: string;
}

interface IEntityCensorshipState {
  list: IEntityCensorship[];
  loading: ILoadingStatus;
  error: unknown;
  savedPagination: any;
  savedList: Array<any>;
}

export const findEntityCensorship = createAsyncThunk<any, any>(
  'entity/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await EntityApi.findEntitiesByAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk<any, any>(
  'entity/removeEntity',
  async (id: string, thunkAPI) => {
    try {
      const response = await EntityApi.removeEntity(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const findEntities = createAsyncThunk<any, any>(
  'entity/findEntities',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await EntityApi.findEntitiesByAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const findOneEntity = createAsyncThunk(
  'entity/findOneEntity',
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, option } = params;
      const response = await EntityApi.findOne(id, option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findProvider = createAsyncThunk(
  'entity/findProvider',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.findProvider(params);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusEntity = createAsyncThunk(
  'entity/updateStatusActivatedEntity',
  async (body: any, { rejectWithValue }) => {
    try {
      const { id, status } = body;
      const response = await EntityApi.updateStatus(id, { status });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusActivatedEntity = createAsyncThunk(
  'entity/updateStatusActivatedEntity',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await EntityApi.updateStatus(id, { status: 0 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMultipleStatusActivatedEntity = createAsyncThunk(
  'entity/updateMultipleStatusActivatedEntity',
  async (
    { IDs, ownerID }: { IDs: string[]; ownerID: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await EntityApi.updateMultipleStatus({
        entityIDs: IDs,
        ownerID,
        status: 0,
      });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
export const updateMultipleStatusRejectEntity = createAsyncThunk(
  'entity/updateMultipleStatusActivatedEntity',
  async (
    { IDs, ownerID }: { IDs: string[]; ownerID: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await EntityApi.updateMultipleStatus({
        entityIDs: IDs,
        ownerID,
        status: 3,
      });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
export const updateStatusDeactivatedEntity = createAsyncThunk(
  'entity/updateStatusDeActivatedEntity',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await EntityApi.updateStatus(id, { status: 1 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusRejectEntity = createAsyncThunk(
  'entity/updateStatusRejectEntity',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await EntityApi.updateStatus(id, { status: 3 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllStatusActivated = createAsyncThunk(
  'product/updateAllStatusActivated',
  async (ownerID: string, { rejectWithValue }) => {
    try {
      const response = await EntityApi.updateAllStatus({ ownerID, status: 0 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllStatusReject = createAsyncThunk(
  'product/updateAllStatusReject',
  async (ownerID: string, { rejectWithValue }) => {
    try {
      const response = await EntityApi.updateAllStatus({ ownerID, status: 3 });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOwnerEntity = createAsyncThunk(
  'entity/updateOwnerEntity',
  async (body: { id: string; ownerID: string }, { rejectWithValue }) => {
    try {
      const { id, ownerID } = body;
      const response = await EntityApi.updateOwner(id, { ownerID });

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
  savedPagination: { current: 1, next: 2, prev: 0, take: 10, total: 0 },
  savedList: [],
} as IEntityCensorshipState;

const entityCensorshipSlice = createSlice({
  name: 'productCensorship',
  initialState,
  reducers: {
    clearEntityCensorshipState(state) {
      state.list = [];
      state.loading = LOADING_STATUS.idle;
    },
    updateSavedPaginationEntity(state, action) {
      state.savedPagination = action.payload;
    },
    updateSavedListEntity(state, action) {
      state.savedList = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findEntityCensorship.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findEntityCensorship.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findEntities.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findEntities.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findEntityCensorship.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      })
      .addCase(findOneEntity.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findOneEntity.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = [data];
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findOneEntity.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});
export const getEntityCensorshipLoading = (state: RootState): ILoadingStatus =>
  state.entityCensorship.loading;

export const getListEntityCensorship = (state: RootState): any =>
  state.entityCensorship.list;

export const getEntityCensorshipById =
  (id: string) =>
  (state: RootState): any =>
    state.entityCensorship.list.find(item => item.id === id);

export const {
  clearEntityCensorshipState,
  updateSavedPaginationEntity,
  updateSavedListEntity,
} = entityCensorshipSlice.actions;
export default entityCensorshipSlice.reducer;
