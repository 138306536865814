export const entityTypes: EntityTypesProps = {
  0: 'user',
  1: 'farm',
  2: 'store',
  3: 'collaborator',
  4: 'warehouse',
  5: 'transport',
  6: 'volunteer',
};

export const personStatusList = [
  { key: 'all', value: 'all', name: 'Tất cả các trạng thái' },
  { key: '0', value: 0, name: 'Đang hoạt động' },
  { key: '1', value: 1, name: 'Dừng hoạt động' },
  { key: '2', value: 2, name: 'Tạm dừng hoạt động' },
];

export const skeletonList = new Array(10).fill('').map((e, idx) => {
  return {
    key: idx,
  };
});

export const unitPrefixList = [
  {
    key: '0',
    value: 0,
    name: 'bỏ trống',
  },
  {
    key: '1',
    value: 1,
    name: 'bịch',
  },
  {
    key: '2',
    value: 2,
    name: 'hộp',
  },
  {
    key: '3',
    value: 3,
    name: 'vỉ',
  },
  {
    key: '4',
    value: 4,
    name: 'phần',
  },
  {
    key: '5',
    value: 5,
    name: 'cái',
  },
  {
    key: '6',
    value: 6,
    name: 'thùng',
  },
  {
    key: '7',
    value: 7,
    name: 'con',
  },
  {
    key: '8',
    value: 8,
    name: 'hũ',
  },
  {
    key: '9',
    value: 9,
    name: 'chai',
  },
  {
    key: '100',
    value: 100,
    name: 'khác',
  },
];

export const unitPostfixList = [
  {
    key: '0',
    value: 0,
    name: 'gr',
  },
  {
    key: '1',
    value: 1,
    name: 'kg',
  },
  {
    key: '2',
    value: 2,
    name: 'yến',
  },
  {
    key: '3',
    value: 3,
    name: 'tạ',
  },
  {
    key: '4',
    value: 4,
    name: 'tấn',
  },
  {
    key: '5',
    value: 5,
    name: 'cm',
  },
  {
    key: '6',
    value: 6,
    name: 'm',
  },
  {
    key: '7',
    value: 7,
    name: 'quả',
  },
  {
    key: '8',
    value: 8,
    name: 'bịch',
  },
  {
    key: '9',
    value: 9,
    name: 'ml',
  },
  {
    key: '10',
    value: 10,
    name: 'l',
  },
  {
    key: '100',
    value: 100,
    name: 'khác',
  },
];

interface EntityTypesProps {
  [key: number]: string;
}
