import { IconProps } from './Icon';

export const ShopColorIcon = ({
  width,
  height,
  className,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.984 511.984"
  >
    <rect
      x="30.921"
      y="165.122"
      style={{ fill: '#FFCE54' }}
      width="448.99"
      height="310.02"
    />
    <rect
      x="324.682"
      y="270.932"
      style={{ fill: '#4FC2E9' }}
      width="93.47"
      height="196.42"
    />
    <rect
      x="35.498"
      y="36.842"
      style={{ fill: '#E6E9ED' }}
      width="439.25"
      height="64.811"
    />
    <rect
      x="84.372"
      y="272.022"
      style={{ fill: '#4FC2E9' }}
      width="192.43"
      height="128.29"
    />
    <g>
      <path
        style={{ fill: '#F5F7FA' }}
        d="M73.684,261.336v149.667h213.807V261.336H73.684z M266.1,389.613H95.059V282.71H266.1V389.613z"
      />
      <path
        style={{ fill: '#F5F7FA' }}
        d="M308.865,261.336v213.806h128.278V261.336H308.865z M415.768,453.767h-85.529V282.71h85.529V453.767
     z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#3BAFDA' }}
        d="M244.648,336.162c-2.969,0-5.625,1.203-7.562,3.141l0,0l-21.374,21.373l0,0
     c-1.938,1.938-3.141,4.609-3.141,7.562c0,5.906,4.797,10.688,10.703,10.688c2.938,0,5.609-1.188,7.547-3.125l0,0l21.39-21.39l0,0
     c1.922-1.922,3.125-4.609,3.125-7.547C255.336,340.958,250.554,336.162,244.648,336.162z"
      />
      <path
        style={{ fill: '#3BAFDA' }}
        d="M252.211,311.662c1.922-1.938,3.125-4.609,3.125-7.562c0-5.906-4.781-10.687-10.688-10.687
     c-2.969,0-5.625,1.188-7.562,3.125l-64.139,64.138c-1.938,1.938-3.141,4.609-3.141,7.562c0,5.906,4.781,10.688,10.688,10.688
     c2.969,0,5.625-1.188,7.562-3.125L252.211,311.662z"
      />
    </g>
    <path
      style={{ fill: '#F5F7FA' }}
      d="M418.143,357.552h-98.591c-5.906,0-10.688,4.78-10.688,10.687s4.781,10.688,10.688,10.688h98.591
 c5.906,0,10.688-4.781,10.688-10.688S424.05,357.552,418.143,357.552z"
    />
    <path
      style={{ fill: '#DA4453' }}
      d="M450.892,239.961c33.733,0,61.092-27.358,61.092-61.092c0-3.25-0.266-6.438-0.75-9.546l0,0
 l-0.344-1.922c-0.062-0.297-0.109-0.594-0.156-0.891l-35.405-86.904h-87.966l14.655,135.918l2.062,2.594
 C415.3,231.461,432.096,239.961,450.892,239.961z"
    />
    <path
      style={{ fill: '#E6E9ED' }}
      d="M299.397,79.606l4.874,135.918c11.156,14.828,28.905,24.437,48.874,24.437
 c19.999,0,37.717-9.609,48.873-24.437L387.363,79.606H299.397z"
    />
    <path
      style={{ fill: '#DA4453' }}
      d="M61.076,239.961C27.343,239.961,0,212.603,0,178.869c0-3.25,0.249-6.438,0.749-9.546H0.733
 l0.359-1.922c0.047-0.297,0.094-0.594,0.156-0.891l35.405-86.904h87.951l-14.672,135.918l-2.047,2.594
 C96.684,231.461,79.872,239.961,61.076,239.961z"
    />
    <path
      style={{ fill: '#E6E9ED' }}
      d="M212.571,79.606l-4.891,135.918c-11.14,14.828-28.874,24.437-48.873,24.437
 c-19.969,0-37.718-9.609-48.874-24.437l14.672-135.918H212.571z"
    />
    <path
      style={{ fill: '#DA4453' }}
      d="M211.43,79.606l-4.875,135.918c11.125,14.828,28.875,24.437,48.858,24.437
 s37.733-9.609,48.857-24.437l-4.874-135.918H211.43z"
    />
    <path
      style={{ fill: '#ED5564' }}
      d="M1.186,166.838c-0.031,0.188-0.062,0.375-0.094,0.562l-0.359,1.922h0.016
 C0.249,172.431,0,175.618,0,178.868c0,33.733,27.343,61.092,61.076,61.092c18.796,0,35.608-8.5,46.811-21.843l2.047-2.594
 l5.266-48.686H1.186V166.838z"
    />
    <path
      style={{ fill: '#F5F7FA' }}
      d="M115.199,166.838l-5.266,48.686c11.156,14.828,28.905,24.437,48.874,24.437
 c19.999,0,37.733-9.609,48.873-24.437l1.75-48.686H115.199z"
    />
    <path
      style={{ fill: '#ED5564' }}
      d="M208.305,166.838l-1.75,48.686c11.125,14.828,28.875,24.437,48.858,24.437
 s37.733-9.609,48.857-24.437l-1.749-48.686H208.305z"
    />
    <path
      style={{ fill: '#F5F7FA' }}
      d="M302.522,166.838l1.749,48.686c11.156,14.828,28.905,24.437,48.874,24.437
 c19.999,0,37.717-9.609,48.873-24.437l-5.25-48.686H302.522z"
    />
    <path
      style={{ fill: '#ED5564' }}
      d="M511.234,169.323l-0.344-1.922c-0.031-0.188-0.078-0.375-0.109-0.562H396.768l5.25,48.686
 l2.062,2.594c11.219,13.343,28.016,21.843,46.812,21.843c33.733,0,61.092-27.358,61.092-61.092
 C511.984,175.619,511.719,172.431,511.234,169.323L511.234,169.323z"
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
