import { Popover } from 'antd';
import {
  Avatar,
  Column,
  FileImageOutlined,
  Icon,
  Row,
  Space,
  Text,
  Tooltip,
  TooltipPlacement,
} from 'farmhub-ui-core';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { NameText, TinyText } from 'components';
import { ConnectionIcon } from 'components/Icon';
import { theme } from 'styled';
import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl, getEntityInfo } from 'utils/function';

type ownerType = {
  avatar: string;
  name: string;
};

interface InfoCellTableProps {
  name: string;
  img: string;
  entityType?: number;
  info?: string;
  link?: string;
  redirectLink?: string;
  width?: number;
  imageType?: 'PRODUCT' | 'CATEGORY';
  owner?: ownerType;
  showConnectionProduct?: boolean;
  connectionProduct?: any;
}

const InfoCellTable = ({
  name,
  img,
  entityType,
  info,
  link,
  redirectLink,
  width,
  imageType,
  owner,
  showConnectionProduct,
  connectionProduct,
}: InfoCellTableProps): JSX.Element => {
  const history = useHistory();
  const handleRedirect = (): void => {
    const win: any = window.open(redirectLink, '_blank');
    win.focus();
  };
  const content = (
    <div>
      <Row>
        <ImgWrapper>
          <Img
            entityType={entityType}
            objectFit="cover"
            alt="img"
            src={formatImageUrl(
              connectionProduct?.avatar,
              IMAGETYPE.AVATAR.xxs
            )}
          />
        </ImgWrapper>
        <DivInfo>
          <Column>
            <Row alignItems="flex-start">
              <Text
                lineHeight="22px"
                title={connectionProduct?.name}
                lineNumber={1}
              >
                {connectionProduct?.name}
              </Text>
              <Space width={6} />
            </Row>
            <TinyText lineNumber={2} color="main">
              {connectionProduct?.code}
            </TinyText>
          </Column>
        </DivInfo>
      </Row>
    </div>
  );

  return (
    <Wrapper
      width={width}
      onClick={() =>
        (link && history.push(link)) || (redirectLink && handleRedirect())
      }
    >
      {img ? (
        <ImgWrapper>
          <Img
            entityType={entityType}
            objectFit={imageType === 'CATEGORY' ? 'contain' : 'cover'}
            alt="img"
            src={formatImageUrl(img, IMAGETYPE.AVATAR.xxs)}
          />
          {entityType && (
            <Tooltip
              placement={TooltipPlacement.Top}
              title={getEntityInfo(entityType).name}
            >
              <Icon width="24px" height="24px" styles={EntityTypeIconStyles}>
                {getEntityInfo(entityType).icon}
              </Icon>
            </Tooltip>
          )}
          {owner && (
            <Avatar
              size={24}
              src={formatImageUrl(owner.avatar, IMAGETYPE.AVATAR.xxs)}
              styles={AvatarStyles}
            />
          )}
        </ImgWrapper>
      ) : (
        <FakeImg>
          {' '}
          <FileImageOutlined
            style={{ fontSize: '30px', color: '#8c8c8c' }}
          />{' '}
        </FakeImg>
      )}
      <Space width={12} />
      <Column styles={{ minHeight: 48 }}>
        <Row alignItems="center">
          <Text weight="bold" lineHeight="22px" title={name} lineNumber={1}>
            {name}
          </Text>
          {showConnectionProduct && (
            <>
              <Space width={6} />
              <Popover content={content} title="Sản phẩm liên kết">
                <div style={{ flexShrink: 0, width: 18, height: 18 }}>
                  <ConnectionIcon
                    width={18}
                    height={18}
                    color={theme.colors.primary.main}
                  />
                </div>
              </Popover>
            </>
          )}
        </Row>

        {owner && (
          <NameText lineNumber={2} color="green">
            {owner.name}
          </NameText>
        )}
        {info && (
          <TinyText lineNumber={2} color="main">
            {info}
          </TinyText>
        )}
        {/* {tags && (
          <EditableTagGroup editAble={editAbleTag} id={id} tags={tags} />
        )} */}
      </Column>
    </Wrapper>
  );
};

export default InfoCellTable;

const Wrapper = styled.div<{ width?: number }>`
  display: flex;
  align-items: flex-start;
  width: ${props => props.width || 280}px;
  height: auto;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const Img = styled.img<{
  entityType?: number;
  objectFit?: string;
}>`
  width: 48px;
  height: 48px;
  border-radius: ${p => (p.entityType != null ? '50%' : '6px')};
  flex-shrink: 0;
  object-fit: ${p => p.objectFit || 'cover'};
`;

const EntityTypeIconStyles = css`
  background: #fafafa;
  border-radius: 50%;
  border: 1px solid ${theme.colors.primary.main};
  position: absolute;
  right: 0px;
  bottom: 8px;
  transform: translate(30%, 30%);
`;

const AvatarStyles = css`
  position: absolute;
  right: 0px;
  bottom: 7px;
  transform: translate(30%, 30%);
`;

const DivInfo = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  margin-left: 12px;
`;

const FakeImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #f6fcf9;
  border-radius: 6px;
`;
