import React from 'react';

import { IconProps } from './Icon';

export const ProductObjectFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="_x31_7_Save_The_World">
      <g>
        <g>
          <path d="m484.136 347.362c-110.206 83.268-113.158 92.891-139.498 100.725-31.56 9.318-62.247 13.291-73.883 13.291-17.947 0-35.528-5.494-50.232-15.802-24.133-16.064-27.421-21.359-38.354-23.441-1.159-4.25.05-.641-49.604-104.126l26.476-16.43c31.343-19.412 73.098-11.407 94.97 18.262 3.82 5.128 9.837 8.163 16.273 7.796l65.354-2.773c17.022-.516 29.779 11.719 32.18 26.006l52.377-39.244c19.564-14.815 46.156-13.526 64.255.994 11 8.842 11.157 25.833-.314 34.742z" />
        </g>
        <path d="m157.931 447.194-64.839 30.834c-8.694 4.185-19.135.53-23.32-8.165l-48.759-101.297c-4.185-8.694-.529-19.135 8.165-23.32l64.839-30.834c8.694-4.185 19.135-.53 23.32 8.165l48.758 101.297c4.185 8.694.53 19.134-8.164 23.32z" />
        <g>
          <path d="m324.127 244.02c12.296 5.808 53.79 26.79 84.609 56.563l-35.058 26.267c-15.978-19.972-37.804-17.479-38.72-17.686l-65.72 2.826c-.942 0-1.936-.576-2.616-1.465-6.959-9.418-15.75-17.267-25.692-23.023 26.006-21.139 54.889-36.261 67.237-42.278 2.323-18.582 1.775-37.739-3.453-56.04-98.343 1.994-146.778-49.652-146.98-143.056 0-7.64 6.017-13.761 13.657-13.866 94.883-1.235 144.995 47.923 145.201 143.004 4.517-61.636 38.954-94.772 107.37-94.97 5.756-.052 10.413 4.552 10.465 10.256.8 69.513-33.888 109.199-107.684 109.411-.994 2.145-1.884 4.395-2.669 6.645 1.414 11.302 1.518 23.755.053 37.412z" />
        </g>
      </g>
    </g>
  </svg>
);
