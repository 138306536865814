/* eslint-disable prettier/prettier */
import React from 'react';

import { IconProps } from './Icon';

export const MoneyBagIcon = ({ width, height, className, color }: IconProps): JSX.Element  => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M330.24,94.72l46.08-53.76C391.68,25.6,381.44,0,358.4,0H153.6c-23.04,0-33.28,25.6-20.48,40.96l46.08,53.76
         C76.8,145.92,0,289.28,0,386.56C0,506.88,115.2,512,256,512s256-5.12,256-125.44C512,289.28,435.2,145.92,330.24,94.72z
          M256,281.6c10.24,0,17.92,2.56,25.6,5.12c30.72,10.24,51.2,38.4,51.2,71.68c0,33.28-20.48,61.44-51.2,71.68v30.72h-51.2v-30.72
         c-30.72-10.24-51.2-38.4-51.2-71.68h51.2c0,15.36,10.24,25.6,25.6,25.6s25.6-10.24,25.6-25.6c0-15.36-10.24-25.6-25.6-25.6
         c-10.24,0-17.92-2.56-25.6-5.12c-30.72-10.24-51.2-38.4-51.2-71.68c0-33.28,20.48-61.44,51.2-71.68V153.6h51.2v30.72
         c30.72,10.24,51.2,38.4,51.2,71.68h-51.2c0-15.36-10.24-25.6-25.6-25.6s-25.6,10.24-25.6,25.6S240.64,281.6,256,281.6z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
