import React from 'react';

import { IconProps } from './Icon';

export const IncognitoColorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="44" r="8" fill="#FDAFA4" />
    <circle cx="45" cy="44" r="8" fill="#FDAFA4" />
    <path
      d="M17.7018 37.2516C18.6335 36.657 19.6965 36.25 20.8376 36.0839L19.0527 51.4396C17.9955 51.0204 17.0488 50.3824 16.2687 49.5814L17.7018 37.2516Z"
      fill="white"
    />
    <path
      d="M41.7018 37.2516C42.6335 36.657 43.6965 36.25 44.8376 36.0839L43.0527 51.4396C41.9955 51.0204 41.0488 50.3824 40.2687 49.5814L41.7018 37.2516Z"
      fill="white"
    />
    <circle cx="21" cy="44" r="8" stroke="#0F277F" strokeWidth="3" />
    <circle cx="45" cy="44" r="8" stroke="#0F277F" strokeWidth="3" />
    <path
      d="M29 44C29.6667 43.3333 30.5 42 33 42C35.5 42 36.3333 43.3333 37 44"
      stroke="#0F277F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M32 26C40.4047 26 45.4019 27.8277 52.2018 31.121C53.1515 31.581 52.8192 33 51.7639 33H12.2361C11.1808 33 10.8485 31.581 11.7982 31.121C18.5981 27.8277 23.5953 26 32 26Z"
      fill="#0F277F"
    />
    <path
      d="M23.7774 13.3355C23.888 12.6719 24.6092 12.3046 25.211 12.6055L28.9359 14.4679C29.6194 14.8097 30.4076 14.8777 31.1396 14.6581L38.9129 12.3261C39.4897 12.1531 40.0876 12.5255 40.1866 13.1196L41.8059 22.8356C41.9075 23.4451 41.4375 24 40.8195 24H23.1805C22.5625 24 22.0925 23.4451 22.1941 22.8356L23.7774 13.3355Z"
      fill="#0F277F"
    />
  </svg>
);
