import {
  Button,
  Dropdown,
  Form,
  FormInstance,
  Input as AntInput,
  Menu,
} from 'antd';
import { Input, WarningOutlined } from 'farmhub-ui-core';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'app';
import { CSelect, NameText } from 'components';
import { getErrorList, initAdjustList, updateAdjustList } from 'slice';

import ReportModal from '../../../Entity/ReportModal';

interface IEntityCensorshipFormItemProps {
  label?: string;
  initialValue?: any;
  name: string;
  prefix?: ReactNode;
  placeholder?: string;
  isReport?: boolean;
  disabled?: boolean;
  form: FormInstance;
  listReport?: any;
  type?: 'input' | 'select' | 'textArea' | 'children';
  rows?: number;
  list?: any;
  children?: ReactNode;
}

const getHasError = (listError, listReport): boolean => {
  let hasError = false;
  listReport?.forEach(item => {
    if (listError[item?.name]) {
      hasError = true;
    }
  });
  return hasError;
};

const CensorshipFormItem = ({
  initialValue,
  label,
  name,
  prefix,
  placeholder,
  isReport,
  disabled,
  form,
  listReport,
  type,
  rows,
  list,
  children,
}: IEntityCensorshipFormItemProps): JSX.Element => {
  const [reportVisible, setReportVisible] = useState<boolean>(false);
  const listError = useAppSelector(getErrorList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    form?.setFieldsValue({ [name]: initialValue });
    dispatch(initAdjustList({ [name]: initialValue }));
  }, [dispatch, form, initialValue, name]);
  const unCheck = getHasError(listError, listReport) || false;

  const menu = (
    <Menu>
      <ReportModal
        listReport={listReport}
        setReportVisible={setReportVisible}
      />
    </Menu>
  );

  const LabelHeader = (
    <Wrapper>
      <NameText>{label}</NameText>
      {isReport && (
        <LabelWrapper>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            visible={reportVisible}
          >
            <Button
              icon={<WarningOutlined />}
              style={{ border: 0 }}
              onClick={() => {
                setReportVisible(true);
              }}
            >
              Báo cáo
            </Button>
          </Dropdown>
        </LabelWrapper>
      )}
    </Wrapper>
  );

  if (type === 'select')
    return (
      <FormItemWrapper>
        <Form.Item
          required={false}
          label={LabelHeader}
          name={name}
          validateStatus={unCheck ? 'error' : 'success'}
          hasFeedback
        >
          <CSelect
            list={list}
            placeholder={placeholder}
            disabled={disabled}
            value={form.getFieldValue(name)}
            onChange={e => {
              form.setFieldsValue({ [name]: e.target.value });
              dispatch(updateAdjustList({ [name]: e.target.value }));
            }}
          />
        </Form.Item>
      </FormItemWrapper>
    );

  if (type === 'textArea')
    return (
      <FormItemWrapper>
        <Form.Item
          required={false}
          label={LabelHeader}
          name={name}
          validateStatus={unCheck ? 'error' : 'success'}
        >
          <AntInput.TextArea
            placeholder={placeholder}
            disabled={disabled}
            value={form.getFieldValue(name)}
            rows={rows}
            onChange={e => {
              form.setFieldsValue({ [name]: e.target.value });
              dispatch(updateAdjustList({ [name]: e.target.value }));
            }}
          />
        </Form.Item>
      </FormItemWrapper>
    );

  if (type === 'children') {
    <FormItemWrapper>
      <Form.Item
        required={false}
        label={LabelHeader}
        name={name}
        validateStatus={unCheck ? 'error' : 'success'}
        hasFeedback
      >
        {children}
      </Form.Item>
    </FormItemWrapper>;
  }

  return (
    <FormItemWrapper>
      <Form.Item
        required={false}
        label={LabelHeader}
        name={name}
        validateStatus={unCheck ? 'error' : 'success'}
        hasFeedback
        style={{ marginBottom: '0' }}
      >
        <Input
          prefix={prefix}
          placeholder={placeholder}
          disabled={disabled}
          value={form.getFieldValue(name)}
          onChange={e => {
            form.setFieldsValue({ [name]: e.target.value });
            dispatch(updateAdjustList({ [name]: e.target.value }));
          }}
        />
      </Form.Item>
    </FormItemWrapper>
  );
};

CensorshipFormItem.defaultProps = {
  label: '',
  initialValue: '',
  prefix: null,
  placeholder: '',
  isReport: true,
  disabled: false,
  type: 'input',
  rows: 10,
};

const FormItemWrapper = styled.div`
  width: 100%;

  label {
    width: 100%;
  }
  .ant-input[disabled] {
    background: transparent;
    color: #000;
  }
  .ant-form-item-children-icon {
    z-index: unset !important;
  }
`;

const Wrapper = styled.div`
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  algin-items: flex-start;
  flex-direction: column;
`;

export default CensorshipFormItem;
