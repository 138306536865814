import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CertificationAPI } from 'api';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { LOADING_STATUS } from 'utils/constants';

export const findCertifications = createAsyncThunk<any, any>(
  'certification/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await CertificationAPI.findByAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCertification = createAsyncThunk(
  'certification/createCertification',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await CertificationAPI.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCertification = createAsyncThunk(
  'certification/removeCertification',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await CertificationAPI.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateCertification = createAsyncThunk(
  'certification/updateCertification',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await CertificationAPI.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: null,
} as any;

const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findCertifications.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findCertifications.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findCertifications.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});

export default certificationSlice.reducer;
