import { Card, Tabs } from 'antd';
import styled from 'styled-components';

import { overlay } from 'styled';

const { Meta } = Card;

export const CTabs = styled(Tabs)`
  height: calc(100vh - 220px);
  border: 1px solid ${props => props.theme.colors.border.light};

  position: sticky;
  top: 18px;
  right: 0px;

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      width: 100%;
    }
  }

  .ant-tabs-tab {
    width: calc(100% / 3);
    margin: 0px !important;

    padding: 12px 18px !important;
    border-radius: 0px !important;
    border: 0px !important;

    display: flex !important;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid ${p => p.theme.colors.border.light};
    }

    .ant-tabs-tab-btn {
      text-align: center;
    }
  }

  .ant-tabs-content-holder {
    overflow: scroll;

    ${overlay}
  }

  .ant-tabs-ink-bar {
    background: #ffffff;
    height: 6px !important;

    &::after {
      content: ' ';
      width: 24px;
      height: 6px !important;
      border-radius: 999px;
      background: ${props => props.theme.colors.primary.main};

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
`;

export const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${p => p.theme.app.padding.horizontal};

  overflow: auto;
`;

export const PreviewDecorationWrapper = styled.div`
  width: 100%;
  height: fit-content;

  // padding: ${p => p.theme.app.padding.horizontal};

  border-radius: ${props => props.theme.radius.md};
  // border: 1px dashed #e1e1e1;
`;

export const PreviewStoreWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const PreviewScaleWrapper = styled.div<{ scale: number }>`
  width: 1340px;
  height: fit-content;

  transform: translate(
      -${props => ((1 - props.scale) * 100) / 2}%,
      -${props => ((1 - props.scale) * 100) / 2}%
    )
    scale(${props => props.scale});
`;

export const PreviewSeoWrapper = styled.div`
  width: 100%;
  // border: 1px dashed #e1e1e1;
`;

export const CCard = styled(Card)`
  .ant-card-body {
    padding: ${p => p.theme.app.padding.horizontal};

    background: #fafafa;
  }

  .ant-card-cover {
    img {
      aspect-ratio: 8 / 5;
      object-fit: cover;
    }
  }
`;

export const CMeta = styled(Meta)`
  .ant-card-meta-title {
    font-weight: ${props => props.theme.font.weight.extraBold};
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
`;

export const Img = styled.img<{
  entityType?: number;
  objectFit?: string;
  size: number;
}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${p => (p.entityType != null ? '50%' : '6px')};
  flex-shrink: 0;
  object-fit: ${p => p.objectFit || 'cover'};
`;

export const DivInfo = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  margin-left: 12px;
`;
