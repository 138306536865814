import { Skeleton } from 'antd';
import { Icon, Column, Space, Text, Tooltip } from 'farmhub-ui-core';
import * as React from 'react';

import { theme } from 'styled';
import { ICONS, IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

import * as S from './styles';

const CheckFilterItem = ({
  id = '',
  selected = false,
  image,
  children,
  onSelect = () => 0,
  width,
  height,
  canDeselect = true,
  typeSelectCreate = false,
}: CheckFilterItemProps): JSX.Element => {
  const handleClick = (): void => {
    if (canDeselect || !selected) {
      onSelect(id);
    }
  };

  return (
    <S.Wrapper
      selected={selected}
      typeSelectCreate={typeSelectCreate}
      onClick={handleClick}
      width={width}
      height={height}
    >
      <Column alignItems="center">
        <S.ImageWrapper>
          {image ? (
            <S.Image src={formatImageUrl(image, IMAGETYPE.AVATAR.xxs)} />
          ) : (
            <Skeleton.Image style={{ width: '100%', height: '48px' }} />
          )}
        </S.ImageWrapper>
        <Space height={6} />
        <Tooltip title={children}>
          <Text
            size="xxs"
            weight="medium"
            lineNumber={2}
            styles={{
              'text-align': 'center',
              color:
                selected && typeSelectCreate
                  ? theme.colors.primary.main
                  : theme.colors.text.primary,
            }}
          >
            {children}
          </Text>
        </Tooltip>
      </Column>
      {selected && !typeSelectCreate && (
        <Icon
          svgProps={{
            icon: ICONS.TICK,
            color: theme.colors.primary.main,
            width: '18px',
            height: '18px',
          }}
          styles={S.IconStyles}
        />
      )}
    </S.Wrapper>
  );
};

export default CheckFilterItem;

export interface CheckFilterItemProps {
  id?: string;
  image: string;
  children: string;
  selected?: boolean;
  onSelect?: (id: string) => void;
  width?: string;
  height?: string;
  canDeselect?: boolean;
  typeSelectCreate?: boolean;
}

export const CheckFilterItemSkeleton = ({
  width,
  height,
}: CheckFilterItemSkeletonProps): JSX.Element => (
  <S.Wrapper selected={false} width={width} height={height}>
    <Column alignItems="center">
      <S.ImageWrapper>
        <Skeleton.Avatar active size={48} shape="square" />
      </S.ImageWrapper>
      <Space height={12} />
      <Skeleton.Input size="small" active style={{ width: '100%' }} />
    </Column>
  </S.Wrapper>
);

export interface CheckFilterItemSkeletonProps {
  width?: string;
  height?: string;
}
