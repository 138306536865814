import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'app';
import { refreshAccessToken } from 'slice';

const WithAuthenticated = WrappedComponent =>
  function HOC(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector((state: RootState) => state.auth);

    React.useEffect(() => {
      const handleSilentRefreshToken = async () => {
        try {
          const actionResult: any = await dispatch(refreshAccessToken());

          unwrapResult(actionResult);
        } catch (error) {
          history.push('/login');
        }
      };

      if (auth.isLogout) {
        history.push('/login');
      }

      if (!auth.isAuthenticated && !auth.isLogout) {
        handleSilentRefreshToken();
      }

      // Refresh silent per 10 minutes
      const intervalRefreshToken = setInterval(
        handleSilentRefreshToken,
        1000 * 60 * 10
      );

      return () => {
        clearInterval(intervalRefreshToken);
      };
    }, [auth.isAuthenticated, auth.isLogout, history, dispatch]);

    return <>{auth.isAuthenticated ? <WrappedComponent {...props} /> : null}</>;
  };

export default WithAuthenticated;
