import { Col, Image, Row as AntRow } from 'antd';
import { Modal, Row, Text } from 'farmhub-ui-core';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { dataEmpty, Empty, SlideSection } from 'components';
import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

interface CertificationModalProps {
  entity: any;
  visible?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}
// const dataModal = {
//   title: 'Chứng nhận vùng sản xuất',
//   entity: {
//     name: 'Vùng sản xuất A',
//     img: Orange,
//   },
//   certificationType: 'Chứng nhận VIETGAP',
//   organization: 'Tổ chức ISO',
//   activeDay: '21/02/2022 - 12/122/2022',
// };
const CertificationModal = ({
  entity,
  visible,
  setVisible,
}: CertificationModalProps): JSX.Element => (
  <Modal
    visible={visible!}
    onCancel={() => setVisible?.(false)}
    width="700px"
    height="650px"
  >
    <Wrapper>
      <AntRow justify="center">
        <Text>{entity.title}</Text>
      </AntRow>
      <AntRow
        style={{ marginTop: '48px', marginBottom: '18px' }}
        justify="start"
        align="middle"
      >
        <Decorate>1</Decorate>
        <Text>Thông tin chứng nhận</Text>
      </AntRow>

      <AntRow style={{ marginBottom: '18px' }}>
        <Col span={10}>
          <LabelText> Loại chứng nhận </LabelText>
        </Col>
        <Col span={14}>
          <Text>{entity?.certification?.name}</Text>
        </Col>
      </AntRow>
      <AntRow style={{ marginBottom: '18px' }}>
        <Col span={10}>
          <LabelText> Tổ chức cấp </LabelText>
        </Col>
        <Col span={14}>
          <Text>{entity?.organization?.name}</Text>
        </Col>
      </AntRow>
      <AntRow>
        <Col span={10}>
          <LabelText> Thời gian hoạt động </LabelText>
        </Col>
        <Col span={14}>
          <Text>{`${moment(entity?.createdAt).format('DD/MM/YYYY')} - ${moment(
            entity?.expiredAt
          ).format('DD/MM/YYYY')}`}</Text>
        </Col>
      </AntRow>
      <AntRow style={{ marginTop: '48px' }} justify="start" align="middle">
        <Decorate>2</Decorate>
        <Text> Hình ảnh chứng nhận </Text>
      </AntRow>
      {console.log(entity?.urls)}
      {entity?.urls?.length !== 0 ? (
        <SlideSection
          slideShowProps={{ spacing: 6 }}
          sectionProps={{
            width: '100%',
          }}
        >
          {entity?.urls?.map((item: any) => (
            <Row
              width="fit-content"
              key={item}
              styles={{ marginRight: '12px' }}
            >
              <Image
                src={formatImageUrl(item, IMAGETYPE.AVATAR.xs)}
                width="180px"
              />
            </Row>
          ))}
        </SlideSection>
      ) : (
        <Empty
          minHeight={320}
          img={dataEmpty.DEFAUTL}
          description="Hiện tại không có chứng nhận"
        />
      )}
    </Wrapper>
  </Modal>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Decorate = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #39b54a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const LabelText = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
`;

CertificationModal.defaultProps = {
  visible: false,
};

export default CertificationModal;
