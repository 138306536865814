import { Dropdown, Menu } from 'antd';
import { DropdownPlacement } from 'farmhub-ui-core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IconWrapper } from 'components';

interface IDataItem {
  name: string;
  link?: any; // TODO: research type of link
  action?: () => void;
  key: string;
  disabled?: boolean;
  icon: any;
}

interface Props {
  data: Array<IDataItem>;
  component: any;
  visible?: boolean;
  onChangeVisible?: () => void;
  placement?: DropdownPlacement;
}

const ExtendedAction = ({
  data,
  component,
  visible,
  onChangeVisible,
  placement = DropdownPlacement.BottomLeft,
}: Props): JSX.Element => {
  const menu = () => (
    <Menu>
      {data.map((item: IDataItem) => {
        if (item.link) {
          return (
            <Link key={item.key} to={item.link}>
              <CMenuItem disabled={item.disabled}>{item.name}</CMenuItem>
            </Link>
          );
        }
        return (
          <CMenuItem onClick={item.action} key={item.key}>
            <IconWrapper
              onClick={item.action}
              hoverBackground="rgba(57, 181, 74, 0.2)"
              size="xs"
              background="#F1F1F1"
              icon={item.icon}
            />
            {item.name}
          </CMenuItem>
        );
      })}
    </Menu>
  );

  return (
    <Wrapper onClick={(e: any) => e.stopPropagation()}>
      <Dropdown trigger={['click']} overlay={menu()} placement={placement}>
        {component}
      </Dropdown>
    </Wrapper>
  );
};

export default ExtendedAction;

const CMenuItem = styled(Menu.Item)`
  font-weight: ${props => props.theme.font.weight.regular} !important;
  color: ${props => props.theme.colors.text.secondary};

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
`;

const Wrapper = styled.div``;
