import { FileImageOutlined } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

const Wapper = styled.div<{
  width: number;
  height: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: #f6fcf9;
  border-radius: 6px;
`;

interface ImgSkeletonProps {
  width: number;
  height: number;
}

const ImgSkeleton = ({ width, height }: ImgSkeletonProps): JSX.Element => (
  <Wapper width={width} height={height}>
    <FileImageOutlined style={{ fontSize: '30px', color: '#8c8c8c' }} />
  </Wapper>
);

export default ImgSkeleton;
