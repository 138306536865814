import { Popover, PopoverPlacement, Row } from 'farmhub-ui-core';
import * as React from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

import { PainIcon } from 'components/Icon';
import { theme } from 'styled';

const DEFAULT_COLOR = [
  theme.colors.primary.main,
  '#25aa95',
  '#0A923C',
  '#f4b915',
  '#4A90E2',
  '#4eb92d',
];

const ChooseColor = ({
  color,
  handleChangeColor,
  width,
  height,
  paintSize = 24,
}: {
  color: string;
  handleChangeColor: (val: string) => void;
  width?: string;
  height?: string;
  paintSize?: number;
}): JSX.Element => {
  const [isVisibleQRCodeColor, setIsVisibleQRCodeColor] = React.useState(false);

  const contentColor = (
    <SketchPicker
      color={color ?? theme.colors.primary.main}
      onChange={color => handleChangeColor(color.hex)}
    />
  );

  return (
    <Row>
      {DEFAULT_COLOR.map(item => (
        <ColorItem
          onClick={() => {
            handleChangeColor(item);
          }}
          color={item}
          active={color == item}
          width={width}
          height={height}
        />
      ))}
      <Popover
        placement={PopoverPlacement.LeftBottom}
        content={contentColor}
        trigger="click"
        visible={isVisibleQRCodeColor}
        onVisibleChange={setIsVisibleQRCodeColor}
      >
        <PainItem
          active={!DEFAULT_COLOR.includes(color)}
          width={width}
          height={height}
        >
          <PainIcon
            className="icon"
            width={paintSize}
            height={paintSize}
            color="#595959"
          />
        </PainItem>
      </Popover>
    </Row>
  );
};

const ColorItem = styled.div<{
  color: string;
  active: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width || '50px'};
  height: ${p => p.height || '50px'};
  border-radius: ${props => props.theme.radius.md};
  margin-right: 12px;
  // margin-bottom: 12px;
  background: ${p => p.color};
  cursor: pointer;
  ${p =>
    p.active &&
    'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border: 1px solid #FFFFFF;'}
`;

const PainItem = styled.div<{
  active: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width || '50px'};
  height: ${p => p.height || '50px'};
  border-radius: 6px;
  margin-right: 12px;
  // margin-bottom: 12px;
  background: #fff;
  border: 1px solid black;
  cursor: pointer;
  ${p =>
    p.active &&
    'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border: 1px solid #FFFFFF;'}
`;

export default ChooseColor;
