import React from 'react';

import { IconProps } from './Icon';

export const BannerFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    id="Capa_1"
    viewBox="0 0 508.738 508.738"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m219.75 314.437 34.619-16.356 34.619 16.356-4.857-37.98 26.253-27.87-37.621-7.115-18.394-33.581-18.393 33.581-37.622 7.115 26.253 27.87z" />
      <path d="m52.56 0h403.618v38.674h-403.618z" />
      <path d="m76.1 384.958 178.27 123.78 178.27-123.78v-316.284h-356.54zm140.373-170.33 37.896-69.187 37.896 69.187 77.51 14.662-54.089 57.421 10.007 78.247-71.324-33.697-71.325 33.697 10.008-78.247-54.089-57.421z" />
    </g>
  </svg>
);
