import { Badge as AntBadge } from 'antd';
import styled from 'styled-components';

export const Badge = styled(AntBadge)<{ styles?: any; sizeNumber?: number }>`
  ${p => p.styles};
  .ant-badge-status-dot {
    width: ${p => `${p.sizeNumber}px`};
    height: ${p => `${p.sizeNumber}px`};
  }
`;
