import * as pkg from '../package.json';

/**
 * Environment variables
 */

export const env = {
  node: process.env.NODE_ENV || 'development',
  isProduction: process.env.NODE_ENV === 'production',
  isTest: process.env.NODE_ENV === 'test',
  isDevelopment: process.env.NODE_ENV === 'development',
  app: {
    name: process.env.REACT_APP_APP_NAME,
    version: pkg.version,
    description: pkg.description,
    host: process.env.REACT_APP_APP_HOST,
    schema: process.env.REACT_APP_PP_SCHEMA,
    routePrefix: process.env.REACT_APP_APP_ROUTE_PREFIX,
    port: process.env.REACT_APP_APP_PORT,
  },
  hubtech: {
    identityService: process.env.REACT_APP_HUBTECH_IDENTITY_SERVICE,
    entityService: process.env.REACT_APP_HUBTECH_ENTITY_SERVICE,
    chatService: process.env.REACT_APP_HUBTECH_CHAT_SERVICE,
    uploadService: process.env.REACT_APP_HUBTECH_UPLOAD_SERVICE,
    notificationService: process.env.REACT_APP_HUBTECH_NOTIFICATION_SERVICE,
    chatWSService: process.env.REACT_APP_HUBTECH_CHAT_WS_SERVICE,
    notificationWSService:
      process.env.REACT_APP_HUBTECH_NOTIFICATION_WS_SERVICE,
    hubhubWebApp: process.env.REACT_APP_HUBTECH_HUBHUB_WEB_APP,
    hubcheckWebApp: process.env.REACT_APP_HUBTECH_HUBCHECK_WEB_APP,
    hubcheckShortLinkWebApp:
      process.env.REACT_APP_HUBTECH_HUBCHECK_SHORT_LINK_WEB_APP,
    hubsupplyWebApp: process.env.REACT_APP_HUBTECH_HUBSUPPLY_WEB_APP,
    adminWebApp: process.env.REACT_APP_HUBTECH_ADMIN_WEB_APP,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  google: {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    googleMapService: process.env.REACT_APP_GOOGLE_MAP_SERVICE,
  },
};
