import { Upload } from 'antd';
import {
  CameraFilled,
  FileImageOutlined,
  LoadingOutlined,
  Avatar,
} from 'farmhub-ui-core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FormItem } from 'components';

const CUpload = styled(Upload)`
  position: relative;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
  .avatar-uploader > .ant-upload {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #f6fcf9;
    border: 2px solid #ffffff;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: none;
    background: #f6fcf9;
    border: 2px solid #ffffff;
  }

  .ant-upload.ant-upload-select-picture-card img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #f6fcf9;
    border: 2px solid #ffffff;
  }
`;

const WrapperCamera = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${p => p.theme.colors.primary.main};
  border-radius: 50%;
  bottom: 0px;
  left: 130px;
`;

interface UploadAvatarProps {
  disabled?: boolean;
  name?: string;
  valuePropName?: string;
  initUrl?: any;
  getValueFromEvent: (e: any) => void;
}

const UploadAvatar = ({
  disabled,
  name,
  valuePropName,
  initUrl,
  getValueFromEvent,
}: UploadAvatarProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');

  const handleChangeImg = ({ file }): void => {
    if (file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  };

  const uploadButton = (
    <WrapperCamera>
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: '30px', color: '#fff' }} />
      ) : (
        <CameraFilled style={{ fontSize: '24px', color: '#fff' }} />
      )}
    </WrapperCamera>
  );

  useEffect(() => {
    const InitData = (): void => {
      if (initUrl) {
        if (typeof initUrl === 'string') {
          setUrl(initUrl);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(initUrl);
          reader.onload = (e: any) => {
            setUrl(e?.target.result);
          };
        }
      } else {
        setUrl('');
      }
    };

    InitData();
  }, [initUrl]);
  return (
    <FormItem
      valuePropName={valuePropName}
      name={name}
      getValueFromEvent={getValueFromEvent}
      marginouter="0px !important"
      margininer="0px !important"
    >
      <CUpload
        disabled={disabled}
        name="avatar"
        onChange={handleChangeImg}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={file => {
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
              setUrl(e?.target.result);
            };
          }
          return false;
        }}
      >
        {url ? (
          <>
            <img src={url} alt="st" />
            {uploadButton}
          </>
        ) : (
          <>
            <Avatar
              styles={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '100px',
                backgroundColor: '#f6fcf9',
                verticalAlign: 'middle',
              }}
              size="large"
              icon={{
                children: (
                  <FileImageOutlined
                    style={{ fontSize: '30px', color: '#2AC17E' }}
                  />
                ),
              }}
            >
              {' '}
              {initUrl}{' '}
            </Avatar>
            {uploadButton}
          </>
        )}
      </CUpload>
    </FormItem>
  );
};

export default UploadAvatar;
