/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'app';
import { NameText } from 'components';
import { getErrorList, updateErrorList } from 'slice';

interface IReportModalProps {
  listReport?: any;
  setReportVisible?: any;
}

const ReportModal = ({
  listReport,
  setReportVisible,
}: IReportModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const errorList = useAppSelector(getErrorList);

  React.useEffect(() => {
    listReport?.forEach(({ name }) => {
      dispatch(updateErrorList({ [name]: undefined }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeErrorList =
    (
      name: string,
      title: string
    ): React.MouseEventHandler<HTMLElement> | undefined =>
    () => {
      dispatch(
        updateErrorList({ [name]: errorList[name] ? undefined : title })
      );
    };

  return (
    <>
      <div
        style={{ position: 'fixed', top: 0, right: 0, left: 0, bottom: 0 }}
        onClick={() => {
          setReportVisible(false);
        }}
      />
      <WrapperModal>
        <NameText>Danh sách vi phạm lỗi</NameText>
        {listReport?.map(({ id, name, title }) => (
          <Checkbox
            style={{ margin: 0 }}
            key={id}
            checked={errorList?.[name]}
            name={name}
            onClick={changeErrorList(name, title)}
          >
            {title}
          </Checkbox>
        ))}
      </WrapperModal>
    </>
  );
};

ReportModal.defaultProps = {};
const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px;
  gap: 10px;
`;

export default ReportModal;
