import {
  Button,
  ButtonProps,
  ButtonType,
  Icon,
  IconProps,
  DropdownItem,
  DropdownItemProps,
} from 'farmhub-ui-core';
import * as React from 'react';
import { Link } from 'react-router-dom';

const Action = ({
  children,
  onClick,
  iconProps,
  buttonProps,
  dropdownItemProps,
  renderProp,
  ...props
}: ActionProps): JSX.Element => {
  if (iconProps) {
    return (
      <Icon onClick={onClick} {...iconProps}>
        {children}
      </Icon>
    );
  }
  if (buttonProps) {
    let button = (
      <Button onClick={onClick} {...buttonProps}>
        {children}
      </Button>
    );
    if (buttonProps.type === ButtonType.Link) {
      button = <Link to={buttonProps.href || onClick?.()}>{button}</Link>;
    }
    return <>{button}</>;
  }
  if (dropdownItemProps) {
    return (
      <DropdownItem onClick={onClick} {...dropdownItemProps} {...props}>
        {children}
      </DropdownItem>
    );
  }
  if (renderProp) {
    return <>{renderProp({ onClick })}</>;
  }

  return <></>;
};

export default Action;

type ClickFunc = (e?: any) => any;

export interface ActionProps {
  buttonProps?: Pick<
    ButtonProps,
    Exclude<keyof ButtonProps, 'onClick' | 'children'>
  >;
  iconProps?: Pick<IconProps, Exclude<keyof IconProps, 'onClick'>>;
  dropdownItemProps?: Pick<
    DropdownItemProps,
    Exclude<keyof DropdownItemProps, 'onClick' | 'children'>
  >;
  onClick: ClickFunc;
  // eslint-disable-next-line no-unused-vars
  renderProp?: ({ onClick }: { onClick: ClickFunc }) => React.ReactNode;
  children?: React.ReactNode;
}
