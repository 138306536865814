import { DatePicker } from 'antd';
import {
  CalendarOutlined,
  Space,
  Row,
  Text,
  Button,
  ButtonType,
  Icon,
  Spacing,
} from 'farmhub-ui-core';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import { NameText } from 'components';
import { ArrowRightIcon } from 'components/Icon';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const { RangePicker } = DatePicker;

const LineChart = (): JSX.Element => {
  const [date, setDate] = useState<any>([
    moment().subtract(30, 'days'),
    moment(),
  ]);

  return (
    <Wrapper>
      <Row justifyContent="space-between">
        <NameText size="sm">Doanh thu bán hàng</NameText>
        <Spacing>
          <CDatePicker
            onChange={setDate}
            suffixIcon={
              <Icon>
                <CalendarOutlined style={{ fontSize: 16, color: '#39B54A' }} />
              </Icon>
            }
            defaultValue={date}
            format={dateFormatList}
          />
          <Button height="50px" type={ButtonType.Text}>
            <Spacing
              styles={{ color: '#39B54A', cursor: 'pointer' }}
              size="middle"
            >
              <Text color="green">Xem dữ liệu</Text>
              <ArrowRightIcon width={12} height={12} />
            </Spacing>
          </Button>
        </Spacing>
      </Row>
      <Space height={24} />
      <WrapperChart>There is a linechart here</WrapperChart>
      <Space height={24} />
    </Wrapper>
  );
};

export default LineChart;

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 460px);
  border-radius: 6px;
  background: #fff;
  padding: 18px;
`;

const CDatePicker = styled(RangePicker)`
  width: 280px;
  height: 50px;
  padding: 18px;
  background: rgba(42, 193, 126, 0.1);
  border: none;
  .ant-picker-input > input {
    color: #39b54a !important;
    font-weight: ${props => props.theme.font.weight.bold} !important;
    font-size: 14px !important;
  }
`;

const WrapperChart = styled.div`
  width: 99%;
  height: auto;
`;
