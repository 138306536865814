import { Text, Tooltip } from 'farmhub-ui-core';

import { formatAppearPhoneNumber } from 'utils';

type PhoneNumberTextProps = {
  phoneNumber?: string;
  size?: string;
};

const PhoneNumberText = ({
  phoneNumber,
  size = 'xxs',
}: PhoneNumberTextProps) => (
  <Tooltip title={formatAppearPhoneNumber(phoneNumber)}>
    <Text
      size={size}
      styles={{ fontStyle: phoneNumber ? 'normal' : 'italic' }}
      lineNumber={1}
      lineHeight="18px"
    >
      {formatAppearPhoneNumber(phoneNumber)}
    </Text>
  </Tooltip>
);

export default PhoneNumberText;
