import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  background: #ffffff;
  border-radius: 6px;
  padding: 18px;
`;

export default Wrapper;
