import {
  checkFirstCharIsNotSpace,
  checkOnlyNumber,
  checkOnlySpaces,
} from './function';

export const validateLocationFieldForm = error => [
  {
    required: true,
    message: `Bạn chưa nhập thông tin tỉnh, huyện, xã!`,
  },
  ({ getFieldValue }) => {
    return {
      validator(_, value) {
        if (error) {
          return Promise.reject(
            `Bạn chưa nhập đầy đủ thông tin tỉnh, huyện, xã!`
          );
        }

        return Promise.resolve();
      },
    };
  },
];

export const validateEmptyStringFieldForm = (name: string) => [
  {
    required: true,
    message: `Bạn chưa nhập tên ${name}!`,
  },
  ({ getFieldValue }) => {
    return {
      validator(_, value) {
        if (value && value.length > 0 && checkOnlySpaces(value)) {
          return Promise.reject(`Tên ${name} không dùng chuỗi rỗng!`);
        }
        if (value && value.length > 0 && checkFirstCharIsNotSpace(value)) {
          return Promise.reject(
            `Tên ${name} không đuợc bắt đầu là khoảng trắng!`
          );
        }

        return Promise.resolve();
      },
    };
  },
];

export const validateCodeForm = (
  validateDuplicateCodeAction?: any,
  defaultValue?: string
) => [
  {
    required: true,
    message: `Bạn chưa nhập mã SKU!`,
  },
  ({ getFieldValue }) => {
    return {
      async validator(_, value) {
        if (value && value.length > 0 && checkOnlySpaces(value)) {
          return Promise.reject(`Mã SKU không dùng chuỗi rỗng!`);
        }

        if (value) {
          const patternSKU = /^([a-zA-Z]{1,10})([0-9]{1,10})(?:[a-zA-Z]+)?$/;
          const onlyNumberPatternSKU = /^(?:[0-9]{0,10})$/;
          const onlyNumberPattern = /^[0-9]+$/;

          const isNumberSKU = onlyNumberPattern.test(value);
          const isValid =
            patternSKU.test(value) || onlyNumberPatternSKU.test(value);

          if (value.length > 10 && isNumberSKU) {
            return Promise.reject(`Mã SKU có độ dài tối đa 10 kí tự số`);
          }

          if (value.length > 20) {
            return Promise.reject(`Mã SKU có độ dài tối đa 20 kí tự`);
          }
          if (!isValid) {
            return Promise.reject(
              `Mã SKU gồm 2 phần hoặc 1 phần số VD: SKU000001/000001`
            );
          }
          if (
            isValid &&
            validateDuplicateCodeAction &&
            (!defaultValue || defaultValue != value)
          ) {
            try {
              const isExistCode = await validateDuplicateCodeAction(value);
              if (isExistCode) {
                return Promise.reject('Mã SKU đã tồn tại');
              }
            } catch (error) {
              return Promise.reject('Mã SKU không hợp lệ!');
            }
          }
        }

        return Promise.resolve();
      },
    };
  },
];

export const validateNumberFieldForm = (max?: number, min?: number) => [
  {
    required: true,
    message: `Giá trị trống!`,
  },
  ({ getFieldValue }) => {
    return {
      validator(_, value) {
        if (value && !checkOnlyNumber(value)) {
          return Promise.reject(`Giá trị là chữ số!`);
        }
        if (value && min != undefined && value < min) {
          return Promise.reject(`Giá trị > ${min}!`);
        }
        if (value && max != undefined && value > max) {
          return Promise.reject(`Giá trị < ${max}!`);
        }

        return Promise.resolve();
      },
    };
  },
];
