import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SubCategoryApi, SwitchSubCategory } from 'api/subCategory';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

import { findCategories } from './categorySlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICategory {
  id: string;
  code: string;
  name: string;
}

interface ICategoryState {
  list: ICategory[];
  loading: ILoadingStatus;
  error: unknown;
}

export const createSubCategory = createAsyncThunk(
  'subCategory/createSubCategory',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await SubCategoryApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeSubCategory = createAsyncThunk(
  'subCategory/removeSubCategory',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await SubCategoryApi.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateSubCategory = createAsyncThunk(
  'subCategory/updateSubCategory',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await SubCategoryApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const switchSubCategory = createAsyncThunk(
  'subCategory/switchSubCategory',
  async (body: SwitchSubCategory, { rejectWithValue }) => {
    try {
      const response = await SubCategoryApi.switch(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
} as ICategoryState;

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findCategories.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findCategories.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findCategories.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});

export default categorySlice.reducer;
