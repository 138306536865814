import { IconProps } from './Icon';

export const MegaphoneIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448.264 448.264"
  >
    <g>
      <g>
        <g>
          <path
            d="M0.003,194.874c-0.195,16.946,10.251,32.197,26.122,38.139v-72.62c0-1.045,0.522-2.612,0.522-3.657
             C10.669,162.63,0.042,177.842,0.003,194.874z"
          />
          <path
            d="M142.631,284.735H74.19l25.6,92.996c3.791,14.192,16.657,24.056,31.347,24.033c10.452,0.124,20.341-4.724,26.645-13.061
             c6.365-7.932,8.328-18.527,5.224-28.212L142.631,284.735z"
          />
          <path d="M26.648,156.212h0.522v-0.522C27.17,156.212,27.17,156.212,26.648,156.212z" />
          <path
            d="M401.244,148.898h-4.18v94.041h4.18c25.969,0,47.02-21.052,47.02-47.02C448.264,169.95,427.212,148.898,401.244,148.898z
             "
          />
          <path
            d="M41.799,160.392v74.188c0.565,19.212,16.307,34.49,35.526,34.482h95.086V128H77.325
             C58.517,128,41.799,141.584,41.799,160.392z"
          />
          <polygon points="188.084,271.151 381.39,348.474 381.39,46.498 188.084,123.821 			" />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
