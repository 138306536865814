import React from 'react';

import { IconProps } from './Icon';

export const BillIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 -12 463.791 463"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m144.972656 291.855469c0-5.40625-4.746094-9.792969-10.605468-9.792969-5.859376 0-10.605469 4.386719-10.605469 9.792969 0 5.410156 4.746093 9.792969 10.605469 9.792969 5.859374 0 10.605468-4.382813 10.605468-9.792969zm0 0" />
    <path d="m134.367188 212.527344c-5.847657 0-10.605469 4.390625-10.605469 9.792968 0 5.398438 4.757812 9.792969 10.605469 9.792969 5.847656 0 10.609374-4.394531 10.609374-9.792969 0-5.402343-4.761718-9.792968-10.609374-9.792968zm0 0" />
    <path d="m144.972656 152.785156c0-5.410156-4.746094-9.792968-10.605468-9.792968-5.859376 0-10.605469 4.382812-10.605469 9.792968 0 5.40625 4.746093 9.792969 10.605469 9.792969 5.859374 0 10.605468-4.386719 10.605468-9.792969zm0 0" />
    <path d="m365.207031.394531c-54.445312 0-98.582031 44.136719-98.578125 98.582031 0 54.445313 44.136719 98.582032 98.582032 98.582032 54.445312 0 98.578124-44.136719 98.578124-98.582032-.058593-54.417968-44.160156-98.519531-98.582031-98.582031zm-.457031 91.53125c16.9375.003907 31.101562 12.871094 32.71875 29.730469 1.617188 16.863281-9.839844 32.191406-26.46875 35.410156v9.21875c0 3.867188-3.132812 7-7 7s-7-3.132812-7-7v-9.546875c-14.730469-3.589843-25.101562-16.777343-25.121094-31.9375 0-3.867187 3.132813-7 7-7 3.863282 0 7 3.132813 7 7 0 10.421875 8.449219 18.871094 18.871094 18.871094s18.871094-8.449219 18.871094-18.871094c0-10.425781-8.449219-18.875-18.871094-18.875-16.644531 0-30.660156-12.441406-32.636719-28.964843-1.976562-16.527344 8.710938-31.921876 24.886719-35.847657v-8.828125c0-3.863281 3.132812-7 7-7s7 3.136719 7 7v8.5c15.445312 3.011719 26.601562 16.535156 26.621094 32.269532 0 3.867187-3.132813 7-7 7-3.863282 0-7-3.132813-7-7-.011719-10.292969-8.269532-18.683594-18.566406-18.855469-.347657.054687-.699219.085937-1.054688.085937-.21875 0-.433594-.011718-.648438-.03125-10.117187.753906-17.828124 9.371094-17.449218 19.511719.375 10.136719 8.703125 18.160156 18.851562 18.160156zm0 0" />
    <path d="m252.230469 436.085938c-11.65625-11.578126-18.214844-27.328126-18.230469-43.757813v-28.042969h-234v28.160156c.117188 26.429688 21.5625 47.804688 47.992188 47.839844h208.976562c-1.714844-1.238281-3.304688-2.644531-4.738281-4.199218zm0 0" />
    <path d="m270.5 159.285156h-84.78125c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h77.109375c-6.765625-14.664062-10.246094-30.628906-10.199219-46.78125.023438-7.800781.832032-15.578125 2.414063-23.21875h-188.390625l-1.851563 275h176.558594c1.78125-.070312 3.507813.601563 4.769531 1.859375 1.261719 1.257813 1.9375 2.984375 1.871094 4.765625v35.425782c.128906 26.488281 21.613281 47.902343 48.101562 47.949218h.15625c26.386719-.054687 47.742188-21.457031 47.742188-47.839844v-182.890624c-30.441406-5.765626-57.089844-23.988282-73.5-50.269532zm-136.132812 156.367188c-13.566407 0-24.605469-10.675782-24.605469-23.796875 0-13.117188 11.039062-23.792969 24.605469-23.792969 13.566406 0 24.609374 10.675781 24.609374 23.792969 0 13.121093-11.039062 23.796875-24.609374 23.796875zm0-69.539063c-13.566407 0-24.605469-10.671875-24.605469-23.792969 0-13.121093 11.039062-23.792968 24.605469-23.792968 13.566406 0 24.609374 10.671875 24.609374 23.792968 0 13.117188-11.039062 23.792969-24.609374 23.792969zm0-69.535156c-13.566407 0-24.605469-10.675781-24.605469-23.792969 0-13.121094 11.039062-23.796875 24.605469-23.796875 13.566406 0 24.609374 10.671875 24.609374 23.792969s-11.039062 23.796875-24.609374 23.796875zm158.007812 122.707031h-106.65625c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h106.65625c3.867188 0 7 3.136719 7 7 0 3.867188-3.132812 7-7 7zm7-77c0 3.867188-3.132812 7-7 7h-106.65625c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h106.65625c3.867188 0 7 3.136719 7 7zm0 0" />
  </svg>
);
