import React from 'react';

import { IconProps } from './Icon';

export const HistoryIconFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    id="Capa_1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m411 8.782v91.218h91.211z" />
    <path d="m396 130c-8.284 0-15-6.716-15-15v-115h-215c-24.813 0-45 20.187-45 45v167.689c4.942-.448 9.943-.689 15-.689 51.128 0 96.897 23.376 127.186 60h162.814c8.284 0 15 6.716 15 15s-6.716 15-15 15h-143.052c9.376 18.296 15.422 38.565 17.363 60h125.689c8.284 0 15 6.716 15 15s-6.716 15-15 15h-125.689c-4.486 49.539-30.954 92.826-69.553 120h235.242c24.813 0 45-20.187 45-45v-337zm30 82h-220c-8.284 0-15-6.716-15-15s6.716-15 15-15h220c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
    <path d="m136 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm40 150h-40c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15s15 6.716 15 15v45h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
  </svg>
);
