import { Badge } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IconNotificationProps {
  count?: any;
  icon: JSX.Element;
  offset?: any;
  background?: string;
}

const IconNotification = ({
  count,
  icon,
  offset,
  background,
}: IconNotificationProps): JSX.Element => (
  <Wrapper background={background}>
    {icon}
    <CBadge
      className="site-badge-count-109"
      // count={count}
      dot={count !== 0}
      offset={offset || [10, 4]}
    />
  </Wrapper>
);

export default IconNotification;

const Wrapper = styled.div<{ background?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${p => p.background || ''};
`;

const CBadge = styled(Badge)`
  position: absolute !important;
  top: 0px;
  /* right: -12px; */
  .ant-badge-dot {
    width: 12px;
    height: 12px;
  }
`;
