import { Table as AntTable } from 'antd';
import styled from 'styled-components';

export const Table = styled(AntTable)<{
  typeSelection?: string;
  switchSize: string;
  styles?: any;
  showCheckAll?: boolean;
  marginPagination?: string;
}>`
  width: 100%;
  /* .ant-table-container table > thead > tr:first-child th:last-child {
    width: 120px;
  } */

  /* .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${p => p.theme.colors.primary.main};
  } */

  .column-invitation {
    width: 100%;
  }

  .ant-table-row {
    .edit-quantity-btn {
      display: none;
    }

    &:hover {
      .edit-quantity-btn {
        display: block;
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: ${p => (p.marginPagination ? p.marginPagination : '16px 0px')};
  }

  .ant-table-cell {
    padding: ${p => p.switchSize === 'small' && '6px'};
    font-size: ${p => p.switchSize === 'small' && p.theme.font.size.xxs};

    span {
      font-size: ${p => p.switchSize === 'small' && p.theme.font.size.xxs};
    }

    .ant-tag-close-icon {
      font-size: 10px;
      height: 10px !important;
    }

    &:last-child {
      span {
        font-size: ${p => p.switchSize === 'small' && p.theme.font.size.xxs};
      }
    }
  }

  .ant-table-expand-icon-col {
    width: 24px;
  }

  .ant-table-selection-column {
    padding: 0px !important;
  }

  .ant-table-row-expand-icon-cell {
    padding: 0px !important;
    width: 24px !important;

    .ant-table-row-expand-icon,
    .ant-table-row-expand-icon-collapsed {
      width: 8px;
      height: 8px;
      border: none !important;
      background: white !important;
      outline: none;

      &::after {
        display: none;
      }

      &::before {
        width: 0;
        height: 0;
        background: #ffffff;
        border: none;
        outline: none;

        top: 0;
        left: 0;
        right: 0;

        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid #acacac;
      }
    }

    .ant-table-row-expand-icon-collapsed::before {
      transform: rotate(-90deg);
    }
  }

  .ant-table-expanded-row {
    > td {
      padding: 12px 0px !important;
      background: #ffffff;

      &:hover {
        background: #ffffff;
      }
    }

    .ant-table {
      border-bottom: none;

      td {
        background: #ffffff;
      }
    }

    .ant-table-row-selected {
      > td {
        background: #ffffff;
      }
    }

    .ant-table-placeholder {
      .ant-table-cell {
        border-bottom: none;
      }
    }
  }

  .ant-table-thead > tr > th {
    &:last-child {
      padding-right: 0px !important;
    }
  }

  .ant-table-body > tr > td {
    &:last-child {
      padding-right: 0px !important;
    }
  }

  .ant-table-tbody > tr:not(.ant-table-placeholder) {
    cursor: pointer;
  }

  .ant-table-row-selected {
    > td {
      background: ${props => `${props.theme.colors.primary.main}10`};
    }

    &:hover {
      > td {
        background: ${props => `${props.theme.colors.primary.main}10`};
      }
    }
  }

  .ant-table-empty {
    .ant-table-placeholder {
      .ant-table-cell {
        border-bottom: none;
        background: #ffffff !important;
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      .ant-checkbox-wrapper {
        display: ${p => (p.showCheckAll ? 'inline-flex' : 'none')};
      }
    }
  }

  tr {
    td,
    th {
      &:first-child {
        padding-left: 0px !important;
      }

      &:last-child {
        padding-right: 0px !important;
      }
    }
  }

  ${p => p.styles}
`;
