import { Badge, Divider, Popover } from 'antd';
import {
  Avatar,
  AvatarShape,
  Column,
  Icon,
  Row,
  Space,
  Text,
  Tooltip,
  TooltipPlacement,
} from 'farmhub-ui-core';
import * as React from 'react';
import { useHistory } from 'react-router';

import { useAppSelector } from 'app';
import { EditableTagGroup, NameText } from 'components';
import { ConnectionIcon } from 'components/Icon';
import { theme } from 'styled';
import { IMAGETYPE, SIZE } from 'utils/constants';
import {
  formatImageUrl,
  genWholesaleUnit,
  getEntityInfo,
  handleRedirect,
} from 'utils/function';

import {
  AvatarStyles,
  DivInfo,
  EntityTypeIconStyles,
  Img,
  ImgWrapper,
  Wrapper,
} from './styles';

type ownerType = {
  avatar: string;
  name: string;
};

interface InfoCardProps {
  id?: string;
  name: string | React.ReactNode;
  img?: string | React.ReactNode;
  nameString?: string;
  info?: string | React.ReactNode;
  entityType?: number;
  link?: string;
  redirectLink?: string;
  width?: number | string;
  tags?: Array<any>;
  isReadOnlyTabs?: boolean;
  editAbleTag?: boolean;
  imageType?: 'PRODUCT' | 'CATEGORY';
  owner?: ownerType;
  showConnectionProduct?: boolean;
  showCustomContent?: boolean;
  popoverCustomerContent?: React.ReactNode;
  customContentName?: React.ReactNode;
  connectionProduct?: any;
  avatarCircle?: boolean;
  size?: SIZE;
  sizeSpaceWithInfo?: number;
  showDot?: boolean;
  optionType?: 'PRODUCT' | 'ORDER';
  onChangeOptions?: (
    options: any[],
    deletedOptionIDs?: string[],
    productOptions?: any[]
  ) => void;
  wrapperStyles?: any;
}

export const InfoCardSKUUnit = ({
  code,
  unitPrefix,
  unitWeight,
  unitPostfix,
  textSize = 'xxs',
  lineNumber = 2,
}: {
  code: string;
  unitPrefix: number;
  unitWeight: number;
  unitPostfix: number;
  textSize?: 'small' | 'tiny' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  lineNumber?: number;
}): JSX.Element => {
  const content = React.useMemo(
    () => (
      <Row>
        <Text
          size={textSize}
          lineHeight="22px"
          lineNumber={lineNumber}
          color="main"
        >
          {code}
        </Text>
        <Space width={8} />
        <Divider
          type="vertical"
          style={{ color: theme.colors.border.main, margin: 0 }}
        />
        <Space width={8} />
      </Row>
    ),
    [code, lineNumber, textSize, unitPostfix, unitPrefix, unitWeight]
  );

  return (
    <>
      {lineNumber === 1 ? (
        <Text
          size={textSize}
          lineHeight="22px"
          lineNumber={lineNumber}
          color="main"
        >
          {content}
        </Text>
      ) : (
        <Text
          size={textSize}
          lineHeight="22px"
          lineNumber={lineNumber}
          color="main"
        >
          {content}
        </Text>
      )}
    </>
  );
};

const InfoCard = ({
  id,
  name,
  nameString,
  img,
  entityType,
  info,
  link,
  redirectLink,
  width,
  tags,
  isReadOnlyTabs,
  editAbleTag,
  imageType,
  owner,
  showConnectionProduct,
  showCustomContent,
  popoverCustomerContent,
  customContentName,
  connectionProduct,
  avatarCircle = false,
  size = SIZE.DEFAULT,
  sizeSpaceWithInfo,
  showDot = false,
  optionType = 'PRODUCT',
  onChangeOptions,
  wrapperStyles,
}: InfoCardProps): JSX.Element => {
  const history = useHistory();

  const content = (
    <div>
      <Row>
        <ImgWrapper>
          <Img
            size={
              size === SIZE.SMALL ? theme.avatar.size.sm : theme.avatar.size.md
            }
            entityType={entityType}
            objectFit="cover"
            alt="img"
            src={formatImageUrl(
              connectionProduct?.avatar,
              IMAGETYPE.AVATAR.xxs
            )}
          />
        </ImgWrapper>
        <DivInfo>
          <Column>
            <Row alignItems="flex-start">
              <Text
                lineHeight="22px"
                title={connectionProduct?.name}
                lineNumber={1}
              >
                {connectionProduct?.name}
              </Text>
              <Space width={6} />
            </Row>
            <Text size="xxs" lineHeight="22px" lineNumber={2} color="main">
              {connectionProduct?.code}
            </Text>
          </Column>
        </DivInfo>
      </Row>
    </div>
  );

  const GenImage = () => {
    if (img) {
      if (typeof img === 'object') {
        return img;
      }
      return (
        <Badge dot={showDot}>
          <ImgWrapper>
            {avatarCircle ? (
              <Avatar
                size={
                  size === SIZE.SMALL
                    ? theme.avatar.size.sm
                    : theme.avatar.size.md
                }
                src={formatImageUrl(String(img), IMAGETYPE.AVATAR.xxs)}
                shape={AvatarShape.Circle}
              />
            ) : (
              <Img
                size={
                  size === SIZE.SMALL
                    ? theme.avatar.size.sm
                    : theme.avatar.size.md
                }
                entityType={entityType}
                objectFit={imageType === 'CATEGORY' ? 'contain' : 'cover'}
                alt="img"
                src={formatImageUrl(String(img), IMAGETYPE.AVATAR.xxs)}
              />
            )}
            {(entityType === 0 || entityType) && (
              <Tooltip
                placement={TooltipPlacement.Top}
                title={getEntityInfo(entityType).name}
              >
                <Icon width="18px" height="18px" styles={EntityTypeIconStyles}>
                  {getEntityInfo(entityType).icon}
                </Icon>
              </Tooltip>
            )}
            {owner && (
              <Tooltip placement={TooltipPlacement.Top} title={owner.name}>
                <Avatar
                  size={size === SIZE.SMALL ? 16 : 18}
                  src={formatImageUrl(owner.avatar, IMAGETYPE.AVATAR.xxs)}
                  styles={AvatarStyles}
                />
              </Tooltip>
            )}
          </ImgWrapper>
        </Badge>
      );
    }
    return <></>;
  };

  const GenName = () => {
    if (customContentName) {
      return customContentName;
    }
    if (name) {
      if (typeof name === 'object') {
        return name;
      }
      return (
        <Tooltip title={name}>
          <Text weight="medium" color="dark" lineHeight="20px" lineNumber={4}>
            {name}
          </Text>
        </Tooltip>
      );
    }
    return (
      <Text weight="medium" lineHeight="22px" title="Trống">
        Trống
      </Text>
    );
  };

  const GenInfo = () => {
    if (info) {
      if (typeof info === 'object') {
        return info;
      }
      return (
        <Text size="xxs" lineHeight="22px" lineNumber={2} color="main">
          {info}
        </Text>
      );
    }
    return null;
  };

  return (
    <Wrapper
      style={wrapperStyles}
      width={width}
      onClick={() =>
        (link && history.push(link)) ||
        (redirectLink && handleRedirect(redirectLink))
      }
    >
      {GenImage()}
      {img && (
        <Space
          width={
            sizeSpaceWithInfo != undefined
              ? sizeSpaceWithInfo
              : size === 'small'
              ? 6
              : 12
          }
        />
      )}
      <Column styles={{ minHeight: size === 'small' ? 36 : 42, gap: 3 }}>
        <Row alignItems="center">
          {GenName()}
          {showConnectionProduct && (
            <>
              <Space width={6} />
              <Popover content={content} title="Sản phẩm liên kết">
                <div style={{ flexShrink: 0, width: 18, height: 18 }}>
                  <ConnectionIcon
                    width={18}
                    height={18}
                    color={theme.colors.primary.main}
                  />
                </div>
              </Popover>
            </>
          )}
          {showCustomContent ? (
            <>
              <Space width={6} />
              {popoverCustomerContent}
            </>
          ) : null}
        </Row>

        {owner && (
          <NameText lineNumber={2} color="green">
            {owner.name}
          </NameText>
        )}
        {GenInfo()}
        {tags && (
          <EditableTagGroup editAble={editAbleTag} id={id} tags={tags} />
        )}
      </Column>
    </Wrapper>
  );
};

export default InfoCard;
