import { Text, Space } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

const Wrapper = styled.div<{ logo: string; active?: boolean }>`
  width: 140px;
  height: 182px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.logo ? 'none' : 'center')};
  padding-top: 12px;
  border-radius: 6px;
  border: 1px solid
    ${props => (props.active ? props.theme.colors.primary.main : '#fff')};
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
`;

const WrapperText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 12px;
`;

interface IPorps {
  name: string;
  logo: string;
  active?: boolean;
}

const OrganizationCard = ({ name, logo, active }: IPorps): JSX.Element => (
  <Wrapper active={active} logo={logo}>
    {logo && (
      <>
        <Img src={formatImageUrl(logo, IMAGETYPE.AVATAR.xxs)} />
        <Space height={10} />
      </>
    )}
    <WrapperText>
      <Text weight="medium" lineNumber={2}>
        {name}
      </Text>
    </WrapperText>
  </Wrapper>
);

export default OrganizationCard;
