import { Form } from 'antd';
import { Input, TextArea } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { FormItem, NameText, UploadAvatar, UploadBanner } from 'components';
import { theme } from 'styled';

import ChooseColor from '../ChooseColor';
import { PanelWrapper, MinText } from './styles';

interface BrandStepProps {
  form: any;
  onSetBodyUpdate: (e: any) => void;
}

const BrandForm = ({ form, onSetBodyUpdate }: BrandStepProps): JSX.Element => {
  const [color, setColor] = React.useState(form.getFieldValue('color'));
  const handleChangeBanner = e => e && e.file;
  const handleChangeAvatar = (e: any) => e && e.file;

  const handleChangeColor = (val: string) => {
    form.setFieldsValue({
      color: val,
    });

    setColor(val);
    onSetBodyUpdate({
      theme: {
        color: val,
      },
    });
  };

  React.useEffect(() => {
    if (form.getFieldValue('color') !== color) {
      setColor(form.getFieldValue('color'));
    }
  }, [form.getFieldValue('color')]);

  return (
    <PanelWrapper>
      <Form
        form={form}
        style={{ width: '100%' }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        labelAlign="left"
        onValuesChange={onSetBodyUpdate}
      >
        {/* <Form.Item
          name="displayName"
          label={<NameText>Tên thương hiệu:</NameText>}
          rules={[
            {
              required: true,
              message: 'Bạn chưa nhập tên!',
            },
          ]}
        >
          <Input height={theme.input.height.md} />
        </Form.Item> */}
        <Form.Item name="title" label={<NameText>Tiêu đề:</NameText>}>
          <Input height={theme.input.height.md} />
        </Form.Item>
        <Form.Item name="description" label={<NameText>Mô tả:</NameText>}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="introduction" label={<NameText>Giới thiệu:</NameText>}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="title" label={<NameText>Màu sắc:</NameText>}>
          <ChooseColor color={color} handleChangeColor={handleChangeColor} />
        </Form.Item>
        <FormItem label={<NameText>Logo thương hiệu:</NameText>}>
          <UploadAvatar
            valuePropName="file"
            name="avatar"
            getValueFromEvent={handleChangeAvatar}
            initUrl={form.getFieldValue('avatar')}
          />
        </FormItem>
        {/* <FormItem label={<NameText>Banner thương hiệu:</NameText>}>
          <UploadBanner
            valuePropName="file"
            name="banner"
            width="100%"
            height="231px"
            getValueFromEvent={handleChangeBanner}
            initUrl={form.getFieldValue('banner')}
          />
          <MinText>
            Hiển thị ở đầu trang dành cho trang giới thiệu của bạn
          </MinText>
        </FormItem> */}
      </Form>
    </PanelWrapper>
  );
};

export default BrandForm;
