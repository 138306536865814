import { EyeOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Icon, LoadingOutlined, Spacing } from 'farmhub-ui-core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FormItem } from 'components';
import { TrashIcon } from 'components/Icon';

import ReviewModal from './ReviewModal';

const CustomUpload = styled(Upload)<{
  width: string;
  height: string;
  isUrl: boolean;
}>`
  position: relative;
  padding: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  width: ${props => props.width};
  display: flex;
  justify-content: center;

  .avatar-uploader > .ant-upload {
    width: 100%;
    height: ${props => props.height};
    background: #f6fcf9;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: ${props => (props.isUrl ? 'auto' : '100%')};
    height: ${props => props.height};
    border: none;
    background: #f6fcf9;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .ant-upload.ant-upload-select-picture-card img {
    width: 100%;
    height: ${props => props.height};
    background: #f6fcf9;
  }
`;

const Video = styled.div`
  position: relative;

  &:hover {
    .action {
      visibility: visible;
    }
  }
`;

const WrapperAction = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 126px;
  height: 100px;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0px;
  left: 0px;
  visibility: hidden;
`;

interface UploadVideoProps {
  form: any;
  width: string;
  height: string;
  disabled?: boolean;
  name: string;
  valuePropName: string;
  initUrl?: any;
  getValueFromEvent: (e: any) => void;
  onRemove?: () => void;
}

const UploadVideo = ({
  form,
  disabled,
  width,
  height,
  name,
  valuePropName,
  initUrl,
  getValueFromEvent = () => console.log('getvalue'),
  onRemove = () => console.log('remove'),
}: UploadVideoProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [isReview, setIsReview] = useState(false);

  const handleChangeImg = ({ file }): void => {
    if (file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  };

  const handleRemove = (e): void => {
    e.stopPropagation();
    setUrl('');
    if (form.getFieldValue('videoID')) {
      form.setFieldsValue({ video: null });
    } else {
      form.setFieldsValue({ videoID: null, video: null });
    }
    onRemove();
  };

  const handleReview = (e): void => {
    e.stopPropagation();
    setIsReview(true);
  };

  useEffect(() => {
    const InitData = (): void => {
      if (initUrl) {
        if (typeof initUrl === 'string') {
          setUrl(initUrl);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(initUrl);
          reader.onload = (e: any) => {
            setUrl(e?.target.result);
          };
        }
      } else {
        setUrl('');
      }
    };

    InitData();
  }, [initUrl]);

  return (
    <>
      <ReviewModal url={url} isVisible={isReview} setIsVisible={setIsReview} />
      <FormItem
        valuePropName={valuePropName}
        name={name}
        getValueFromEvent={getValueFromEvent}
        marginouter="0px !important"
        margininer="0px !important"
      >
        <CustomUpload
          width={width}
          height={height}
          isUrl={url !== ''}
          disabled={disabled}
          onChange={handleChangeImg}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={file => {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e: any) => {
                setUrl(e?.target.result);
              };
            }
            return false;
          }}
        >
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: '30px', color: '#2AC17E' }} />
          ) : url ? (
            <Video>
              <video width={width} height={height}>
                <track kind="captions" />
                <source src={url} type="video/mp4" />
              </video>
              <WrapperAction
                onClick={e => e.stopPropagation()}
                className="action"
              >
                <Spacing size="middle">
                  <Icon onClick={handleReview} styles={{ cursor: 'pointer' }}>
                    <EyeOutlined style={{ fontSize: 18, color: '#fff' }} />
                  </Icon>
                  {/* <VerticalLine /> */}
                  <Icon onClick={handleRemove} styles={{ cursor: 'pointer' }}>
                    <TrashIcon width={18} height={18} color="#fff" />
                  </Icon>
                </Spacing>
              </WrapperAction>
            </Video>
          ) : (
            <VideoCameraAddOutlined
              style={{ fontSize: '30px', color: '#2AC17E' }}
            />
          )}
        </CustomUpload>
      </FormItem>
    </>
  );
};

export default UploadVideo;
