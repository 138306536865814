import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  selected: boolean;
}>`
  width: 140px;
  height: 130px;
  flex-shrink: 0;
  border-radius: 6px;

  border: ${props =>
    props.selected
      ? `1px solid ${props.theme.colors.primary.main}`
      : '1px solid #f1f1f1'};

  overflow: hidden;

  position: relative;

  transition: all 0.2s;
  cursor: pointer;

  box-sizing: border-box;

  &:hover {
    border: ${props =>
      props.selected
        ? `1px solid ${props.theme.colors.primary.main}`
        : '1px solid #CCC'};
  }
`;

export const Image = styled.img`
  width: 48px;
  height: 48px;

  object-fit: contain;
`;

export const Content = styled.div`
  padding: 12px;
`;

export const IconStyles = css`
  position: absolute;
  top: 6px;
  right: 6px;
`;
