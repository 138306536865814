import * as React from 'react';

import { IconProps } from './Icon';

export const ColorUserIcon = ({ width }: IconProps): JSX.Element => {
  const DEFAULT_WIDTH = '18';

  return (
    <svg
      width={width || DEFAULT_WIDTH}
      height={width || DEFAULT_WIDTH}
      viewBox="-42 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m333.671875 123.308594c0 33.886718-12.152344 63.21875-36.125 87.195312-23.972656 23.972656-53.308594 36.125-87.195313 36.125h-.058593c-33.84375-.011718-63.160157-12.164062-87.132813-36.125-23.976562-23.976562-36.125-53.308594-36.125-87.195312 0-33.875 12.148438-63.210938 36.125-87.183594 23.960938-23.964844 53.277344-36.1132812 87.132813-36.125h.058593c33.875 0 63.210938 12.152344 87.195313 36.125 23.972656 23.972656 36.125 53.308594 36.125 87.183594zm0 0"
        fill="#ffbb85"
      />
      <path
        d="m427.167969 423.945312c0 26.734376-8.503907 48.378907-25.253907 64.320313-16.554687 15.753906-38.449218 23.734375-65.070312 23.734375h-246.53125c-26.621094 0-48.515625-7.980469-65.058594-23.734375-16.761718-15.953125-25.253906-37.59375-25.253906-64.320313 0-10.28125.339844-20.453124 1.019531-30.234374.691407-10 2.089844-20.882813 4.152344-32.363282 2.078125-11.574218 4.75-22.515625 7.949219-32.515625 3.320312-10.351562 7.8125-20.5625 13.371094-30.34375 5.773437-10.152343 12.554687-18.996093 20.15625-26.277343 7.96875-7.621094 17.710937-13.742188 28.972656-18.203126 11.222656-4.4375 23.664062-6.6875 36.976562-6.6875 5.222656 0 10.28125 2.136719 20.03125 8.488282 6.101563 3.980468 13.132813 8.511718 20.894532 13.472656 6.703124 4.273438 15.78125 8.28125 27.003906 11.902344 9.863281 3.191406 19.875 4.972656 29.765625 5.28125 1.089843.039062 2.179687.058594 3.269531.058594 10.984375 0 22.09375-1.800782 33.046875-5.339844 11.222656-3.621094 20.3125-7.628906 27.011719-11.902344 7.84375-5.011719 14.875-9.539062 20.886718-13.460938 9.757813-6.363281 14.808594-8.5 20.042969-8.5 13.300781 0 25.742188 2.25 36.972657 6.6875 11.261718 4.460938 21.003906 10.59375 28.964843 18.203126 7.613281 7.28125 14.394531 16.125 20.164063 26.277343 5.5625 9.789063 10.0625 19.992188 13.371094 30.332031 3.203124 10.011719 5.882812 20.953126 7.960937 32.535157 2.050781 11.492187 3.453125 22.375 4.140625 32.347656.691406 9.75 1.03125 19.921875 1.042969 30.242187zm0 0"
        fill="#6aa9ff"
      />
      <path
        d="m210.351562 246.628906h-.058593v-246.628906h.058593c33.875 0 63.210938 12.152344 87.195313 36.125 23.972656 23.972656 36.125 53.308594 36.125 87.183594 0 33.886718-12.152344 63.21875-36.125 87.195312-23.972656 23.972656-53.308594 36.125-87.195313 36.125zm0 0"
        fill="#f5a86c"
      />
      <path
        d="m427.167969 423.945312c0 26.734376-8.503907 48.378907-25.253907 64.320313-16.554687 15.753906-38.449218 23.734375-65.070312 23.734375h-126.550781v-225.535156c1.089843.039062 2.179687.058594 3.269531.058594 10.984375 0 22.09375-1.800782 33.046875-5.339844 11.222656-3.621094 20.3125-7.628906 27.011719-11.902344 7.84375-5.011719 14.875-9.539062 20.886718-13.460938 9.757813-6.363281 14.808594-8.5 20.042969-8.5 13.300781 0 25.742188 2.25 36.972657 6.6875 11.261718 4.460938 21.003906 10.59375 28.964843 18.203126 7.613281 7.28125 14.394531 16.125 20.164063 26.277343 5.5625 9.789063 10.0625 19.992188 13.371094 30.332031 3.203124 10.011719 5.882812 20.953126 7.960937 32.535157 2.050781 11.492187 3.453125 22.375 4.140625 32.347656.691406 9.75 1.03125 19.921875 1.042969 30.242187zm0 0"
        fill="#2682ff"
      />
    </svg>
  );
};
