import {
  ButtonShape,
  ButtonType,
  LeftOutlined,
  RightOutlined,
} from 'farmhub-ui-core';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Action } from 'components';

const Wrapper = styled.div<{
  width: number;
}>`
  width: ${props => props.width}px;
  position: relative;

  .prev-button,
  .next-button {
    opacity: 0;

    transition: opacity 0.2s;
  }

  &:hover {
    .prev-button,
    .next-button {
      opacity: 1;
    }
  }
`;

const SlideShowWrapper = styled.div<{
  width: number;
}>`
  width: ${props => props.width}px;

  box-sizing: content-box;

  position: relative;

  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StylePrev = css`
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
`;

const StyleNext = css`
  top: 0;
  right: 0;
  transform: translate(50%, 0);
`;

const ButtonWrapper = styled.div<{
  type: string;
}>`
  width: 48px;
  height: 100%;

  position: absolute;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.type === 'PREV' ? StylePrev : StyleNext)}
`;

const ImageSlideShow = ({
  children,
  spacing,
  width,
}: ImageSlideShowProps): JSX.Element => {
  const refSlide = React.useRef<HTMLDivElement>(null);
  const refPrev = React.useRef<HTMLDivElement>(null);
  const refNext = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (refPrev.current && refNext.current && refSlide.current) {
      if (refSlide.current?.scrollWidth <= width) {
        refNext.current.style.display = 'none';
      }

      refPrev.current.style.display = 'none';
    }
  });

  const nextSlide = (): void => {
    if (
      refNext.current &&
      refSlide.current &&
      refSlide.current.scrollLeft + 2 * width + 12 + spacing >=
        refSlide.current.scrollWidth
    ) {
      refNext.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft + width + spacing,
        behavior: 'smooth',
      });
    }

    if (refPrev.current) {
      refPrev.current.style.display = 'flex';
    }
  };

  const prevSlide = (): void => {
    if (
      refPrev.current &&
      refSlide.current &&
      refSlide.current.scrollLeft - (width + spacing) <= 0
    ) {
      refPrev.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft - (width + spacing),
        behavior: 'smooth',
      });
    }

    if (refNext.current) {
      refNext.current.style.display = 'flex';
    }
  };

  return (
    <Wrapper width={width}>
      <SlideShowWrapper ref={refSlide} width={width}>
        {children}
      </SlideShowWrapper>
      <ButtonWrapper className="prev-button" ref={refPrev} type="PREV">
        <Action
          onClick={prevSlide}
          buttonProps={{
            shape: ButtonShape.Circle,
            type: ButtonType.Default,
            icon: { children: <LeftOutlined /> },
            width: '32px',
            height: '32px',
          }}
        />
      </ButtonWrapper>
      <ButtonWrapper className="next-button" ref={refNext} type="NEXT">
        <Action
          onClick={nextSlide}
          buttonProps={{
            shape: ButtonShape.Circle,
            type: ButtonType.Default,
            icon: { children: <RightOutlined /> },
            width: '32px',
            height: '32px',
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ImageSlideShow;

interface ImageSlideShowProps {
  children: React.ReactNode;
  spacing: number;
  width: number;
}
