import React from 'react';

import { IconProps } from './Icon';

export const ConnectIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m362 302c-16.539062 0-30 13.460938-30 30s13.460938 30 30 30 30-13.460938 30-30-13.460938-30-30-30zm0 0" />
    <path d="m512 362c0-82.703125-67.296875-150-150-150-48.050781 0-90.417969 23.121094-117.890625 58.375l-44.621094-29.417969c6.542969-13.679687 10.511719-28.808593 10.511719-44.957031 0-9.871094-1.808594-19.242188-4.367188-28.304688l109.492188-49.21875c13.617188 19.007813 35.761719 31.523438 60.875 31.523438 41.351562 0 75-33.648438 75-75s-33.648438-75-75-75-75 33.648438-75 75c0 5.535156.695312 10.898438 1.839844 16.097656l-109.242188 49.105469c-18.617187-29.457031-51.253906-49.203125-88.597656-49.203125-57.890625 0-105 47.109375-105 105s47.109375 105 105 105c30.960938 0 58.546875-13.730469 77.785156-35.121094l45.320313 29.882813c-9.976563 20.0625-16.105469 42.351562-16.105469 66.238281 0 84.164062 70.5625 150 150 150 79.214844 0 150-65.523438 150-150zm-92.148438 104.472656c-6.867187-25.890625-30.175781-44.472656-57.851562-44.472656s-50.984375 18.582031-57.851562 44.472656c-9.15625-5.09375-17.75-11.023437-25.242188-18.242187 13.40625-33.210938 45.476562-56.230469 83.09375-56.230469-33.089844 0-60-26.910156-60-60s26.910156-60 60-60 60 26.910156 60 60-26.910156 60-60 60c37.617188 0 69.6875 23.019531 83.09375 56.230469-7.492188 7.222656-16.085938 13.148437-25.242188 18.242187zm0 0" />
  </svg>
);
