import { unwrapResult } from '@reduxjs/toolkit';
import { Notification } from 'farmhub-ui-core';
import { unflatten } from 'flat';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: circle import
import useQueryParam from 'hooks/useQueryParam';

import { useAppSelector } from '../app';
import { clearPrevRoute } from '../slice';

const paginationInit = {
  current: 1,
  next: 2,
  prev: 0,
  take: 10,
  total: 0,
};

interface Props {
  isVisible?: boolean;
  action: any;
  query: any;
  option: any;
  deps?: any;
  formatData?: (e: any) => any;
  setQuery?: any;
}

const useFetchDataTable = ({
  isVisible = true,
  action,
  query = {},
  option = {},
  deps = [],
  formatData,
}: Props) => {
  const dispatch = useDispatch();

  const [data, setData] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState(paginationInit);
  const [isLoading, setIsLoading] = React.useState(false);
  const prevRoute = useAppSelector(state => state.appRoute.prevRoute);

  useQueryParam({ query, option, deps });

  const onReFreshList = async () => {
    const orderQuery: any = {};
    orderQuery[query.order?.name] = query.order?.value;

    const standardQuery = unflatten({ ...query });

    try {
      if (!query) {
        return;
      }

      const actionResult = await dispatch(
        action({
          ...standardQuery,
          order: orderQuery,
          page: query?.page,
        })
      );

      const { pagination: paginationResult, data: dataResult } =
        unwrapResult(actionResult);

      let formatList: any[] = [];
      if (formatData) {
        formatList = dataResult.map(item => formatData(item));
      } else {
        formatList = dataResult.map(item => {
          return {
            key: item.id,
            value: item.id,
            ...item,
          };
        });
      }

      setData(formatList);
      setPagination(paginationResult);
    } catch (err: any) {
      Notification('error', 'Tải dữ liệu thất bại', err);
    }
  };

  React.useEffect(() => {
    let isMounted = true;

    const orderQuery: any = {};
    orderQuery[query.order?.name] = query.order?.value;

    const standardQuery = unflatten({ ...query });

    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!query) {
          return;
        }

        const actionResult = await dispatch(
          action({
            ...standardQuery,
            order: orderQuery,
          })
        );
        const { pagination: paginationResult, data: dataResult } =
          unwrapResult(actionResult);

        let formatList: any[] = [];

        if (formatData) {
          formatList = dataResult.map(item => formatData(item));
        } else {
          formatList = dataResult.map(item => {
            return {
              key: item.id,
              value: item.id,
              ...item,
            };
          });
        }

        setData(formatList);
        if (isMounted) {
          setPagination(paginationResult);
        }
      } catch (err: any) {
        Notification('error', 'Tải dữ liệu thất bại', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isVisible) {
      fetchData();
    }

    return () => {
      isMounted = false;
      prevRoute && dispatch(clearPrevRoute());
    };
  }, [...deps, isVisible]); //eslint-disable-line

  return {
    isLoading,
    data,
    setData,
    onReFreshList,
    pagination,
    query,
    setPagination,
  };
};

export default useFetchDataTable;
