import { ButtonShape, Row } from 'farmhub-ui-core';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Action } from 'components';
import { ArrowRightIcon } from 'components/Icon';
import { DEFAULT_WIDTH_AS_NUMBER } from 'utils/constants';

const Wrapper = styled.div<{
  width: number;
}>`
  width: 100%;
  position: relative;

  .prev-button,
  .next-button {
    opacity: 0;
  }
`;

const SlideShowWrapper = styled.div<{
  background: string;
  padding?: string;
}>`
  width: 100%;
  background: ${p => p.background};

  padding: ${p => p.padding || '6px 0px'};
  //box-sizing: content-box;

  position: relative;

  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StylePrev = css`
  // background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  top: 0;
  left: 0;
  transform: translate(-36%, 0);
`;

const StyleNext = css`
  // background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  top: 0;
  right: 0;
  transform: translate(36%, 0);
`;

const ButtonWrapper = styled.div<{
  type: string;
}>`
  width: 36px;
  height: 100%;

  position: absolute;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.type == 'PREV' ? StylePrev : StyleNext)};
`;

const IconButtonStyles = css`
  width: 36px;
  height: 36px;
  background-color: #ffffff;

  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  // border: 1px solid ${p => p.theme.colors.text.secondary};
  border: none;

  border-radius: 999px;

  color: ${p => p.theme.colors.text.secondary};

  svg {
    path {
      fill: ${p => p.theme.colors.text.secondary};

      transition: all 0.4s;
    }
  }

  &:hover {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.primary.main};

    svg {
      path {
        fill: ${p => p.theme.colors.primary.main};
      }
    }
  }

  &:focus {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.primary.main};
  }
`;

const SlideShow = ({
  children,
  width = DEFAULT_WIDTH_AS_NUMBER,
  spaceScroll = width,
  background = '#fff',
  spacing = 0,
  padding = 0,
  wrapperPadding,
}: SlideShowProps): JSX.Element => {
  const refSlide = React.useRef<HTMLDivElement>(null);
  const refPrev = React.useRef<HTMLDivElement>(null);
  const refNext = React.useRef<HTMLDivElement>(null);
  const refDiv = React.useRef<HTMLDivElement>(null);
  const [slideShowWidth, setSlideShowWidth] = React.useState<number>(width);
  const [slideShowSpaceScroll, setSlideShowSpaceScroll] =
    React.useState<number>(spaceScroll);

  const changeWidth = () => {
    setSlideShowWidth(
      refDiv.current ? refDiv.current.offsetWidth : DEFAULT_WIDTH_AS_NUMBER
    );
    setSlideShowSpaceScroll(
      refDiv.current ? refDiv.current.offsetWidth : DEFAULT_WIDTH_AS_NUMBER
    );
  };

  React.useLayoutEffect(() => {
    if (width === DEFAULT_WIDTH_AS_NUMBER) {
      changeWidth();
    }
  }, [width]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  }, []);

  React.useEffect(() => {
    if (refPrev.current && refNext.current && refSlide.current) {
      if (refSlide.current?.scrollWidth <= slideShowWidth) {
        refNext.current.style.display = 'none';
      } else {
        refNext.current.style.display = 'flex';
      }

      refPrev.current.style.display = 'none';
    }
  }, [slideShowWidth, refSlide.current?.scrollWidth]);

  const nextSlide = () => {
    if (
      refNext.current &&
      refSlide.current &&
      refSlide.current.scrollLeft + 2 * slideShowSpaceScroll + 12 + spacing >=
        refSlide.current.scrollWidth
    ) {
      refNext.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft + slideShowSpaceScroll + spacing,
        behavior: 'smooth',
      });
    }

    if (refPrev.current) {
      refPrev.current.style.display = 'flex';
    }
  };

  const prevSlide = () => {
    if (
      refPrev.current &&
      refSlide.current &&
      refSlide.current.scrollLeft - (slideShowSpaceScroll + spacing) <= 0
    ) {
      refPrev.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft - (slideShowSpaceScroll + spacing),
        behavior: 'smooth',
      });
    }

    if (refNext.current) {
      refNext.current.style.display = 'flex';
    }
  };

  return (
    <Row justifyContent="center" ref={refDiv}>
      <Wrapper width={slideShowWidth - padding}>
        <SlideShowWrapper
          ref={refSlide}
          background={background}
          padding={wrapperPadding}
        >
          {children}
        </SlideShowWrapper>
        <ButtonWrapper className="prev-button" ref={refPrev} type="PREV">
          <Action
            onClick={prevSlide}
            buttonProps={{
              shape: ButtonShape.Circle,
              icon: { children: <ArrowRightIcon /> },
              width: '36px',
              height: '36px',
              styles: IconButtonStyles,
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper className="next-button" ref={refNext} type="NEXT">
          <Action
            onClick={nextSlide}
            buttonProps={{
              shape: ButtonShape.Circle,
              icon: { children: <ArrowRightIcon /> },
              width: '36px',
              height: '36px',
              styles: IconButtonStyles,
            }}
          />
        </ButtonWrapper>
      </Wrapper>
    </Row>
  );
};

export default SlideShow;

export interface SlideShowProps {
  width?: number;
  spaceScroll?: number;
  spacing: number;
  background?: string;
  wrapperPadding?: string;
  padding?: number;
  children: React.ReactNode | React.ReactNode[];
}
