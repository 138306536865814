import * as S from './styles';

export enum SwitchSize {
  Default = 'default',
  Small = 'small',
}

const PurchaseTable = ({
  switchSize = SwitchSize.Default,
  pagination,
  columns,
  dataSource,
  ...props
}: TableProps): JSX.Element => (
  <S.Table
    columns={columns}
    dataSource={dataSource}
    switchSize={switchSize}
    pagination={pagination}
    {...props}
  />
);

export default PurchaseTable;

export interface TableProps {
  loading?: boolean;
  switchSize?: SwitchSize;
  styles?: any;
  columns: any;
  dataSource: any;
  pagination?: any;
  bordered?: boolean;
  rowSelection?: any;
  components?: TableComponents;
  expandable?: any;
  locale?: any;
  sticky?: boolean;
  scroll?: {
    scrollToFirstRowOnChange?: boolean;
    x?: string | number | true;
    y?: string | number;
  };
  onChange?: (e: any) => void;
}

export interface TableComponents {
  table?: any;
  header?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
  body?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
}
