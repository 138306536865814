import React from 'react';

import { IconProps } from './Icon';

export const BuyingFullFillIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 348.047 348.047"
  >
    <g>
      <g>
        <g>
          <g>
            <path
              d="M306.141,319.493c-2.021,8.564-10.877,15.572-19.676,15.572H61.584c-8.8,0-17.654-7.008-19.676-15.572L9.259,181.183
					c-2.021-8.565,3.524-15.572,12.324-15.572h304.882c8.799,0,14.346,7.007,12.324,15.572L306.141,319.493z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  d="M100.497,306.062c-2.75-0.021-5.512-2.23-6.139-4.908L70.549,199.374c-0.626-2.678,1.111-4.844,3.861-4.812
							l8.487,0.098c2.75,0.031,5.512,2.248,6.139,4.925l23.795,101.717c0.626,2.679-1.111,4.851-3.861,4.828L100.497,306.062z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M247.551,306.062c2.75-0.021,5.512-2.23,6.139-4.908l23.809-101.779c0.627-2.678-1.109-4.844-3.859-4.812
							l-8.486,0.098c-2.75,0.031-5.512,2.248-6.139,4.925l-23.795,101.717c-0.627,2.679,1.109,4.851,3.859,4.828L247.551,306.062z"
                />
              </g>
            </g>
            <g>
              <g>
                <path d="M183.023,300.599c0,2.75-2.25,5-5,5h-8c-2.75,0-5-2.25-5-5V199.505c0-2.75,2.25-5,5-5h8c2.75,0,5,2.25,5,5V300.599z" />
              </g>
            </g>
          </g>
        </g>
        <path
          d="M341.465,109.093H304c-2.5,0-3.834-1.172-4.34-1.679l-92.393-92.391c-2.721-2.722-7.176-2.722-9.898,0l-7.071,7.071
			c-2.722,2.722-2.722,7.177,0,9.899l74.577,74.578c0,0,2.75,2.521-1.375,2.521c-45.438,0-134.212,0-180.624,0
			c-2.167,0-0.603-1.622-0.603-1.622l75.477-75.479c2.723-2.722,2.723-7.177,0-9.899l-7.072-7.07c-2.722-2.722-7.177-2.722-9.898,0
			l-92.393,92.392c-0.506,0.507-1.762,1.679-3.887,1.679H6.584c-4.4,0-7.294,3.53-6.432,7.845l3.862,19.311
			c0.863,4.314,5.169,7.845,9.569,7.845h320.882c4.398,0,8.705-3.53,9.568-7.845l3.861-19.311
			C348.758,112.623,345.863,109.093,341.465,109.093z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
