import styled from 'styled-components';

export const ScrollBody = styled.div<{
  height: string;
  padding?: string;
}>`
  height: ${p => p.height};
  padding: ${p => p.padding || '0px'};

  overflow: overlay;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent !important;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    width: 1px;
    border-radius: 10px;
    background-color: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;

    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e1e1e1;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }
`;
