import * as S from './styles';

const Badge = ({
  styles,
  className,
  status,
  text,
  ...props
}: BadgeProps): JSX.Element => (
  <S.Badge
    className={className}
    styles={styles}
    status={status}
    text={text}
    {...props}
  />
);

export default Badge;

export interface BadgeProps {
  styles?: any;
  className?: string;
  count?: number;
  status?: 'success' | 'processing' | 'default' | 'error' | 'warning';
  text?: string;
  color?: string;
  sizeNumber?: number;
  size?: 'default' | 'small';
}
