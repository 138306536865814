import { Input } from 'antd';
import { Space } from 'farmhub-ui-core';
import styled from 'styled-components';

import { useAppSelector } from 'app';
import { NameText } from 'components';
import { EditIcon } from 'components/Icon';
import { getAdjustList, getInitList } from 'slice';

const { TextArea } = Input;

interface IDiffList {
  init: string;
  adjust: string;
  key: string;
}

const getDiff = (adjustList, initList): IDiffList[] => {
  const keys = Object.keys(adjustList);
  const diffList: IDiffList[] = [];
  keys.forEach(key => {
    if (adjustList[key] !== initList[key]) {
      diffList.push({
        key,
        init: initList[key],
        adjust: adjustList[key],
      });
    }
  });
  return diffList;
};

const AdjustListPane = (): JSX.Element => {
  const adjustList = useAppSelector(getAdjustList);
  const initList = useAppSelector(getInitList);
  const diffList = getDiff(adjustList, initList);
  return (
    <Wrapper>
      <CardWrapper>
        <NameText>Danh sách chỉnh sửa</NameText>
        <Space height={6} />
        <Card>
          {diffList?.map(({ key, adjust, init }) => (
            <EditText key={key}>
              <EditIcon width={24} height={24} />
              {`Chỉnh sửa "${init}" => "${adjust}"`}
            </EditText>
          ))}
        </Card>
      </CardWrapper>
      <CardWrapper style={{ marginTop: '24px' }}>
        <NameText>Nhận xét</NameText>
        <Space height={6} />
        <TextArea
          style={{ height: '250px', width: '100%' }}
          placeholder="@ Bạn có thể viết lời nhận xét"
        />
      </CardWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Card = styled.div`
  width: 100%;
  height: 250px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: scroll;
`;

const EditText = styled.div`
  padding: 18px;
  width: 400px;
  height: 100px;
  background: #ffefdb;
  border: 1px dashed #ec911c;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export default AdjustListPane;
