import { Input } from 'antd';
import { InputNumber } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { CSelect, FormItem } from 'components';
import { REPORT_TYPE } from 'utils/constants';
import { getRelativeQuantityColor } from 'utils/function';

const reportType = [
  { key: '0', value: '0', name: 'SL tương đối' },
  { key: '1', value: '1', name: 'SL tuyệt đối' },
];

const quantity = [
  { key: '0', value: '0', name: 'Hết hàng' },
  { key: '1', value: '1', name: 'Có ít' },
  { key: '2', value: '2', name: 'Có vừa' },
  { key: '3', value: '3', name: 'Có nhiều' },
];

interface ReportQuantityInputProps {
  form?: any;
  initValue?: any;
}

const ReportQuantityInput = ({
  form,
  initValue,
}: ReportQuantityInputProps): JSX.Element => {
  const [type, setType] = React.useState<any>(initValue?.reportType || '0');
  const [color, setColor] = React.useState(
    getRelativeQuantityColor({
      reportType: REPORT_TYPE.RELATIVE,
      relativeQuantity: Number(form.getFieldValue('relativeQuantity') || '3'),
    })
  );

  React.useEffect(() => {
    const initData = (): void => {
      if (!initValue) {
        if (form.getFieldValue('reportType')) {
          setType(form.getFieldValue('reportType'));
        }
      }
    };

    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, setType]);

  return (
    <Wrapper onClick={(e: any) => e.stopPropagation()} width="100%">
      <Input.Group compact>
        <FormItem
          style={{ marginBottom: 0, width: '50%' }}
          name="reportType"
          initialValue="0"
        >
          <CSelect
            weight="bold"
            height="md"
            width="100%"
            list={reportType}
            onChange={setType}
          />{' '}
        </FormItem>
        {type === '0' ? (
          <FormItem
            initialValue="3"
            style={{ marginBottom: 0, width: '50%' }}
            name="relativeQuantity"
          >
            <CSelect
              weight="bold"
              height="md"
              width="100%"
              list={quantity}
              onChange={value => {
                setColor(
                  getRelativeQuantityColor({
                    reportType: REPORT_TYPE.RELATIVE,
                    relativeQuantity: Number(value),
                  })
                );
              }}
              styles={{ color }}
            />
          </FormItem>
        ) : (
          <FormItem style={{ marginBottom: 0 }} name="absoluteQuantity">
            <InputNumber
              width="130px"
              height="46px"
              fontWeight="bold"
              placeholder="Nhập tên sản phẩm"
            />
          </FormItem>
        )}
      </Input.Group>
    </Wrapper>
  );
};

export default ReportQuantityInput;

const Wrapper = styled.div<{ width?: string }>`
  ${p => p.width && `width: ${p.width}`}
`;
