import { Spacing, Text } from 'farmhub-ui-core';
import styled from 'styled-components';

import { NameText } from 'components';

interface IInfo {
  name: string;
  count: number;
}

interface IStyle {
  background: string;
  color: string;
  icon: any;
}

interface OverViewCardProps {
  style: IStyle;
  info: IInfo;
}

const OverViewCard = ({ style, info }: OverViewCardProps): JSX.Element => (
  <Wrapper>
    <Spacing>
      <WrapperIcon background={style.background} color={style.color}>
        {style.icon}
      </WrapperIcon>
      <Spacing direction="vertical">
        <NameText size="sm">{info.count}</NameText>
        <Text>{info.name}</Text>
      </Spacing>
    </Spacing>
  </Wrapper>
);

export default OverViewCard;

const Wrapper = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  padding: 18px;
`;

const WrapperIcon = styled.div<{ background: string; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: ${props => props.background};
  color: ${props => props.color};
  border-radius: 50%;
`;
