import { Text, Space } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { NameText, TitleText } from 'components';

interface PageTitleProps {
  title?: string;
  breadcrumb?: React.ReactNode;
  subTitle?: string;
  description?: string;
  justifycontent?: string;
}

const PageTitle = ({
  title,
  breadcrumb,
  subTitle,
  description,
  justifycontent,
}: PageTitleProps): JSX.Element => (
  <Wrapper
    justifycontent={justifycontent}
    width={title ? '50%' : ''}
    padding={subTitle}
  >
    {breadcrumb}
    {title && (
      <NameText lineHeight="28px" size="lg">
        {title}
      </NameText>
    )}
    {subTitle && <TitleText>{subTitle}</TitleText>}
    <Space height={12} />
    {description && <Text>{description}</Text>}
  </Wrapper>
);

export default PageTitle;

const Wrapper = styled.div<{
  justifycontent?: string;
  padding?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: ${props => props.justifycontent && 'space-around'};
  padding: ${props => props.padding && '18px 0px;'};
  width: ${props => (props.width ? props.width : '100%')};
`;
