import React from 'react';

import { IconProps } from './Icon';

export const MoreIcon = ({
  width,
  height,
  className,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 426.667 426.667"
  >
    <g>
      <g>
        <circle cx="42.667" cy="213.333" r="42.667" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="213.333" cy="213.333" r="42.667" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="384" cy="213.333" r="42.667" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
