import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CreateMultiCustomDomainBody, UserCustomDomainApi } from 'api';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface IUserCustomDomainState {
  list: any[];
  loading: ILoadingStatus;
  error: unknown;
}

export const findUserCustomDomainsForAdmin = createAsyncThunk<any, any>(
  'userCustomDomain/findUserCustomDomainsForAdmin',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await UserCustomDomainApi.findForAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createUserCustomDomain = createAsyncThunk(
  'userCustomDomain/createUserCustomDomain',
  async (body: CreateUserCustomDomainBody, { rejectWithValue }) => {
    try {
      const response = await UserCustomDomainApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const createMultiUserCustomDomain = createAsyncThunk(
  'userCustomDomain/createMultiUserCustomDomain',
  async (body: CreateMultiCustomDomainBody, { rejectWithValue }) => {
    try {
      const response = await UserCustomDomainApi.createMulti(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUserCustomDomain = createAsyncThunk(
  'userCustomDomain/removeUserCustomDomain',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await UserCustomDomainApi.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}

export const updateUserCustomDomain = createAsyncThunk(
  'userCustomDomain/updateUserCustomDomain',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await UserCustomDomainApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findUserByAdminForCreateUserCustomDomain = createAsyncThunk(
  'userCustomDomain/findUserByAdminForCreateUserCustomDomain',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await UserCustomDomainApi.findUserByAdmin(params);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
} as IUserCustomDomainState;

const userCustomDomainSlice = createSlice({
  name: 'userCustomDomain',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findUserCustomDomainsForAdmin.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findUserCustomDomainsForAdmin.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(
        findUserCustomDomainsForAdmin.rejected,
        (state, { payload: error }) => {
          state.loading = LOADING_STATUS.rejected;
          state.error = error;
        }
      )
      .addCase(createUserCustomDomain.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list.push(data);
      })
      .addCase(removeUserCustomDomain.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = state.list.filter(item => item.id !== data.id);
      });
  },
});

export const getListUserCustomDomain = (state: RootState): any =>
  state.userCustomDomain.list;

export default userCustomDomainSlice.reducer;

export interface CreateUserCustomDomainBody {
  userID: string;
  domain: string;
  ip?: string;
  avatar: string;
  theme: any;
  seo: any;
  displayName: string;
  status: number;
}
