import { ButtonType, Spacing, Button } from 'farmhub-ui-core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CButton } from 'components';

interface FooterProps {
  onSubmit?: any;
  src?: any;
  content?: Array<any>;
  rules?: Array<any>;
  isSubmitting?: boolean;
}

const Footer = ({
  onSubmit,
  src,
  content = ['', ''],
  rules = [],
  isSubmitting = false,
}: FooterProps): JSX.Element => {
  const history = useHistory();

  return (
    <Wrapper>
      <Spacing size="middle">
        <Button
          onClick={() => history.goBack()}
          height="50px"
          weight="bold"
          type={ButtonType.Text}
        >
          {content[0]}
        </Button>
        <CButton
          height="50px"
          src={src}
          onClick={onSubmit}
          type={ButtonType.Primary}
          rules={rules}
          loading={isSubmitting}
        >
          {content[1]}
        </CButton>
      </Spacing>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
  background: #fff;
  bottom: 0px;
  border-radius: 6px;
  padding: 18px;
`;
