import { Text } from 'farmhub-ui-core';
import React from 'react';
import { css } from 'styled-components';

import { Badge } from 'components';

const BadgeStyles = css`
  .ant-badge-status-processing {
    background-color: ${props => props.theme.colors.primary.main};
  }
  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
`;

interface StatusCellTableProps {
  status: number;
}

const StampStatusCellTable = ({
  status,
}: StatusCellTableProps): JSX.Element => {
  switch (status) {
    case 0:
      return (
        <Badge styles={BadgeStyles} status="processing" text="Đang kích hoạt" />
      );
    case 1:
      return <Text>Chưa kích hoạt</Text>;
    default:
      return <></>;
  }
};

export default StampStatusCellTable;
