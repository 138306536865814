import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from 'farmhub-ui-core';
import * as React from 'react';
import styled, { css } from 'styled-components';

const Wrap = styled.div<{ width: number }>`
  width: ${props => props.width}px;
  position: relative;

  .prev-button,
  .next-button {
    opacity: 0;

    transition: opacity 0.2s;
  }

  &:hover {
    .prev-button,
    .next-button {
      opacity: 1;
    }
  }
`;

const WrapSlideShow = styled.div<{
  width: number;
  ref: any;
}>`
  width: ${props => props.width}px;

  box-sizing: content-box;

  position: relative;

  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StylePrev = css`
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
`;

const StyleNext = css`
  top: 0;
  right: 0;
  transform: translate(50%, 0);
`;

const WrapButton = styled.div<{
  type: string;
}>`
  width: 48px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 10;

  ${props => (props.type === 'PREV' ? StylePrev : StyleNext)}
`;

interface SlideShowImageProps {
  children: any;
  spacing: number;
  width: number;
  initSlide?: number;
}

const SlideShowImage = ({
  children,
  spacing,
  width,
  initSlide,
}: SlideShowImageProps): JSX.Element => {
  const refSlide = React.useRef<HTMLDivElement>(null);
  const refPrev = React.useRef<HTMLDivElement>(null);
  const refNext = React.useRef<HTMLDivElement>(null);

  const nextSlide = React.useCallback(() => {
    if (
      refNext.current &&
      refSlide.current &&
      refSlide.current.scrollLeft + 2 * width + 12 + spacing >=
        refSlide.current.scrollWidth
    ) {
      refNext.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft + width + spacing,
        behavior: 'smooth',
      });
    }

    if (refPrev.current) {
      refPrev.current.style.display = 'flex';
    }
  }, []);

  const prevSlide = (): void => {
    if (
      refPrev.current &&
      refSlide.current &&
      refSlide.current.scrollLeft - (width + spacing) <= 0
    ) {
      refPrev.current.style.display = 'none';
    }

    if (refSlide.current) {
      refSlide.current.scroll({
        top: 0,
        left: refSlide.current.scrollLeft - (width + spacing),
        behavior: 'smooth',
      });
    }

    if (refNext.current) {
      refNext.current.style.display = 'flex';
    }
  };

  React.useEffect(() => {
    if (refPrev.current && refNext.current && refSlide.current) {
      if (refSlide.current?.scrollWidth <= width) {
        refNext.current.style.display = 'none';
      } else {
        refNext.current.style.display = 'flex';
      }

      refPrev.current.style.display = 'none';
    }

    if (initSlide) {
      let count = initSlide;
      while (count > 0) {
        nextSlide();
        count--;
      }
    }
  }, [width, refSlide.current?.scrollWidth, initSlide, nextSlide]);

  return (
    <Wrap width={width}>
      <WrapSlideShow ref={refSlide} width={width}>
        {children}
      </WrapSlideShow>
      <WrapButton
        className="prev-button"
        ref={refPrev}
        type="PREV"
        onClick={prevSlide}
      >
        <Button type="default" shape="circle" icon={<LeftOutlined />} />
      </WrapButton>
      <WrapButton
        className="next-button"
        ref={refNext}
        type="NEXT"
        onClick={nextSlide}
      >
        <Button type="default" shape="circle" icon={<RightOutlined />} />
      </WrapButton>
    </Wrap>
  );
};

export default SlideShowImage;
