import { serialize } from '../utils/crud';
import { FindOptions } from './common';
import { entityService } from './services';

const pathname = '/user-custom-domains';

export const UserCustomDomainApi = {
  findForAdmin: async (option: FindOptions) => {
    let url = `${pathname}/find-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findUserByAdmin: async (option: any) => {
    let url = `${pathname}/find-user-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  create: async (body: any) => {
    const url = `${pathname}/admin`;
    try {
      const response = await entityService.post(`${url}`, body);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createMulti: async (body: CreateMultiCustomDomainBody) => {
    const url = `${pathname}/admin/multi`;
    try {
      const response = await entityService.post(`${url}`, body);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findOne: async (id: string) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  update: async (id: string, body: any = {}) => {
    const url = `${pathname}/admin/${id}`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  remove: async (id: string) => {
    const url = `${pathname}/admin/${id}`;

    try {
      const response = await entityService.delete(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export interface CreateUserCustomDomainBody {
  userID: string;
  ip?: string;
  banner?: any;
  domain: string;
  appType?: number;
  avatar: any;
  displayName: string;
  logoName: any;
  seo: any;
  theme: any;
}

export interface CreateMultiCustomDomainBody {
  userCustomDomains: CreateUserCustomDomainBody[];
}
