import { IconProps } from './Icon';

export const PolicyIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M432.299,0H79.684c-10.99,0-19.899,8.91-19.899,19.899v413.029c0,10.99,8.91,19.899,19.899,19.899h71.02v39.248
               c0,16.357,18.732,25.748,31.836,15.921l73.451-55.073l73.45,55.072c13.015,9.759,31.837,0.563,31.837-15.921v-50.913
               l85.093-85.118c0.451-0.45,0.866-0.914,1.262-1.386c0.023-0.028,0.044-0.056,0.066-0.084c3.302-3.985,4.688-8.677,4.499-13.242
               V19.899C452.198,8.91,443.289,0,432.299,0z M267.943,412.135c-7.078-5.317-16.82-5.317-23.9,0
               c-12.237,9.189,2.643-1.969-53.538,40.15c0-64.566,0.096-86.989,0.154-116.123c19.219,12.181,41.751,18.9,65.291,18.907
               c0.012,0,0.023,0,0.035,0c23.615,0,46.223-6.751,65.487-18.993c0.003,1.603,0.011,114.881,0.011,116.209
               C319.253,450.612,271.36,414.701,267.943,412.135z M324.688,278.304c-32.492,49.186-104.767,49.485-137.459-0.129
               c-35.731-54.237,3.127-127.392,68.763-127.392C321.158,150.783,360.777,223.337,324.688,278.304z M367.731,104.358H144.255
               c-10.99,0-19.899-8.91-19.899-19.899s8.91-19.899,19.899-19.899h223.477c10.99,0,19.899,8.91,19.899,19.899
               S378.721,104.358,367.731,104.358z"
          />
          <path
            d="M256,175.85c-31.542,0-57.204,25.655-57.204,57.19s25.662,57.19,57.204,57.19c31.535,0,57.19-25.655,57.19-57.19
               S287.535,175.85,256,175.85z M238.594,233.04c0-9.59,7.808-17.392,17.405-17.392c9.59,0,17.392,7.802,17.392,17.392
               C273.391,256.025,238.594,256.029,238.594,233.04z"
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
