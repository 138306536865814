/* eslint-disable react/no-array-index-key */
import { Col, Row, Skeleton } from 'antd';
import { Space } from 'farmhub-ui-core';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  .ant-skeleton {
    width: 100%;
  }
`;

const SlideShowWithMainImage = (): JSX.Element => (
  <Wrapper>
    <Skeleton.Image
      style={{ width: '100%', height: 320, borderRadius: '6px' }}
    />
    <Space height={18} />
    <Row gutter={12}>
      {Array(4)
        .fill(0)
        .map((item, index) => (
          <Col key={index} flex="auto">
            <Skeleton.Image
              style={{ width: '100%', height: 86, borderRadius: '6px' }}
            />
          </Col>
        ))}
    </Row>
  </Wrapper>
);

export default SlideShowWithMainImage;
