import React from 'react';

import { IconProps } from './Icon';

export const ConnectionIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m18 7h-2a1 1 0 0 0 0 2h2a3 3 0 0 1 0 6h-2a1 1 0 0 0 0 2h2a5 5 0 0 0 0-10z" />
    <path d="m8 7h-2a5 5 0 0 0 0 10h2a1 1 0 0 0 0-2h-2a3 3 0 0 1 0-6h2a1 1 0 0 0 0-2z" />
    <path d="m7 12a1 1 0 0 0 1 1h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 -1 1z" />
  </svg>
);
