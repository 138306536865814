import { Layout, Menu } from 'antd';
import styled from 'styled-components';

const AntContent = Layout.Content;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #ffffff;

  display: flex;
`;

export const HeadWrapper = styled.div`
  padding: 0px 12px;
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  height: 98px;
  cursor: pointer;
  .logoText {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Image = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 68px;
  height: 50px;
`;

export const LogoFullText = styled.img`
  height: 50px;
`;

export const WrapperMenu = styled.div<{
  isToggle: boolean;
}>`
  width: ${p => (p.isToggle ? '270px' : '100px')};
  height: 100vh;
  flex-shrink: 0;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  transition: width 0.3s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  .ant-layout-sider-trigger {
    background: ${p => p.theme.colors.primary.main};
  }
  z-index: 1000;
`;

export const ContentMenu = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  padding: 0px 12px;
  overflow: overlay;
  overflow-x: hidden;

  &:hover {
    ::-webkit-scrollbar {
      width: 6px;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafafa;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: #ccc;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8c8c8c;
  }
`;

export const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 18px;
  .titleText {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const CMenu = styled(Menu)`
  border-right: none;
`;

export const WrapperContent = styled(AntContent)`
  margin: 12px;
  border-radius: 6px !important;
`;

export const WrapperFilter = styled.div<{
  display: string;
}>`
  width: 100%;
  height: auto;
  display: ${p => p.display};
  flex-direction: column;
  /* overflow: hidden;
  white-space: nowrap;

  * {
    overflow: hidden;
    white-space: nowrap;
  } */
`;

export const Content = styled.div<{ width: string }>`
  width: ${p => p.width};
  height: 100%;
`;

export const WrapperTag = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
`;

export const WrapperAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #f05540;
  color: #ffffff;
  font-size: 18px;
`;
