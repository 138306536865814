import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PackageAPI } from 'api';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IPackage {
  id: string;
  name: string;
  price: number;
}

interface IPackageState {
  list: IPackage[];
  loading: ILoadingStatus;
  isLoaded: boolean;
  error: unknown;
}

export const findPackagesMaster = createAsyncThunk<any, void>(
  'package/findPackagesMaster',
  async (_, thunkAPI) => {
    try {
      const response = await PackageAPI.findMasterData();
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
  isLoaded: false,
} as IPackageState;

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findPackagesMaster.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findPackagesMaster.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
        state.isLoaded = true;
      })
      .addCase(findPackagesMaster.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});

export const getListPackage = (state: RootState): any => state.package.list;

export default packageSlice.reducer;
