import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EntityApi, ResetPasswordByAdmin } from 'api/entity';
import { updateCurrentEntity } from 'api/services/entity';
import { SELECTED_ENTITY } from 'types/localStorage';

// 0: 'user', 1: 'farm', 2: 'store', 3: 'collaborator', 4: 'warehouse', 5: 'transport', 6: 'volunteer'
export const findAccount = createAsyncThunk(
  'account/findAccount',
  async (option: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.findEntitiesByAdmin(option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findEntitiesInChainByAdmin = createAsyncThunk(
  'account/findEntitiesInChainByAdmin',
  async (option: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.findEntitiesInChainByAdmin(option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findAdminDashboardTotal = createAsyncThunk(
  'account/findAdminDashboardTotal',
  async (credential, { rejectWithValue }) => {
    try {
      const response = await EntityApi.findAdminDashboardTotal();

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordByAdmin = createAsyncThunk(
  'account/resetPasswordByAdmin',
  async (param: ResetPasswordByAdmin, { rejectWithValue }) => {
    try {
      const response = await EntityApi.resetPasswordByAdmin(param);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findOneAccount = createAsyncThunk(
  'account/findOneAccount',
  async (params: Param, { rejectWithValue }) => {
    try {
      const { id, option } = params;
      const response = await EntityApi.findOne(id, option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const selectOneAccount = createAsyncThunk(
  'account/selectOneAccount',
  async (params: Param, { rejectWithValue }) => {
    try {
      const { id, option } = params;
      const response = await EntityApi.findOne(id, option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findOneOwner = createAsyncThunk(
  'account/findOneOwner',
  async (params: Param, { rejectWithValue }) => {
    try {
      const { id, option } = params;
      const response = await EntityApi.findOne(id, option);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  'account/updateAccount',
  async (params: Param, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await EntityApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDomainStatus = createAsyncThunk(
  'account/updateDomainStatus',
  async (params: Param, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await EntityApi.updateDomainStatus(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'account/create',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const createAnonymous = createAsyncThunk(
  'account/createAnonymous',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.createAnonymous(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface IInitialState {
  list: Array<any>;
  item: any;
  owner: any;
  isToggle: boolean;
  reloadFlag: boolean;
}

const initialState: IInitialState = {
  list: [],
  item: undefined,
  owner: undefined,
  isToggle: true,
  reloadFlag: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateQuantity(state, action) {
      const { count, type } = action.payload;
      state.item[type] += count;
    },

    updateMultiTypeQuantity(state, action) {
      const dataUpdate = action.payload;
      dataUpdate.forEach((item): void => {
        state.item[item.type] += item.count;
      });
    },

    updateUserQuantity(state, action) {
      const { count, type } = action.payload;
      state.owner[type] += count;
    },

    updateMenuToggle(state, action) {
      const toggle = action.payload;
      state.isToggle = toggle;
    },

    reloadEntity(state) {
      state.reloadFlag = !state.reloadFlag;
    },
  },
  extraReducers: builder => {
    builder.addCase(findOneOwner.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.owner = data;
    });
    builder.addCase(create.fulfilled, (state, action) => {
      const data = action.payload;
      const { type, id } = data;
      const newItem = {
        ...data,
        key: data.id,
        img: data.avatar,
        ...accountItemSkeleton,
      };
      state.list = state.list.concat(newItem);
      state.item = newItem;

      localStorage.setItem(SELECTED_ENTITY, JSON.stringify({ type, id }));
      updateCurrentEntity(id, type);
    });
  },
});

const { actions, reducer: accountReducer } = accountSlice;

export const {
  updateQuantity,
  updateMultiTypeQuantity,
  updateUserQuantity,
  updateMenuToggle,
  reloadEntity,
} = actions;
export default accountReducer;

export interface Param {
  id: string;
  option?: Record<string, unknown>;
  body?: Record<string, unknown>;
}

const accountItemSkeleton = {
  totalFarmAccount: 0,
  totalStoreAccount: 0,
  totalCollaboratorAccount: 0,
  totalWarehouseAccount: 0,
  totalTransportAccount: 0,
  totalVolunteerAccount: 0,
  totalPrice: 0,
  totalReceivedPrice: 0,
  totalUsedPrice: 0,
  totalProduct: 0,
  totalCoBuy: 0,
  totalCampaign: 0,
  totalEmployee: 0,
  totalPartner: 0,
  totalFieldProductObject: 0,
  totalFarmingProductObject: 0,
  totalProductionProductObject: 0,
  totalWikiImage: 0,
  totalWikiFile: 0,
  totalProcess: 0,
  totalArea: 0,
  totalBranch: 0,
  totalFollower: 0,
  totalRating: 0,
  avgRating: 0,
};
