import { Card, Tabs } from 'antd';
import { Button } from 'farmhub-ui-core';
import styled from 'styled-components';

import { overlay } from 'styled';

const { Meta } = Card;

export const CTabs = styled(Tabs)`
  height: calc(100vh - 72px);
  border: 1px solid ${props => props.theme.colors.border.light};

  position: sticky;
  top: 18px;
  right: 0px;

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      width: 100%;
    }
  }

  .ant-tabs-tab {
    width: calc(100% / 3);
    margin: 0px !important;

    padding: 12px 18px !important;
    border-radius: 0px !important;
    border: 0px !important;

    display: flex !important;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid ${p => p.theme.colors.border.light};
    }

    .ant-tabs-tab-btn {
      text-align: center;
    }
  }

  .ant-tabs-content-holder {
    overflow: scroll;

    ${overlay}
  }
`;

export const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${p => p.theme.app.padding.horizontal};

  overflow: auto;
`;

export const PreviewDecorationWrapper = styled.div`
  width: 100%;
  height: fit-content;

  // padding: ${p => p.theme.app.padding.horizontal};

  border-radius: 6px;
  // border: 1px dashed #e1e1e1;
`;

export const PreviewStoreWrapper = styled.div`
  width: 100%;
`;

export const PreviewScaleWrapper = styled.div<{ scale: number }>`
  width: 1360px;

  transform: translate(
      -${props => ((1 - props.scale) * 100) / 2}%,
      -${props => ((1 - props.scale) * 100) / 2}%
    )
    scale(${props => props.scale});
`;

export const PreviewSeoWrapper = styled.div`
  width: 100%;
  // border: 1px dashed #e1e1e1;
`;

export const CCard = styled(Card)`
  .ant-card-body {
    padding: ${p => p.theme.app.padding.horizontal};

    background: #fafafa;
  }

  .ant-card-cover {
    img {
      aspect-ratio: 8 / 5;
      object-fit: cover;
    }
  }
`;

export const CMeta = styled(Meta)`
  .ant-card-meta-title {
    font-weight: ${props => props.theme.font.weight.extraBold};
  }
`;

export const AddProductButton = styled(Button)`
  width: 100%;
  height: ${props => props.theme.button.height.md};
  border: 1px dashed ${props => props.theme.colors.primary.main};
  transition: 0.4s all;
  span {
    color: ${props => props.theme.colors.primary.main};
  }
  &:disabled {
    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &:hover:not([disabled]) {
    background: ${props => props.theme.colors.primary.main};

    span {
      color: #fff !important;
    }
  }
`;

export const MinText = styled.div`
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: 10px;
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: 12px;
`;
