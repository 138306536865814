import { Empty as AntEmpty } from 'antd';
import { Row } from 'farmhub-ui-core';

import ACCOUNT from 'assets/images/no_data/img_account.png';
import ADDRESS from 'assets/images/no_data/img_address.png';
import AREA from 'assets/images/no_data/img_area.png';
import BANNER from 'assets/images/no_data/img_banner.png';
import CATEGORY from 'assets/images/no_data/img_category.png';
import CERTIFICATION from 'assets/images/no_data/img_certification.png';
import COBUY from 'assets/images/no_data/img_co_buy.png';
import COMMENT from 'assets/images/no_data/img_comment.png';
import IMAGE from 'assets/images/no_data/img_image.png';
import OBJECT from 'assets/images/no_data/img_object.png';
import ORDER from 'assets/images/no_data/img_order.png';
import PROCESS from 'assets/images/no_data/img_process.png';
import PRODUCT from 'assets/images/no_data/img_product.png';
import REVENUE from 'assets/images/no_data/img_revenue.png';
import DEFAUTL from 'assets/images/no_data/img_revenue.png';
import CAMPAIGN from 'assets/images/no_data/img_sale_off.png';
import SHOP from 'assets/images/no_data/img_shop.png';
import SHOPPING_CART from 'assets/images/no_data/img_shopping_cart.png';
import STAMP from 'assets/images/no_data/img_stamp.png';
import COUPON from 'assets/images/no_data/img_voucher.png';
import VOUCHER from 'assets/images/no_data/img_voucher.png';

export const dataEmpty = {
  DEFAUTL,
  ACCOUNT,
  ADDRESS,
  VOUCHER,
  CATEGORY,
  PRODUCT,
  OBJECT,
  PROCESS,
  CERTIFICATION,
  ORDER,
  COMMENT,
  IMAGE,
  AREA,
  REVENUE,
  COBUY,
  CAMPAIGN,
  STAMP,
  SHOP,
  COUPON,
  SHOPPING_CART,
  BANNER,
};

const Empty = ({
  minHeight = 200,
  description = 'Không tìm thấy dữ liệu',
  img = dataEmpty.DEFAUTL,
}: EmptyProps): JSX.Element => (
  <Row justifyContent="center" styles={{ 'min-height': minHeight }}>
    <AntEmpty
      style={{
        color: '#8C8C8C',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
      }}
      image={img}
      description={description}
      imageStyle={{ width: 72, height: 72, marginBottom: 18 }}
    />
  </Row>
);

export default Empty;

export interface EmptyProps {
  minHeight?: number;
  description?: string;
  img?: string;
}
