import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface State {
  prevRoute: string;
}

const initialState: State = {
  prevRoute: '',
};

const appRouteSlice = createSlice({
  name: 'appRoute',
  initialState,
  reducers: {
    saveRoute: (state, action) => {
      state.prevRoute = action.payload;
    },
    clearPrevRoute: state => {
      state.prevRoute = '';
    },
  },
  extraReducers: {},
});

const { reducer: appRouteReducer } = appRouteSlice;

export const { saveRoute, clearPrevRoute } = appRouteSlice.actions;
export default appRouteReducer;
