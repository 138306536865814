import { TextProps } from 'farmhub-ui-core';
import styled, { css } from 'styled-components';

const BoldText = styled.span<BoldTextProps>`
  word-break: break-word;
  color: ${p => p.theme.colors.text[p.color || 'primary']};
  line-height: ${p => p.lineHeight || '22px'};
  font-size: ${p => p.theme.font.size.xs};
  font-weight: ${p => p.theme.font.weight[p.weight || 'bold']} !important;
  ${p =>
    p.textTransform &&
    css`
      text-transform: ${p.textTransform};
    `};
  ${p =>
    p.capitalizeFirstLetter &&
    css`
      &::first-letter {
        text-transform: capitalize !important;
      }
    `};
  ${p => p.styles}

  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.lineNumber};
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export default BoldText;

type BoldTextProps = Pick<TextProps, Exclude<keyof TextProps, 'size'>>;
