import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { App } from 'containers/App';
import { WithAuthenticated } from 'containers/App/Auth/components';
import { LoginPage } from 'containers/App/Auth/LoginPage/Loadable';

const AppWithAuthAdminFarm = WithAuthenticated(App);

export const Router = (): JSX.Element => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route path="/" component={AppWithAuthAdminFarm} />
    </Switch>
  </BrowserRouter>
);
