import styled, { css } from 'styled-components';

import { overlay } from 'styled';

export const Wrapper = styled.div``;

export const ModalStyles = css`
  .ant-modal-header {
    display: none !important;
  }

  .ant-modal-body {
    overflow: scroll;

    ${overlay}
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  // padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background: rgba(57, 181, 74, 0.2);
  color: ${props => props.theme.colors.primary.main};
  border-radius: 50%;
  flex-shrink: 0;
`;
