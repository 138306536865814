import { DownloadOutlined, Spacing, Text } from 'farmhub-ui-core';
import styled from 'styled-components';

import { NameText, UnderlinedText } from 'components';
import { MicrosoftWordColorIcon } from 'components/Icon';

interface FileCardProps {
  name: string;
}

const FileCard = ({ name }: FileCardProps): JSX.Element => (
  <Wrapper>
    <HeadCard>
      <MicrosoftWordColorIcon width={52} height={52} />
    </HeadCard>
    <InfoCard>
      <NameText>{name}</NameText>
      <Text>12/12/2021 08:08:08</Text>
      <Spacing>
        <DownloadOutlined style={{ color: '#4276FE' }} />
        <UnderlinedText>Tải xuống</UnderlinedText>
      </Spacing>
    </InfoCard>
  </Wrapper>
);

export default FileCard;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  cursor: pointer;
`;

const HeadCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 126px;
  background: #fafafa;
  border-radius: 6px 6px 0px 0px;
`;
const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 126px);
  padding: 12px 18px;
`;
