import { DatePicker as AntDatePicker } from 'antd';
import styled from 'styled-components';

const DatePicker = styled(AntDatePicker)`
  min-height: 50px;
  font-size: ${props => props.theme.font.size.xs} !important;
  font-weight: ${props => props.theme.font.weight.regular} !important;
  .ant-input-lg {
    font-size: ${props => props.theme.font.size.xs} !important;
  }
  border-width: 1px;
  border-style: solid;
  color: #d9d9d9;
`;

export default DatePicker;
