import React from 'react';

import { IconProps } from './Icon';

export const EditIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9995 0C7.1633 0 0 7.16384 0 15.9997C0 24.8355 7.1633 32 15.9995 32C24.8356 32 31.9998 24.8359 31.9998 15.9997C31.9998 7.16341 24.8356 0 15.9995 0ZM23.6054 11.5141L22.0858 13.0338L18.9923 9.94059L17.8188 11.1141L20.9122 14.2076L13.3248 21.7943L10.2316 18.7014L9.05802 19.875L12.1512 22.9682L11.3952 23.7243L11.3808 23.7099C11.297 23.8479 11.1606 23.9486 10.9987 23.9845L8.11413 24.6277C8.07093 24.6374 8.02698 24.6421 7.98357 24.6421C7.82613 24.6421 7.67317 24.5802 7.55978 24.4665C7.41429 24.3215 7.3537 24.1121 7.3985 23.9115L8.04138 21.0277C8.07765 20.8659 8.17856 20.7293 8.31637 20.6457L8.30176 20.631L20.5119 8.42037C20.6909 8.24171 20.9816 8.24171 21.1606 8.42091L23.6056 10.8654C23.7846 11.0444 23.7846 11.3351 23.6054 11.5141Z"
      fill="#EC911C"
    />
  </svg>
);
