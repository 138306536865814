import { serialize } from '../utils';
import { FindWithAuthOption } from './common';
import productService from './services/entity';
// #endregion Local Imports

const pathname = '/products';

export const ProductApi = {
  findProductsByAdmin: async (option: any = {}) => {
    let url = `${pathname}/find-products-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await productService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findWithAuth: async (option: FindWithAuthOption = {}) => {
    let url = '/product/auth';
    const query = serialize(option);
    url += query;

    try {
      const response = await productService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findProvider: async (option: FindWithAuthOption = {}) => {
    let url = `${pathname}/provider-count`;
    const query = serialize(option);
    url += query;

    try {
      const response = await productService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateMultipleStatus: async (body: any = {}) => {
    const url = `${pathname}/multiple-status-by-admin`;

    try {
      const response = await productService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateAllStatus: async (body: any = {}) => {
    const url = `${pathname}/all-status-by-admin`;

    try {
      const response = await productService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  create: async (body: any) => {
    try {
      const response = await productService.post(`/product/entity`, body);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createAnonymous: async (body: any) => {
    try {
      const response = await productService.post(
        `/product/anonymous-entity`,
        body
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findOne: async (id: string, option: any = {}) => {
    let url = `${pathname}/${id}`;
    const query = serialize(option);
    url += query;

    try {
      const response = await productService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  update: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await productService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateDomainStatus: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}/domain-status`;

    try {
      const response = await productService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateStatus: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}/status-by-admin`;

    try {
      const response = await productService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getLocationID: async (provinceCode: string, districtCode: string) => {
    try {
      const response = await productService.get(
        `/locations?where={"provinceCode":"${provinceCode}","districtCode":"${districtCode}"}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteAllEntityProducts: async (id: string) => {
    const url = `${pathname}/entity/${id}`;

    try {
      const response = await productService.delete(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
