import { Text, Space } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
`;

const Img = styled.img`
  width: 48px;
  height: 48px;
`;

interface CheckBoxCardProps {
  name: string;
  logo: string;
}

const CheckBoxCard = ({ name, logo }: CheckBoxCardProps): JSX.Element => (
  <Wrapper>
    <Img src={formatImageUrl(logo, IMAGETYPE.AVATAR.xxs)} />
    <Space width={24} />
    <Text weight="medium" lineNumber={1}>
      {name}
    </Text>
  </Wrapper>
);

export default CheckBoxCard;
