import React from 'react';

import { IconProps } from './Icon';

export const StampIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <g>
      <g>
        <path
          d="M11.276,7.584c0.337,0,0.648-0.03,0.927-0.081c0.326,0.125,0.72,0.201,1.151,0.201
           c1.097,0,1.987-0.482,1.987-1.075c0-0.593-0.891-1.074-1.987-1.074c-0.413,0-0.796,0.066-1.114,0.184
           c-0.128-0.112-0.305-0.184-0.501-0.184h-0.288c-0.366,0-0.657,0.25-0.701,0.57C9.859,6.209,9.199,6.496,9.199,6.84
           C9.199,7.252,10.13,7.584,11.276,7.584z"
        />
        <path
          d="M3.127,16.406h13.819c0.161,0,0.291-0.116,0.291-0.262V3.855c0-0.143-0.13-0.261-0.291-0.261H3.127
           c-0.161,0-0.292,0.118-0.292,0.261v12.29C2.835,16.29,2.966,16.406,3.127,16.406z M3.853,4.493h12.295v11.163h-0.874
           c-0.512-1.171-1.138-3.097-2.091-2.877c-1.117,0.255-1.678,2.877-1.678,2.877s-0.569-2.921-2.147-4.335
           c-1.575-1.411-3.103,4.335-3.103,4.335H3.853V4.493z"
        />
        <path
          d="M20,2.811v-1.77h-2.068c0,0.883-0.799,1.599-1.784,1.599c-0.986,0-1.784-0.716-1.784-1.599h-2.7
           c-0.127,0.771-0.862,1.365-1.759,1.365c-0.897,0-1.631-0.594-1.759-1.365H5.398C5.271,1.812,4.536,2.407,3.639,2.407
           c-0.896,0-1.631-0.594-1.758-1.365H0v1.77C0.044,2.809,0.085,2.8,0.13,2.8c0.986,0,1.784,0.715,1.784,1.598
           S1.116,5.997,0.13,5.997c-0.044,0-0.086-0.009-0.13-0.012v2.418c0.923,0.061,1.654,0.744,1.654,1.586
           c0,0.843-0.731,1.526-1.654,1.588v2.438c0.923,0.061,1.654,0.744,1.654,1.588c0,0.843-0.731,1.524-1.654,1.586v1.77h2.6
           c0-0.02-0.007-0.039-0.007-0.061c0-0.883,0.799-1.599,1.785-1.599s1.784,0.716,1.784,1.599c0,0.021-0.006,0.041-0.007,0.061h2.7
           c0.098-0.8,0.846-1.425,1.765-1.425c0.92,0,1.668,0.625,1.765,1.425h2.735c0.098-0.8,0.847-1.425,1.766-1.425
           s1.667,0.625,1.765,1.425H20v-1.77c-0.044,0.003-0.085,0.012-0.13,0.012c-0.985,0-1.783-0.716-1.783-1.598
           c0-0.885,0.798-1.599,1.783-1.599c0.045,0,0.086,0.009,0.13,0.011v-2.418c-0.923-0.061-1.654-0.745-1.654-1.586
           c0-0.844,0.731-1.527,1.654-1.587V5.984c-0.923-0.061-1.654-0.744-1.654-1.587C18.346,3.555,19.077,2.872,20,2.811z
            M17.868,17.017H2.205V2.984h15.664L17.868,17.017L17.868,17.017z"
        />
        <circle cx="5.907" cy="7.262" r="1.326" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
