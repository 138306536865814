import { Form, Spin } from 'antd';
import {
  Button,
  Input,
  Notification,
  Row,
  SelectOption,
  Space,
  TabPane,
  Tabs,
} from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { CreateMultiCustomDomainBody, CreateUserCustomDomainBody } from 'api';
import { useAppDispatch } from 'app';
import { entityTypes } from 'commons/data';
import {
  Empty,
  InfoCard,
  PageTitleBar,
  PhoneNumberText,
  dataEmpty,
} from 'components';
import { CSelect } from 'components/Form';
import {
  CreateUserSmsAccountBody,
  createUserSMSAccount,
  findUserByAdminForCreateUserSmsAccount,
} from 'slice';
import { uploadSingleFile } from 'slice/uploadSlice';
import {
  createMultiUserCustomDomain,
  createUserCustomDomain,
  findUserByAdminForCreateUserCustomDomain,
} from 'slice/userCustomDomainSlice';
import { theme } from 'styled';
import {
  IMAGE_UPLOAD_TYPE,
  SIZE,
  USER_CUSTOM_DOMAIN_COLOR,
  USER_CUSTOM_DOMAIN_TEXT,
  USER_CUSTOM_DOMAIN_TYPE,
  USER_CUSTOM_DOMAIN_VALUE,
} from 'utils';
import {
  formatPhoneNumber,
  handleUploadSingleImage,
  isVietnamesePhoneNumber,
  parseUtils,
} from 'utils/function';

import DecorationTabs from './components/DecorationTabs';
import BrandForm from './components/forms/BrandForm';
import SEOForm from './components/forms/SEOForm';
import PreviewDecoration from './components/PreviewDecoration';
import PreviewHubAdmin from './components/PreviewHubAdmin';
import PreviewBilling from './components/PreviewHubBilling';
import PreviewHubCheck from './components/PreviewHubCheck';
import PreviewSeo from './components/PreviewSeo';
import { WrapperPreview, WrapperTabs } from './styles';

interface CreateBrandDomainPageProps {
  isVisible?: boolean;
  setIsVisible: (e: boolean) => void;
  handleReload?: any;
}

const INIT_SEO = {
  banner:
    'https://public-asset-cdn.hubtech.asia/website/hubtech/seo_banners/hub_seo_banner_1200_630.png',
  description:
    'Nhận được các ưu đãi giảm giá, tham gia nhóm mua chung, tìm hiểu thông tin nông sản, cửa hàng và cộng sự bán hàng',
  icon: 'https://public-asset-cdn.hubtech.asia/website/hubtech/logo/logo_hub.png',
  title: 'Sàn thương mại điện tử nông nghiệp minh bạch',
};

const INIT_THEME = {
  background_color: '#ffffff',
  color: '#39B54A',
  frames: [],
};

interface HubValueType {
  seo: any;
  theme: any;
  isChangeValue: boolean;
  appType: number;
  domain: string;
  displayName: string;
  avatar?: string;
  banner?: string;
  [key: string]: any;
}

const CreateBrandDomainPage = ({
  isVisible,
  setIsVisible,
  handleReload,
}: CreateBrandDomainPageProps): JSX.Element => {
  const [form] = Form.useForm();
  const [infoForm] = Form.useForm();
  const [selectedEntity, setSelectedEntity] = React.useState<any>();

  const [branchForm] = Form.useForm();
  const [seoForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userList, setUserList] = React.useState<any[]>([]);
  const [isLoadingSearchUser, setIsLoadingSearchUser] =
    React.useState<boolean>(false);

  const [keySearchUser, setKeySearchUser] = React.useState<string>('');
  const [activeKey, setActiveKey] = React.useState<any>('1');
  const [brandPreviewData, setBrandPreviewData] = React.useState<any>({});
  const [brandBodyUpdate, setBrandBodyUpdate] = React.useState<any>({});
  const [seoBodyUpdate, setSeoBodyUpdate] = React.useState<any>({});
  const [seoPreviewData, setSeoPreviewData] = React.useState<any>({});
  const [appType, setAppType] = React.useState<string>(
    USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]
  );

  const [hubAdminData, setHubAdminData] = React.useState<HubValueType>({
    seo: INIT_SEO,
    theme: INIT_THEME,
    isChangeValue: false,
    appType: USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN,
    displayName: '',
    domain: '',
    avatar: '',
  });

  const [hubCheckData, setHubCheckData] = React.useState<HubValueType>({
    seo: INIT_SEO,
    theme: INIT_THEME,
    isChangeValue: false,
    appType: USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK,
    displayName: '',
    domain: '',
  });

  const [hubBillingData, setHubBillingData] = React.useState<HubValueType>({
    seo: INIT_SEO,
    theme: INIT_THEME,
    isChangeValue: false,
    appType: USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING,
    displayName: '',
    domain: '',
  });

  const [hubIdentityData, setHubIdentityData] = React.useState<HubValueType>({
    seo: INIT_SEO,
    theme: INIT_THEME,
    isChangeValue: false,
    appType: USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY,
    displayName: '',
    domain: '',
  });

  const renderAppPage = (appType: string | number) => {
    const entityPageList = {
      [USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]]: (
        <PreviewHubAdmin data={brandPreviewData} />
      ),
      [USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]]: (
        <PreviewHubCheck data={brandPreviewData} />
      ),
      [USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]]: (
        <PreviewBilling data={brandPreviewData} />
      ),
      [USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]]: (
        <PreviewBilling data={brandPreviewData} />
      ),
    };

    return entityPageList[appType];
  };

  const handleSetUpdateHubValueWithAppType = (
    appType: string,
    value: any,
    type?: 'THEME' | 'SEO'
  ) => {
    let appValueType = USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN;

    if (
      appType == USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]
    ) {
      appValueType = USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN;
    }

    if (
      appType == USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]
    ) {
      appValueType = USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING;
    }

    if (
      appType == USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]
    ) {
      appValueType = USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK;
    }

    if (
      appType == USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]
    ) {
      appValueType = USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY;
    }

    if (appValueType == USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN) {
      if (type === 'SEO') {
        setHubAdminData({
          ...hubAdminData,
          seo: { ...hubAdminData.seo, ...value },
        });
      }

      if (type === 'THEME') {
        setHubAdminData({
          ...hubAdminData,
          theme: { ...hubAdminData.theme, ...value },
        });
      }

      if (!type) {
        setHubAdminData({ ...hubAdminData, ...value });
      }
    }

    if (appValueType == USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING) {
      if (type === 'SEO') {
        setHubBillingData({
          ...hubBillingData,
          seo: { ...hubBillingData.seo, ...value },
        });
      }
      if (type === 'THEME') {
        setHubBillingData({
          ...hubBillingData,
          theme: { ...hubBillingData.theme, ...value },
        });
      }

      if (!type) {
        setHubBillingData({ ...hubBillingData, ...value });
      }
    }

    if (appValueType == USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK) {
      if (type === 'SEO') {
        setHubCheckData({
          ...hubCheckData,
          seo: { ...hubCheckData.seo, ...value },
        });
      }
      if (type === 'THEME') {
        setHubCheckData({
          ...hubCheckData,
          theme: { ...hubCheckData.theme, ...value },
        });
      }

      if (!type) {
        setHubCheckData({ ...hubCheckData, ...value });
      }
    }

    if (appValueType == USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY) {
      if (type === 'SEO') {
        setHubIdentityData({
          ...hubIdentityData,
          seo: { ...hubIdentityData.seo, ...value },
        });
      }
      if (type === 'THEME') {
        setHubIdentityData({
          ...hubIdentityData,
          theme: { ...hubIdentityData.theme, ...value },
        });
      }
      setHubIdentityData({ ...hubIdentityData, ...value });
    }
  };

  const handleSetBrandBodyUpdate = (value: any) => {
    setBrandBodyUpdate({ ...brandBodyUpdate, ...value });
    setBrandPreviewData({ ...brandPreviewData, ...value });
    handleSetUpdateHubValueWithAppType(appType, { theme: value });
  };

  const handleSetSeoBodyUpdate = (value: any) => {
    setSeoBodyUpdate({ ...seoBodyUpdate, ...value });
    setSeoPreviewData({ ...seoPreviewData, ...value });
    handleSetUpdateHubValueWithAppType(appType, { seo: value });
  };

  const resetState = () => {
    setIsVisible(false);
    form.resetFields();
    // handleReload();
  };

  const onCancel = () => {
    setIsVisible!(false);
    form.resetFields();
  };

  React.useEffect(() => {
    const initPartnerList = async (search: string): Promise<void> => {
      try {
        setUserList([]);
        setIsLoadingSearchUser(true);
        const name = isVietnamesePhoneNumber(search?.trim())
          ? formatPhoneNumber(search)
          : search?.replace(/\s+/g, ' ').trim();

        const { data } = await dispatch(
          findUserByAdminForCreateUserCustomDomain({
            name,
          })
        ).unwrap();
        setUserList(data || []);
        setIsLoadingSearchUser(false);
      } catch (error: any) {
        setIsLoadingSearchUser(false);
        Notification('error', 'Tìm đối tác thất bại', error);
      }
    };

    const delayTime = setTimeout(() => {
      initPartnerList(keySearchUser);
    }, 800);
    return () => clearTimeout(delayTime);
  }, [keySearchUser, dispatch, setUserList, isVisible]);

  const buildLogoName = name => [{ text: name, color: '#025333' }];

  const handleUploadBanner = async (fileList: any[]) => {
    if (!fileList) {
      return undefined;
    }
    let result: any;

    try {
      const { data } = await dispatch(
        uploadSingleFile({ file: fileList, type: IMAGE_UPLOAD_TYPE.BANNER })
      ).unwrap();

      result = data;
    } catch (error: any) {
      Notification('error', 'Tải ảnh thất bại');
    }
    return result;
  };

  const handleSubmitBodyCreate = async () => {
    const infoValues = await infoForm.validateFields();

    setIsLoading(true);
    try {
      const { domain, displayName } = infoValues;

      const domainInfoValue: any = {
        domain,
        displayName,
      };

      const hubValues = [
        hubAdminData,
        hubCheckData,
        hubBillingData,
        hubIdentityData,
      ];

      const userCustomDomains: CreateUserCustomDomainBody[] = hubValues
        .filter(item => item.isChangeValue)
        .map(item => {
          return {
            avatar: item.theme.avatar || '',
            seo: item.seo,
            theme: item.theme,
            appType: item.appType,
            userID: selectedEntity?.id,
            domain: item.domain,
            logoName: buildLogoName(item.displayName),
            displayName: item.displayName || 'Chưa đặt tên',
          };
        });

      const isLackOfAvatar = userCustomDomains.find(item => !item.avatar);

      if (isLackOfAvatar) {
        Notification('error', 'Chưa thêm hình ảnh đại diện thương hiệu!');
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of userCustomDomains) {
        if (item?.avatar) {
          // eslint-disable-next-line no-await-in-loop
          item.avatar = await handleUploadSingleImage(item?.avatar, e =>
            dispatch(uploadSingleFile({ ...e, type: IMAGE_UPLOAD_TYPE.AVATAR }))
          );
        }

        if (item?.banner) {
          // eslint-disable-next-line no-await-in-loop
          item.banner = await handleUploadBanner(item.banner);
        }

        parseUtils(item, 'avatar', 'image');
        parseUtils(item, 'banner', 'image');
      }

      await dispatch(
        createMultiUserCustomDomain({
          userCustomDomains,
        })
      ).unwrap();
      // resetState();
    } catch (exists: any) {
      Notification('error', 'Thêm mới domain thương hiệu thất bại!');
    } finally {
      setIsLoading(false);
    }
  };

  const renderPreviewPage = (key: number) => {
    const previewList = {
      '1': renderAppPage(appType),
      '2': <PreviewSeo data={seoPreviewData} />,
    };

    return previewList[key];
  };

  const handleChangeTab = (val: string) => {
    const valueNumber = Number(val);
    setActiveKey(valueNumber);
  };

  const handleChangeAppType = (val: string) => {
    setAppType(val);

    const hubValues = [
      hubAdminData,
      hubCheckData,
      hubBillingData,
      hubIdentityData,
    ];

    const foundHubValue = hubValues.find(
      item => USER_CUSTOM_DOMAIN_VALUE[item.appType] == val
    );

    infoForm.setFieldsValue({
      displayName: foundHubValue?.displayName || '',
      domain: foundHubValue?.domain || undefined,
    });
    branchForm.setFieldsValue({
      color: theme.colors.primary.main,
      background_color: theme.colors.primary.main,
      ...foundHubValue?.theme,
    });

    seoForm.setFieldsValue({
      icon: foundHubValue?.avatar || '',
      banner: foundHubValue?.banner,
      displayName: foundHubValue?.displayName,
      title: `${foundHubValue?.displayName} - ${
        foundHubValue?.title ? foundHubValue?.title : 'Nông sản sạch'
      }`,
      description: '',
      ...foundHubValue?.seo,
    });
  };

  const radioMapping = [
    USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN,
    USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK,
    USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING,
    USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY,
  ].map(item => {
    return {
      value: item,
      color: USER_CUSTOM_DOMAIN_COLOR[item],
      text: USER_CUSTOM_DOMAIN_TEXT[item],
      type: item,
    };
  });

  const handleSelectUser = value => {
    const foundUser = userList.find(item => item.id == value);
    setSelectedEntity(foundUser);
  };

  const handleChangeInfoForm = (value: any) => {
    handleSetUpdateHubValueWithAppType(appType, {
      ...value,
      isChangeValue: true,
    });
  };

  React.useEffect(() => {
    branchForm.setFieldsValue({
      ...INIT_THEME,
    });

    seoForm.setFieldsValue({
      ...INIT_SEO,
    });
  }, []);

  return (
    <WrapperContent>
      <PageTitleBar title="Thêm mới domain thương hiệu" />
      <Space height={24} />
      <Form
        layout="vertical"
        form={infoForm}
        onValuesChange={handleChangeInfoForm}
      >
        <Row justifyContent="space-between" styles={{ gap: theme.spacing.sm }}>
          <Form.Item
            style={{ width: '50%' }}
            label="Tìm người dùng"
            name="userID"
            rules={[
              {
                required: true,
                message: 'Bạn chưa chọn người dùng!',
              },
            ]}
            // style={{
            //   width: '100%',
            // }}
          >
            <CSelect
              allowClear
              width="100%"
              placeholder="Nhập tên hoặc số điện thoại người dùng"
              optionLabelProp="label"
              showSearch
              onSearch={setKeySearchUser}
              filterOption={false}
              onChange={handleSelectUser}
              notFoundContent={
                isLoadingSearchUser ? (
                  <Spin size="small" />
                ) : (
                  <Empty
                    minHeight={120}
                    img={dataEmpty.ACCOUNT}
                    description="Hiện không có người dùng nào"
                  />
                )
              }
            >
              {userList.map((item: any) => (
                <SelectOption
                  label={item.displayName}
                  value={item.id}
                  key={item.id}
                >
                  <Row width="100%" justifyContent="space-between">
                    <InfoCard
                      size={SIZE.SMALL}
                      name={item.displayName}
                      img={item.avatar}
                      entityType={item.type}
                      info={<PhoneNumberText phoneNumber={item.phoneNumber} />}
                      width="fit-content"
                    />
                  </Row>
                </SelectOption>
              ))}
            </CSelect>
          </Form.Item>

          <Form.Item
            style={{ width: '50%' }}
            label="Domain"
            name="domain"
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập domain',
              },
              {
                type: 'url',
                message: 'Đường dẫn không hợp lệ',
              },
            ]}
          >
            <Input
              height={theme.input.height.md}
              placeholder="Nhập URL domain thương hiệu"
            />
          </Form.Item>

          <Form.Item
            style={{ width: '50%' }}
            label="Tên thương hiệu"
            name="displayName"
            rules={[
              {
                required: true,
                message: 'Bạn chưa nhập tên thương hiệu',
              },
            ]}
          >
            <Input
              height={theme.input.height.md}
              placeholder="Nhập Tên thương hiệu"
            />
          </Form.Item>
          <Row
            height="90px"
            alignItems="center"
            width="fit-content"
            styles={{ 'margin-top': '5px' }}
          >
            <Button
              height={theme.button.height.md}
              loading={isLoading}
              onClick={handleSubmitBodyCreate}
            >
              Thêm mới
            </Button>
          </Row>
        </Row>
      </Form>
      <Form form={form} layout="vertical" style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1" onChange={handleChangeAppType}>
          <TabPane
            tab="Hub Admin"
            key={USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]}
          >
            <Row width="100%" height="fit-content" alignItems="flex-start">
              <WrapperPreview>
                <PreviewDecoration>
                  {renderPreviewPage(activeKey)}
                </PreviewDecoration>
              </WrapperPreview>
              <Space width={24} />
              <WrapperTabs>
                <DecorationTabs onChange={handleChangeTab}>
                  <TabPane tab="Thương hiệu" key="1">
                    <BrandForm
                      form={branchForm}
                      onSetBodyUpdate={handleSetBrandBodyUpdate}
                    />
                  </TabPane>
                  <TabPane tab="SEO Thương hiệu" key="2">
                    <SEOForm
                      form={seoForm}
                      onSetBodyUpdate={handleSetSeoBodyUpdate}
                    />
                  </TabPane>
                </DecorationTabs>
              </WrapperTabs>
            </Row>
          </TabPane>
          <TabPane
            tab="Hub Check"
            key={USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]}
          >
            <Row width="100%" height="fit-content" alignItems="flex-start">
              <WrapperPreview>
                <PreviewDecoration>
                  {renderPreviewPage(activeKey)}
                </PreviewDecoration>
              </WrapperPreview>
              <Space width={24} />
              <WrapperTabs>
                <DecorationTabs onChange={handleChangeTab}>
                  <TabPane tab="Thương hiệu" key="1">
                    <BrandForm
                      form={branchForm}
                      onSetBodyUpdate={handleSetBrandBodyUpdate}
                    />
                  </TabPane>
                  <TabPane tab="SEO Thương hiệu" key="2">
                    <SEOForm
                      form={seoForm}
                      onSetBodyUpdate={handleSetSeoBodyUpdate}
                    />
                  </TabPane>
                </DecorationTabs>
              </WrapperTabs>
            </Row>
          </TabPane>
          <TabPane
            tab="Hub Billing"
            key={USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]}
          >
            <Row width="100%" height="fit-content" alignItems="flex-start">
              <WrapperPreview>
                <PreviewDecoration>
                  {renderPreviewPage(activeKey)}
                </PreviewDecoration>
              </WrapperPreview>
              <Space width={24} />
              <WrapperTabs>
                <DecorationTabs onChange={handleChangeTab}>
                  <TabPane tab="Thương hiệu" key="1">
                    <BrandForm
                      form={branchForm}
                      onSetBodyUpdate={handleSetBrandBodyUpdate}
                    />
                  </TabPane>
                  <TabPane tab="SEO Thương hiệu" key="2">
                    <SEOForm
                      form={seoForm}
                      onSetBodyUpdate={handleSetSeoBodyUpdate}
                    />
                  </TabPane>
                </DecorationTabs>
              </WrapperTabs>
            </Row>
          </TabPane>
          <TabPane
            tab="Hub Identity"
            key={USER_CUSTOM_DOMAIN_VALUE[USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]}
          >
            <Row width="100%" height="fit-content" alignItems="flex-start">
              <WrapperPreview>
                <PreviewDecoration>
                  {renderPreviewPage(activeKey)}
                </PreviewDecoration>
              </WrapperPreview>
              <Space width={24} />
              <WrapperTabs>
                <DecorationTabs onChange={handleChangeTab}>
                  <TabPane tab="Thương hiệu" key="1">
                    <BrandForm
                      form={branchForm}
                      onSetBodyUpdate={handleSetBrandBodyUpdate}
                    />
                  </TabPane>
                  <TabPane tab="SEO Thương hiệu" key="2">
                    <SEOForm
                      form={seoForm}
                      onSetBodyUpdate={handleSetSeoBodyUpdate}
                    />
                  </TabPane>
                </DecorationTabs>
              </WrapperTabs>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </WrapperContent>
  );
};

CreateBrandDomainPage.defaultProps = {
  isVisible: false,
};
export default CreateBrandDomainPage;

const WrapperContent = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px);
  background: #ffffff;
  border-radius: 6px;
  padding: 18px;
  margin-bottom: 18px;
`;
