import { Form, Input } from 'antd';
import { TextArea } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { FormItem, NameText, UploadAvatar, UploadBanner } from 'components';
import { theme } from 'styled';

import { PanelWrapper } from './styles';

interface BrandStepProps {
  form: any;
  onSetBodyUpdate: (e: any) => void;
}

const SEOForm = ({ form, onSetBodyUpdate }: BrandStepProps): JSX.Element => {
  const handleChangeBanner = e => e && e.file;
  const handleChangeAvatar = (e: any) => e && e.file;

  return (
    <PanelWrapper>
      <Form
        style={{ width: '100%' }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        labelAlign="left"
        onValuesChange={onSetBodyUpdate}
        form={form}
      >
        <Form.Item
          name="displayName"
          label={<NameText>Tên thương hiệu SEO:</NameText>}
          rules={[
            {
              required: true,
              message: 'Bạn chưa nhập tên!',
            },
          ]}
        >
          <Input
            onInput={(e: any) => {
              if (e.target && e.target.value) {
                e.target.value = e.target.value.toUpperCase();
              }
            }}
            style={{ height: theme.input.height.md }}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label={<NameText>Tiêu đề SEO:</NameText>}
          rules={[
            {
              required: true,
              message: 'Bạn chưa nhập tiêu đề SEO!',
            },
          ]}
        >
          <Input
            onInput={(e: any) => {
              if (e.target && e.target.value) {
                e.target.value = e.target.value.toUpperCase();
              }
            }}
            style={{ height: theme.input.height.md }}
          />
        </Form.Item>
        <Form.Item name="description" label={<NameText>Mô tả SEO:</NameText>}>
          <TextArea rows={4} />
        </Form.Item>
        <FormItem label={<NameText>SEO Icon:</NameText>}>
          <UploadAvatar
            valuePropName="file"
            name="icon"
            getValueFromEvent={handleChangeAvatar}
            initUrl={form.getFieldValue('icon')}
          />
        </FormItem>
        <FormItem label={<NameText>SEO Banner:</NameText>}>
          <UploadBanner
            valuePropName="file"
            name="banner"
            width="100%"
            height="231px"
            getValueFromEvent={handleChangeBanner}
            initUrl={form.getFieldValue('banner')}
          />
          <MinText>
            Hiển thị ở đầu trang dành cho trang giới thiệu của bạn
          </MinText>
        </FormItem>
      </Form>
    </PanelWrapper>
  );
};

export default SEOForm;

const MinText = styled.div`
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: 10px;
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: 12px;
`;
