import axios from 'axios';
import queryString from 'query-string';

import { env } from 'env';
import { SELECTED_ENTITY, USER_ID } from 'types/localStorage';

// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs
const { CancelToken } = axios;
export const sourceCancel = CancelToken.source();

const baseURLEntity = `${env.hubtech.entityService}/api/v1`;

// entity service
const entityService = axios.create({
  baseURL: baseURLEntity,
  cancelToken: sourceCancel.token,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: params => queryString.stringify(params),
});

const loadJWTToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    entityService.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

loadJWTToken();

entityService.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(SELECTED_ENTITY);
      updateTokenBearer('');
      window.location.replace('/');
    }
    throw error;
  }
);

export const getTokenBearer = (): string =>
  entityService.defaults.headers.common.Authorization;

export const updateTokenBearer = (token: string): void => {
  entityService.defaults.headers.common.Authorization = token;
};

export const updateTokenID = (token: string): void => {
  entityService.defaults.headers.common['token-id'] = token;
};

export const updateCurrentEntity = (entityID: string, type: string): void => {
  entityService.defaults.headers.common['entity-id'] = entityID;
  entityService.defaults.headers.common.type = type;
};

export const removeCurrentEntity = (): void => {
  delete entityService.defaults.headers.common['entity-id'];
  delete entityService.defaults.headers.common.type;
};

export default entityService;
