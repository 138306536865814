import {
  BellFilled,
  Button,
  ButtonType,
  Column,
  Modal,
  Row,
  Space,
  Spacing,
  Text,
} from 'farmhub-ui-core';
import * as React from 'react';

import { NameText, TitleText } from 'components';
import { theme } from 'styled';

import { Wrapper, ModalStyles, WrapperContent, WrapperIcon } from './styles';

interface FormConfirmModalProps {
  isVisible: boolean;
  setIsVisible: (e: boolean) => void;
  onOke?: () => void;
  onCancel?: () => void;
  title?: string;
  message?: React.ReactNode;
  name?: string;
  isLoading?: boolean;
  okText?: string;
  children?: React.ReactNode;
  bodyPadding?: string;
}

const FormConfirmModal = ({
  isVisible,
  setIsVisible,
  onOke,
  onCancel,
  title,
  message,
  name,
  isLoading,
  okText,
  children,
  bodyPadding = '24px',
}: FormConfirmModalProps): JSX.Element => (
  <Wrapper onClick={(e: any) => e.stopPropagation()}>
    <Modal
      maskClosable={false}
      centered
      bodyHeight="auto"
      styles={ModalStyles}
      bodyPadding={bodyPadding}
      visible={isVisible}
      onCancel={() => {
        onCancel && onCancel();
        setIsVisible(false);
      }}
    >
      <WrapperContent>
        <Column styles={{ gap: 8 }} alignItems="center">
          <WrapperIcon>
            <BellFilled style={{ fontSize: 54 }} />
          </WrapperIcon>
          <Column styles={{ gap: 2 }} alignItems="center">
            <TitleText color="dark" style={{ textAlign: 'center' }}>
              {title}
            </TitleText>
            <Text style={{ textAlign: 'center' }}>{message}</Text>
            <NameText style={{ textAlign: 'center' }}>{name}</NameText>
          </Column>

          {children}
          <Row justifyContent="center" styles={{ gap: 6 }}>
            {onCancel && (
              <Button
                height={theme.button.height.md}
                width="108px"
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  }
                  setIsVisible(false);
                }}
                type={ButtonType.Default}
              >
                Hủy bỏ
              </Button>
            )}
            {onOke && (
              <Button
                height={theme.button.height.md}
                onClick={() => {
                  if (onOke) {
                    onOke();
                  }
                }}
                type={ButtonType.Primary}
                width="108px"
                loading={isLoading}
              >
                {okText || 'Xác nhận'}
              </Button>
            )}
          </Row>
        </Column>
      </WrapperContent>
    </Modal>
  </Wrapper>
);

export default FormConfirmModal;
