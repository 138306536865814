import { flatten, unflatten } from 'flat';
import pick from 'object.pick';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  query?: any;
  option?: any;
  deps?: Array<any>;
}

const useQueryParam = ({ query = {}, option = {}, deps = [] }: Props): void => {
  const [firstLoad, setFirstLoad] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const standardQuery = unflatten(query);

    const { isVisible, whiteList } = option;

    if (isVisible) {
      if (!firstLoad) {
        history.push(
          `?${queryString.stringify(flatten(pick(standardQuery, whiteList)), {
            skipEmptyString: true,
            skipNull: true,
            allowDots: true,
          })}`
        );
      }
      setFirstLoad(false);
    }
  }, deps); //eslint-disable-line
};

export default useQueryParam;
