import styled from 'styled-components';

export const WrapperPreview = styled.div`
  width: calc(100% - 524px);
  height: 100%;
`;

export const WrapperTabs = styled.div`
  width: 480px;

  position: sticky;
  top: 24px;
  right: 0px;
`;
