import React from 'react';

import { IconProps } from './Icon';

export const DiagramIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 477.867 477.867"
  >
    <g>
      <g>
        <path
          d="M460.8,341.333h-34.133v-68.267c0-9.426-7.641-17.067-17.067-17.067H256V136.533h85.333
         c9.426,0,17.067-7.641,17.067-17.067v-102.4C358.4,7.641,350.759,0,341.333,0h-204.8c-9.426,0-17.067,7.641-17.067,17.067v102.4
         c0,9.426,7.641,17.067,17.067,17.067h85.333V256h-153.6c-9.426,0-17.067,7.641-17.067,17.067v68.267H17.067
         C7.641,341.333,0,348.974,0,358.4v102.4c0,9.426,7.641,17.067,17.067,17.067h102.4c9.426,0,17.067-7.641,17.067-17.067V358.4
         c0-9.426-7.641-17.067-17.067-17.067H85.333v-51.2h136.533v51.2h-34.133c-9.426,0-17.067,7.641-17.067,17.067v102.4
         c0,9.426,7.641,17.067,17.067,17.067h102.4c9.426,0,17.067-7.641,17.067-17.067V358.4c0-9.426-7.641-17.067-17.067-17.067H256
         v-51.2h136.533v51.2H358.4c-9.426,0-17.067,7.641-17.067,17.067v102.4c0,9.426,7.641,17.067,17.067,17.067h102.4
         c9.426,0,17.067-7.641,17.067-17.067V358.4C477.867,348.974,470.226,341.333,460.8,341.333z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
