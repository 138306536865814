import React from 'react';

import { IconProps } from './Icon';

export const BoxColorIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      style={{ fill: '#FFE17D' }}
      d="M248.768,1.654L19.499,112.336c-2.875,1.388-4.701,4.298-4.701,7.49v272.348
 c0,3.192,1.827,6.102,4.701,7.49l229.269,110.682c4.569,2.206,9.895,2.206,14.464,0l229.269-110.682
 c2.875-1.388,4.701-4.298,4.701-7.49V119.826c0-3.192-1.827-6.102-4.701-7.49L263.232,1.654
 C258.663-0.551,253.337-0.551,248.768,1.654z"
    />
    <path
      style={{ fill: '#FFC350' }}
      d="M16.181,115.273c-0.874,1.331-1.383,2.898-1.383,4.553v272.348c0,3.192,1.827,6.102,4.701,7.49
 l229.269,110.681c2.284,1.103,4.758,1.654,7.232,1.654V231.048L16.181,115.273z"
    />
    <path
      style={{ fill: '#FFD164' }}
      d="M495.819,115.273c0.874,1.331,1.383,2.898,1.383,4.553v272.348c0,3.192-1.827,6.102-4.701,7.49
 L263.232,510.345C260.948,511.448,258.474,512,256,512V231.048L495.819,115.273z"
    />
    <path
      style={{ fill: '#FFE17D' }}
      d="M83.407,377.657c-1.21,0-2.445-0.268-3.606-0.828L44.404,359.74
 c-4.142-1.998-5.872-6.969-3.874-11.103c1.99-4.134,6.977-5.856,11.103-3.874l35.397,17.09c4.142,1.998,5.872,6.969,3.874,11.103
 C89.475,375.927,86.502,377.657,83.407,377.657z"
    />
    <path
      style={{ fill: '#FFF6D8' }}
      d="M115.986,356.45c-1.21,0-2.445-0.268-3.606-0.828l-67.928-32.79
 c-4.134-1.998-5.872-6.969-3.874-11.103c1.99-4.126,6.985-5.856,11.103-3.874l67.927,32.79c4.134,1.998,5.872,6.969,3.874,11.103
 C122.054,354.719,119.081,356.45,115.986,356.45z"
    />
    <polygon
      style={{ fill: '#FF8087' }}
      points="414.549,154.373 173.887,38.11 110.447,68.86 351.649,185.303 "
    />
    <path
      style={{ fill: '#E6646E' }}
      d="M351.649,185.303v90.207c0,3.066,3.205,5.078,5.967,3.745l52.232-25.215
 c2.875-1.388,4.701-4.298,4.701-7.49v-92.176L351.649,185.303z"
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
