import { Text } from 'farmhub-ui-core';
import React from 'react';
import { css } from 'styled-components';

import { Badge } from 'components';

const BadgeStyles = css`
  .ant-badge-status-processing {
    background-color: ${props => props.theme.colors.primary.main};
  }
  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
`;

interface StatusCellTableProps {
  status: number;
  name?: string;
}

const StatusCellTable = ({
  status,
  name = 'sản xuất',
}: StatusCellTableProps): JSX.Element => {
  switch (status) {
    case 0:
      return (
        <Badge styles={BadgeStyles} status="processing" text={`Đang ${name}`} />
      );
    case 1:
      return <Text>Dừng {name}</Text>;
    case 2:
      return <Text>Tạm dừng {name}</Text>;
    case 3:
      return <Text>Chưa hoàn thành</Text>;
    default:
      return <></>;
  }
};

export default StatusCellTable;
