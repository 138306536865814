import * as React from 'react';

import { IconProps } from './Icon';

export const CheckFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 408.576 408.576"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288
			S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6
			c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344
			c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"
    />
  </svg>
);
