import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ErrorCensorshipApi } from 'api';
import { RootState } from 'app';

interface IInitialState {
  errorList: Record<string, unknown>;
  initList: Record<string, unknown>;
  adjustList: Record<string, unknown>;
  comment: string;
}
export const createErrorCensorship = createAsyncThunk<any, any>(
  'errorCensorship/create',
  async (params, thunkAPI) => {
    try {
      const response = await ErrorCensorshipApi.create(params);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState: IInitialState = {
  errorList: {},
  initList: {},
  adjustList: {},
  comment: '',
};

const errorListSlice = createSlice({
  name: 'errorList',
  initialState,
  reducers: {
    updateErrorList(state, action) {
      state.errorList = {
        ...state.errorList,
        ...action.payload,
      };
    },
    removeErrorList(state, action) {
      state.errorList = {
        ...state.errorList,
        [action.payload?.name]: undefined,
      };
    },
    clearErrorList(state) {
      state.errorList = {};
    },
    initAdjustList(state, action) {
      state.initList = {
        ...state.initList,
        ...action.payload,
      };
      state.adjustList = {
        ...state.adjustList,
        ...action.payload,
      };
    },
    updateAdjustList(state, action) {
      state.adjustList = {
        ...state.adjustList,
        ...action.payload,
      };
    },
    updateComment(state, action) {
      state.comment = action.payload;
    },
  },
});

const { actions, reducer } = errorListSlice;

export const {
  updateErrorList,
  removeErrorList,
  clearErrorList,
  updateAdjustList,
  initAdjustList,
  updateComment,
} = actions;

export const getErrorList = (state: RootState): any =>
  state.errorList.errorList;
export const getAdjustList = (state: RootState): any =>
  state.errorList.adjustList;
export const getInitList = (state: RootState): any => state.errorList.initList;
export const getComment = (state: RootState): string => state.errorList.comment;
export default reducer;
