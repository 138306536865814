import React from 'react';

import { IconProps } from './Icon';

export const CloudIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M436.236,104.188c-6.084-24.359-19.683-46.45-38.951-62.997c-22.076-18.957-50.239-29.396-79.304-29.396
         c-21.805,0-42.692,5.623-61.127,16.366c-19.523-17.854-45.121-27.884-72.019-27.884c-57.693,0-104.847,45.991-106.729,103.233
         c-19.945,5.575-38.004,16.923-51.809,32.752C9.34,155.708,0,180.61,0,206.381c0,58.883,47.904,106.787,106.787,106.787h298.426
         c58.883,0,106.787-47.904,106.787-106.787C512,159.17,480.688,117.696,436.236,104.188z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M161,343.17v28.55h-53.097c-6.548-23.055-27.777-39.997-52.903-39.997c-30.327,0-55,24.673-55,55
         c0,30.327,24.673,55,55,55c25.128,0,46.358-16.945,52.905-40.003H176c8.28,0,15-6.71,15-15v-43.55H161z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M271,403.819V343.17h-30v60.649c-23.057,6.547-40,27.777-40,52.904c0,30.327,24.673,55,55,55c30.327,0,55-24.673,55-55
         C311,431.596,294.057,410.366,271,403.819z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M457,331.723c-25.126,0-46.355,16.942-52.903,39.997H351v-28.55h-30v43.55c0,8.29,6.72,15,15,15h68.095
         c6.547,23.058,27.777,40.003,52.905,40.003c30.327,0,55-24.673,55-55C512,356.396,487.327,331.723,457,331.723z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
