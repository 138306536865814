import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  selected?: boolean;
  typeSelectCreate?: boolean;
  width?: string;
  height?: string;
}>`
  width: ${p => p.width || '130px'};
  height: ${p => p.height || '108px'};
  background: #fff;

  padding: 8px 6px;

  border-radius: 6px;
  border: 1px solid
    ${props =>
      props.selected && !props.typeSelectCreate
        ? props.theme.colors.primary.main
        : '#FFF'};

  box-shadow: ${props =>
    props.selected && props.typeSelectCreate ? '0 2px 6px #e1e1e1' : 'none'};

  flex-shrink: 0;

  position: relative;
  box-sizing: border-box;
  transition: all 0.2s;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px #e1e1e1;
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;

  .ant-skeleton-element {
    width: 100%;
  }
`;

export const Image = styled.img`
  height: 48px;
  width: 90%;
  object-fit: contain;
`;

export const IconStyles = css`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  .ant-skeleton-element {
    width: 100%;
    height: 100%;
  }
`;
