import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { UserSubscriptionApi } from 'api';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { ILoadingStatus, LOADING_STATUS } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface IUserSubscriptionState {
  list: any[];
  loading: ILoadingStatus;
  error: unknown;
}

export const findUserSubscriptionsForAdmin = createAsyncThunk<any, any>(
  'userSubscription/findUserSubscriptionsForAdmin',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await UserSubscriptionApi.findForAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const findUserByAdminForCreateUserSubscription = createAsyncThunk(
  'userSubscription/findUserByAdfindUserByAdminForCreateUserSubscriptioninForCreateUserSmsAccount',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await UserSubscriptionApi.findUserByAdmin(params);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserSubscription = createAsyncThunk(
  'userSubscription/createUserSubscription',
  async (body: CreateUserSubscriptionBody, { rejectWithValue }) => {
    try {
      const response = await UserSubscriptionApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUserSubscription = createAsyncThunk(
  'userSubscription/removeUserSubscription',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await UserSubscriptionApi.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}

export const updateUserSubscription = createAsyncThunk(
  'userSubscription/updateUserSubscription',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await UserSubscriptionApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
} as IUserSubscriptionState;

const userSubscriptionSlice = createSlice({
  name: 'userSubscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(findUserSubscriptionsForAdmin.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findUserSubscriptionsForAdmin.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(
        findUserSubscriptionsForAdmin.rejected,
        (state, { payload: error }) => {
          state.loading = LOADING_STATUS.rejected;
          state.error = error;
        }
      )
      .addCase(createUserSubscription.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list.push(data);
      })
      .addCase(removeUserSubscription.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = state.list.filter(item => item.id !== data.id);
      });
  },
});

export const getListUserSubscription = (state: RootState): any =>
  state.userSubscription.list;

export default userSubscriptionSlice.reducer;

export interface CreateUserSubscriptionBody {
  userID: string;
  packageID: string;
  startedAt: string;
  endedAt: string;
}
