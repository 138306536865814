import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import JWTDecode from 'jwt-decode';

import { AuthApi, EntityApi } from 'api';
import { updateTokenBearer as updateTokenBearerEntityService } from 'api/services/entity';
import { updateTokenBearer as updateTokenBearerIdentityService } from 'api/services/identity';
import { updateTokenBearer as updateTokenBearerNotificationService } from 'api/services/notification';
import { updateTokenBearer as updateTokenBearerUploadService } from 'api/services/upload';
import { REFRESH_TOKEN, SELECTED_ENTITY, USER_ID } from 'types/localStorage';

export type Credentials = {
  phoneNumber: string;
  password: string;
};

export const login = createAsyncThunk(
  'auth/loginForUser',
  async (credential: any, { rejectWithValue }) => {
    try {
      const response = await AuthApi.login(credential);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessTokenForUser',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage?.getItem(REFRESH_TOKEN) || '';
      const response = await AuthApi.refreshAccessToken(refreshToken);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logoutForUser',
  async (credential, { rejectWithValue }) => {
    try {
      const response = await AuthApi.logout();

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const findToCheckPhoneNumberExist = createAsyncThunk<
  any,
  {
    options: { phoneNumber: string; shouldReturnEntity?: boolean };
    activated?: boolean;
  },
  { rejectValue: { message: string } }
>(
  'auth/findToCheckPhoneNumberExist',
  async ({ options, activated = false }, { rejectWithValue }) => {
    try {
      const response = await EntityApi.findToCheckPhoneNumberExist(
        options,
        activated
      );
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserByAdmin = createAsyncThunk(
  'auth/createUserByAdmin',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await EntityApi.createUserForAdmin(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface IUser {
  sub: string;
  fullName: string;
  displayName: string;
  avatar: string;
  email: string;
}

interface AuthState {
  isAuthenticated: boolean;
  isLogout: boolean;
  accessToken: any;
  refreshToken: any;
  item: any;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isLogout: false,
  accessToken: undefined,
  refreshToken: undefined,
  item: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { accessToken, refreshToken } = action.payload.data;
        const {
          sub: id,
          fullName,
          displayName,
          avatar,
          email,
        }: IUser = JWTDecode(accessToken);

        state.isAuthenticated = true;
        state.isLogout = false;
        state.accessToken = accessToken;
        state.item = {
          id,
          fullName,
          displayName,
          avatar,
          email,
        };

        localStorage.setItem(USER_ID, id);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        updateTokenBearerUploadService(`Bearer ${accessToken}`);
        updateTokenBearerEntityService(`Bearer ${accessToken}`);
        updateTokenBearerIdentityService(`Bearer ${accessToken}`);
        updateTokenBearerNotificationService(`Bearer ${accessToken}`);
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        const { accessToken } = action.payload.data;
        const {
          sub: id,
          fullName,
          displayName,
          avatar,
          email,
        }: IUser = JWTDecode(accessToken);

        state.isAuthenticated = true;
        state.isLogout = false;
        state.accessToken = accessToken;
        state.item = {
          id,
          fullName,
          displayName,
          avatar,
          email,
        };

        localStorage.setItem(USER_ID, id);
        updateTokenBearerUploadService(`Bearer ${accessToken}`);
        updateTokenBearerEntityService(`Bearer ${accessToken}`);
        updateTokenBearerIdentityService(`Bearer ${accessToken}`);
        updateTokenBearerNotificationService(`Bearer ${accessToken}`);
      })
      .addCase(logout.fulfilled, state => {
        state.isAuthenticated = false;
        state.isLogout = true;
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.item = undefined;

        localStorage.removeItem(USER_ID);
        localStorage.removeItem(SELECTED_ENTITY);
        updateTokenBearerUploadService('');
        updateTokenBearerEntityService('');
        updateTokenBearerIdentityService('');
        updateTokenBearerNotificationService('');
      });
  },
});

const { reducer: authReducer } = authSlice;

export default authReducer;
