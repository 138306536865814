import React from 'react';

import { IconProps } from './Icon';

export const ProcessingIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    id="Capa_1"
    viewBox="0 0 512 512"
  >
    <g>
      <path d="m121 256c0 74.399 60.601 135 135 135s135-60.601 135-135-60.601-135-135-135-135 60.601-135 135zm205.499-19.501-85.499 85.802-55.499-55.801 20.999-20.999 34.5 34.198 64.501-64.199z" />
      <path d="m256 512c140.7 0 256-115.3 256-256h-60c0 107.401-88.599 195-196 195s-195-87.599-195-195 87.599-195 195-195c43.801 0 84.3 14.399 116.7 39l-22.8 22.8 105.899 21-20.998-105.901-19.2 19.2c-43.801-35.099-99.301-57.099-159.601-57.099-140.7 0-256 115.3-256 256s115.3 256 256 256z" />
    </g>
  </svg>
);
