import { Icon, Space, PictureOutlined } from 'farmhub-ui-core';
import React from 'react';
import styled from 'styled-components';

import { NameText, TitleText } from 'components';
import { ImageIcon } from 'components/Icon';
import { IMAGETYPE } from 'utils/constants';
import { formatImageUrl } from 'utils/function';

interface GalleryCardProps {
  name: string;
  logo: string;
  total?: number;
}

const GalleryCard = ({ name, logo, total }: GalleryCardProps): JSX.Element => (
  <Wrapper>
    <WrapperImg>
      {logo ? (
        <Img src={formatImageUrl(logo, IMAGETYPE.AVATAR.xxs)} />
      ) : (
        <WrapperImageSkeleton>
          <Icon>
            <ImageIcon color="#8c8c8c" width={48} height={38} />
          </Icon>
        </WrapperImageSkeleton>
      )}

      <ProductionInfo>
        <TitleText color="white">{total}</TitleText>
        <Space height={10} />
        <PictureOutlined style={{ color: '#fff', fontSize: '24px' }} />
      </ProductionInfo>
      <WrapperAction className="action">
        <GalleryAction>
          <TitleText color="white">XEM TẤT CẢ</TitleText>
        </GalleryAction>
      </WrapperAction>
    </WrapperImg>
    <Space height={12} />
    <NameText title={name} lineNumber={1}>
      {name}
    </NameText>
  </Wrapper>
);

export default GalleryCard;

const WrapperImageSkeleton = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    .action {
      opacity: 1;
    }
  }
`;

const WrapperImg = styled.div`
  position: relative;
`;

const WrapperAction = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  top: 0px;
`;

const ProductionInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100px;
  height: 160px;
  right: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
`;

const Img = styled.img`
  width: 100%;
  height: 160px;

  object-fit: cover;
`;

const GalleryAction = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
