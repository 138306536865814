import { Select } from 'antd';
import {
  Text,
  CaretDownOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { theme } from 'styled';
import { filterOption } from 'utils/function';

const { Option } = Select;

const Wrapper = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  width: 230px;
  height: ${p => p.height || '42px'};
  border: 1px solid ${props => props.theme.colors.border.main};
  background: #fff;
  border-radius: ${props => props.theme.radius.md};
  padding-left: 12px;
`;

const CSelect = styled(Select)<{ height?: string }>`
  width: 230px;
  height: 100%;
  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    display: flex;
    align-items: center;
    ${p => p.height && `height: 100% !important;`}

    .ant-select-selection-search-input {
      ${p => p.height && `height: 100% !important;`}
      ${p => p.height && `line-height: ${p.height};`}
    }

    .ant-select-selection-placeholder {
      ${p => p.height && `line-height: ${p.height};`}
      font-weight: ${p => p.theme.font.weight.regular} !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      border: 0 !important;
    }
  }
`;

interface OrderTableProps {
  value?: any;
  list: Array<any>;
  onChange?: (e: any) => void;
  height?: string;
}

const OrderTable = ({
  value,
  list,
  onChange = () => 0,
  height = '36px',
}: OrderTableProps): JSX.Element => (
  <Wrapper height={height}>
    {value && value.value === 1 ? (
      <SortDescendingOutlined
        onClick={() =>
          onChange({
            ...value,
            value: 0,
          })
        }
        style={{ color: theme.colors.primary.main, fontSize: 18 }}
      />
    ) : (
      <SortAscendingOutlined
        onClick={() =>
          onChange({
            ...value,
            value: 1,
          })
        }
        style={{ color: 'red', fontSize: 18 }}
      />
    )}
    <CSelect
      height={height}
      showSearch
      filterOption={(input: string, option: any) => filterOption(input, option)}
      suffixIcon={<CaretDownOutlined />}
      value={value && value.name}
      onChange={e =>
        onChange({
          ...value,
          name: e,
        })
      }
    >
      {list.map(item => (
        <Option key={item.key} value={item.value}>
          <Text lineNumber={1}>{item.name}</Text>
        </Option>
      ))}
    </CSelect>
  </Wrapper>
);

export default OrderTable;
