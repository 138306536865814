import { Col } from 'antd';
import { Text, Space, Row as CRow } from 'farmhub-ui-core';
import styled from 'styled-components';

import { UserCard, NameText, PriceText } from 'components';
import { CoinIcon, MoneyBagIcon } from 'components/Icon';
import { formatMoney } from 'utils/function';

const Statistics = (): JSX.Element => (
  <>
    <Col span={12}>
      <WrapperTotalRevenue>
        <NameText size="sm" weight="bold">
          Tổng doanh thu:
        </NameText>
        <Space height={12} />
        <PriceText size="lg">{formatMoney(0)}đ</PriceText>
        <Space height={36} />
        <CRow justifyContent="space-between">
          <UserCard
            avatar={
              <WrapperAvatar background="rgba(57, 181, 74, 0.1)">
                <MoneyBagIcon color="#39B54A" width={21} height={21} />
              </WrapperAvatar>
            }
            name={<PriceText size="md"> {formatMoney(0)}đ</PriceText>}
            info={<Text>Số tiền nhận được</Text>}
            // middleSpace={6}
          />
          <UserCard
            avatar={
              <WrapperAvatar background="rgba(250, 173, 20, 0.1)">
                <CoinIcon color="#FAAD14" width={21} height={21} />
              </WrapperAvatar>
            }
            name={<PriceText size="md"> {formatMoney(0)}đ</PriceText>}
            info={<Text>Số tiền đã sử dụng</Text>}
            // middleSpace={6}
          />
        </CRow>
      </WrapperTotalRevenue>
    </Col>
    <Col span={6}>
      <WrapperSubInfo>
        <NameText size="sm" weight="bold">
          Số tiền hôm nay
        </NameText>
        <Space height={24} />
        <PriceText size="lg">0đ</PriceText>
      </WrapperSubInfo>
    </Col>
    <Col span={6}>
      <WrapperSubInfo>
        <NameText size="sm">Số tiền TB trong tuần</NameText>
        <Space height={24} />
        <PriceText size="lg">0đ</PriceText>
      </WrapperSubInfo>
    </Col>
  </>
);

export default Statistics;

const WrapperTotalRevenue = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background: #fff;
  padding: 18px;
`;

const WrapperSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background: #fff;
`;

const WrapperAvatar = styled.div<{
  background: string;
}>`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.background};
`;
