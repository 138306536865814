import { Space } from 'farmhub-ui-core';
import styled from 'styled-components';

import { DEFAULT_WIDTH } from 'utils/constants';

export const Wrapper = styled.div<{
  height?: string;
  width?: string;
  padding?: string;
  styles?: any;
}>`
  height: ${p => p.height || 'fit-content'};
  width: ${p => p.width || DEFAULT_WIDTH};
  background: #fff;
  border-radius: 6px;
  padding: ${p => p.padding || '24px 18px'};

  ${p => p.styles}
`;

export const Content = styled.div<{
  padding?: string;
}>`
  width: 100%;
  padding: ${p => p.padding || '0px'};
  & > ${Space}:last-child {
    display: none;
  }
`;
