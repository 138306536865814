/* eslint-disable max-len */
export const DEFAULT_WIDTH = '600px';
export const DEFAULT_WIDTH_AS_NUMBER = 550;
export const LANDLINE_REGEX = /((^(\+84|84|0){1})(2([0-9]{1,2})))+([0-9]{8})$/;
export const CELLPHONE_REGEX =
  /((^(\+84|84|0){1})(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})$/;

export const PASSWORD_PATTERN = /^\S{6,20}$/g; // length from 6 to 20 character, not include spacing
export const MESSAGE_INVALID_PASSWORD = 'Mật khẩu 6 đến 20 ký tự chữ hoặc số';

export const BANNER =
  'https://public-asset-cdn.hubtech.asia/master/skeleton/logo_skeleton_320_320_01.webp';

export const LOGO =
  'https://public-asset-cdn.hubtech.asia/website/hubtech/logo/logo_hub.png';

export enum SELECT_MODE {
  MULTIPLE = 'multiple',
  TAGS = 'tags',
}

export const CONNECT_ROLE = {
  SUPPLIER: 0,
  CUSTOMER: 1,
};

export const ACTIVE_STATUS = {
  ACTIVATED: 0,
  DEACTIVATED: 1,
  PAUSED: 2,
  DRAFTED: 3,
  TEMP_REMOVED: 4,
};

export const COLLABORATOR_TEXT = {
  ABBREVIATION: 'ĐSBH',
  CAPITALIZE: 'Đại sứ bán hàng',
  LOWERCASE: 'đại sứ bán hàng',
  UPPERCASE: 'ĐẠI SỨ BÁN HÀNG',
};

export const IMAGE_UPLOAD_TYPE = {
  CATEGORY: 'category',
  SUB_CATEGORY: 'sub-category',
  AVATAR: 'avatar',
  LOGO: 'logo',
  PRODUCT: 'product',
  AREA: 'area',
  BRANCH: 'branch',
  PRODUCT_OBJECT: 'product-object',
  BANNER: 'banner',
  GALLERY: 'gallery',
  BLOG: 'blog',
  CERTIFICATION: 'certification',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const SMS_APP_TYPE = {
  VIVAS: 1,
  ESMS: 0,
};

export enum USER_CUSTOM_DOMAIN_TYPE {
  HUB_TECH = 0,
  HUB_ADMIN = 1,
  HUB_CHECK = 2,
  HUB_BILLING = 3,
  HUB_IDENTITY = 4,
  HUB_STORE = 5,
  HUB_FARM = 6,
  HUB_COLLABORATOR = 7,
  HUB_LANDING_PAGE = 8,
  HUB_CHECK_SHORT_URL = 9,
  HUB_CENTRAL_DCOOP = 10,
  HUB_CENTRAL_DCOM = 11,
  HUB_SHOP = 12,
  HUB_ABOUT_US = 13,
}

export enum USER_CUSTOM_DOMAIN_STATUS {
  ACTIVATED = 0,
  DEACTIVATED = 1,
  WAITING_FOR_APPROVAL = 2,
  WAITING_FOR_APPROVAL_AND_SUPPORT = 3,
}

export const PACKAGE_TYPE = {
  BASIC: 'BASIC',
  PRO: 'PRO',
  ENTERPRISE: 'ENTERPRISE',
};

export const PACKAGE_TYPE_TEXT = {
  [PACKAGE_TYPE.BASIC]: 'Cơ bản',
  [PACKAGE_TYPE.PRO]: 'Chuyên nghiệp',
  [PACKAGE_TYPE.ENTERPRISE]: 'Cao cấp',
};

export const PACKAGE_TYPE_CONVERT = {
  [PACKAGE_TYPE_TEXT[PACKAGE_TYPE.BASIC]]: PACKAGE_TYPE.BASIC,
  [PACKAGE_TYPE_TEXT[PACKAGE_TYPE.PRO]]: PACKAGE_TYPE.PRO,
  [PACKAGE_TYPE_TEXT[PACKAGE_TYPE.ENTERPRISE]]: PACKAGE_TYPE.ENTERPRISE,
};

export const PACKAGE_TYPE_COLOR = {
  [PACKAGE_TYPE.BASIC]: 'green',
  [PACKAGE_TYPE.PRO]: 'blue',
  [PACKAGE_TYPE.ENTERPRISE]: 'red',
};

export const USER_CUSTOM_DOMAIN_TEXT = {
  [USER_CUSTOM_DOMAIN_TYPE.HUB_TECH]: 'HubTech',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]: 'Hub Admin',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]: 'Hub Billing',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]: 'Hub Check',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]: 'Hub Identity',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_STORE]: 'Hub Store',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_FARM]: 'Hub Farm',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_COLLABORATOR]: 'Hub Collaborator',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_LANDING_PAGE]: 'Hub Landing Page',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK_SHORT_URL]: 'Hub Check Short URL',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CENTRAL_DCOOP]: 'Hub Central Dcoop',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CENTRAL_DCOM]: 'Hub Central Dcom',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_SHOP]: 'Hub Shop',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_ABOUT_US]: 'Hub About Us',
};

export const USER_CUSTOM_DOMAIN_VALUE = {
  [USER_CUSTOM_DOMAIN_TYPE.HUB_TECH]: 'hubtech',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]: 'hub_admin',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]: 'hub_billing',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]: 'hub_check',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]: 'hubidentity',
};

export const USER_CUSTOM_DOMAIN_COLOR = {
  [USER_CUSTOM_DOMAIN_TYPE.HUB_TECH]: 'geekblue',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_ADMIN]: 'purple',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_BILLING]: 'cyan',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_CHECK]: 'blue',
  [USER_CUSTOM_DOMAIN_TYPE.HUB_IDENTITY]: 'warning',
};

export const SMS_APP_TYPE_TEXT = {
  [SMS_APP_TYPE.ESMS]: 'ESms',
  [SMS_APP_TYPE.VIVAS]: 'Vivas',
};

export const MENU_TITLE_ORDER_BY_STATUS = {
  PENDING: 'Chờ xử lý',
  CONFIRM: 'Chờ KH xác nhận',
  SUPPLIER_CONFIRM: 'Chờ NCC xác nhận',
  PROCESSING: 'Đang xử lý',
  COMPLETE: 'Hoàn thành',
  DELIVERY: 'Giao hàng',
  REJECT: 'Từ chối',
  DRAF: 'Bản nháp',
  PRE_PROCESSING: 'Tiền xử lý',
};

export const MENU_TITLE_DOMAIN_BY_STATUS = {
  ACTIVE: 'Đang hoạt động',
  DEACTIVE: 'Dừng hoạt động',
  PENDING: 'Chờ duyệt',
  PENDING_SUPPORT: 'Chờ duyệt và hỗ trợ',
};

export const ICONS = {
  BELL_FILLED: {
    paths: [
      'm413.417969 360.8125c-32.253907-27.265625-50.75-67.117188-50.75-109.335938v-59.476562c0-75.070312-55.765625-137.214844-128-147.625v-23.042969c0-11.796875-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.535156-21.332031 21.332031v23.042969c-72.257812 10.410156-128 72.554688-128 147.625v59.476562c0 42.21875-18.496094 82.070313-50.945312 109.503907-8.296876 7.105469-13.054688 17.429687-13.054688 28.351562 0 20.589844 16.746094 37.335938 37.332031 37.335938h352c20.589844 0 37.335938-16.746094 37.335938-37.335938 0-10.921875-4.757813-21.246093-13.25-28.519531zm0 0',
      'm213.332031 512c38.636719 0 70.957031-27.542969 78.378907-64h-156.757813c7.425781 36.457031 39.746094 64 78.378906 64zm0 0',
    ],
    viewBox: '-43 0 512 512',
  },
  BILL: {
    paths: [
      'm144.972656 291.855469c0-5.40625-4.746094-9.792969-10.605468-9.792969-5.859376 0-10.605469 4.386719-10.605469 9.792969 0 5.410156 4.746093 9.792969 10.605469 9.792969 5.859374 0 10.605468-4.382813 10.605468-9.792969zm0 0',
      'm134.367188 212.527344c-5.847657 0-10.605469 4.390625-10.605469 9.792968 0 5.398438 4.757812 9.792969 10.605469 9.792969 5.847656 0 10.609374-4.394531 10.609374-9.792969 0-5.402343-4.761718-9.792968-10.609374-9.792968zm0 0',
      'm144.972656 152.785156c0-5.410156-4.746094-9.792968-10.605468-9.792968-5.859376 0-10.605469 4.382812-10.605469 9.792968 0 5.40625 4.746093 9.792969 10.605469 9.792969 5.859374 0 10.605468-4.386719 10.605468-9.792969zm0 0',
      'm365.207031.394531c-54.445312 0-98.582031 44.136719-98.578125 98.582031 0 54.445313 44.136719 98.582032 98.582032 98.582032 54.445312 0 98.578124-44.136719 98.578124-98.582032-.058593-54.417968-44.160156-98.519531-98.582031-98.582031zm-.457031 91.53125c16.9375.003907 31.101562 12.871094 32.71875 29.730469 1.617188 16.863281-9.839844 32.191406-26.46875 35.410156v9.21875c0 3.867188-3.132812 7-7 7s-7-3.132812-7-7v-9.546875c-14.730469-3.589843-25.101562-16.777343-25.121094-31.9375 0-3.867187 3.132813-7 7-7 3.863282 0 7 3.132813 7 7 0 10.421875 8.449219 18.871094 18.871094 18.871094s18.871094-8.449219 18.871094-18.871094c0-10.425781-8.449219-18.875-18.871094-18.875-16.644531 0-30.660156-12.441406-32.636719-28.964843-1.976562-16.527344 8.710938-31.921876 24.886719-35.847657v-8.828125c0-3.863281 3.132812-7 7-7s7 3.136719 7 7v8.5c15.445312 3.011719 26.601562 16.535156 26.621094 32.269532 0 3.867187-3.132813 7-7 7-3.863282 0-7-3.132813-7-7-.011719-10.292969-8.269532-18.683594-18.566406-18.855469-.347657.054687-.699219.085937-1.054688.085937-.21875 0-.433594-.011718-.648438-.03125-10.117187.753906-17.828124 9.371094-17.449218 19.511719.375 10.136719 8.703125 18.160156 18.851562 18.160156zm0 0',
      'm252.230469 436.085938c-11.65625-11.578126-18.214844-27.328126-18.230469-43.757813v-28.042969h-234v28.160156c.117188 26.429688 21.5625 47.804688 47.992188 47.839844h208.976562c-1.714844-1.238281-3.304688-2.644531-4.738281-4.199218zm0 0',
      'm270.5 159.285156h-84.78125c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h77.109375c-6.765625-14.664062-10.246094-30.628906-10.199219-46.78125.023438-7.800781.832032-15.578125 2.414063-23.21875h-188.390625l-1.851563 275h176.558594c1.78125-.070312 3.507813.601563 4.769531 1.859375 1.261719 1.257813 1.9375 2.984375 1.871094 4.765625v35.425782c.128906 26.488281 21.613281 47.902343 48.101562 47.949218h.15625c26.386719-.054687 47.742188-21.457031 47.742188-47.839844v-182.890624c-30.441406-5.765626-57.089844-23.988282-73.5-50.269532zm-136.132812 156.367188c-13.566407 0-24.605469-10.675782-24.605469-23.796875 0-13.117188 11.039062-23.792969 24.605469-23.792969 13.566406 0 24.609374 10.675781 24.609374 23.792969 0 13.121093-11.039062 23.796875-24.609374 23.796875zm0-69.539063c-13.566407 0-24.605469-10.671875-24.605469-23.792969 0-13.121093 11.039062-23.792968 24.605469-23.792968 13.566406 0 24.609374 10.671875 24.609374 23.792968 0 13.117188-11.039062 23.792969-24.609374 23.792969zm0-69.535156c-13.566407 0-24.605469-10.675781-24.605469-23.792969 0-13.121094 11.039062-23.796875 24.605469-23.796875 13.566406 0 24.609374 10.671875 24.609374 23.792969s-11.039062 23.796875-24.609374 23.796875zm158.007812 122.707031h-106.65625c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h106.65625c3.867188 0 7 3.136719 7 7 0 3.867188-3.132812 7-7 7zm7-77c0 3.867188-3.132812 7-7 7h-106.65625c-3.867188 0-7-3.132812-7-7 0-3.863281 3.132812-7 7-7h106.65625c3.867188 0 7 3.136719 7 7zm0 0',
    ],
    viewBox: '0 -12 463.791 463',
  },
  BOX: {
    paths: [
      'm16.12 1.929-10.891 5.576-4.329-2.13 10.699-5.283c.24-.122.528-.122.78 0z',
      'm23.088 5.375-11.082 5.49-4.15-2.045-.6-.305 10.903-5.575.6.304z',
      'm11.118 12.447-.012 11.553-10.614-5.539c-.3-.158-.492-.475-.492-.816v-10.688l4.498 2.216v3.896c0 .499.408.913.9.913s.9-.414.9-.913v-2.995l.6.292z',
      'm23.988 6.969-11.07 5.466-.012 11.553 11.094-5.793z',
    ],
    viewBox: '0 0 24 24',
  },
  CAMERA: {
    // width=height
    // path no fill
    // fill: theme.colors.border.light
    paths: [
      'M23.921,20.528c0,3.217-2.617,5.834-5.834,5.834s-5.833-2.617-5.833-5.834s2.616-5.834,5.833-5.834 S23.921,17.312,23.921,20.528z M36.174,12.244v16.57c0,2.209-1.791,4-4,4H4c-2.209,0-4-1.791-4-4v-16.57c0-2.209,1.791-4,4-4h4.92 V6.86c0-1.933,1.566-3.5,3.5-3.5h11.334c1.934,0,3.5,1.567,3.5,3.5v1.383h4.92C34.383,8.244,36.174,10.035,36.174,12.244z M26.921,20.528c0-4.871-3.963-8.834-8.834-8.834c-4.87,0-8.833,3.963-8.833,8.834s3.963,8.834,8.833,8.834 C22.958,29.362,26.921,25.399,26.921,20.528z',
    ],
    viewBox: '0 0 36.174 36.174',
  },
  CONNECT: {
    paths: [
      'm362 302c-16.539062 0-30 13.460938-30 30s13.460938 30 30 30 30-13.460938 30-30-13.460938-30-30-30zm0 0',
      'm512 362c0-82.703125-67.296875-150-150-150-48.050781 0-90.417969 23.121094-117.890625 58.375l-44.621094-29.417969c6.542969-13.679687 10.511719-28.808593 10.511719-44.957031 0-9.871094-1.808594-19.242188-4.367188-28.304688l109.492188-49.21875c13.617188 19.007813 35.761719 31.523438 60.875 31.523438 41.351562 0 75-33.648438 75-75s-33.648438-75-75-75-75 33.648438-75 75c0 5.535156.695312 10.898438 1.839844 16.097656l-109.242188 49.105469c-18.617187-29.457031-51.253906-49.203125-88.597656-49.203125-57.890625 0-105 47.109375-105 105s47.109375 105 105 105c30.960938 0 58.546875-13.730469 77.785156-35.121094l45.320313 29.882813c-9.976563 20.0625-16.105469 42.351562-16.105469 66.238281 0 84.164062 70.5625 150 150 150 79.214844 0 150-65.523438 150-150zm-92.148438 104.472656c-6.867187-25.890625-30.175781-44.472656-57.851562-44.472656s-50.984375 18.582031-57.851562 44.472656c-9.15625-5.09375-17.75-11.023437-25.242188-18.242187 13.40625-33.210938 45.476562-56.230469 83.09375-56.230469-33.089844 0-60-26.910156-60-60s26.910156-60 60-60 60 26.910156 60 60-26.910156 60-60 60c37.617188 0 69.6875 23.019531 83.09375 56.230469-7.492188 7.222656-16.085938 13.148437-25.242188 18.242187zm0 0',
    ],
    viewBox: '0 0 512 512',
  },
  CHECK: {
    // width=height
    // path no fill
    // fill: theme.colors.border.light
    paths: [
      'M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288 S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6 c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344 c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z',
    ],
    viewBox: '0 0 408.576 408.576',
  },
  USER_OUTLINE: {
    // fill:none
    // path fill=#595959
    paths: [
      'M15.2227 18C14.8343 18 14.5195 17.6852 14.5195 17.2969C14.5195 14.5442 12.28 12.3047 9.52734 12.3047H8.47266C5.71996 12.3047 3.48047 14.5442 3.48047 17.2969C3.48047 17.6852 3.16568 18 2.77734 18C2.38901 18 2.07422 17.6852 2.07422 17.2969C2.07422 13.7688 4.94455 10.8984 8.47266 10.8984H9.52734C13.0554 10.8984 15.9258 13.7688 15.9258 17.2969C15.9258 17.6852 15.611 18 15.2227 18Z',
      'M8.92969 9.49219C6.31269 9.49219 4.18359 7.36309 4.18359 4.74609C4.18359 2.1291 6.31269 0 8.92969 0C11.5467 0 13.6758 2.1291 13.6758 4.74609C13.6758 7.36309 11.5467 9.49219 8.92969 9.49219ZM8.92969 1.40625C7.0881 1.40625 5.58984 2.9045 5.58984 4.74609C5.58984 6.58768 7.0881 8.08594 8.92969 8.08594C10.7713 8.08594 12.2695 6.58768 12.2695 4.74609C12.2695 2.9045 10.7713 1.40625 8.92969 1.40625Z',
    ],
    viewBox: '0 0 18 18',
  },
  GLOBAL: {
    paths: [
      'm231.816406 447.050781c34.230469-4.863281 64.238282-40.589843 83.121094-93.351562-27.296875-6.113281-55.152344-9.394531-83.121094-9.785157zm0 0',
      'm286.503906 438.660156c2.023438-.585937 4.039063-1.175781 6.039063-1.824218 1.6875-.542969 3.351562-1.128907 5.015625-1.710938 1.96875-.679688 3.929687-1.378906 5.882812-2.121094 1.664063-.632812 3.3125-1.304687 4.964844-1.976562 1.90625-.800782 3.808594-1.597656 5.691406-2.398438 1.644532-.722656 3.277344-1.480468 4.910156-2.242187 1.847657-.871094 3.6875-1.757813 5.511719-2.679688 1.601563-.816406 3.199219-1.648437 4.800781-2.503906 1.792969-.960937 3.574219-1.941406 5.34375-2.949219 1.601563-.90625 3.144532-1.816406 4.703126-2.753906 1.734374-1.066406 3.460937-2.132812 5.175781-3.199219 1.535156-.976562 3.066406-1.96875 4.578125-2.992187 1.6875-1.136719 3.351562-2.320313 5.007812-3.503906 1.488282-1.066407 2.96875-2.128907 4.421875-3.199219 1.601563-1.234375 3.242188-2.511719 4.847657-3.792969 1.425781-1.136719 2.847656-2.265625 4.25-3.433594 1.597656-1.328125 3.128906-2.703125 4.679687-4.078125 1.359375-1.207031 2.726563-2.402343 4.054687-3.640625 1.527344-1.425781 3.015626-2.902344 4.503907-4.367187 1.289062-1.273438 2.59375-2.527344 3.855469-3.832031.234374-.242188.457031-.503907.699218-.753907-17.449218-9.011719-35.828125-16.085937-54.816406-21.09375-9.875 31.492188-27.449219 60.03125-51.128906 83.023438.648437-.167969 1.296875-.304688 1.945312-.472657 1.710938-.480468 3.390625-1.007812 5.0625-1.503906zm0 0',
      'm447.632812 231.683594h-95.921874c-.363282 37.453125-5.796876 74.683594-16.152344 110.679687 21.117187 5.640625 41.496094 13.75 60.714844 24.160157 31.554687-38.03125 49.617187-85.449219 51.359374-134.839844zm0 0',
      'm231.816406 215.683594h103.894532c-.40625-36.128906-5.707032-72.035156-15.75-106.742188-28.929688 6.597656-58.476563 10.121094-88.144532 10.511719zm0 0',
      'm231.816406.316406v103.136719c27.96875-.394531 55.824219-3.671875 83.121094-9.785156-18.882812-52.761719-48.890625-88.488281-83.121094-93.351563zm0 0',
      'm231.816406 327.914062c29.667969.394532 59.214844 3.917969 88.144532 10.515626 10.042968-34.707032 15.34375-70.617188 15.75-106.746094h-103.894532zm0 0',
      'm396.273438 80.84375c-19.21875 10.410156-39.597657 18.519531-60.714844 24.160156 10.355468 35.996094 15.789062 73.226563 16.152344 110.679688h95.921874c-1.746093-49.386719-19.808593-96.804688-51.359374-134.839844zm0 0',
      'm385.464844 68.707031c-.234375-.238281-.457032-.496093-.6875-.742187-1.265625-1.304688-2.578125-2.5625-3.867188-3.832032-1.484375-1.464843-2.964844-2.945312-4.496094-4.367187-1.324218-1.234375-2.695312-2.402344-4.054687-3.632813-1.550781-1.375-3.101563-2.761718-4.695313-4.089843-1.382812-1.167969-2.800781-2.285157-4.207031-3.40625-1.601562-1.296875-3.242187-2.585938-4.890625-3.824219-1.445312-1.089844-2.910156-2.144531-4.382812-3.199219-1.671875-1.203125-3.351563-2.402343-5.054688-3.546875-1.496094-1.007812-3.015625-1.992187-4.535156-2.957031-1.730469-1.113281-3.457031-2.203125-5.21875-3.257813-1.542969-.925781-3.101562-1.839843-4.664062-2.726562-1.789063-1.023438-3.582032-2.015625-5.390626-2.984375-1.601562-.800781-3.199218-1.671875-4.800781-2.472656-1.839843-.929688-3.695312-1.824219-5.597656-2.703125-1.601563-.761719-3.226563-1.503906-4.875-2.226563-1.894531-.839843-3.808594-1.597656-5.71875-2.398437-1.648437-.671875-3.289063-1.335938-4.953125-1.96875-1.941406-.742188-3.910156-1.4375-5.878906-2.117188-1.664063-.585937-3.328125-1.167968-5.015625-1.714844-2-.648437-4-1.238281-6.054688-1.832031-1.664062-.488281-3.335937-.984375-5.019531-1.429687-.644531-.175782-1.300781-.3125-1.949219-.480469 23.679688 22.992187 41.25 51.527344 51.128907 83.023437 19.007812-5.007812 37.414062-12.085937 54.878906-21.113281zm0 0',
      'm0 215.683594h95.921875c.363281-37.453125 5.796875-74.683594 16.148437-110.679688-21.113281-5.636718-41.492187-13.746094-60.710937-24.160156-31.554687 38.03125-49.617187 85.453125-51.359375 134.839844zm0 0',
      'm215.816406 447.050781v-103.136719c-27.96875.394532-55.824218 3.671876-83.121094 9.785157 18.878907 52.761719 48.890626 88.488281 83.121094 93.351562zm0 0',
      'm215.816406 231.683594h-103.894531c.40625 36.128906 5.703125 72.039062 15.75 106.746094 28.929687-6.601563 58.472656-10.125 88.144531-10.515626zm0 0',
      'm215.816406.316406c-34.230468 4.863282-64.242187 40.589844-83.121094 93.351563 27.296876 6.117187 55.152344 9.394531 83.121094 9.785156zm0 0',
      'm215.816406 119.453125c-29.667968-.390625-59.214844-3.914063-88.144531-10.511719-10.046875 34.707032-15.34375 70.613282-15.75 106.742188h103.894531zm0 0',
      'm168.113281 6.789062c-.648437.167969-1.296875.304688-1.945312.472657-1.695313.453125-3.367188.957031-5.054688 1.445312-2.007812.585938-4 1.175781-6.015625 1.816407-1.699218.550781-3.371094 1.136718-5.042968 1.71875-1.957032.691406-3.917969 1.378906-5.855469 2.113281-1.671875.640625-3.320313 1.304687-4.976563 1.984375-1.902344.800781-3.808594 1.601562-5.6875 2.398437-1.648437.722657-3.277344 1.480469-4.910156 2.242188-1.847656.871093-3.6875 1.757812-5.511719 2.679687-1.601562.816406-3.203125 1.652344-4.800781 2.503906-1.792969.960938-3.570312 1.945313-5.335938 2.953126-1.601562.894531-3.175781 1.8125-4.71875 2.75-1.738281 1.046874-3.457031 2.128906-5.167968 3.199218-1.539063.984375-3.066406 1.976563-4.578125 3-1.6875 1.136719-3.351563 2.320313-5.007813 3.503906-1.488281 1.066407-2.96875 2.128907-4.425781 3.203126-1.636719 1.230468-3.199219 2.511718-4.847656 3.789062-1.421875 1.136719-2.855469 2.265625-4.246094 3.441406-1.601563 1.320313-3.121094 2.6875-4.664063 4.054688-1.367187 1.21875-2.746093 2.402344-4.082031 3.664062-1.519531 1.417969-3 2.890625-4.484375 4.351563-1.289062 1.273437-2.601562 2.53125-3.867187 3.839843-.230469.242188-.453125.507813-.695313.753907 17.445313 9.015625 35.828125 16.089843 54.816406 21.097656 9.875-31.476563 27.4375-59.996094 51.105469-82.976563zm0 0',
      'm66.71875 383.234375c1.488281 1.464844 2.96875 2.945313 4.496094 4.371094 1.328125 1.230469 2.695312 2.398437 4.058594 3.628906 1.550781 1.378906 3.101562 2.761719 4.695312 4.089844 1.382812 1.160156 2.792969 2.28125 4.207031 3.40625 1.601563 1.296875 3.199219 2.585937 4.894531 3.832031 1.441407 1.082031 2.90625 2.128906 4.371094 3.203125 1.671875 1.199219 3.359375 2.398437 5.0625 3.550781 1.496094 1.007813 3.015625 1.992188 4.535156 2.960938 1.730469 1.109375 3.457032 2.199218 5.21875 3.253906 1.542969.929688 3.101563 1.839844 4.664063 2.730469 1.789063 1.023437 3.582031 2.015625 5.390625 2.980469 1.597656.800781 3.199219 1.671874 4.800781 2.472656 1.839844.929687 3.695313 1.824218 5.597657 2.707031 1.601562.753906 3.226562 1.496094 4.875 2.222656 1.894531.839844 3.804687 1.597657 5.71875 2.398438 1.648437.671875 3.289062 1.335937 4.953124 1.96875 1.941407.746093 3.910157 1.441406 5.878907 2.121093 1.664062.582032 3.328125 1.167969 5.015625 1.710938 2 .648438 4 1.242188 6.054687 1.832031 1.664063.488281 3.335938.984375 5.015625 1.433594.648438.175781 1.304688.3125 1.953125.480469-23.679687-22.996094-41.25-51.53125-51.128906-83.027344-18.988281 5.011719-37.371094 12.085938-54.816406 21.105469.234375.238281.457031.496093.691406.742187 1.199219 1.296875 2.492187 2.554688 3.796875 3.824219zm0 0',
      'm51.359375 366.523438c19.21875-10.410157 39.597656-18.519532 60.710937-24.160157-10.351562-35.996093-15.785156-73.226562-16.148437-110.679687h-95.921875c1.746094 49.386718 19.804688 96.804687 51.359375 134.839844zm0 0',
    ],
    viewBox: '0 0 447.632 447',
  },
  LOCATION: {
    paths: [
      'm12 0c-4.962 0-9 4.066-9 9.065 0 7.103 8.154 14.437 8.501 14.745.143.127.321.19.499.19s.356-.063.499-.189c.347-.309 8.501-7.643 8.501-14.746 0-4.999-4.038-9.065-9-9.065zm0 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z',
    ],
    viewBox: '0 0 24 24',
  },
  LOCATION2: {
    // width=height=24
    // fill:none
    // path fill:#595959
    paths: [
      'M19.1773 2.9731C17.2601 1.05586 14.711 0 11.9997 0C9.28833 0 6.73917 1.05586 4.82198 2.9731C2.90474 4.89038 1.84888 7.43945 1.84888 10.1508C1.84888 15.6357 7.03504 20.1978 9.82125 22.6487C10.2084 22.9893 10.5428 23.2835 10.8092 23.5323C11.1429 23.844 11.5713 23.9999 11.9996 23.9999C12.428 23.9999 12.8563 23.844 13.1901 23.5323C13.4565 23.2834 13.7909 22.9893 14.1781 22.6487C16.9643 20.1978 22.1504 15.6357 22.1504 10.1508C22.1504 7.43945 21.0946 4.89038 19.1773 2.9731ZM13.2495 21.5932C12.8538 21.9412 12.5121 22.2419 12.2304 22.505C12.101 22.6258 11.8983 22.6258 11.7688 22.505C11.4871 22.2418 11.1454 21.9412 10.7498 21.5931C8.13037 19.289 3.25466 15 3.25466 10.1508C3.25466 5.32885 7.17759 1.40592 11.9996 1.40592C16.8215 1.40592 20.7445 5.32885 20.7445 10.1508C20.7445 15 15.8688 19.289 13.2495 21.5932Z',
      'M12.0004 5.29395C9.53382 5.29395 7.5271 7.30063 7.5271 9.76725C7.5271 12.2339 9.53382 14.2405 12.0004 14.2405C14.4671 14.2405 16.4737 12.2339 16.4737 9.76725C16.4737 7.30063 14.4671 5.29395 12.0004 5.29395ZM12.0004 12.8346C10.309 12.8346 8.93298 11.4585 8.93298 9.7672C8.93298 8.07585 10.309 6.69978 12.0004 6.69978C13.6918 6.69978 15.0678 8.07585 15.0678 9.7672C15.0678 11.4585 13.6918 12.8346 12.0004 12.8346Z',
    ],
    viewBox: '0 0 24 24',
  },
  MESSAGE: {
    paths: [
      'm256 0c-141.484375 0-256 114.496094-256 256 0 44.902344 11.710938 88.757812 33.949219 127.4375l-32.984375 102.429688c-2.300782 7.140624-.410156 14.96875 4.894531 20.273437 5.253906 5.253906 13.0625 7.214844 20.273437 4.894531l102.429688-32.984375c38.679688 22.238281 82.535156 33.949219 127.4375 33.949219 141.484375 0 256-114.496094 256-256 0-141.484375-114.496094-256-256-256zm0 472c-40.558594 0-80.09375-11.316406-114.332031-32.726562-4.925781-3.078126-11.042969-3.910157-16.734375-2.078126l-73.941406 23.8125 23.8125-73.941406c1.804687-5.609375 1.042968-11.734375-2.082032-16.734375-21.40625-34.238281-32.722656-73.773437-32.722656-114.332031 0-119.101562 96.898438-216 216-216s216 96.898438 216 216-96.898438 216-216 216zm25-216c0 13.804688-11.191406 25-25 25s-25-11.195312-25-25c0-13.808594 11.191406-25 25-25s25 11.191406 25 25zm100 0c0 13.804688-11.191406 25-25 25s-25-11.195312-25-25c0-13.808594 11.191406-25 25-25s25 11.191406 25 25zm-200 0c0 13.804688-11.191406 25-25 25-13.804688 0-25-11.195312-25-25 0-13.808594 11.195312-25 25-25 13.808594 0 25 11.191406 25 25zm0 0',
    ],
    viewBox: '0 0 512 512.0002',
  },
  PLUS: {
    paths: [
      'm437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0',
    ],
    viewBox: '0 0 469.33333 469.33333',
  },
  PHONE: {
    // no height
    // fill:theme.colors.text.secondary
    // path fill:black
    paths: [
      'M20.557 5.59453L18.0609 3.10078C17.7562 2.79609 17.3484 2.625 16.9172 2.625C16.4859 2.625 16.0781 2.79375 15.7734 3.10078L13.0852 5.78437C12.7805 6.08906 12.6094 6.49922 12.6094 6.93047C12.6094 7.36406 12.7781 7.76953 13.0852 8.07656L15.1852 10.1789C14.7068 11.2936 14.0194 12.3063 13.1602 13.1625C12.3 14.0273 11.2945 14.7094 10.1789 15.1922L8.07891 13.0898C7.77422 12.7852 7.36641 12.6141 6.93516 12.6141C6.72244 12.6133 6.51172 12.655 6.31532 12.7366C6.11892 12.8183 5.9408 12.9384 5.79141 13.0898L3.10078 15.7734C2.79609 16.0781 2.625 16.4883 2.625 16.9195C2.625 17.3531 2.79375 17.7586 3.10078 18.0656L5.59453 20.5594C6.11484 21.0797 6.83203 21.3773 7.56797 21.3773C7.72031 21.3773 7.86797 21.3656 8.01797 21.3398C11.1211 20.8289 14.2008 19.1766 16.6875 16.6922C19.1719 14.2031 20.8219 11.1234 21.3375 8.01797C21.4852 7.13672 21.1898 6.22969 20.557 5.59453ZM19.6758 7.73906C19.2188 10.5023 17.7328 13.2586 15.4945 15.4969C13.2562 17.7352 10.5023 19.2211 7.73906 19.6781C7.39219 19.7367 7.03594 19.6195 6.78281 19.3687L4.33359 16.9195L6.93047 14.3203L9.73828 17.1328L9.75937 17.1539L10.2656 16.9664C11.8006 16.402 13.1945 15.5107 14.3508 14.354C15.507 13.1974 16.3979 11.8032 16.9617 10.268L17.1492 9.76172L14.318 6.93281L16.9148 4.33359L19.3641 6.78281C19.6172 7.03594 19.7344 7.39219 19.6758 7.73906Z',
    ],
    viewBox: '0 0 24 24',
  },
  TICK: {
    paths: [
      'M12 0C5.38346 0 0 5.38346 0 12C0 18.6165 5.38346 24 12 24C18.6165 24 24 18.6165 24 12C24 5.38346 18.6165 0 12 0ZM18.7068 8.84211L11.0376 16.4511C10.5865 16.9023 9.86466 16.9323 9.38346 16.4812L5.32331 12.782C4.84211 12.3308 4.81203 11.5789 5.23308 11.0977C5.68421 10.6165 6.43609 10.5865 6.91729 11.0376L10.1353 13.985L16.9925 7.12782C17.4737 6.64662 18.2256 6.64662 18.7068 7.12782C19.188 7.60902 19.188 8.3609 18.7068 8.84211Z',
    ],
    viewBox: '0 0 24 24',
  },
  SHIPPING: {
    // width=height=18
    // fill="none"
    // path fill="#39B54A"
    paths: [
      'M9.00002 5.02539C7.73147 5.02539 6.69946 6.05744 6.69946 7.32595C6.69946 8.59445 7.73147 9.6265 9.00002 9.6265C10.2686 9.6265 11.3006 8.59445 11.3006 7.32595C11.3006 6.05744 10.2685 5.02539 9.00002 5.02539Z',
      'M14.3833 2.22982C12.9454 0.791894 11.0336 0 9.00006 0C6.96655 0 5.05471 0.791894 3.61682 2.22982C2.17886 3.66778 1.38696 5.57958 1.38696 7.61305C1.38696 11.7268 5.27665 15.1484 7.36634 16.9866C7.6567 17.242 7.90747 17.4626 8.10719 17.6492C8.35754 17.8831 8.67873 18 9.00002 18C9.32128 18 9.64254 17.883 9.89285 17.6492C10.0926 17.4626 10.3434 17.2421 10.6337 16.9866C12.7234 15.1484 16.6131 11.7268 16.6131 7.61309C16.6131 5.57958 15.8212 3.66778 14.3833 2.22982ZM9.00006 10.6804C7.1501 10.6804 5.64509 9.17536 5.64509 7.3254C5.64509 5.47545 7.1501 3.97044 9.00006 3.97044C10.85 3.97044 12.355 5.47545 12.355 7.3254C12.355 9.17536 10.85 10.6804 9.00006 10.6804Z',
    ],
    viewBox: '0 0 18 18',
  },
};

export const ORDER_STATUS = {
  ERROR: -2,
  ALL: -1,
  PENDING: 0,
  PROCESSING: 1,
  DELIVERY: 2,
  CUSTOMER_CONFIRM: 3,
  REJECT: 4,
  CUSTOMER_DRAFT: 5,
  COMPLETE: 6,
  PRE_PROCESSING: 7,
  SUPPLIER_CONFIRM: 8,
  PENDING_EXECUTE: 9,
  SUPPLIER_DRAFT: 10,
  ADDITIONAL_ORDER: 11,
  MERGED: 12,
};

export const ORDER_STATUS_TEXT = {
  [ORDER_STATUS.ERROR]: 'Lỗi chưa liên kết SP',
  [ORDER_STATUS.ALL]: 'Tất cả trạng thái',
  [ORDER_STATUS.PENDING]: 'Chờ xử lý',
  [ORDER_STATUS.PROCESSING]: 'Đang xử lý',
  [ORDER_STATUS.DELIVERY]: 'Giao hàng',
  [ORDER_STATUS.CUSTOMER_CONFIRM]: 'Chờ KH xác nhận',
  [ORDER_STATUS.REJECT]: 'Từ chối',
  [ORDER_STATUS.CUSTOMER_DRAFT]: 'Bản nháp KH',
  [ORDER_STATUS.COMPLETE]: 'Hoàn thành',
  [ORDER_STATUS.PRE_PROCESSING]: 'Tiền xử lý',
  [ORDER_STATUS.SUPPLIER_CONFIRM]: 'Chờ NCC xác nhận',
  [ORDER_STATUS.PENDING_EXECUTE]: 'Chờ thực thi',
  [ORDER_STATUS.SUPPLIER_DRAFT]: 'Bản nháp NCC',
  [ORDER_STATUS.ADDITIONAL_ORDER]: 'Đơn bổ sung',
  [ORDER_STATUS.MERGED]: 'Đơn đã gộp',
};

export const REPORT_TYPE = {
  RELATIVE: 0,
  ABSOLUTE: 1,
};

export type ILoadingStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected';
interface ILoadingStatusObject {
  idle: ILoadingStatus;
  pending: ILoadingStatus;
  fulfilled: ILoadingStatus;
  rejected: ILoadingStatus;
}

export const LOADING_STATUS: ILoadingStatusObject = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

export type Nullable<T> = T | null;

export type IPagination = {
  current: number;
  next: number;
  prev: number;
  take: number;
  total: number;
};

export const REPORT_NAME = {
  0: 'SL tương đối',
  1: 'SL tuyệt đối',
};

export const QUANTITY = {
  0: 'Hết hàng',
  1: 'Có ít',
  2: 'Có vừa',
  3: 'Có nhiều',
};

export enum ENTITY_TYPE {
  USER = 0,
  FARM = 1,
  STORE = 2,
  COLLABORATOR = 3,
  WAREHOUSE = 4,
  TRANSPORT = 5,
  VOLUNTEER = 6,
  ANONYMOUS = 7,
}

export enum ENTITY_STATUS {
  ACTIVATED = 0,
  DEACTIVATED = 1,
  WAITING_FOR_APPROVAL = 2,
  REJECTED = 3,
}

export enum SIZE {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'LARGE',
}

export const ImageSize = {
  xxs: 160,
  xs: 450,
  sm: 720,
  md: 1080,
  lg: 1920,
};

export const IMAGETYPE: IMAGETYPETYPE = {
  CERTIFICATION: {
    sm: 'sm',
    md: 'md',
  },
  CATEGORY: {
    xxs: 'xxs',
    xs: 'xs',
  },
  SUB_CATEGORY: {
    xxs: 'xxs',
    xs: 'xs',
  },
  AVATAR: {
    xxs: 'xxs',
    xs: 'xs',
  },
  LOGO: {
    xxs: 'xxs',
    xs: 'xs',
  },
  PRODUCT: {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
  },
  AREA: {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
  },
  BRANCH: {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
  },
  PRODUCT_OBJECT: {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
  },
  BANNER: {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  },
  GALLERY: {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  },
  BLOG: {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  },
};

export interface IMAGETYPETYPE {
  CERTIFICATION: {
    sm: 'sm';
    md: 'md';
  };
  CATEGORY: {
    xxs: 'xxs';
    xs: 'xs';
  };
  SUB_CATEGORY: {
    xxs: 'xxs';
    xs: 'xs';
  };
  AVATAR: {
    xxs: 'xxs';
    xs: 'xs';
  };
  LOGO: {
    xxs: 'xxs';
    xs: 'xs';
  };
  PRODUCT: {
    xxs: 'xxs';
    xs: 'xs';
    sm: 'sm';
  };
  AREA: {
    xxs: 'xxs';
    xs: 'xs';
    sm: 'sm';
  };
  BRANCH: {
    xxs: 'xxs';
    xs: 'xs';
    sm: 'sm';
  };
  PRODUCT_OBJECT: {
    xxs: 'xxs';
    xs: 'xs';
    sm: 'sm';
  };
  BANNER: {
    xs: 'xs';
    sm: 'sm';
    md: 'md';
    lg: 'lg';
  };
  GALLERY: {
    xs: 'xs';
    sm: 'sm';
    md: 'md';
    lg: 'lg';
  };
  BLOG: {
    xs: 'xs';
    sm: 'sm';
    md: 'md';
    lg: 'lg';
  };
}
