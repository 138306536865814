import { Text } from 'farmhub-ui-core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ width?: number | string }>`
  display: flex;
  align-items: center;
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width || 240}px`};
  height: auto;
  flex-shrink: 0;
`;

export const ImgWrapper = styled.div`
  position: relative;
`;

export const Img = styled.img<{
  entityType?: number;
  objectFit?: string;
  size: number;
}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${p => (p.entityType != null ? '50%' : '6px')};
  flex-shrink: 0;
  object-fit: ${p => p.objectFit || 'cover'};
`;

export const EntityTypeIconStyles = css`
  background: #fafafa;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primary.main};
  position: absolute;
  right: 2px;
  bottom: 4px;
  transform: translate(30%, 30%);
`;

export const AvatarStyles = css`
  position: absolute;
  right: 0px;
  bottom: 7px;
  transform: translate(30%, 30%);
`;

export const DivInfo = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  margin-left: 12px;
`;

export const FakeImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #f6fcf9;
  border-radius: ${props => props.theme.radius.md};
`;

export const CLink = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primary.main};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;
