import React from 'react';

import { IconProps } from './Icon';

export const DraftIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    mirror-in-rtl="true"
  >
    <path d="M17.707 8.86l-5.756 5.662c-.392.387-2.485 1.8-2.882 1.41s1.052-2.437 1.445-2.824l5.756-5.662a1.023 1.023 0 0 1 1.43.007.983.983 0 0 1 .007 1.407z" />
    <path d="M12.6 0H1.4A1.417 1.417 0 0 0 0 1.43v15.14A1.417 1.417 0 0 0 1.4 18h11.2a1.417 1.417 0 0 0 1.4-1.43v-2.72l-1.39 1.39c-.02.02-1.93 1.76-3.35 1.76a1.22 1.22 0 0 1-.9-.35c-.8-.81-.06-2.08.26-2.63a9.97 9.97 0 0 1 1.16-1.61L14 8.19V1.43A1.417 1.417 0 0 0 12.6 0zM3.03 2H8a1 1 0 0 1 0 2H3.03a1 1 0 0 1 0-2zM7 12.01L3 12a1 1 0 1 1 0-1.99h4a1 1 0 0 1 0 2zM11 8H3a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z" />
  </svg>
);
