import { Button, ButtonProps } from 'farmhub-ui-core';
import React, { useEffect, useState } from 'react';

interface IProps extends ButtonProps {
  src?: any;
  rules?: Array<any>;
  children?: any;
}

const CButton = ({
  children,
  src,
  rules = [],
  loading,
  ...props
}: IProps): JSX.Element => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const handleValidate = (source: any, listRules: any): boolean => {
      if (!source || listRules.length === 0) {
        return false;
      }
      let isDisable = false;
      // eslint-disable-next-line consistent-return
      listRules.forEach((e: any) => {
        if (e.field === 'any' && Object.keys(source).length !== 0) {
          return false;
        }

        e.conditions.forEach((condition: any) => {
          switch (condition.name) {
            case 'minLength':
              if (
                !source[e.field] ||
                source[e.field].length < condition.value
              ) {
                isDisable = true;
              }
              break;

            case 'any':
              if (
                !source[e.field] ||
                source[e.field].length < condition.value
              ) {
                isDisable = true;
              }
              break;

            default:
              if (!source[e.field]) {
                isDisable = true;
              }
              break;
          }
        });
      });

      return isDisable;
    };

    const isDisable = handleValidate(src, rules);
    setDisabled(isDisable);
  }, [src, rules]);

  return (
    <Button disabled={disabled} loading={loading} height="50px" {...props}>
      {children}
    </Button>
  );
};

export default CButton;
