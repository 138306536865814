import { Badge, Tag } from 'antd';
import { Tooltip } from 'farmhub-ui-core';
import styled from 'styled-components';

import { theme } from 'styled';

const CTag = styled(Tag)<{
  height?: string;
  margin?: number;
  borderRadius?: string;
}>`
  position: relative;
  display: flex;
  height: ${p => p.height || '24px'};
  align-items: center;
  margin-bottom: ${p => p.margin}px;
  cursor: pointer;
  max-width: 240px;
  width: fit-content;
  flex-shrink: 0;
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.medium};
  border-radius: ${p => p.borderRadius || p.theme.radius.md};

  .text {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
`;

interface GroupTagProps {
  tooltipContent?: React.ReactNode;
  name?: string | React.ReactNode;
  active?: boolean;
  quantity?: number;
  icon?: React.ReactNode;
  margin?: number;
  onClick?: (e: any) => void;
  height?: string;
  borderRadius?: string;
  size?: 'small' | 'default' | undefined;
  offset?: [number, number];
}

const GroupTag = ({
  tooltipContent,
  name,
  quantity,
  icon,
  active,
  margin = 14,
  onClick,
  height,
  borderRadius,
  size = 'default',
  offset,
}: GroupTagProps): JSX.Element => (
  <Badge offset={offset || [-16, -2]} size={size} count={quantity}>
    <CTag
      color={active ? theme.colors.success : undefined}
      onClick={onClick}
      icon={icon}
      height={height}
      margin={margin}
      borderRadius={borderRadius}
    >
      <Tooltip title={tooltipContent}>
        <span className="span text">{name}</span>
      </Tooltip>
    </CTag>
  </Badge>
);

export default GroupTag;
