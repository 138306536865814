import {
  CaretDownOutlined,
  Dropdown,
  Icon,
  Row,
  Space,
  Text,
} from 'farmhub-ui-core';
import * as React from 'react';
import { css } from 'styled-components';

import { Action } from 'components';
import {
  BoxColorIcon,
  FarmerColorIcon,
  PeopleLinkColorIcon,
  ShopColorIcon,
  TruckColorIcon,
  UserColorIcon,
  VolunteerColorIcon,
} from 'components/Icon';
import { COLLABORATOR_TEXT } from 'utils/constants';

const IconStyles = css`
  margin-right: 6px;
`;

const AccountSelector = ({
  entityType,
  setEntityType,
  entityTypesToSelect,
}: AccountSelectorProps): JSX.Element => {
  const getDropdownItemProps = (
    icon: React.ReactNode,
    pathname: string
  ): any => {
    return {
      icon: { children: icon },
      key: pathname,
    };
  };

  const getActionDataByEntityType = (type: string): any => {
    switch (type) {
      case 'all':
        return {
          displayText: 'Tất cả tài khoản',
          icon: <UserColorIcon />,
        };
      case 'farm':
        return {
          displayText: 'Tài khoản nông trại',
          icon: <FarmerColorIcon />,
        };
      case 'store':
        return {
          displayText: 'Tài khoản cửa hàng',
          icon: <ShopColorIcon />,
        };
      case 'transport':
        return {
          displayText: 'Tài khoản vận chuyển',
          icon: <TruckColorIcon />,
        };
      case 'warehouse':
        return {
          displayText: 'Tài khoản kho chứa',
          icon: <BoxColorIcon />,
        };
      case 'collaborator':
        return {
          displayText: `Tài khoản ${COLLABORATOR_TEXT.LOWERCASE}`,
          icon: <PeopleLinkColorIcon />,
        };
      case 'volunteer':
        return {
          displayText: 'Tài khoản thiện nguyện',
          icon: <VolunteerColorIcon />,
        };
      default:
        return {};
    }
  };

  const dropdownItems = React.useMemo(
    () =>
      (
        entityTypesToSelect || [
          'all',
          'farm',
          'store',
          'transport',
          'warehouse',
          'collaborator',
        ]
      ).map((type: string) => {
        const { displayText, icon } = getActionDataByEntityType(type);
        return (
          <Action
            key={type}
            onClick={() => setEntityType(type)}
            dropdownItemProps={getDropdownItemProps(icon, type)}
          >
            {displayText}
          </Action>
        );
      }),
    [setEntityType, entityTypesToSelect]
  );

  const getCurrentAccount = React.useCallback(() => {
    const { displayText, icon } = getActionDataByEntityType(entityType);
    return (
      <>
        <Icon styles={IconStyles}>{icon}</Icon>
        <Text color="primary" weight="bold" lineHeight="22px">
          {displayText}
        </Text>
      </>
    );
  }, [entityType]);

  return (
    <Dropdown
      items={dropdownItems}
      containerId="changeAccount"
      menu={{ width: '300px' }}
    >
      <Row width="fit-content" styles={{ cursor: 'pointer' }}>
        {getCurrentAccount()}
        <Space width={6} />
        <CaretDownOutlined />
      </Row>
    </Dropdown>
  );
};

export default AccountSelector;

export interface AccountSelectorProps {
  entityType: string;
  setEntityType: (value: string) => void;
  entityTypesToSelect?: string[];
}
