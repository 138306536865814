/* eslint-disable react/no-array-index-key */
import { LoadingOutlined, Space } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { IconWrapper } from 'components';
import { MoreIcon } from 'components/Icon';
import { ExtendedAction } from 'containers/WorkSpace/components';
import { SIZE } from 'utils/constants';

export type ActionTable =
  | {
      action?: any;
      icon: any;
      tooltip?: string;
      popover?: React.ReactNode;
      hoverColorIcon?: string;
      isLoading?: boolean;
    }
  | undefined;

interface IconActionTableProps {
  data: ActionTable[];
  extendedAction?: React.ReactNode;
  noteAction?: React.ReactNode;
  amountButton?: number;
  size?: SIZE;
  styles?: any;
  iconLoadingDefault?: JSX.Element;
}

const IconActionTable = ({
  data,
  extendedAction,
  noteAction,
  amountButton = 2,
  size = SIZE.DEFAULT,
  styles,
  iconLoadingDefault = (
    <LoadingOutlined className="icon" style={{ fontSize: 18 }} spin />
  ),
}: IconActionTableProps): JSX.Element => {
  const [showActionList, setShowActionList] = React.useState<any[]>([]);
  const [hiddenActionList, setHiddenActionList] = React.useState<any[]>([]);
  const [waitingShow, setWaitingShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    const initData = () => {
      const countShowItem = noteAction ? 1 : amountButton;

      const newShowActionList = data
        .slice(0, countShowItem)
        .map((item: any) => {
          return {
            ...item,
            icon: item?.isLoading ? iconLoadingDefault : item.icon,
            action: item?.isLoading ? null : item.action,
          };
        });

      const newHiddenActionList = data
        .slice(countShowItem)
        .map((item: any, index: number) => {
          return {
            key: index,
            name: item.tooltip,
            action: item.action,
            icon: item.icon,
            hoverColorIcon: item.hoverColorIcon,
            isLoading: item.isLoading,
          };
        });

      setWaitingShow(newHiddenActionList.some((item: any) => item?.isLoading));
      setShowActionList(newShowActionList);
      setHiddenActionList(newHiddenActionList);
    };

    initData();
  }, [data]);

  return (
    <Wrapper width="100%" style={styles}>
      {noteAction && (
        <>
          {noteAction} <Space width={size !== SIZE.SMALL ? 8 : 4} />
        </>
      )}
      {showActionList.map((item: any, index: number) => (
        <IconWrapper
          key={index}
          // styles={{
          //   'margin-right': size !== SIZE.SMALL ? '8px' : 0,
          // }}
          onClick={item.action}
          hoverBackground="rgba(57, 181, 74, 0.2)"
          size="sm"
          background="#F1F1F1"
          icon={item.icon}
          tooltip={item.tooltip}
          popover={item.popover}
          hoverColorIcon={item.hoverColorIcon}
        />
      ))}
      {hiddenActionList.length !== 0 && (
        <ExtendedAction
          component={
            <IconWrapper
              // onClick={(e: any) => {
              //   e.stopPropagation();
              // }}
              hoverBackground="rgba(57, 181, 74, 0.2)"
              size="sm"
              background="#F1F1F1"
              icon={
                waitingShow ? (
                  iconLoadingDefault
                ) : (
                  <MoreIcon
                    width={size === SIZE.SMALL ? 16 : 18}
                    height={size === SIZE.SMALL ? 16 : 18}
                  />
                )
              }
              tooltip="Thêm chức năng"
            />
          }
          data={hiddenActionList}
        />
      )}

      {extendedAction}
    </Wrapper>
  );
};

export default IconActionTable;

const Wrapper = styled.div<{ width: string }>`
  width: ${p => p.width};
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;
