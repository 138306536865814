import styled from 'styled-components';

const ActionButton = styled.button<{
  width?: string;
  height?: string;
  color?: string;
  weight?: number;
  size?: number;
  radius?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '42px'};
  border-radius: ${props => props.radius || '6px'};
  border: none;
  font-size: ${props => props.size || 14}px;
  font-weight: ${props => props.weight || props.theme.font.weight.regular};
  padding: 0px 14px;
  cursor: pointer;
  span {
    color: ${props => props.color || props.theme.colors.text.primary};
  }
  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  background: ${props => props.theme.colors.dropdown.main};
`;

export default ActionButton;
