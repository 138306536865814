import { serialize } from '../utils/crud';
import { FindOptions } from './common';
import { entityService } from './services';

const pathname = '/user-sms-accounts';

export const UserSMSAccountApi = {
  findForAdmin: async (option: FindOptions) => {
    let url = `${pathname}/find-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findUserByAdmin: async (option: any) => {
    let url = `${pathname}/find-user-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  create: async (body: any) => {
    try {
      const response = await entityService.post(`${pathname}`, body);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findOne: async (id: string) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  update: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  remove: async (id: string) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.delete(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
