import { LeftCircleFilled, Tooltip, TooltipPlacement } from 'farmhub-ui-core';
import styled from 'styled-components';

import { NameText } from 'components';

interface BackMenuProps {
  isToggle: boolean;
  onClick: () => void;
}

const BackMenu = ({ isToggle, onClick }: BackMenuProps): JSX.Element => (
  <Tooltip title={!isToggle && 'Quay lại'} placement={TooltipPlacement.Right}>
    <WrapperBack onClick={onClick}>
      <LeftCircleFilled
        style={{
          fontSize: '24px',
          color: '#595959',
        }}
      />

      {isToggle && (
        <NameText weight="medium" className="text" style={{ marginLeft: 10 }}>
          Quay lại danh mục
        </NameText>
      )}
    </WrapperBack>
  </Tooltip>
);

export default BackMenu;

const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 18px;
  border-radius: 6px;
  cursor: pointer;
  .text {
    color: #595959;
    overflow: hidden;
    white-space: nowrap;
  }
  &:hover {
    background: #fafafa;
  }
`;
