/* eslint-disable no-useless-catch */
import { serialize } from '../utils';
import { FindOptions } from './common';
import { entityService } from './services';
// #endregion Local Imports

const pathname = '/sub-categories';

export const SubCategoryApi = {
  find: async (option: FindOptions) => {
    let url = pathname;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      throw error;
    }
  },
  findMasterData: async () => {
    const url = `${pathname}/master-data`;

    try {
      return await entityService.get(url);
    } catch (error) {
      throw error;
    }
  },
  create: async (body: any) => {
    try {
      const response = await entityService.post(`${pathname}`, body);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      throw error;
    }
  },
  remove: async (id: string) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  },
  switch: async (body: SwitchSubCategory) => {
    const url = `${pathname}/switch`;
    try {
      const response = await entityService.post(url, body);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export interface SwitchSubCategory {
  subCategoryIDs: string[];
  targetSubCategoryID: string;
}
