import { Input } from 'antd';
import { SearchOutlined } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { theme } from 'styled';

const Wrapper = styled(Input)<{
  background?: string;
  width: number | string;
  height: string;
  fontSize?: string;
  styles?: any;
}>`
  width: ${p => (typeof p.width == 'string' ? p.width : `${p.width}px`)};
  height: ${p => p.theme.input.height[p.height]};
  background: ${p => p.background} !important;

  .ant-input {
    font-size: ${p => p.theme.font.size[p.fontSize || 'xs']};
    background: ${p => p.background || '#fafafa'};

    &::placeholder {
      font-size: ${props => props.theme.font.size.xs} !important;
      color: ${props => props.theme.colors.text.secondary};
    }
  }

  ${p => p.styles}
`;

interface SearchTableProps {
  value?: string;
  placeholder: string;
  onChange?: (e: any) => void;
  width?: number | string;
  maxWidth?: number;
  height?: 'xs' | 'sm' | 'md' | 'lg';
  background?: string;
  fontSize?: string;
  suffix?: React.ReactNode;
  onEnter?: (e: any) => void;
  styles?: any;
  isFocus?: boolean;
}

const SearchTable = ({
  value = '',
  width,
  maxWidth,
  height = 'md',
  placeholder,
  onEnter,
  onChange = () => 0,
  background,
  fontSize,
  styles,
  suffix,
  isFocus,
}: SearchTableProps): JSX.Element => {
  const [isSearching, setIsSearching] = React.useState(true);
  const [valueChange, setValueChange] = React.useState('');

  React.useEffect(() => {
    setValueChange(value);
  }, [value]);

  const handleChange = (e): void => {
    setValueChange(e.target.value);

    // if (e.target.value.length === 0) {
    //   onChange(e);
    //   e.preventDefault();
    // }
  };

  const handlePressEnter = (e: any): void => {
    onChange(e);
    onEnter && onEnter(e);
    e.preventDefault();
  };

  React.useEffect(() => {
    if (isFocus) {
      const inputRef = document.getElementById('input-search');
      inputRef?.focus();
    }
  }, [isFocus]);

  return (
    <Wrapper
      width={width || (isSearching ? maxWidth || 240 : 42)}
      height={height}
      onFocus={() => setIsSearching(true)}
      onBlur={() => setIsSearching(true)}
      background={background || (isSearching ? '#fff' : '#fff')}
      onPressEnter={handlePressEnter}
      size="large"
      value={valueChange}
      onChange={handleChange}
      placeholder={width ? placeholder : isSearching ? placeholder : ''}
      prefix={
        <SearchOutlined
          style={{ fontSize: '18px', color: theme.colors.text.secondary }}
        />
      }
      suffix={suffix}
      fontSize={fontSize}
      allowClear
      styles={styles}
    />
  );
};

export default SearchTable;
