import * as React from 'react';

import { IconProps } from './Icon';

export const StopFilledIcon = ({
  width,
  height,
  className,
  color,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 419.84 419.84"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M209.92,0C93.696,0,0,93.696,0,209.92s93.696,209.92,209.92,209.92s209.92-93.696,209.92-209.92S326.144,0,209.92,0z
			 M292.352,268.288c0,12.8-10.24,23.552-23.552,23.552H151.552c-12.8,0-23.552-10.24-23.552-23.552l0.512-116.736
			c0-12.8,10.24-23.552,23.552-23.552H268.8c12.8,0,23.552,10.24,23.552,23.552V268.288z"
    />
  </svg>
);
