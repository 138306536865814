/* eslint-disable react/no-array-index-key */
import { Form } from 'antd';
import { CloseOutlined, Space, Spacing } from 'farmhub-ui-core';
import * as React from 'react';

import {
  CSelect,
  GroupTag,
  NameText,
  OrderStatus,
  OrderTable,
  SearchTable,
} from 'components';
import { theme } from 'styled';
import { SELECT_MODE, SIZE } from 'utils/constants';

import {
  WrapperFilter,
  WrapperIcon,
  WrapperStatus,
  WrapperTagsFilter,
} from './styles';

interface DataItem {
  loading?: boolean;
  name: string;
  list?: any;
  placeholder?: string;
  mode?: 'multiple' | 'tags' | undefined;
  disabled?: boolean;
}

interface FilterTableProps {
  name: string;
  searchName?: string;
  searchPlaceholder?: string;
  form: any;
  isDisplayFilter: boolean;
  data: DataItem[];
  extraAction?: React.ReactNode;
  extraFilter?: React.ReactNode;
  filterTagInfo?: any;
  padding?: string;
  handleFilterFormChange?: (e: any) => void;
  borderBottom?: boolean;
  searchWidth?: number;
  width?: string;
  size?: SIZE;
  initDataDefault?: (string | number)[];
  background?: string;
  initFilterMultiKey?: string[];
}

const FilterTable = ({
  name,
  searchName,
  searchPlaceholder,
  isDisplayFilter,
  data,
  form,
  padding,
  extraAction,
  extraFilter,
  filterTagInfo,
  handleFilterFormChange,
  borderBottom = true,
  searchWidth,
  size = SIZE.SMALL,
  initDataDefault = [],
  width = '100%',
  background = 'ffffff',
  initFilterMultiKey = [],
}: FilterTableProps): JSX.Element => {
  const [currentTags, setCurrentTags] = React.useState<string[]>(['all']);

  const handleChooseTag = (value: string) => {
    let newCurrentTags = [...currentTags];

    if (value === 'all') {
      newCurrentTags = ['all'];
    } else {
      if (newCurrentTags.includes('all')) {
        newCurrentTags.shift();
      }

      if (newCurrentTags.includes(value)) {
        if (currentTags.length === 1) {
          return;
        }
        newCurrentTags = newCurrentTags.filter(item => item !== value);
      } else {
        newCurrentTags.push(value);
      }
    }
    handleFilterFormChange &&
      handleFilterFormChange({
        [filterTagInfo.name]: newCurrentTags,
      });
    setCurrentTags(newCurrentTags);
  };

  const handleChangeFilter = (e = {}) => {
    const key = Object.keys(e)[0];
    const value: any = Object.values(e)[0];
    let newValue = e;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        newValue = {
          [key]: initDataDefault,
        };
        form.setFieldsValue(newValue);
      } else if (
        key === 'where.statuses' ||
        key === 'where.status' ||
        key === 'where.order.status.in' ||
        key === 'inOrderStatuses' ||
        initFilterMultiKey.includes(key)
      ) {
        const lastValue = value[value.length - 1];
        if (lastValue === 'all') {
          form.setFieldsValue({ [key]: ['all'] });
          newValue[key] = ['all'];
        } else {
          const clearString = value.filter(val => typeof val === 'number');

          form.setFieldsValue({ [key]: clearString });
          newValue[key] = clearString;
        }
      }
    }

    handleFilterFormChange && handleFilterFormChange(newValue);
  };

  const tagRender = (props: any): JSX.Element => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <WrapperStatus>
        <OrderStatus
          fontSize="12px"
          status={value}
          label={label}
          styles={{ 'line-height': size === SIZE.SMALL ? '18px' : '' }}
          padding={size === SIZE.SMALL ? '0px 7px' : ''}
        />
        <WrapperIcon onMouseDown={onPreventMouseDown} onClick={onClose}>
          <CloseOutlined />
        </WrapperIcon>
      </WrapperStatus>
    );
  };

  return (
    <Form style={{ width }} onValuesChange={handleChangeFilter} form={form}>
      <WrapperFilter
        width={width}
        background={background}
        borderBottom={filterTagInfo ? false : borderBottom}
        padding={padding}
        isDisplayFilter={isDisplayFilter}
      >
        <Spacing size={12}>
          {data.map((item: DataItem, index: number) => {
            if (item.name === 'order') {
              return (
                <Form.Item
                  key={index}
                  style={{ marginBottom: 0 }}
                  name={item.name}
                >
                  <OrderTable
                    height={theme.select.height.sm}
                    list={item.list}
                  />
                </Form.Item>
              );
            }
            return (
              <Form.Item
                key={index}
                style={{ marginBottom: 0 }}
                name={item.name}
                initialValue={item.list.length > 0 ? item.list[0].value : ''}
                className="select-options"
              >
                <CSelect
                  loading={item?.loading != undefined ? item?.loading : false}
                  mode={item.mode}
                  height="sm"
                  width={item.mode === SELECT_MODE.MULTIPLE ? '400px' : '180px'}
                  list={item.list}
                  placeholder={item.placeholder}
                  tagRender={tagRender}
                  disabled={item.disabled}
                />
              </Form.Item>
            );
          })}
          {extraFilter}
        </Spacing>
        <Space width={12} />
        <Spacing size={12}>
          {extraAction}
          {searchName && (
            <Form.Item
              name={searchName || 'search'}
              style={{ marginBottom: 0 }}
            >
              <SearchTable
                height={size === SIZE.SMALL ? 'sm' : 'md'}
                width={searchWidth}
                placeholder={searchPlaceholder || `Tìm theo tên ${name}`}
              />
            </Form.Item>
          )}
        </Spacing>
      </WrapperFilter>
      {filterTagInfo && (
        <WrapperTagsFilter borderBottom={borderBottom}>
          <NameText color="green">{filterTagInfo.title}:</NameText>
          <Space width={12} />
          <GroupTag
            onClick={() => handleChooseTag('all')}
            active={currentTags.includes('all')}
            margin={0}
            name="Tất cả"
          />
          {filterTagInfo?.list?.map(item => (
            <GroupTag
              onClick={() => handleChooseTag(item.value)}
              active={currentTags.includes(item.value)}
              margin={0}
              name={item.name}
            />
          ))}
        </WrapperTagsFilter>
      )}
    </Form>
  );
};

export default FilterTable;
