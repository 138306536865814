/* eslint-disable no-useless-catch */
import { ACTIVE_STATUS, serialize } from '../utils';
import { FindWithAuthOption } from './common';
import entityService from './services/entity';
// #endregion Local Imports

const pathnameAdmin = '/admin/entities';
const pathname = '/entities';

export const EntityApi = {
  findAdminDashboardTotal: async () => {
    const url = `${pathnameAdmin}/admin-dashboard`;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findEntitiesByAdmin: async (option: any) => {
    let url = `${pathnameAdmin}/find-entities-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findEntitiesInChainByAdmin: async (option: any) => {
    let url = `${pathnameAdmin}/find-entities-in-chain-by-admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findProvider: async (option: FindWithAuthOption = {}) => {
    let url = `${pathname}/provider-count`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  create: async (body: any) => {
    try {
      const response = await entityService.post(
        `${pathnameAdmin}/entity`,
        body
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createAnonymous: async (body: any) => {
    try {
      const response = await entityService.post(
        `${pathnameAdmin}/anonymous-entity`,
        body
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findOne: async (id: string, option: any = {}) => {
    let url = `${pathnameAdmin}/${id}`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  update: async (id: string, body: any = {}) => {
    const url = `${pathnameAdmin}/${id}`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  removeEntity: async (id: string) => {
    const url = `${pathnameAdmin}/entity/${id}`;

    try {
      const response = await entityService.delete(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateDomainStatus: async (id: string, body: any = {}) => {
    const url = `${pathnameAdmin}/${id}/domain-status`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateStatus: async (id: string, body: any = {}) => {
    const url = `${pathnameAdmin}/${id}/status-by-admin`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateOwner: async (id: string, body: { ownerID: string }) => {
    const url = `${pathnameAdmin}/${id}/owner`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateMultipleStatus: async (body: any = {}) => {
    const url = `${pathnameAdmin}/multiple-status-by-admin`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateAllStatus: async (body: any = {}) => {
    const url = `${pathnameAdmin}/all-status-by-admin`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  resetPasswordByAdmin: async (body: ResetPasswordByAdmin) => {
    const url = `${pathnameAdmin}/reset-password-by-admin`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getLocationID: async (provinceCode: string, districtCode: string) => {
    try {
      const response = await entityService.get(
        `/locations?where={"provinceCode":"${provinceCode}","districtCode":"${districtCode}"}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  findToCheckPhoneNumberExist: async (
    options: { phoneNumber: string },
    activated = true
  ) => {
    let url = `${pathname}/check-phone-number-exist`;
    let query = serialize({ ...options });
    if (activated) {
      query = serialize({
        ...options,
        where: { status: ACTIVE_STATUS.ACTIVATED },
      });
    }
    url += query;

    try {
      return await entityService.get(url);
    } catch (error) {
      throw error;
    }
  },

  createUserForAdmin: async (body: any) => {
    try {
      const response = await entityService.post(
        `${pathnameAdmin}/user-by-admin`,
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export interface ResetPasswordByAdmin {
  userID: string;
  newPassword: string;
}
