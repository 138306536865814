import { IconProps } from './Icon';

export const RejectOrderIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M345.6,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S416.179,256,345.6,256z M405.334,392.534
			H285.867c-4.71,0-8.533-3.823-8.533-8.533s3.823-8.533,8.533-8.533h119.467c4.71,0,8.533,3.823,8.533,8.533
			S410.044,392.534,405.334,392.534z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M394.172,121.327L275.473,2.629c-5.615-5.615-15.206-1.638-15.206,6.298v118.289c0,5.146,4.173,9.318,9.318,9.318h118.289
			C395.81,136.534,399.787,126.934,394.172,121.327z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M396.8,230.4v-68.267c0-4.71-3.823-8.533-8.533-8.533H251.734c-4.71,0-8.533-3.823-8.533-8.533V8.534
			c0-4.71-3.823-8.533-8.533-8.533H46.934C42.223,0,38.4,3.823,38.4,8.534v469.333c0,4.71,3.823,8.533,8.533,8.533l177.084,0.009
			c3.251,0,6.221-1.852,7.654-4.779c1.434-2.918,1.084-6.4-0.905-8.977c-19.465-25.165-29.892-55.253-30.225-87.091
			c-0.666-64.341,41.557-122.88,103.39-140.698c27.375-7.885,55.731-7.825,81.98-0.256c4.54,1.271,9.259-1.306,10.564-5.837
			C396.996,236.971,396.8,231.527,396.8,230.4z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
